import $ from 'jquery';
import _ from 'underscore';
import OpenLayers from 'lib/OpenLayers-2.12/OpenLayers.debug';
import Backbone from 'js/backbone';
import AIS from 'js/AIS';
import * as consts from 'js/consts';
import api from 'js/api';
import GeoObjects from 'js/model/GeoObjects';
import Shape from 'js/model/Shapes';
import 'js/view/pano/krpano.xml';
import embedpano from 'lib/krpano/krpano';

const panoStub = {
  isStub: true,
  get() {
  },
  set() {
  },
  call() {
  },
};

// регулярное выражение для извлечения даты из сессии
const dateRegExp = /20\d\d-\d\d-\d\d/;

const PanoView = Backbone.View.extend({
  events: {
    'click .brightness-button': 'toggleBrightness',
    'click .make-screenshot-button': 'makeScreenshot',
    'click .show-radar-position-button': 'showRadarPosition',
    'click .measure-button': 'toggleMeasure',
    'click .pano-measure-button': 'clickMeasure',
    'click .pano-measure-clear-button': 'clearMeasure',
    'click .pano-measure-close-button': 'toggleMeasure',
    'click .pano-measure-toggle-rotate-button': function () {
      this.toggleShouldMeasureOnRotate();
    },
    'change #pano-archive-select-main': function (event) {
      this.selectPanoArchive(event, 'main');
    },
    'change #pano-archive-select-extra': function (event) {
      this.selectPanoArchive(event, 'extra');
    },
  },
  measureFeatureShape: Shape.Line,
  measureFeature: null,
  shouldMeasureOnRotate: true,
  measureFeatureGeometryBackup: null,
  cube_template: _.template(
    `<krpano debugmode="true">
      <preview url="<%= url %>.tiles/cube/preview.jpg" />
      <image
        type="CUBE"
        multires="true"
        tilesize="512"
        progressive="true"
      >
        <level tiledimagewidth="2176" tiledimageheight="2176">
          <cube url="<%= url %>.tiles/cube/%s/l2/%v/l2_%s_%v_%h.jpg" />
        </level>
        <level tiledimagewidth="1152" tiledimageheight="1152">
          <cube url="<%= url %>.tiles/cube/%s/l1/%v/l1_%s_%v_%h.jpg" />
        </level>
      </image>

      <textstyle
        name="STYLE"
        font="Times"
        fontsize="12.0"
        bold="true"
        italic="false"
        background="true"
        backgroundcolor="0xFFFFFF"
        border="true"
        bordercolor="0x000000"
        textcolor="0x000000"
        alpha="1.0"
        blendmode="normal"
        effect=""
        origin="cursor"
        edge="top"
        textalign="none"
        xoffset="0"
        yoffset="-3"
        showtime="0.1"
        fadetime="0.0"
        fadeintime="0.0"
        noclip="true"
      />
    </krpano>`,
  ),
  sphere_template: _.template(
    `<krpano debugmode="true">
      <preview url="<%= url %>.tiles/preview.jpg" />
      <image
        type="SPHERE"
        multires="true"
        tilesize="875"
        progressive="true"
      >
        <level tiledimagewidth="7000" tiledimageheight="3500">
          <sphere url="<%= url %>.tiles/l3_%0v_%0h.jpg" />
        </level>
        <level tiledimagewidth="3500" tiledimageheight="1750">
          <sphere url="<%= url %>.tiles/l2_%0v_%0h.jpg" />
        </level>
        <level tiledimagewidth="1750" tiledimageheight="875">
          <sphere url="<%= url %>.tiles/l1_%0v_%0h.jpg" />
        </level>
      </image>

      <textstyle
        name="STYLE"
        font="Times"
        fontsize="12.0"
        bold="true"
        italic="false"
        background="true"
        backgroundcolor="0xFFFFFF"
        border="true"
        bordercolor="0x000000"
        textcolor="0x000000"
        alpha="1.0"
        blendmode="normal"
        effect=""
        origin="cursor"
        edge="top"
        textalign="none"
        xoffset="0"
        yoffset="-3"
        showtime="0.1"
        fadetime="0.0"
        fadeintime="0.0"
        noclip="true"
      />
    </krpano>`,
  ),
  panoTypeCache: {},

  initialize: function () {
    this.drawnPanoFeatures = [];
    this.panoNameObject = {
      roadAxis: {},
      get name() {
        return this.roadAxis.name || '';
      },
      get partName() {
        return this.roadAxis.part_name != null && this.roadAxis.part_name.trim().length > 0 ? ` (${this.roadAxis.part_name})` : '';
      },
      get startCoord() {
        return (this.roadAxis.linear_coord != null ? parseInt(this.roadAxis.linear_coord / 1000, 10).toString() : '');
      },
      get endCoord() {
        return (
          this.roadAxis.linear_coord != null
            ? (this.roadAxis.linear_coord % 1000).toString().padStart(3, '0')
            : ''
        );
      },
      get stationing() {
        return (`${this.startCoord}+${this.endCoord}`);
      },
      get fullName() {
        return (this.name + this.partName);
      },
    };

    if (!AIS.featureEnabled('disable_pano')) {
      embedpano(
        {
          html5: 'only+webgl',
          target: this.$el.find('.pano').attr('id'),
          bgcolor: '#FFFFFF',
          xml: '/krpano.xml',
          vars: { key: this.model.get('name') },
        },
      );
    }

    this.on('ready', this.onready, this);
    this.on('rotate', _.throttle(this.onrotate, 500), this);
    this.on('rotate', _.debounce(this.rotateMeasure, 500), this);
    this.model.on('click', this.click, this);
    this.on('onnewpano', this.onnewpano, this);
    this.on('onloadcomplete', this.showCrosshair, this);

    this.model.on('drawFeatures', this.drawFeatures, this);
    this.model.on('drawNavs', this.drawNavs, this);
    this.model.on('removeFeatures', this.removeFeatures, this);
    this.model.on('updatePanoLinearCoord', this.updatePanoLinearCoord, this);

    this.model.on('change:url', function (pano, value) {
      if (value) {
        this.changePano(pano.previous('azimuth'));
      }
    }, this);

    this.model.on('change:azimuth', this.onAzimuthChange, this);

    this.model.on('change:active', this.showCrosshair, this);

    this.boundKeyUp = _.bind(this.keyUp, this);
    this.model.on('change:measure', function (model, measure) {
      this.$el.find('.measure-button').toggleClass('btn-warning active', measure).blur();
      this.$el.find('.pano-measure').css('display', measure ? 'block' : 'none');
      this.clearMeasure();
      this.showCrosshair();
      if (measure) {
        $(document).on('keyup', this.boundKeyUp);
      } else {
        $(document).off('keyup', this.boundKeyUp);
      }
    }, this);
    this.model.on('change:brightness_adjusted', function (model, brightness_adjusted) {
      this.$el.find('.brightness-button').toggleClass('btn-warning active', brightness_adjusted).blur();
    }, this);
    this.model.on('change:pano_archive change:actual_pano', _.debounce(this.updatePanoArchiveSelect, 250), this);

    AIS.on('editor:close', function () {
      this.model.set({ angles: null, active: false });
    }, this);

    AIS.on('editor:close', this.showPanoFeatures, this);

    AIS.on('triangulate:active', function (state) {
      if (state) {
        this.model.set('visible', state);
      }
      this.model.set('active', state);
    }, this);

    AIS.on('road_locate:active', function (state) {
      if (this.model.isMain()) {
        if (state) {
          this.model.set('visible', state);
        }
        this.model.set('active', state);
      }
    }, this);

    AIS.on('map:click', function (lonlat) { // обработчик клика по карте
      this.model.set({ focus_both: lonlat }); // перемещаем обе панорамы
    }, this);

    AIS.on('geometry:measure:update', this.showPanoFeatures, this);

    /**
     * Хэндлер, отвечающий за скрытие и показывание слоёв на панорамах.
     */
    this.options.workset.on('change:visible', (layer, newVisibility) => {
      this.setFeaturesVisibility(
        this.model.get('pano_features_ids').filter(id => layer.idSet.has(id)),
        newVisibility,
      );
    });
  },

  /**
   * Изменилось ли горизонтальное направление взгляда панорамы с прошлой проверки.
   *
   * @param {Number} tolerance порог, после которого значение hlookat считается изменившимся
   * @returns {boolean} true если изменилось, иначе false
   */
  hasHlookatChanged(tolerance = 0.1) {
    if (!this.krpanoReady()) return false;

    const currentHlookat = this._krpano.get('view.hlookat');

    if (
      this.lastSeenHlookat == null
      || Math.abs(this.lastSeenHlookat - currentHlookat) >= tolerance
    ) {
      this.lastSeenHlookat = currentHlookat;
      return true;
    }

    return false;
  },

  /**
   * Коллбек, вызываемый при изменении азимута.
   */
  onAzimuthChange() {
    if (!this.krpanoReady()) return;
    this._krpano.set('locked', true);
    this._krpano.set('view.hlookat', this.hLookatFor(this.model));
    this._krpano.set('locked', false);
  },

  updateSaveImageName() {
    const kp = this.getKrpano();
    kp.set('layer[snapshot].filename', `${this.panoNameObject.fullName}, ${this.panoNameObject.stationing}`);
  },

  keyUp(event) {
    if (this.model.get('measure')) {
      switch (event.keyCode) {
        case 32:
          this.clickMeasure();
          break;
        case 10:
        case 13:
          this.toggleShouldMeasureOnRotate();
          break;
        default:
      }
    }
  },

  /**
   * Получить дату съемки панорамы из сессии.
   * @param {String} session - сессия съемки
   * @returns {String} - дата в представлении DD.MM.YYYY
   */
  dateFromSession: function (session) {
    if (!session) {
      return '';
    }
    const [year, month, day] = session.match(dateRegExp)[0].split('-', 3);
    return `${year}-${month}-${day}`;
  },

  /** Получаем запросом пикетаж, и обновляем данные о дорожной оси в самом окне панорамы. */
  async updatePanoLinearCoord() {
    try {
      if (Number.isNaN(parseInt(this.model.id, 10)) || !this.model.get('visible')) {
        return;
      }
      const pano_name = this.model.get('name');
      const el = $(`.${pano_name}-pano .pano-title-info`);
      let result = null;
      if (AIS.featureEnabled('linear_coord')) {
        result = await api.panoLinearCoord(this.model.id);
      }

      if (result) {
        this.panoNameObject.roadAxis = result;
        const panoInfo = `${this.panoNameObject.fullName}, <strong>${this.panoNameObject.stationing}</strong>`;
        el.html(`${panoInfo}, `);
        this.updateSaveImageName();
      }
    } catch (e) {
      console.error(e); // eslint-disable-line no-console
    }
  },
  changePano(saved_azimuth) {
    this.load();
    const azimuth = this.model.get('azimuth') || saved_azimuth;
    if (azimuth) {
      this.model.set('azimuth', azimuth);
    }
    this.updatePanoLinearCoord();
  },
  _crosshair(visible) {
    const kp = this.getKrpano();
    kp.set('layer[crosshair].visible', visible);
  },

  /**
   * Показать "прицел".
   *
   * Если панорама ещё не готова - подождать и попробовать ещё раз.
   */
  showCrosshair() {
    if (this.krpanoReady()) {
      this._crosshair(
        this.model.get('active')
        || this.model.get('measure'),
      );
    }
  },
  ref_hs_name(id) {
    return `ais_ref_${id}`;
  },
  /** Получение объекта
   * @returns {Object} - объект krpano
   */
  getKrpano() {
    if (this.krpanoReady()) {
      return this._krpano;
    }
    return panoStub;
  },
  /** Проверка, что элемент krpano создан и готов к использованию.
   * @returns {Boolean} - готов ли объект krpano к использованию
   */
  krpanoReady() {
    return this._krpano && ('call' in this._krpano);
  },
  /** Определение типа панорамы и выбор соответствующего шаблона.
   * @returns {string} - шаблон конфигурации krpano
   */
  getPanoTemplate() {
    const panoPath = this.model.get('url').split('/')[0];
    const url = `${AIS.environment.panoUrl}/${AIS.environment.panoProject}/${this.model.get('url')}`;
    let panoIsCube = this.panoTypeCache[panoPath];
    if (panoIsCube === undefined) {
      const xmlHttp = new XMLHttpRequest();
      const checkurl = `${url}.tiles/cube/preview.jpg`;
      xmlHttp.open('GET', checkurl, false);
      try {
        xmlHttp.send(null);
        if (xmlHttp.status / 100 < 4) {
          panoIsCube = true;
        }
      } catch (e) {
        panoIsCube = false;
      }
      this.panoTypeCache[panoPath] = panoIsCube;
    }
    return panoIsCube ? this.cube_template({ url }) : this.sphere_template({ url });
  },
  /** Загрузка панорамы. */
  load() {
    const hlookat = this.hLookatFor(this.model) || 0;
    const vlookat = this.krpanoReady() ? this.getKrpano().get('view.vlookat') : '';
    const xml_template = this.getPanoTemplate();
    const xml = `loadxml(${xml_template}, view.hlookat=${hlookat}&view.vlookat=${vlookat}, KEEPBASE, NOBLEND)`;

    if (this.krpanoReady()) {
      this.getKrpano().call(xml);
    } else {
      this.pendingXml = xml;
    }
  },
  /** Коллбек, вызываемый после того, как панорама была создана. */
  onready() {
    // eslint-disable-next-line prefer-destructuring
    this._krpano = this.$el.find('.pano').children().first()[0];
    if (this.pendingXml) {
      this.getKrpano().call(this.pendingXml);
      this.updateSaveImageName();
      this.pendingXml = null;
    }
    if (this.model.get('url')) {
      this.load();
    }
  },
  /** Коллбек, вызываемый при повороте панорамы. */
  onrotate() {
    const hlookat = this.getKrpano().get('view.hlookat');
    const course = this.model.get('course');
    const newAzimuth = course + hlookat;
    this.model.set({
      azimuth: newAzimuth,
    });
  },
  /** Метод, вызываемый после перехода на новую панораму.
   * Проверяет существование this.forcedPano и при необходимости
   * устанавливает нужные углы на панораме и центрирует карту.
   */
  onnewpano() {
    if ('isStub' in this.getKrpano()) {
      console.error('Using non-initialized pano!'); // eslint-disable-line no-console
      return;
    }
    if (
      this.forcedPano
      && this.forcedPano.original_id === this.model.get('original_id')
      && this.forcedPano.session === this.model.get('session')
    ) {
      this.setPanoAngles(this.forcedPano.angles);
      this.onrotate();
      AIS.trigger('map:center', this.model.get('x'), this.model.get('y'));
    }
    this.forcedPano = null;
  },
  /** Изменение панорамы. Проверяем, изменилась панорама или угол просмотра.
   * Вызываем необходимый колбек в зависимости от типа изменения.
   *
   * @param {number} original_id  идентификатор панорамы
   * @param {string} session      сессия, к которой принадлежит панорама (обычно дата съемки)
   * @param {Array} angles        углы просмотра
   */
  navigate(original_id, session, angles) {
    if (
      original_id === this.model.get('original_id')
      && session === this.model.get('session')
      && angles
    ) {
      this.setPanoAngles(angles);
      AIS.trigger('map:center', this.model.get('x'), this.model.get('y'));
      this.forcedPano = null;
    } else {
      this.forcedPano = {
        original_id,
        session,
        angles,
      };
      if (this.model.isMain()) {
        this.model.updateBothPanos({ session, original_id });
      } else {
        this.model.updatePano({ session, original_id });
      }
    }
  },
  /**
   * Показать фичи для отображения на панорамах.
   *
   * При каждом обновлении перерисовываем все ephemeras,
   * прячем все существующие фичи, для которых есть редактируемая копия,
   * снова показываем те фичи, которые более не редактируются.
   */
  showPanoFeatures() {
    if (!this.model.get('visible')) {
      // Не показывать фичи на панораме, если панорама скрыта
      return;
    }

    this.drawnPanoFeatures.forEach((name) => {
      this.removeHotspot(name);
    });
    this.drawnPanoFeatures = [];

    const panoFeatures = AIS.getPanoFeatures();
    const panoFeatureIds = panoFeatures.filter(
      feature => feature.model && typeof feature.model.id === 'number',
    ).map(
      feature => feature.model.id,
    );

    if (this.invisibleFeatures && this.invisibleFeatures.length) {
      this.setFeaturesVisibility(
        this.invisibleFeatures.filter(id => !panoFeatureIds.includes(id)),
        true,
      );
      this.invisibleFeatures = [];
    }
    this.invisibleFeatures = panoFeatureIds;
    this.setFeaturesVisibility(this.invisibleFeatures, false);

    panoFeatures.forEach((feature) => {
      if (!feature || !feature.geometry) return;

      const geojson = AIS.transformReaderGeoJSON.write(feature.geometry);

      if (geojson) {
        const name = `eph_${_.uniqueId()}`;
        this.ajaxProjection(name, geojson, feature);
      }
    });
  },

  /**
   * Обновить выпадающий список с историческими панорамами в связи с
   * обновлением списка.
   */
  updatePanoArchiveSelect: function () {
    const panoName = this.model.get('name');
    const select = $(`#pano-archive-select-${panoName}`);
    const pano_archive = new Map(this.model.get('pano_archive'));
    const has_archive_panos = (pano_archive.size > 0);
    const actual_pano = this.model.get('actual_pano') || {};

    // Очищаем выпадающий список и добавляем актуальную панораму в его начало.
    select.empty();
    $(`<option value="actual" class="pano-archive-actual">${this.dateFromSession(actual_pano.session)}</option>`).appendTo(select);

    // Конвертируем `Map` исторических панорам в массив, оставляем только нужные данные.
    const pano_archive_array = Array.from(pano_archive.values()).map(pano => [pano.id, this.dateFromSession(pano.session)]);

    // Сортируем массив исторических панорам по сессии (дате съемки),
    // свежие должны быть сначала, старые -- в конце.
    const pano_archive_sorted = pano_archive_array.sort((a, b) => {
      const date_a = a[1];
      const date_b = b[1];
      if (date_a === date_b) return 0;
      return (date_a > date_b) ? -1 : 1;
    });

    // Добавляем упорядоченные исторические панорамы в выпадающий список.
    pano_archive_sorted.forEach(([id, session_date]) => {
      $(`<option value="${id}">${session_date}</option>`).appendTo(select);
    });

    // Устанавливаем тот же элемент, что был выбран до смены панорамы.
    select.val(this.model.get('selected_id'));

    // В зависимости от наличия архивных панорам, делаем выпадающий список активным или неактивным.
    select.prop('disabled', !has_archive_panos);
  },

  /**
   * Хендлер, обрабатывающий события выбора значения из списка исторических панорам.
   *
   * @param {Event} event - событие изменения значения в выпадающем списке
   * @param {string} panoName - имя панорамы, для которой произошло событие ('main' или 'extra')
   */
  selectPanoArchive: function (event, panoName) {
    if (panoName !== this.model.get('name')) {
      return;
    }

    const { value } = event.target;
    const selected_id = this.model.get('selected_id');
    let original_id;
    let session;

    if (value === 'actual') {
      const actual_pano = this.model.get('actual_pano') || {};
      ({ original_id, session } = actual_pano);
    } else {
      const feature_id = Number(value);
      const pano_archive = new Map(this.model.get('pano_archive'));
      const pano = pano_archive.get(feature_id);
      ({ session, original_id } = pano);
    }

    if (selected_id === value) {
      return;
    }

    this.model.set('selected_id', value);
    this.navigate(original_id, session);
  },

  /** Получение spotName фичи по ее идентификатору.
   *
   * @param {number} id       идентификатор фичи
   * @returns {string} - spotName
   */
  getSpotNameById: id => `ais_${id}`,

  /** Удаление объектов с панорамы.
   *
   * @param {Array} feature_ids id фич, которые должны быть удалены
   */
  removeFeatures(feature_ids) {
    feature_ids.forEach((feature_id) => {
      this.removeHotspot(this.getSpotNameById(feature_id));
    });
  },

  /** Изменить видимость фич на панораме.
   *
   * @param {Array} featureIds id фич, видимость которых будет изменена
   * @param {Boolean} newVisibility новое значение видимости
   */
  setFeaturesVisibility(featureIds, newVisibility) {
    featureIds.forEach((featureId) => {
      this.setHotspotVisibility(this.getSpotNameById(featureId), newVisibility);
    });
  },
  /** Отрисовка всех фич на панораме.
   *
   * @param {Array} features - фичи, которые будут отрисованы на панораме.
   */
  drawFeatures(features) {
    features.forEach((feature) => {
      const spotName = this.getSpotNameById(feature.id);
      /* Всё это производим только для того, чтобы получить стили фичи. */
      const meta = AIS.meta.findByName(feature.layer);
      const layer = AIS.workset.get(meta.get('class'));
      const isLayerVisible = layer.get('visible');
      const collection = layer.getCollection();
      const model = collection.models.filter(model => model.id === feature.id)[0] || new (meta.getClass())();
      const style = model.getPanoStyle();
      /* Добавляем реакцию на нажатие на фичу на полигоне, если у фичи определён стиль. */
      if (style != null) {
        style.onclick = `js(AIS.pano_click(${feature.layer}, ${feature.id}))`;
      }

      switch (feature.type) {
        case 'Point': {
          /* Масштабируем иконку точки в зависимости от ее расстояния до центра панорамы. */
          style.scale = feature.distance < 10 ? 1 : 10.0 / feature.distance;
          const meta = model.getMeta();
          if (meta != null) {
            const { attributes } = meta;
            if (typeof attributes !== 'undefined') {
              const { table } = attributes;
              if (typeof table !== 'undefined' && table.toString().endsWith('_electro')) {
                style.scale *= 2;
              }
            }
          }
          this.drawPoint(spotName, feature.coords, style);
          break;
        }
        case 'LineString':
          this.drawLine(spotName, feature.coords, style);
          break;
        case 'Polygon':
          this.drawPolygon(spotName, feature.coords, style);
          break;
        default:
      }

      /* Если фича обновилась на слое, который сейчас скрыт, то скроем её. */
      if (!isLayerVisible) {
        this.setHotspotVisibility(spotName, false);
      }
    });
  },

  /** Отрисовка панорам на панораме.
   *
   * @param {Array} navs - фичи панорам.
   */
  drawNavs(navs) {
    navs.forEach((feature) => {
      const spotName = this.getSpotNameById(feature.id);
      const style = {
        url: '/icon/ellipse.png',
        alpha: 0.6,
        ath: feature.hlookat,
        atv: feature.vlookat,
        scale: feature.distance < 10 ? 1 : 10.0 / feature.distance,
        distorted: true,
        handcursor: true,
        onclick: `js(AIS.panoPanel.handleClickOnPanoHotspot(get('key'), ${feature.original_id}, ${feature.session}))`,
        zorder: 10,
        capture: false,
        renderer: 'css3d',
      };
      this.createHotspot(spotName, style);
    });
  },
  hLookatFor(hotspot) {
    return hotspot.get('azimuth') - this.model.get('course');
  },
  /**
   * Получить направление взгляда панорамы.
   * @returns {{hlookat: number, vlookat: number}} направление взгляда панорамы
   */
  getPanoAngles() {
    const krpano = this.getKrpano();
    return {
      hlookat: krpano.get('view.hlookat'),
      vlookat: krpano.get('view.vlookat'),
    };
  },
  getStyle(model) {
    return {
      url: model.getIcon(),
      edge: 'bottom',
      zoom: false,
      distorted: true,
    };
  },
  /** Отрисовка точки на панораме.
   * @param {string} spotName - spotName
   * @param {Object} coords - координаты точки на панораме (углы, расстояние)
   * @param {Object} style - стили для отрисовки точки.
   */
  drawPoint(spotName, coords, style = GeoObjects.Point.prototype.getPanoStyle()) {
    const krpano = this.getKrpano();
    this.createHotspot(spotName, style);

    const [hlookat, vlookat] = coords[0];
    krpano.set(`hotspot[${spotName}].ath`, hlookat);
    krpano.set(`hotspot[${spotName}].atv`, vlookat);

    krpano.set(`hotspot[${spotName}].capture`, false);
  },
  /** Отрисовка линии на панораме.
   *
   * В виду того, что используемая версия krpano не умеет рисовать линии, мы имитируем ее, рисуя полигон.
   * То есть для линии из точек (1, 2, 3, 4), рисуем следующий полигон: 1->2->3->4->4->3->2->1
   *
   * @param {string} spotName - spotName
   * @param {Array} coords - массив координат точек(из которых состоит линия) на панораме (углы, расстояние)
   * @param {Object} style - стили для отрисовки линии
   */
  drawLine(spotName, coords, style = GeoObjects.Line.prototype.getPanoStyle()) {
    const krpano = this.getKrpano();
    const asPolygonCoords = [...coords, ...[...coords].reverse()]; // Преобразуем координаты линии в координаты полигона

    this.createHotspot(spotName, style);

    krpano.set(`hotspot[${spotName}].point.count`, asPolygonCoords.length);
    krpano.set(`hotspot[${spotName}].renderer`, 'css3d');
    krpano.set(`hotspot[${spotName}].visible`, 'true');
    krpano.set(`hotspot[${spotName}].capture`, false);

    asPolygonCoords.forEach(([hlookat, vlookat], index) => {
      krpano.set(`hotspot[${spotName}].point[${index}].ath`, hlookat);
      krpano.set(`hotspot[${spotName}].point[${index}].atv`, vlookat);
    });
  },
  /** Отрисовка полигона на панораме.
   * @param {string} spotName - spotName
   * @param {Array} coords - массив координат точек(из которых состоит полигон) на панораме (углы, расстояние)
   * @param {Object} style - стили для отрисовки полигона
   */
  drawPolygon(spotName, coords, style = GeoObjects.Polygon.prototype.getPanoStyle()) {
    const krpano = this.getKrpano();
    this.createHotspot(spotName, style);

    krpano.set(`hotspot[${spotName}].point.count`, coords.length);

    coords.forEach(([hlookat, vlookat], index) => {
      krpano.set(`hotspot[${spotName}].point[${index}].ath`, hlookat);
      krpano.set(`hotspot[${spotName}].point[${index}].atv`, vlookat);
    });

    krpano.set(`hotspot[${spotName}].renderer`, 'css3d');
    krpano.set(`hotspot[${spotName}].visible`, 'true');
    krpano.set(`hotspot[${spotName}].capture`, false);
  },
  /** Добавление объекта на панораму.
   *
   * @param {string} name  "панорамное" название объекта (spotName)
   * @param {Object} data  данные объекта (стили и проч.)
   */
  createHotspot(name, data) {
    if (data == null) {
      return;
    }
    const krpano = this.getKrpano();
    krpano.call(`addhotspot(${name})`);
    Object.keys(data).forEach(key => krpano.set(`hotspot[${name}].${key}`, data[key]));
  },
  /** Удаление объекта с панорамы.
   *
   * @param {string} name "панорамное" название объекта (spotName)
   */
  removeHotspot(name) {
    this.getKrpano().call(`removehotspot(${name})`);
  },
  /** Изменить видимость объекта на панораме.
   *
   * @param {string} name "панорамное" название объекта (spotName)
   * @param {Boolean} newVisibility новое значение видимости
   */
  setHotspotVisibility(name, newVisibility) {
    this.getKrpano().set(`hotspot[${name}].visible`, newVisibility);
  },
  setPanoAngles(angles) {
    if (!angles) {
      return;
    }
    const krpano = this.getKrpano();
    krpano.set('view.hlookat', angles[0]);
    krpano.set('view.vlookat', angles[1]);
  },
  hashState() {
    const angles = this.getPanoAngles();
    if (!angles) {
      return `${this.model.get('session')}:${this.model.get('original_id')}`;
    }
    return `${this.model.get('session')}:${this.model.get('original_id')},`
      + `${angles.hlookat ? angles.hlookat.toFixed(2) : 0.0},${angles.vlookat ? angles.vlookat.toFixed(2) : 0.0}`;
  },
  /** Сделать скриншот */
  makeScreenshot() {
    const pluginObject = this.getKrpano().get('plugin[screenshot]');
    pluginObject.makeScreenshot(`${this.panoNameObject.fullName}, ${this.panoNameObject.stationing}`);
  },
  /** Центрирование карты по панораме. */
  showRadarPosition() {
    AIS.trigger('map:center', this.model.get('x'), this.model.get('y'));
  },
  /** Переключить увеличение яркости */
  toggleBrightness() {
    this.model.set('brightness_adjusted', !this.model.get('brightness_adjusted'));
    const pluginObject = this.getKrpano().get('plugin[pp_light]');
    pluginObject.enabled = this.model.get('brightness_adjusted');
  },
  getMeasureFeatures() {
    return this.measureFeature ? [this.measureFeature] : [];
  },
  updateMeasureFeature(feature) {
    let count = 0;
    let length = 0;
    let area = 0;
    if (!feature && this.measureFeature) {
      this.measureFeature.destroy();
    }
    this.measureFeature = feature;
    if (feature) {
      count = feature.geometry.getVertices().length;
      length = Shape.Line.getMeasure(feature);
      area = Shape.Polygon.getMeasure(
        new OpenLayers.Feature.Vector(
          new OpenLayers.Geometry.Polygon(
            new OpenLayers.Geometry.LinearRing(
              feature.geometry.getVertices(),
            ),
          ),
        ),
      );
    }
    this.$el.find('.pano-measure .count').text(parseInt(count, 10) || 0);
    this.$el.find('.pano-measure .length').text((parseFloat(length) || 0).toFixed(1));
    this.$el.find('.pano-measure .area').text((parseFloat(area) || 0).toFixed(1));
    AIS.trigger('geometry:measure:update');
  },
  updateToggleRotateButton() {
    this.$el.find('.pano-measure .pano-measure-toggle-rotate-button').html(
      this.shouldMeasureOnRotate ? 'Остановить' : 'Возобновить',
    );
  },
  toggleMeasure() {
    this.model.set('measure', !this.model.get('measure'));
    this.shouldMeasureOnRotate = this.model.get('measure');
    this.clearMeasure();
    this.updateToggleRotateButton();
  },
  copyMeasureFeatureFromBackup() {
    if (this.measureFeatureGeometryBackup && this.measureFeature) {
      this.measureFeatureShape.copyFromBackup(
        this.measureFeatureGeometryBackup,
        this.measureFeature,
      );
    }
  },
  roadPointLocateMeasureFeature() {
    this.roadPointLocateMeasure(
      this.measureFeatureShape,
      this.measureFeature,
      feature => this.updateMeasureFeature(feature),
    );
  },
  toggleShouldMeasureOnRotate(optionalEnabled) {
    let enabled;
    if (optionalEnabled == null) {
      enabled = !this.shouldMeasureOnRotate;
    } else {
      enabled = Boolean(optionalEnabled);
    }
    if (this.shouldMeasureOnRotate !== enabled) {
      if (this.shouldMeasureOnRotate) {
        this.copyMeasureFeatureFromBackup();
        this.measureFeatureGeometryBackup = null;
        this.updateMeasureFeature(this.measureFeature);
      }
      this.shouldMeasureOnRotate = enabled;
      this.rotateMeasure();
      this.updateToggleRotateButton();
    }
  },
  clearMeasure() {
    this.measureFeatureGeometryBackup = null;
    this.updateMeasureFeature(null);
    this.toggleShouldMeasureOnRotate(true);
  },

  /**
   * Перерисовать фичу инструмента измерения расстояния по панораме.
   *
   * Коллбек, вызываемый при повороте панорамы.
   */
  rotateMeasure() {
    if (
      !(
        this.measureFeature
        && this.shouldMeasureOnRotate
        && this.hasHlookatChanged()
      )
    ) return;

    if (!this.measureFeatureGeometryBackup) {
      this.measureFeatureGeometryBackup = this.measureFeature.geometry.clone();
    } else {
      this.copyMeasureFeatureFromBackup();
    }
    this.roadPointLocateMeasureFeature();
  },
  clickMeasure() {
    this.copyMeasureFeatureFromBackup();
    this.measureFeatureGeometryBackup = null;
    this.roadPointLocateMeasureFeature();
    this.toggleShouldMeasureOnRotate(true);
  },
  /**
   * Получить с сервера проекцию и отобразить фичу на панораме.
   * @param  {string} spotName имя хотспота
   * @param  {Object} geojson  GeoJSON
   * @param  {Object} ephemera фича
   */
  ajaxProjection(spotName, geojson, ephemera) {
    const pano_id = this.model.get('id');
    if (typeof pano_id === 'number') {
      api.panoProject(pano_id, geojson, consts.PANO_DISPLAY_DISTANCE).then((result) => {
        result.result.forEach((projection, index) => {
          const name = `${spotName}_${index}`;
          this.showEphemera(name, ephemera, projection);
          this.drawnPanoFeatures.push(name);
        });
      }).catch(() => {
        AIS.panoPanel.showError('Неизвестная ошибка запроса');
      });
    }
  },
  /**
   * Получить отобразить проекцию фичи на панораме.
   * @param  {string} spotName имя хотспота
   * @param  {Object} ephemera фича
   * @param  {Object} projection проекция
   */
  showEphemera(spotName, ephemera, projection) {
    const style = (ephemera && ephemera.model) ? ephemera.model.getPanoStyle() : undefined;
    if (!projection) {
      this.removeHotspot(spotName);
      return;
    }

    if (!projection.coordinates) {
      return;
    }

    if (projection.type === 'Polygon') {
      this.drawPolygon(spotName, projection.coordinates, style);
    }
    if (projection.type === 'LineString') {
      this.drawLine(spotName, projection.coordinates, style);
    }
    if (projection.type === 'Point') {
      this.drawPoint(spotName, projection.coordinates, style);
    }
  },
  /**
   * Получить направление взгляда панорамы и её идентификатор.
   * @returns {{id: number, hlookat: number, vlookat: number}} направление взгляда панорамы и её идентификатор
   */
  getPanoIdAndAngles() {
    return {
      id: this.model.get('id'),
      ...this.getPanoAngles(),
    };
  },
  /**
   * Спроецировать точку в плоскости дороги.
   * @param {Object} shape форма
   * @param {Object} feature фича
   * @param {number} pointNumber номер точки по порядку
   * @param {Array<Object>} points массив точек
   * @param {Function} callback колбек
   */
  roadPointLocate(shape, feature, pointNumber, points, callback) {
    AIS.panoPanel.roadPointLocate(
      this.getPanoIdAndAngles(),
      shape,
      feature,
      pointNumber,
      points,
      callback,
    );
  },
  /**
   * Спроецировать точку в плоскости дороги для измерения по панораме.
   * @param {Object} shape форма
   * @param {Object} feature фича
   * @param {Function} callback колбек
   */
  roadPointLocateMeasure(shape, feature, callback) {
    AIS.panoPanel.roadPointLocateMeasure(
      this.getPanoIdAndAngles(),
      shape,
      feature,
      callback,
    );
  },
});

export default PanoView;
