export default [
  {
    name: '1. Символьная разметка',
    items: {
      1.18: {
        variants: [
          {
            name: '1.18',
            items: [
              // eslint-disable-next-line max-len
              'левее', 'левее и разворот', 'левее, направо и налево', 'налево', 'налево и левее', 'налево и налево', 'налево и правее', 'налево и разворот', 'направо', 'направо и левее', 'направо и налево', 'направо и правее', 'направо и разворот', 'правее', 'правее и левее', 'правее и разворот', 'правее, направо и налево', 'прямо', 'прямо и левее', 'прямо и налево', 'прямо и направо', 'прямо и правее', 'прямо и разворот', 'прямо с левым изгибом', 'прямо с левым изгибом и налево', 'прямо с левым изгибом и направо', 'прямо с левым изгибом и разворот', 'прямо с левым изгибом, направо и налево', 'прямо с правым изгибом', 'прямо с правым изгибом и налево', 'прямо с правым изгибом и направо', 'прямо с правым изгибом и разворот', 'прямо с правым изгибом, налево и направо', 'прямо, направо и налево', 'прямо, направо, налево и левее', 'прямо, правее, направо и налево', 'разворот'],
          },
        ],
      },
      1.19: {
        variants: [
          {
            name: '1.19',
            items: ['левее', 'правее'],
          },
        ],
      },
      '1.20': {
      },
      1.21: {
      },
      '1.23.1': {
      },
      '1.23.2': {
      },
      '1.23.3': {
      },
      '1.24.1': {
        variants: [
          {
            name: '1.24.1',
            items: ['1.20.2', '1.22', '1.23'],
          },
        ],
      },
      '1.24.2': {
        variants: [
          {
            name: '1.24.2',
            items: ['20', '30', '40', '50', '60', '70', '80'],
          },
        ],
      },
      '1.24.3': {
      },
      '1.24.4': {
      },
      '1.24.5': {},
      ДЕТИ: {
      },
      ШКОЛА: {
      },
    },
  },
  {
    name: '2. Вертикальная символьная разметка',
    items: {
      '2.1.1': {
      },
      '2.1.2': {
      },
      '2.1.3': {
      },
      2.2: {
      },
      2.3: {
      },
      2.4: {
      },
    },
  },
];
