import * as actionTypes from 'js/constants/actionTypes';
import { TAB_MAP, TAB_OUTER_LIST, TAB_ROADS } from 'js/constants/tabs';
import AIS from 'js/AIS';

/**
 * Маппинг для преобразования имени вкладки в идентификатор элемента в скрытом переключателе.
 * @type {{[tabId: string]: string}}
 */
const tabIdToSwitcherIdMapping = {
  [TAB_MAP]: 'tabs-switcher-map',
  [TAB_OUTER_LIST]: 'tabs-switcher-outer-list',
  [TAB_ROADS]: 'tabs-switcher-roads',
};


const changeTab = (activeTab) => {
  if (activeTab === TAB_OUTER_LIST) {
    AIS.grid.updateGrid();
  } else if (activeTab === TAB_ROADS) {
    AIS.roads_grid.render();
  }
  $(`#${tabIdToSwitcherIdMapping[activeTab]}`).tab('show');
  AIS.trigger('layout:center:resize');
};


const layoutMiddleware = () => next => (action) => {
  let actionCanProceed = true;

  if (action.type === actionTypes.LAYOUT_CHANGE_TAB) {
    if (action.activeTab === TAB_ROADS && AIS.featureEnabled('hide_roads_tab')) {
      // Заблокируем прохождение экшна, если вкладка "Дороги" скрыта,
      // но на неё пытаются переключиться.
      actionCanProceed = false;
    } else {
      changeTab(action.activeTab);
    }
  }

  if (actionCanProceed) {
    next(action);
  }
};

export default layoutMiddleware;
