import { SEARCH_UPDATE_SUGGESTIONS } from 'js/constants/actionTypes';

const initialState = {
  query: '',
  suggestions: {},
};

const searchReducer = (state = initialState, action) => {
  switch (action.type) {
    case SEARCH_UPDATE_SUGGESTIONS:
      return {
        ...state,
        suggestions: action.suggestions,
      };
    default:
      return state;
  }
};

export default searchReducer;
