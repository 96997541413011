import OpenLayers from 'lib/OpenLayers-2.12/OpenLayers.debug';


const RTreeSnappingControl = OpenLayers.Class(OpenLayers.Control.Snapping, {
  keyhandler: null,
  autoActivate: true,

  initialize: function () {
    OpenLayers.Control.Snapping.prototype.initialize.apply(this, arguments);
    this.keyhandler = new OpenLayers.Handler.Keyboard(this, {
      keydown(evt) {
        if (this.keyhandler.active && evt.keyCode === 17) {
          this.setEnabled(false);
        }
      },
      keyup(evt) {
        if (this.keyhandler.active && evt.keyCode === 17) {
          this.setEnabled(true);
        }
      },
    }, {});
  },
  draw() {
    OpenLayers.Control.prototype.draw.apply(this, arguments);

    $(this.div).html('<label><input type="checkbox" checked>Привязка</label>').addClass('olButton');

    return this.div;
  },
  getEnabled() {
    const snapping = sessionStorage.getItem('snapping');
    return typeof snapping === 'string' ? snapping === 'yes' : true;
  },
  updateCheckbox() {
    $(this.div).find('input:checkbox').prop('checked', this.getEnabled());
  },
  setEnabled(enabled) {
    sessionStorage.setItem('snapping', enabled ? 'yes' : 'no');
    this.updateCheckbox();
  },
  onButtonClick(event) {
    if (event.buttonElement === this.div) {
      this.setEnabled(!this.getEnabled());
    }
  },
  activate() {
    if (OpenLayers.Control.Snapping.prototype.activate.apply(this, arguments)) {
      this.setEnabled(this.getEnabled());
      this.keyhandler.activate();
      this.map.events.register('buttonclick', this, this.onButtonClick);
    }
  },
  deactivate() {
    if (OpenLayers.Control.Snapping.prototype.deactivate.apply(this, arguments)) {
      this.keyhandler.deactivate();
      this.map.events.unregister('buttonclick', this, this.onButtonClick);
    }
  },
  destroy() {
    OpenLayers.Control.Snapping.prototype.destroy.apply(this, arguments);
    this.keyhandler.destroy();
    this.keyhandler = null;
  },
  /**
   * Method: testTarget
   *
   * @param {Object} target - Object with target layer configuration.
   * @param {OpenLayers.Geometry.Point} loc - The location of the mouse in map coords.
   * @returns {Object} - A result object with rank, dist, x, and y properties.
   *     Returns null if candidate is not eligible for snapping.
   */
  testTarget(target, loc) {
    if (!this.getEnabled()) {
      return null;
    }
    const resolution = this.layer.map.getResolution();
    if ('minResolution' in target) {
      if (resolution < target.minResolution) {
        return null;
      }
    }
    if ('maxResolution' in target) {
      if (resolution >= target.maxResolution) {
        return null;
      }
    }
    const tolerance = {
      node: this.getGeoTolerance(target.nodeTolerance, resolution),
      vertex: this.getGeoTolerance(target.vertexTolerance, resolution),
      edge: this.getGeoTolerance(target.edgeTolerance, resolution),
    };
    // this could be cached if we don't support setting tolerance values directly
    const maxTolerance = Math.max(
      tolerance.node, tolerance.vertex, tolerance.edge,
    );
    let result = {
      rank: Number.POSITIVE_INFINITY, dist: Number.POSITIVE_INFINITY,
    };
    let eligible = false;

    if (!target || !target.layer || !target.layer.getVisibility() || typeof target.layer.rtreeSearch !== 'function') {
      return null;
    }
    const features = target.layer.rtreeSearch({
      minX: loc.x - maxTolerance,
      minY: loc.y - maxTolerance,
      maxX: loc.x + maxTolerance,
      maxY: loc.y + maxTolerance,
    });
    let feature;
    let type;
    let vertices;
    let vertex;
    let closest;
    let dist;
    let found;
    const numTypes = this.precedence.length;
    for (let i = 0, len = features.length; i < len; i += 1) {
      feature = features[i];
      if (feature !== this.feature && !feature._sketch
          && feature.state !== OpenLayers.State.DELETE
          && (!target.filter || target.filter.evaluate(feature))) {
        for (let j = 0, stop = Math.min(result.rank + 1, numTypes); j < stop; j += 1) {
          type = this.precedence[j];
          if (target[type]) {
            if (type === 'edge') {
              closest = feature.geometry.distanceTo(loc, { details: true });
              dist = closest.distance;
              if (dist <= tolerance[type] && dist < result.dist) {
                result = {
                  rank: j,
                  dist,
                  x: closest.x0,
                  y: closest.y0, // closest coords on feature
                };
                eligible = true;
                // don't look for lower precedence types for this feature
                break;
              }
            } else {
              // look for nodes or vertices
              vertices = feature.geometry.getVertices(type === 'node');
              found = false;
              for (let k = 0, klen = vertices.length; k < klen; k += 1) {
                vertex = vertices[k];
                dist = vertex.distanceTo(loc);
                if (dist <= tolerance[type]
                    && (j < result.rank || (j === result.rank && dist < result.dist))) {
                  result = {
                    rank: j,
                    dist,
                    x: vertex.x,
                    y: vertex.y,
                  };
                  eligible = true;
                  found = true;
                }
              }
              if (found) {
                // don't look for lower precedence types for this feature
                break;
              }
            }
          }
        }
      }
    }
    return eligible ? result : null;
  },
});

export default RTreeSnappingControl;
