import * as actionTypes from 'js/constants/actionTypes';
import { SEARCH_TYPE_AIS, SEARCH_TYPE_COORDINATES, SEARCH_TYPE_GEOCODING } from 'js/constants/searchTypes';
import * as searchActions from 'js/actions/searchActions';
import * as mapActions from 'js/actions/mapActions';
import AIS from 'js/AIS';
import _ from 'underscore';
import { changeStringLayoutFromEnglishToRussian } from 'js/utils';
import api, { SearchSuggestionItem } from 'js/api';
import { MAP_DEFAULT_ZOOM } from 'js/consts';

// дебаунс в строке поиска (мс)
const SEARCH_DEBOUNCE = 300;

// регулярное выражение для поиска координат
const coordsRegExp = /^(-?\d+\.\d+)\s*,\s*(-?\d+\.\d+)$/;

/**
 * Обработать пользовательский ввод из строки поиска и предложить варианты, соответствующие запросу.
 *
 * Если пользователь ввел координаты, то будет предложен вариант перейти по координатам.
 * Иначе будут произведены запросы к серверу АИС и к API геокодинга Яндекса (если не отключено).
 * @param {string} query текст, введённый пользователем в строку поиска
 * @param {Function} dispatch метод для запуска действий, store.dispatch
 */
export const search = (query, dispatch) => {
  const trimmedQuery = query.trim();

  // При пустом поисковом запросе удалим все варианты автодополнения и не будем производить поиск.
  if (trimmedQuery === '') {
    dispatch(searchActions.searchUpdateSuggestions({}));
    return;
  }

  const coordsMatch = trimmedQuery.match(coordsRegExp);

  if (coordsMatch !== null) {
    const [, lat, lon] = coordsMatch;
    if (lat != null && lon != null) {
      dispatch(searchActions.searchUpdateSuggestions({
        [SEARCH_TYPE_COORDINATES]: [
          new SearchSuggestionItem(
            SEARCH_TYPE_COORDINATES,
            0,
            `${lat}, ${lon}`,
            '',
            lon,
            lat,
            false,
          ),
        ],
      }));
      return;
    }
  }

  const queryInRussianLayout = changeStringLayoutFromEnglishToRussian(trimmedQuery);

  // Бэкенд АИСа не найдет нужный результат, если запрос набран не в той раскладке,
  // поэтому если запрос можно преобразовать в русскую раскладку (есть хотя бы один символ, который не русская буква),
  // то произведем поиск и по оригинальному запросу, и по преобразованному.
  const aisSearchOriginalPromise = api.search(trimmedQuery);
  const aisSearchWithSwitchedLayoutPromise = (trimmedQuery !== queryInRussianLayout)
    ? api.search(queryInRussianLayout)
    : Promise.resolve([]);

  Promise
    .all([aisSearchOriginalPromise, aisSearchWithSwitchedLayoutPromise])
    .then(([aisOriginalSearch, aisSearchWithSwitchedLayout]) => {
      // Запрашиваем геокодинг только тогда, когда он включен и результат поиска по базе пуст
      // Геокодинг Яндекса достаточно умный, чтобы понять запрос в любой раскладке, поэтому будем отправлять
      // только оригинальный запрос (в том числе и в целях экономии запросов к API Геокодинга, они ограничены).
      if (AIS.featureEnabled('geocode') && aisOriginalSearch.length === 0 && aisSearchWithSwitchedLayout.length === 0) {
        return api.geocode(trimmedQuery);
      }

      dispatch(searchActions.searchUpdateSuggestions({
        [SEARCH_TYPE_AIS]: [...aisOriginalSearch, ...aisSearchWithSwitchedLayout],
      }));
      return Promise.reject();
    })
    .then((geocodingSearch) => {
      dispatch(searchActions.searchUpdateSuggestions({
        [SEARCH_TYPE_GEOCODING]: geocodingSearch,
      }));
    }, () => {});
};


export const debouncedSearch = _.debounce(search, SEARCH_DEBOUNCE);


/**
 * Выбрать вариант автодополнения в строке поиска.
 * @param {Object} suggestions все варианты автодополнения, сгруппированные по типу (берётся из Redux)
 * @param {string} type тип выбранного варианта автодополнения
 * @param {number} id идентификатор выбранного варианта автодополнения
 * @param {Function} dispatch метод хранилища Redux для запуска экшнов
 */
export const selectSuggestion = (suggestions, type, id, dispatch) => {
  const [selectedSuggestion] = suggestions[type].filter(suggestion => suggestion.id === id);
  const {
    lon,
    lat,
    pano,
    featureId,
  } = selectedSuggestion;
  dispatch(mapActions.mapCenter(lon, lat, MAP_DEFAULT_ZOOM));
  if (pano) {
    dispatch(mapActions.mapShowPano(lon, lat));
  }
  if (featureId) {
    dispatch(mapActions.mapOpenPopupForId(featureId, lon, lat));
  }
};

export const searchMiddlewareFactory = (searchCallback, selectSuggestionCallback) => store => next => (action) => {
  if (action.type === actionTypes.SEARCH_UPDATE_QUERY) {
    searchCallback(action.query, store.dispatch);
  } else if (action.type === actionTypes.SEARCH_SELECT_SUGGESTION) {
    selectSuggestionCallback(
      store.getState().search.suggestions,
      action.suggestion.type,
      action.suggestion.id,
      store.dispatch,
    );
  }
  next(action);
};

export default searchMiddlewareFactory(debouncedSearch, selectSuggestion);
