export const SEARCH_UPDATE_QUERY = 'SEARCH_UPDATE_QUERY';
export const SEARCH_UPDATE_SUGGESTIONS = 'SEARCH_UPDATE_SUGGESTIONS';
export const SEARCH_SELECT_SUGGESTION = 'SEARCH_SELECT_SUGGESTION';

export const MAP_CENTER = 'MAP_CENTER';
export const MAP_SHOW_PANO = 'MAP_SHOW_PANO';
export const MAP_OPEN_POPUP_FOR_ID = 'MAP_OPEN_POPUP_FOR_ID';

export const LAYOUT_CHANGE_TAB = 'LAYOUT_CHANGE_TAB';

export const USER_LOGIN = 'USER_LOGIN';
export const USER_LOGOUT = 'USER_LOGOUT';

export const EDITOR_CREATE = 'EDITOR_CREATE';
