import * as actionTypes from 'js/constants/actionTypes';
import api from 'js/api';

export const userMiddlewareFactory = reloadPageCallback => () => next => (action) => {
  if (action.type === actionTypes.USER_LOGOUT) {
    api.logout().then(() => reloadPageCallback());
  }
  next(action);
};

export default userMiddlewareFactory(() => document.location.reload());
