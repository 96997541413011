import _ from 'underscore';
import Backbone from 'js/backbone';
import imageExtensions from 'js/data/ImageExtensions';
import AIS from 'js/AIS';
import api from 'js/api';
import { relationModels, relationUpdateModels } from 'js/consts';


const Property = { };

Property.Generic = Backbone.Model.extend({
  getName() {
    return this.get('field');
  },
  asString(value) {
    return value;
  },
  extractFrom(model) {
    const raw = model.get(this.getName());
    if (raw != null) {
      //
      return this.attributes.function === 'local_coordinates' ? raw : this.asString(raw);
    }
    return '<не указано>';
  },
  editable() {
    return true;
  },
  toPropertiesTableRow(model) {
    return {
      caption: this.get('name'),
      content: this.extractFrom(model),
      unit: this.get('unit'),
    };
  },
  getDefaultValue() {
    let raw = this.get('default');
    if (_.isUndefined(raw)) raw = null;
    return raw;
  },
  filterIsEmpty(value) {
    return !value;
  },
});

/**
 * Поле, хранящее мануальные связи один-ко-многим.
 */
Property.OneToManyManualRelations = Property.Generic.extend({
  defaults: {
    name: 'Ручные связи',
    type: 'oneToManyManualRelations',
    id: 'relations',
  },
  /**
   * Получить имя поля, которое будет передано как ключ в запросе на сервер.
   * @returns {string} имя поля
   */
  getName() {
    return 'relations';
  },
  async relationsFrom(model, onlyEnabled = null) {
    const layer = model.getMeta().get('class');
    const relationPromises = Object.entries(AIS.relations)
      .filter(([, relation]) => (
        relation.update_model === relationUpdateModels.MANUAL
        && relation.child === layer
        && relation.model === relationModels.ONETOMANY
      ))
      .map(async ([relationName]) => {
        const relationParents = (await api.getManualRelationParents(relationName, model.id, onlyEnabled)).parents;
        return [relationName, relationParents];
      });
    const relationNameToParentsMapping = await Promise.all(relationPromises);
    return Object.fromEntries(relationNameToParentsMapping);
  },
  /**
   * Получить значение поля из модели фичи.
   * @param {Backbone.Model} model модель фичи
   * @returns {Promise<Object>} промис, который будет зарезолвлен при получении значения с сервера
   */
  async extractFrom(model) {
    return this.relationsFrom(model);
  },
});

Property.Geometry = Property.Generic.extend({
});

Property.Boolean = Property.Generic.extend({
  asString(value) {
    return value ? 'да' : 'нет';
  },
  filterIsEmpty(value) {
    return value == null;
  },
});

Property.Select = Property.Generic.extend({
  initialize: function () {
    this._index = {};
    _.each(this.get('select'), function (item) {
      this._index[item.id] = item;
    }, this);
  },
  asString(value) {
    return this._index[value] ? this._index[value].text : `${value}`;
  },
  getDefaultValue() {
    if (this.has('default')) return this.get('default');
    if (this.has('select')) return this.get('select')[0].id;
  },
});

Property.Special = Property.Generic.extend({
  getIcon(value) {
    const hash = this.find(value);
    return hash.icon;
  },
  asString(value) {
    const hash = this.find(value);
    return hash.name || value;
  },
  lookfor(code) {
    let section;
    let hash;
    if (!code || (typeof (code) !== 'string')) return {};

    if (this.get('flat')) {
      section = this.get('data');
      hash = section[code];
    } else {
      if (!this._index) {
        this._index = {};
        _.each(this.get('data'), function (section) {
          _.extend(this._index, section.items);
        }, this);
      }
      hash = this._index[code];
    }
    return hash;
  },
  find(code) {
    return this.lookfor(code) || {};
  },
  getData() {
    return this.get('data');
  },
});

const Property_Subtype = Property.Special.extend({
  initialize: function () {
    if (!(this.typeName && this.typeField && this.typeSubField)) {
      throw new Error('Property_Subtype is not intended to be used directly, use createSubtypeProperty instead');
    }
    return Property.Special.prototype.initialize.apply(this, arguments);
  },
  _createSubtypeIndex() {
    this._subtype_index = {};
    _.each(this.get('data'), function (section) {
      _.each(section.items, function (subsection, subsection_type) {
        if (_.has(subsection, 'variants')) {
          if (!_.has(this._subtype_index, subsection_type)) {
            this._subtype_index[subsection_type] = {};
          }
          _.each(subsection.variants, function (subsection_variant) {
            _.each(subsection_variant.items, function (item) {
              this._subtype_index[subsection_type][item] = subsection;
            }, this);
          }, this);
        }
      }, this);
    }, this);
  },
  hasSubtypes(type) {
    if (!this._subtype_index) {
      this._createSubtypeIndex();
    }
    return _.has(this._subtype_index, type);
  },
  hasSubtype(type, subtype) {
    return this.hasSubtypes(type) && _.has(this._subtype_index[type], subtype);
  },
  getIcon(type, type_subtype, big, isproject, toremove) {
    if (!type || !this.lookfor(type)) {
      return '/icon/other/unknown.png';
    }
    const hash = this.find(type);
    let base = `/icon/${this.typeName}${(big) ? '-large/' : '-small/'}`;
    if (!big) {
      if (isproject) {
        base += 'project/';
      } else if (toremove) {
        base += 'remove/';
      }
    }
    let actualSubtype = type_subtype;
    if (_.has(hash, 'variants')) {
      if (this.hasSubtype(type, type_subtype)) {
        actualSubtype = `_${type_subtype}`;
      } else {
        actualSubtype = '_unknown';
      }
    } else {
      actualSubtype = '';
    }
    return `${base + type + actualSubtype}.png`;
  },
});

const createSubtypeProperty = function (typeName, typeField, typeSubField) {
  return Property_Subtype.extend({
    typeName,
    typeField,
    typeSubField,
  });
};

Property.RoadSign = createSubtypeProperty('roadsigns',
  'gost', 'gost_subtype');
Property.RoadMarkSymbol = createSubtypeProperty('road_marking_symbol',
  'type', 'type_subtype');

Property.Integer = Property.Generic.extend({
  asString(value) {
    return value != null ? parseInt(Math.round(parseFloat(value)), 10) : null;
  },
  filterIsEmpty(value) {
    return (
      value === null
      || !(value instanceof Object)
      || (
        Number.isNaN(parseInt(value.min, 10))
        && Number.isNaN(parseInt(value.max, 10))
      )
    );
  },
});

Property.Float = Property.Integer.extend({
  _defaultPrecision: 2,
  asString(value) {
    let precision = parseInt(this.get('precision'), 10);
    if (Number.isNaN(precision)) {
      precision = this._defaultPrecision || 2;
    }
    return value ? value.toFixed(precision) : null;
  },
  filterIsEmpty(value) {
    return value === null || !(value instanceof Object) || (Number.isNaN(parseFloat(value.min)) && Number.isNaN(parseFloat(value.max)));
  },
});

Property.Measure = Property.Float.extend({});
Property.Highness = Property.Float.extend({});
Property.Azimuth = Property.Integer.extend({});

Property.Calculation = Property.Float.extend({
  editable() {
    return false;
  },
});

Property.Date = Property.Generic.extend({
  // return empty string if value is not set or string representing date value in format "d.mm.yyyy"
  asString(value) {
    if (value !== null) {
      const date = new Date(value * 1000);
      const month = date.getUTCMonth() + 1;
      return `${date.getUTCDate()}.${month < 10 ? (`0${month.toString()}`) : month}.${date.getUTCFullYear()}`;
    }
    return '';
  },
});

Property.Attachments = Property.Generic.extend({
  /**
   * Отделить имя файла от полного пути.
   *
   * @param {string} fullname полный путь к файлу
   * @returns {string} имя файла
   */
  strip(fullname) {
    const idx = fullname.lastIndexOf('/');
    return fullname.substring(idx + 1);
  },
  /**
   * Является ли файл изображением.
   *
   * Определяется по расширению файла сравнением с известными расширениями изображений.
   *
   * @param {string} name имя файла
   * @returns {boolean} `true`, если изображение
   */
  isImage(name) {
    const splitted = name.split('.');
    const extension = splitted[splitted.length - 1];
    return imageExtensions.includes(extension);
  },
  /**
   * Получить отформатированный html для вставки в таблицу свойств объекта.
   *
   * @param {Object} model модель бэкбона
   * @returns {Object} объект, готовый для вставки в шаблон
   */
  toPropertiesTableRow(model) {
    const list = _.map(model.getAttachments(), function (attachment) {
      const strippedName = this.strip(attachment);
      if (this.isImage(attachment)) {
        return `<a
            class='fancybox'
            data-fancybox="gallery"
            data-type="iframe"
            data-src="/storage/${attachment}"
            href=''
          >
            ${strippedName}
          </a>`;
      }
      return `<a href="/storage/${attachment}" target="_blank">${strippedName}</a>`;
    }, this);
    return {
      caption: this.get('name'),
      content: list.join('<br/>'),
    };
  },
});

Property.map = {
  date: Property.Date,
  select: Property.Select,
  boolean: Property.Boolean,
  geometry: Property.Geometry,
  integer: Property.Integer,
  float: Property.Float,
  measure: Property.Measure,
  highness: Property.Highness,
  azimuth: Property.Azimuth,
  calculation: Property.Calculation,
  attachments: Property.Attachments,
};

Property.find = function (type) {
  return this.map[type] || Property.Generic;
};

export default Property;
