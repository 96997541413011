import $ from 'jquery';
import _ from 'underscore';
import Backbone from 'js/backbone';
import AIS from 'js/AIS';


export default Backbone.View.extend({
  className: 'panel',
  template: _.template($('#tmpl-svobject-filter').html()),
  events: {
    'click i': 'expand',
    'click .commit-button': 'commit',
    'click .clear-button': 'clear',
  },
  expand(ev) {
    if (!$(ev.target).hasClass('icon-chevron-down') && !$(ev.target).hasClass('icon-chevron-left')) return;

    if (!this.expanded) {
      this.$el.find('.content').show();
    } else {
      this.$el.find('.content').hide();
    }

    if (this.expanded) {
      this.$el.find('.icon-chevron-down').removeClass('icon-chevron-down').addClass('icon-chevron-left');
    } else {
      this.$el.find('.icon-chevron-left').removeClass('icon-chevron-left').addClass('icon-chevron-down');
    }
    this.expanded = !this.expanded;
  },
  /**
   * Установка фильтра по светофорному объекту.
   * @param {string} [id]    идентификатор светофорного объекта
   */
  setTrafficLightNodeFilter: (id) => {
    AIS.trafficLightNodeFilter.setTrafficLightNodeFilter(id);
  },
  /** Фильтрация по выбранному светофорному объекту. */
  commit() {
    const id = $('.sv-object-field').select2('val');
    this.setTrafficLightNodeFilter(id);
  },
  /** Очистка значения в селекторе светофорного объекта и обнуление фильтра. */
  clear() {
    $('.sv-object-field').select2('val', null);
    this.setTrafficLightNodeFilter();
  },
  render() {
    this.$el.empty().append(this.template());

    if (!this.expanded) {
      this.$el.find('.content').hide();
    }

    this.$el.find('.sv-object-field').select2({
      minimumInputLength: 1,
      placeholder: 'Выберите объект',
      allowClear: true,
      ajax: {
        url: '/ajax/autocomplete/sv_object',
        data(term) {
          return {
            q: term,
          };
        },
        results(data) {
          return data;
        },
      },
    });

    return this;
  },
});
