import * as actionTypes from 'js/constants/actionTypes';

/**
 * Создать экшн обновления поискового запроса.
 * @param {string} query новый запрос в строке поиска
 * @returns {{type: string, query: string}} экшн обновления поискового запроса
 */
export const searchUpdateQuery = query => ({
  type: actionTypes.SEARCH_UPDATE_QUERY,
  query,
});

/**
 * Создать экшн обновления предложенных вариантов к строке поиска.
 * @param {Object} suggestions предложенные варианты автодополнения
 * @returns {{type: string, suggestions: Array<Object>}} экшн обновления вариантов автодополнения
 */
export const searchUpdateSuggestions = suggestions => ({
  type: actionTypes.SEARCH_UPDATE_SUGGESTIONS,
  suggestions,
});

/**
 * Создать экшн выбора варианта автодополнения в строке поиска.
 * @param {string} type тип автодополнения: координаты, поиск по АИС, геокодинг
 * @param {number} id идентификатор варианта автодополнения в категории
 * @returns {{type: string, suggestion: {type: string, id: number}}} экшн выбора варианта автодополнения
 */
export const searchSelectSuggestion = (type, id) => ({
  type: actionTypes.SEARCH_SELECT_SUGGESTION,
  suggestion: { type, id },
});
