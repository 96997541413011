import { USER_LOGIN, USER_LOGOUT } from 'js/constants/actionTypes';

/**
 * Создать экшн успешной авторизации пользователя в АИС.
 * @param {string} username имя пользователя
 * @param {string} token токен для обращения к бэкенду
 * @param {boolean} readOnly имеет ли пользователь права только на чтение
 * @param {Array?} layers список доступных пользователю слоёв
 * @param {Object} filters список слоёв, принадлежащих пользователю
 * @returns {{type: string, username: string, token: string, layers: Array?, readOnly: boolean}} экшн логина
 */
export const userLogin = (username, token, readOnly, layers, filters) => ({
  type: USER_LOGIN,
  username,
  token,
  readOnly,
  layers,
  filters,
});

/**
 * Создать экшн выхода пользователя из АИС.
 * @returns {{type: string}} экшн логаута
 */
export const userLogout = () => ({
  type: USER_LOGOUT,
});
