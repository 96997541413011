import OpenLayers from 'lib/OpenLayers-2.12/OpenLayers.debug';
import Backbone from 'js/backbone';
import AIS from 'js/AIS';
import api from 'js/api';
import { JUMP_MIN_DISTANCE } from 'js/constants/panoramas';


const Pano = Backbone.Model.extend({
  defaults: {
    // видно ли панораму на экране?
    visible: false,

    // используется ли панорама для триангуляции?
    active: false,

    // используется ли панорама для измерений?
    measure: false,

    // используется ли корректировка яркости?
    brightness_adjusted: false,

    // является ли панорама архивной?
    is_archive_pano: false,

    // список исторических панорам
    // маппинг, где ключ -- id, значение -- объект исторической панорамы
    pano_archive: null,

    // объект, хранящий данные актуальной панорамы
    actual_pano: null,

    // идентификатор выбранной в данный момент исторической панорамы
    // равен 'actual', если выбрана актуальная панорама.
    selected_id: '',

    // массив идентификаторов фич, которые сейчас отображаются на панораме
    pano_features_ids: null,
  },
  /**
   * Инициализация модели.
   */
  initialize: function () {
    if (this.isMain()) {
      // Событие `change:focus_both` запускает перемещение главной панорамы.
      // Генерируется при "перетаскивании" и при обработке клика по карте
      this.on('change:focus_both', () => this.updateBothPanos({ ...this.get('focus_both') }));
    } else {
      // Событие `change:focus_to` запускает перемещение дополнительной панорамы. Генерируется при "перетаскивании"
      this.on('change:focus_to', () => this.updatePano({ ...this.get('focus_to') }));
      this.on('change:active', () => {
        if (this.get('active')) {
          // При активации дополнительной панорамы она должна подскочить к главной:
          //  - при первом открытии она делает это всегда,
          //  - в остальных случаях, если находится на достаточном рассточнии от первой
          if (!this.get('initial_pano_id') || this.panosFarApart(AIS.pano1, AIS.pano2)) {
            this.jumpToPano(AIS.pano1.id);
          }
        }
      });
    }
    this.set('pano_features_ids', []);
  },

  /**
   * Панорама подскакивает к другой панораме и разворачивается в сторону её взгляда.
   * @param {number} panoId id панорамы, к которой надо подскочить
   */
  async jumpToPano(panoId) {
    const id_changed = await this.updatePano({ feature_id: panoId });
    if (id_changed !== false) {
      AIS.panoPanel.adjust();
    }
  },

  /**
   * Посчитать расстояние между двумя панорамами.
   * @param {Backbone.Model} pano1 первая панорама
   * @param {Backbone.Model} pano2 вторая панорама
   * @returns {number} расстояние в метрах
   */
  distanceBetweenPanos(pano1, pano2) {
    const { lon: lon1, lat: lat1 } = pano1.getTranslations();
    const { lon: lon2, lat: lat2 } = pano2.getTranslations();
    const line = new OpenLayers.Geometry.LineString([
      new OpenLayers.Geometry.Point(lon1, lat1),
      new OpenLayers.Geometry.Point(lon2, lat2),
    ]);
    return line.getGeodesicLength(new OpenLayers.Projection('EPSG:4326'));
  },

  /**
   * Находятся ли панорамы на достаточном расстоянии друг от друга.
   *
   * Используется для определения того, следует ли второй панораме подскочить к первой.
   * @param {Backbone.Model} pano1 первая панорама
   * @param {Backbone.Model} pano2 вторая панорама
   * @returns {boolean} true, если далеко, иначе false
   */
  panosFarApart(pano1, pano2) {
    return this.distanceBetweenPanos(pano1, pano2) > JUMP_MIN_DISTANCE;
  },

  /**
   * Проверяем, является ли данная панорама главной.
   * @returns {Boolean} true если является
   */
  isMain() {
    return this.get('name') === 'main';
  },

  /**
   * Получить вьюху для текущей панорамы.
   * @returns {Object} вью для текущей панорамы
   */
  getPanoView() {
    return this.isMain() ? AIS.panoPanel.panoView : AIS.panoPanel.panoView2;
  },

  /**
   * Обновление главной панорамы, которое обновляет и поворачивает дополнительную панораму, если она активна.
   *
   * @param {Object} fields аргумент для updatePano
   */
  async updateBothPanos(fields) {
    const main_id_changed = await AIS.pano1.updatePano(fields);
    if (main_id_changed && AIS.pano2.get('active')) {
      if (this.panosFarApart(AIS.pano1, AIS.pano2)) {
        await AIS.pano2.jumpToPano(AIS.pano1.id);
      }
    }
  },

  /** Вызов событий для перерисовки обновленных фич на панораме.
   *
   * @param {Object} removed - удалившиеся фичи
   * @param {Object} features - добавленные фичи
   * @param {Object} navs - метки панорам
   */
  triggerRedraw(removed, features, navs) {
    this.trigger('removeFeatures', removed);
    this.trigger('drawFeatures', features);
    this.trigger('drawNavs', navs);
  },

  /**
   * Обновление панорамы при переходе на новую панораму.
   * @param {Object} fields аргументы для panoLocate
   * @returns {Boolean} true, если панорама была переключена на другую, иначе false
   */
  async updatePano(fields) {
    let pano = null;
    try {
      pano = await api.panoLocate(fields);
    } catch (error) {
      if (error.status === 404) {
        return false;
      }
      throw error;
    }
    const id_before_update = this.get('id');

    pano.pano_archive = new Map();
    if (pano.layer === 'pano') {
      pano.is_archive_pano = false;
      pano.selected_id = 'actual';
      pano.actual_pano = pano;
    } else {
      pano.is_archive_pano = true;
    }
    this.set(pano);
    if (!this.isMain() && this.get('initial_pano_id') === undefined) {
      this.set('initial_pano_id', this.get('id'));
    }
    const panoView = this.getPanoView();
    panoView.showPanoFeatures();
    if (this.get('active')) {
      panoView.showCrosshair();
    }
    return pano.id !== id_before_update; // если панорама не меняется, то возвращаем false
  },

  /** Коллбек, вызываемый при обновлении фильтра панорамы.
   *
   * @param {Object} message - результат обновления фильтра (проекции фич на текущую панораму)
   */
  onUpdate(message) {
    const rows = message.payload;
    const removed = rows.filter(row => row.remove).map(row => row.feature_id);
    const updated = rows.filter(row => !row.remove).map(row => row.data);
    const navs = updated.filter(feature => feature.layer === 'pano');
    const pano_archive = updated.filter(feature => feature.layer === 'pano_archive');
    const features = updated.filter(feature => feature.layer !== 'pano' && feature.layer !== 'pano_archive');
    const featuresIds = features.map(row => row.id);

    this.trigger('updatePanoLinearCoord');
    this.updatePanoArchive(pano_archive, removed);
    const panoView = this.getPanoView();
    if (!panoView.krpanoReady()) {
      // если krpano ещё не готово, то запланируем обновление фич
      panoView.once('onloadcomplete', () => {
        this.triggerRedraw(removed, features, navs);
      }, this);
    } else {
      this.triggerRedraw(removed, features, navs);
    }

    // обновляем массив идентификаторов отображаемых на панораме фич
    const panoFeaturesIds = [
      ...new Set([...this.get('pano_features_ids'), ...featuresIds]),
    ].filter(id => !removed.includes(id));
    this.set('pano_features_ids', panoFeaturesIds);
  },

  /**
   * Добавить новые, обновить или удалить исторические панорамы.
   * @param {Object[]} updated - добавленные или обновленные исторические панорамы
   * @param {Number[]} removed - идентификаторы удаленных фич
   */
  updatePanoArchive: function (updated, removed) {
    if (
      (typeof updated === 'undefined' || updated.length === 0)
      && (typeof removed === 'undefined' || removed.length === 0)
    ) {
      return;
    }

    const pano_archive = new Map(this.get('pano_archive'));

    updated.forEach((pano) => {
      pano_archive.set(pano.id, pano);
    });

    removed.forEach((id) => {
      pano_archive.delete(id);
    });

    this.set('pano_archive', pano_archive);
  },

  getRotations() {
    return {
      yaw: this.get('course'),
      pitch: this.get('pitch'),
      roll: this.get('roll'),
    };
  },

  getTranslations() {
    return {
      lat: this.get('y'),
      lon: this.get('x'),
      alt: this.get('alt'),
    };
  },
});

export default Pano;
