import $ from 'jquery';
import _ from 'underscore';
import OpenLayers from 'lib/OpenLayers-2.12/OpenLayers.debug';
import Backbone from 'js/backbone';
import AIS from 'js/AIS';
import Shape from 'js/model/Shapes';
import PointCoordinatesView from 'js/view/PointCoordinates';
import api from 'js/api';


const GeometryField = {};

const Tool = Backbone.View.extend({
  tagName: 'button',
  className: 'btn tool',
  _active: false,

  activate() {
    if (!this.isActive()) {
      this.$el.addClass('active');
      this.toggle(true);
    }
  },
  deactivate() {
    if (this.isActive()) {
      this.$el.removeClass('active');
      this.toggle(false);
    }
  },
  setDisabled(disable) {
    this.options.disabled = disable;
    this.$el.prop('disabled', disable);
  },
  toggle(enable) {
    this._active = enable;
  },
  render() {
    this.setDisabled(this.options.disabled);
    this.$el.html(`<i class="${this.icon}"></i>`);
    this.delegateEvents();
    return this;
  },
  isActive() {
    return this._active;
  },
});

const CreateTool = Tool.extend({
  id: 'from-map-button',
  attributes: { rel: 'tooltip', title: 'На карте (z)' },
  icon: 'icon-pencil',

  initialize: function () {
    this.layer = this.options.view.layerToDraw();
  },
  /**
   * Начать редактирование.
   */
  startEdit() {
    this.options.view.startEdit(this);
  },
  /**
   * Начать рисование объекта.
   */
  startDraw() {
    AIS.on('map:featureadded', this.addFeature, this);
    AIS.on('map:sketchmodified', this.updateSketch, this);
    AIS.trigger('editor:draw:begin', this.layer, this.options.view.getDrawHandler());
  },
  /**
   * Закончить рисование объекта.
   */
  endDraw() {
    AIS.off('map:featureadded', this.addFeature, this);
    AIS.off('map:sketchmodified', this.updateSketch, this);
    AIS.trigger('editor:draw:end');
  },
  /**
   * Начать редактирование объекта.
   */
  startModify() {
    this.options.view.getFeature().locked = true;
    AIS.on('map:featuremodified', this.modifyFeature, this);
    AIS.trigger('editor:modify:begin', this.layer, this.options.view.getFeature());
  },
  /**
   * Закончить редактирование объекта.
   */
  endModify() {
    const feature = this.options.view.getFeature();
    AIS.off('map:featuremodified', this.modifyFeature, this);
    AIS.trigger('editor:modify:end', this.layer, feature);
    if (feature) {
      feature.locked = false;
    }
  },
  /**
   * Коллбек, вызывающийся после рисования фичи.
   * @param {OpenLayers.Feature} feature добавляемый объект с геометрией
   */
  addFeature(feature) {
    this.options.view.onCreate(feature);
    this.endDraw();

    /* Переключиться в режим редактирования только что созданной фичи при возникновении события 'map:editafteradd'.
     * Этот костыль с событием вместо вызова функии, нужен для  соблюдения правильной последовательности
     * включения/выключения разных инструментов.
     *
     * Как это было:
     * 1. Пользователь делает дабл-клик и занесение фичи завершается.
     * 2. Включаем инструмент редактирования, который включает прилипание.
     * 3. Инструмент занесения выключается и выключает прилипание.
     * 4. Пользователь не может использовать прилипание :(
     *
     * Как это сейчас:
     * 1. Пользователь делает дабл-клик и занесение фичи завершается.
     * 2. Выключаем инструмент занесения, который выключает прилипание.
     * 3. Триггерим событие.
     * 4. Включается инструмент редактирования, который включает прилипание.
     * 5. Пользователь может использовать прилипание!
     */
    AIS.once('map:editafteradd', this.startModify, this);
  },
  /**
   * Коллбек, вызывающийся после редактирования фичи.
   */
  modifyFeature() {
    this.options.view.onModify();
  },
  /**
   * Коллбек, вызывающийся после изменения скетча нового объекта.
   * @param {Event} event - событие
   */
  updateSketch(event) {
    this.options.view.updateLineLengthChange(event.feature);
  },
  activate() {
    if (!this.isActive()) {
      Tool.prototype.activate.apply(this);
      this.startEdit();
      this.startDraw();
      this.options.view.startLineLengthChange();
    }
  },
  deactivate() {
    if (this.isActive()) {
      Tool.prototype.deactivate.apply(this);
      this.endDraw();
      this.endModify();
      this.options.view.endLineLengthChange();
    }
  },
  render() {
    Tool.prototype.render.apply(this);
    this.setDisabled(!this.options.view.isEmpty());
    return this;
  },
});

const SwitchCreateTool = CreateTool.extend({
  /** Выбор типа занесения геометрии полигона. */
  id: 'from-map-button',
  attributes: { rel: 'tooltip', title: 'На карте (z)' },
  icon: 'icon-pencil',
  activate() {
    /** Если этот инструмент активируется, то отобразим кнопки для выбора типа занесения. */
    if (!this.isActive()) {
      this.options.view.startSwitchEdit(this);
      Tool.prototype.activate.apply(this);
    }
  },
  deactivate() {
    if (this.isActive()) {
      this.options.view.endSwitchEdit(this);
      CreateTool.prototype.deactivate.apply(this);
    }
  },
  render() {
    Tool.prototype.render.apply(this);
    this.setDisabled(!this.options.view.isEmpty());
    return this;
  },
});

const CreateDefaultTool = CreateTool.extend({
  /**
    Наследуемся от CreateTool, переопределив только атрибуты для DOM'а:
    id, class, иконку, всплывающую подсказку, функцию, вызывающуюся при активации инструмента.
    Стандартное занесение объекта по карте
    */
  className: 'btn create-tool',
  id: 'from-map-default-button',
  attributes: { rel: 'tooltip', title: 'Обычное занесение (q)' },
  icon: 'icon-stop',
  startEdit() {},
  addFeature(feature) {
    CreateTool.prototype.addFeature.call(this, feature);
    this.options.view.endSwitchEdit();
  },
  /**
    * Деактивировать инструмент.
    */
  deactivate() {
    if (this.isActive()) {
      Tool.prototype.deactivate.apply(this);
      this.endDraw();
    }
  },
});

const CreateAxisAndWidthTool = CreateDefaultTool.extend({
  /** Занесение полигона через ось и ее ширину */
  id: 'from-map-axis-and-width-button',
  attributes: { rel: 'tooltip', title: 'По оси и ширине (w)' },
  icon: 'icon-resize-full',

  setPreFeature(feature) {
    /** Добавление на карту оси, по которой в дальнейшем сформируется полигон с шириной.
        feature - объект оси ( с геометрией и т.п.)
      */
    this.options.view.setPreFeature(feature);
  },
  activate() {
    if (!this.isActive()) {
      Tool.prototype.activate.apply(this);
      this.startEdit();
      // Вызывается при добавлении оси для ввода ширины
      AIS.on('map:pre_featureadded', this.setPreFeature, this);
      AIS.on('map:featureadded', this.addFeature, this);

      AIS.trigger('editor:drawAxisAndWidth:begin', this.layer);
    }
  },
});

const CreateAdjacentPolygonTool = CreateDefaultTool.extend({
  /** Занесение примыкающего полигона вдоль границы другого полигона */
  id: 'adjacent-polygon-button',
  attributes: { rel: 'tooltip', title: 'Примыкающий полигон вдоль границы другого полигона (e)' },
  icon: 'icon-bookmark',

  initialize: function () {
    const type = this.model.getMeta().getGeometryType();
    const filters = AIS.workset.filter((filter) => {
      const layer = filter.getLayer();
      const m = filter.getMeta();
      return (m.getGeometryType() === type) && layer;
    });
    this.layers = _.map(filters, filter => filter.getLayer());
    this.layer = this.options.view.layerToDraw();
  },

  setPreFeature(feature) {
    /** Добавление на карту оси из двух точек на полигоне, по которой в дальнейшем сформируется примыкающий полигон с шириной.
        feature - объект оси ( с геометрией и т.п.)
      */
    this.options.view.setPreFeature(feature);
  },
  activate() {
    if (!this.isActive()) {
      Tool.prototype.activate.apply(this);
      this.startEdit();
      // Вызывается при добавлении оси на полигоне
      AIS.on('map:pre_featureadded', this.setPreFeature, this);
      AIS.on('map:featureadded', this.addFeature, this);

      AIS.trigger('editor:drawAdjacentPolygon:begin', this.layer, this.layers);
    }
  },
});

const CreatePaintBucketTool = CreateDefaultTool.extend({
  /** Занесение через заполнение пространства */
  id: 'paint-bucket-button',
  attributes: { rel: 'tooltip', title: 'Заполнение пространства (r)' },
  icon: 'icon-tint',
  activate() {
    if (!this.isActive()) {
      Tool.prototype.activate.apply(this);
      this.startEdit();
      AIS.on('map:featureadded', this.addFeature, this);
      AIS.trigger('editor:drawPaintBucketPoint:begin', this.layer);
    }
  },
});

const EditTool = CreateTool.extend({
  id: 'modify-button',
  attributes: { rel: 'tooltip', title: 'Редактировать по карте (z)' },
  icon: 'icon-pencil',

  initialize: function () {
    this.layer = this.options.view.layerToDraw();
  },
  activate() {
    if (!this.isActive()) {
      Tool.prototype.activate.apply(this);
      this.startEdit();
      this.startModify();
      this.options.view.startLineLengthChange();
    }
  },
  deactivate() {
    if (this.isActive()) {
      Tool.prototype.deactivate.apply(this);
      this.endModify();
      this.options.view.endLineLengthChange();
    }
  },
  render() {
    Tool.prototype.render.apply(this);
    this.setDisabled(this.options.view.isEmpty());
    return this;
  },
});

const TriangulateTool = Tool.extend({
  id: 'triangulate-button',
  attributes: { rel: 'tooltip', title: 'По двум панорамам (x)' },
  icon: 'icon-picture',

  activate() {
    if (!this.isActive()) {
      Tool.prototype.activate.apply(this);
      this.options.view.startEdit(this);
      AIS.trigger('triangulate:active', true);
      AIS.trigger('togglePanoLayer', true);
    }
  },
  deactivate() {
    if (this.isActive()) {
      Tool.prototype.deactivate.apply(this);
      AIS.trigger('triangulate:active', false);
    }
  },
  render() {
    Tool.prototype.render.apply(this);
    this.setDisabled(!this.options.view.isEmpty());
    return this;
  },
});

const RoadTriangulationTool = TriangulateTool.extend({
  id: 'road-triangulate-button',
  attributes: { rel: 'tooltip', title: 'В плоскости дороги (c)' },
  icon: 'icon-road',
  points: [],

  activate() {
    if (!this.isActive()) {
      Tool.prototype.activate.apply(this);
      this.points = [];
      this.options.view.startEdit(this);
      AIS.trigger('road_locate:active', true);
      AIS.trigger('togglePanoLayer', true);
    }
  },
  deactivate() {
    if (this.isActive()) {
      Tool.prototype.deactivate.apply(this);
      AIS.trigger('road_locate:active', false);
    }
  },
});

const CopyTool = Tool.extend({
  id: 'copy-button',
  attributes: { rel: 'tooltip', title: 'Скопировать (v)' },
  icon: 'icon-hand-up',
  className: 'btn tool',

  initialize: function () {
    const type = this.model.getMeta().getGeometryType();
    this.layers = AIS.workset
      .filter(classFilter => classFilter.getGeometryType() === type)
      .map(classFilter => classFilter.getLayer());
  },
  select(feature) {
    this.options.onCopy(feature);
  },
  activate() {
    if (!this.isActive()) {
      Tool.prototype.activate.apply(this);
      this.options.view.startEdit(this);
      AIS.on('editor:copy:select', this.select, this);
      AIS.trigger('editor:copy:begin', this.layers);
    }
  },
  deactivate() {
    if (this.isActive()) {
      Tool.prototype.deactivate.apply(this);
      AIS.off('editor:copy:select', this.select, this);
      AIS.trigger('editor:copy:end', this.layers);
    }
  },
  render() {
    Tool.prototype.render.apply(this);
    this.setDisabled(!this.options.view.isEmpty());
    return this;
  },
});

const CreateCopyShiftTool = CreateTool.extend({
  /** Занесение линии с помощью копирования другой линии с фиксированным сдвигом. */

  id: 'copy-shift-button',
  attributes: { rel: 'tooltip', title: 'Копирование линии со сдвигом 0.5 м.' },
  icon: 'icon-resize-full',

  initialize: function () {
    const type = this.model.getMeta().getGeometryType();
    this.layers = AIS.workset
      .filter(classFilter => classFilter.getGeometryType() === type)
      .map(classFilter => classFilter.getLayer());
    this.layer = this.options.view.layerToDraw();
  },

  activate() {
    if (!this.isActive()) {
      Tool.prototype.activate.apply(this);
      this.options.view.startEdit(this);
      this.options.view.startLineShift();
      // Вызывается при получении сдвинутой геометрии
      AIS.on('map:featureadded', this.addFeature, this);
      AIS.trigger('editor:drawShiftCopy:begin', this.layer, this.layers);
    }
  },

  deactivate() {
    if (this.isActive()) {
      Tool.prototype.deactivate.apply(this);
      this.options.view.endLineShift();
    }
  },
});

const ReverseTool = Tool.extend({
  id: 'reverse-button',
  attributes: { rel: 'tooltip', title: 'Обратить' },
  icon: 'icon-refresh',
  className: 'btn',
  events: {
    click: 'click',
  },

  initialize: function () {
    this.layer = this.options.view.layerToDraw();
  },

  reverseGeometry(geometry) {
    return new OpenLayers.Geometry.LineString(
      geometry.getVertices().slice().reverse(),
    );
  },

  click() {
    AIS.trigger('editor:unselect:all');
    this.model.updateGeometry(
      this.reverseGeometry(this.options.view.getFeature().geometry),
    );
    this.options.view.onModify();
    this.options.view.clickSave();
  },

  render() {
    const type = this.model.getMeta().getGeometryType();
    Tool.prototype.render.apply(this);
    this.setDisabled(this.options.view.isEmpty() || type !== 'line');
    return this;
  },
});

const ClearTool = Tool.extend({
  id: 'clear-button',
  attributes: { rel: 'tooltip', title: 'Очистить' },
  icon: 'icon-remove',
  className: 'btn',
  events: {
    click: 'click',
  },

  click() {
    this.options.view.onClear();
  },
  render() {
    Tool.prototype.render.apply(this);
    this.setDisabled(this.options.view.isEmpty());
    return this;
  },
});

/** Занесение точки, по которой будет считаться азимут. */
const AzimuthCreateTool = CreateTool.extend({
  attributes: { rel: 'tooltip', title: 'Указать направление на карте' },

  // eslint-disable-next-line valid-jsdoc
  /** Переопределяем рендер, чтобы при незаполненной геометрии основной фичи этот инструмент был недоступен. */
  render() {
    Tool.prototype.render.apply(this);
    this.setDisabled(this.options.view.isEmpty());
    return this;
  },
  /**
    * Коллбек, вызывающийся после рисования фичи.
    * @param {OpenLayers.Feature} feature добавляемый объект с геометрией
    */
  addFeature(feature) {
    this.options.view.onCreate(feature);
    this.endDraw();
  },
});

/** Повернуть азимут на 90 градусов. */
const AzimuthTurnTool = Tool.extend({
  attributes: { rel: 'tooltip', title: 'Повернуть на 90 градусов' },
  icon: 'icon-refresh',
  className: 'btn',
  events: {
    click: 'click',
  },

  click() {
    const oldAzimuth = this.options.view.getValue();
    const newAzimuth = (oldAzimuth + 90) % 360;
    this.options.view.setValue(newAzimuth);
    $('.measure-input-value').val(newAzimuth);
    this.options.view.clickSave();
  },
  render() {
    Tool.prototype.render.apply(this);
    this.setDisabled(this.options.view.isEmpty());
    return this;
  },
});

/** Угадать значения азимута. */
const AzimuthGuessTool = Tool.extend({
  attributes: { rel: 'tooltip', title: 'Заполнить автоматически' },
  icon: 'icon-magnet',
  className: 'btn',
  events: {
    click: 'click',
  },
  /**
    * Запрос азимута с сервера.
    */
  async requestAzimuth() {
    const predictedAzimuth = await api.azimuth(
      AIS.transformReaderGeoJSON.write(
        this.options.model.feature.geometry,
      ),
    );
    if (predictedAzimuth) {
      this.setAzimuth(predictedAzimuth);
    }
  },
  /**
   * Устанавливает значение азимута в соответствующее поле
   * и сохраняет геометрию.
   * @param {number} azimuthValue - значение азимута
   */
  setAzimuth(azimuthValue) {
    this.options.view.setValue(azimuthValue);
    $('.measure-input-value').val(azimuthValue);
    this.options.view.clickSave();
  },
  click() {
    this.requestAzimuth();
  },
  render() {
    Tool.prototype.render.apply(this);
    this.setDisabled(this.options.view.isEmpty());
    return this;
  },
});

GeometryField.Generic = Backbone.View.extend({
  tagName: 'div',
  tools: [],
  events: {
    'click .tool': 'toggleButton',
    'click .create-tool': 'toggleCreateTool',
    'click .field-save-button': 'clickSave',
    'click .field-cancel-button': 'clickCancel',
    'click .field-input-button': 'clickInput',
    'click .line-length-change-submit': 'clickLineLengthChange',
  },
  _additional: null,
  _dirty: false,
  geometryFieldTemplate: _.template($('#tmpl-geometry-field').html()),

  initialize: function (options) {
    this._shape = Shape.byType(options.property.get('geometry'));
    this.on('geom_changed', this.onChange, this);
    this.on('geom_changed', this.validate, this);
    this.on('geom_changed', function () {
      this.updateLineLengthChange();
    }, this);

    this._layer = this.layerToDraw();

    this.tools = this.createTools();
    this.fromMapTools = this.createFromMapTools();

    if (this.model.isNew()) {
      AIS.ephemeras.add(this.model);
    }

    this.options.fieldState.on('change:multi', function (model, value) {
      if (value) this.lock();
      else this.unlock();
    }, this);
    this.boundKeyUp = _.bind(this.keyUp, this);
    $(document).on('keyup', this.boundKeyUp);
  },
  /**
    * Реакции на нажатие определенных клавиш.
    * @param  {event} event евент, из которого мы получаем нажатую клавишу
    */
  keyUp(event) {
    const thisLineLengthChangeInputInFocus = this.$el.find('.line-length-change input').is(':focus');
    const anyLineLengthChangeInputInFocus = $('#object-form').find('.line-length-change input:focus').length > 0;
    if (anyLineLengthChangeInputInFocus) {
      if (thisLineLengthChangeInputInFocus && event.key === 'Enter') {
        this.clickLineLengthChange();
      }
      return;
    }
    const noModifiersPressed = !event.altKey && !event.ctrlKey && !event.shiftKey;
    switch (event.keyCode) {
      case 32:
        if (noModifiersPressed) {
          this.clickInput();
        }
        break;
      case 10:
      case 13:
        if (noModifiersPressed) {
          this.clickSave();
        }
        break;
      default:
    }
  },
  startEdit(tool) {
    this.lockForm(tool);
    this.createReserve();
  },
  _disableElement($el) {
    $el.prop('disabled', true);
  },
  layerToDraw() {
    const filter = this.model.getFilter();
    return filter.getLayer();
  },
  getFeature() {
    return this.model.feature;
  },
  setFeature(feature) {
    if (feature) {
      this.model.bindFeature(feature);
    } else {
      this.model.unbindFeature();
    }
  },
  createReserve() {
    if (this.isEmpty()) return;
    this._backup = this.getFeature().geometry.clone();
  },
  dropReserve() {
    if (this._backup) this._backup.destroy();
    this._backup = null;
  },
  restoreReserve() {
    if (this._backup) {
      this.model.setGeometry(this._backup, this.getFeature(), this._shape);
    } else {
      if (!this.isEmpty()) {
        this.model.destroyFeature();
      }
      if (this.preFeature) {
        this.preFeature.destroyAll();
        this.setPreFeature(null);
      }
      this.setFeature(null);
    }
  },
  onChange() {
    this._syncViews();
    this._setDirty();
  },
  getValue() {
    if (!this.isEmpty()) return AIS.transformReaderGeoJSON.write(this.getFeature().geometry);
  },
  validate() {
    const feature = this.getFeature();
    const valid = (feature) ? this._shape.checkValidity(feature) : false;
    if (valid) {
      this.$el.find('.field-save-button').prop('disabled', false);
    } else {
      this._disableElement(this.$el.find('.field-save-button'));
    }
  },
  /**
    * Показывать ли геометрию этого поля на панораме.
    * @returns {Boolean} true если показывать
    */
  showOnPano() {
    return this.getFeature();
  },
  /**
   * Сохранить фичу после триангуляции или проецирования в плоскость дороги.
   * @param {Object} feature фича OpenLayers
   */
  handleTriangulation(feature) {
    if (this._locked) {
      return;
    }
    if (!this.roadPointLocate.isActive() && !this.triangulate.isActive()) return;

    if (!this.isEmpty()) {
      this._layer.removeFeatures(this.getFeature());
    }
    this.setFeature(feature);
    this._layer.addFeatures(this.getFeature());

    this.saveTriangulationArguments();

    this.trigger('geom_changed');
  },
  _setDirty() {
    if (!this._dirty) {
      this._dirty = true;
      this.$el.find('.field-button').show();
    }
    if (!AIS.featureEnabled('disable_pano')) {
      if (this.triangulate.isActive() || this.roadPointLocate.isActive()) {
        this.$el.find('.field-button-vary').show();
      }
    }
  },
  _setClean() {
    this._dirty = false;
    this.render();
    this.model.trigger('geom_unlock', this);
    if (this.activeTool) {
      this.activeTool.deactivate();
    }
  },
  commitValue() {
    if (!this.isEmpty()) {
      const fieldName = this.options.property.getName();
      this.model.set(fieldName, this.getValue());
    }
  },
  _syncViews() {
    this.specificRender();
    this.layerToDraw().redraw();
    this.ephemera_up();
    AIS.panoPanel.showPanoFeatures();
  },
  setValue(value) {
    this.options.fieldState.set('value', value);
    this.options.fieldState.set('dirty', true);
  },
  /** Сохранить без подстановки азимута. */
  _clickSave() {
    this.setValue(this.getValue());
    this.dropReserve();
    this._syncViews();
    this._setClean();
  },
  /** Сохранить, подставив азимут. */
  clickSave() {
    this.model.trigger('geometrySaved');
    this._clickSave();
  },
  clickCancel() {
    this._additional = null;
    this.restoreReserve();
    this._syncViews();
    this._setClean();
  },
  saveTriangulationArguments() {
    if (this.triangulate.isActive() && !this.roadPointLocate.isActive()) {
      if (!this._additional) {
        this._additional = { action: 'triangulate', points: [] };
      }
      this._shape.saveTriangulationArguments(this._additional, {
        pano1: AIS.panoView('main').getPanoIdAndAngles(),
        pano2: AIS.panoView('extra').getPanoIdAndAngles(),
      });
    }
    if (!this.triangulate.isActive() && this.roadPointLocate.isActive()) {
      if (!this._additional) {
        this._additional = { action: 'roadPointLocate', points: [] };
      }
      this._shape.saveTriangulationArguments(this._additional, {
        pano1: AIS.panoView('main').getPanoIdAndAngles(),
      });
    }
  },
  clickInput() {
    if (this.roadPointLocate.isActive()) {
      const pointNumber = this.roadPointLocate.points.length;
      this.roadPointLocate.points.push(null);
      AIS.panoView('main').roadPointLocate(
        this._shape,
        this.getFeature(),
        pointNumber,
        this.roadPointLocate.points,
        feature => this.handleTriangulation(feature),
      );
    } else if (this.triangulate.isActive()) {
      AIS.panoPanel.triangulate(this._shape, this.getFeature(), feature => this.handleTriangulation(feature));
    }
  },
  isEmpty() {
    return !(this.getFeature()) || !(this.getFeature().geometry);
  },
  render() {
    this.$el.html(this.geometryFieldTemplate());

    this.$el.find('.field-button').hide();
    this.$el.find('.field-button-vary').hide();
    this.$el.find('.field-cancel-button').prop('disabled', true);

    this.specificRender();

    const tools = this.$el.find('.tools');
    _.each(this.tools, (tool) => {
      tools.append(tool.render().el);
    }, this);

    if (this.isEmpty()) {
      this.toolEdit.$el.hide();
      this.toolCreate.$el.show();
    } else {
      this.toolCreate.$el.hide();
      this.toolEdit.$el.show();
    }
    return this;
  },
  toggleButton(event) {
    _.each(this.tools, (tool) => {
      if (tool.isActive() && (event.currentTarget !== tool.el)) {
        tool.deactivate();
      }
    });
    _.each(this.tools, function (tool) {
      if (event.currentTarget === tool.el) {
        tool.activate();
        this.activeTool = tool;
      }
    }, this);
    this.$el.find('.field-cancel-button').prop('disabled', false);
  },
  toggleCreateTool(event) {
    /**
        Выбор инструмента для создания полигона.
        Сперва проходим по всем инструментам и отключаем текущий активный. Затем в еще одном цикле включаем необходимый.
        Два цикла необходимы, так как нельзя активейтить тулзу пока активна какая-то другаяЮ
        event - вызванное событие, из которого получаем выбранный инструмент.
      */
    if (this.preFeature) {
      this.preFeature.destroyAll();
      this.setPreFeature(null);
    }
    _.each(this.fromMapTools, (tool) => {
      if (tool.isActive() && (event.currentTarget !== tool.el)) {
        tool.deactivate();
      }
    });
    _.each(this.fromMapTools, (tool) => {
      if (event.currentTarget === tool.el) {
        tool.activate();
      }
    }, this);
  },
  startSwitchEdit(tool) {
    /** Заблокируем форму, добавим кнопки выбора на панель и сделаем первое(дефолтное) занесение активным
        tool - текущий активный инструмент.
      */
    this.startEdit(tool);
    const fromMapTools = this.$el.find('.from-map-tools');
    _.each(this.fromMapTools, (tool) => {
      fromMapTools.append(tool.render().el);
    }, this);
    this.fromMapTools[0].activate();
  },
  endSwitchEdit() {
    /** Полностью скроем панель с кнопками выбора типа ввода геометрии по карте */
    _.each(this.fromMapTools, (tool) => {
      tool.deactivate();
    });
    const fromMapTools = this.$el.find('.from-map-tools');
    fromMapTools[0].innerHTML = '';
  },
  /**
    * Показать форму редактирования длины линии, если применимо.
    */
  startLineLengthChange() {
    if (this._shape.shapeName() === 'Line') {
      this.$el.find('.line-length-change').show();
      this.updateLineLengthChange();
    }
  },
  /**
    * Обновить длину в форме изменения длины линии.
    * @param  {OpenLayers.Feature} feature объект, если не передан, то длина будет посчитана из текущего объекта
    */
  updateLineLengthChange(feature) {
    let length = null;
    if (feature) {
      length = Shape.Line.getMeasure(feature);
    } else if (!this.isEmpty()) {
      length = Shape.Line.getMeasure(this.getFeature());
    }
    this.$el.find('.line-length-change input, .line-length-change button').prop('disabled', this.isEmpty());
    this.$el.find('.line-length-change-input').val(length ? length.toFixed(1) : null);
  },
  /**
    * Колбек, вызываемый по нажатию кнопки в форме изменения длины линии.
    * Делает запрос на сервер и обновляет геометрию.
    */
  async clickLineLengthChange() {
    const me = this;
    const feature = this.getFeature();
    const rawLength = this.$el.find('.line-length-change-input').val();
    const length = parseFloat(rawLength);

    if (Number.isNaN(length)) {
      alert('Неправильный формат длины'); // eslint-disable-line no-alert
    } else if (length <= 0) {
      alert('Длина должна быть больше ноля'); // eslint-disable-line no-alert
    } else {
      try {
        const geojson = await api.changeLineLength(
          AIS.transformReaderGeoJSON.write(feature.geometry),
          length,
        );

        if (geojson) {
          me.activeTool.endModify();
          me.getFeature().geometry = AIS.transformReaderGeoJSON.read(geojson, 'Geometry');
          me.onModify();
          me.activeTool.startModify();
        }
      } catch (e) {
        alert('Произошла ошибка при попытке изменить длину линии.'); // eslint-disable-line no-alert
      }
    }
  },
  /**
    * Скрыть форму изменения длины линии
    */
  endLineLengthChange() {
    this.$el.find('.line-length-change').hide();
  },
  /**
    * Показать форму редактирования отступа.
    */
  startLineShift() {
    if (this._shape.shapeName() === 'Line') {
      this.$el.find('.line-shift-distance').show();
      this.$el.find('.line-shift-distance-input').prop('disabled', false);
      this.$el.find('.line-shift-distance-input').val(3);
    }
  },
  /**
    * Скрыть форму изменения отступа.
    */
  endLineShift() {
    this.$el.find('.line-shift-distance-input').prop('disabled', true);
    this.$el.find('.line-shift-distance').hide();
  },
  deactivate() {
    _.each(this.tools, (tool) => {
      tool.deactivate();
    });

    if (!this.isEmpty()) {
      this.model.setAttributes(this.getFeature());
      this.model.bindFeature(this.getFeature());
    }

    this.layerToDraw().redraw();
    AIS.ephemeras.remove(this.model);
    $(document).off('keyup', this.boundKeyUp);
  },
  lockForm(activeTool) {
    this._setDirty();
    this.model.trigger('geom_lock', this);

    _.each(this.tools, function (tool) {
      if (tool !== activeTool) {
        this._disableElement(tool.$el);
      }
    }, this);
  },
  lock() {
    this._disableElement(this.$el.find('button'));
    this._locked = true;
  },
  unlock() {
    this.render();
    this._locked = false;
  },
  ephemera_up() {
    let a;
    if (this.isEmpty()) {
      a = null;
    } else {
      a = AIS.transformReaderGeoJSON.write(this.getFeature().geometry);
    }
    this.model.set('geojson', a);
  },
  reallyRender(el) {
    const feature = this.getFeature();
    const points = this._shape.getPointList(feature);

    for (let i = 0; i < points.length; i += 1) {
      const point = points[i];
      const pv = new PointCoordinatesView({
        point,
      });
      el.append(pv.render().el);
    }
  },
  specificRender() {
    const el = this.$el.find('.text');
    el.empty();

    if (this.isEmpty()) {
      return;
    }
    this.reallyRender(el);
  },
  getDrawHandler() {
    return this._shape.getDrawHandler();
  },
  additional() {
    return this._additional;
  },

  /* Create Tools */
  commonOptions() {
    return {
      model: this.model,
      view: this,
    };
  },
  setPreFeature(feature) {
    /** Добавим в контекст информацию о фиче, которая будет использоваться для создания геометрии заносимого объекта.
        feature - промежуточный объект с геометрией (к примеру, для занесения объекта по оси и ширине, preFeature будет осью.)
      */
    this.preFeature = feature;
  },
  onCreate(feature) {
    this.setFeature(feature);
    this.model.setAttributes(this.getFeature());
    feature.style = this.model.getStyle(); // eslint-disable-line no-param-reassign
    this.layerToDraw().drawFeature(feature);
    this.trigger('geom_changed', true);
  },
  /**
    * Колбек вызываемый при изменении фичи.
    */
  onModify() {
    this.trigger('geom_changed', true);
  },

  _createTool() {
    /** Для полигонов при занесении новых объектов вместо простого занесения по карте дадим несколько способов */
    let tool;
    if (this._shape.shapeName() === 'Polygon' && this.isEmpty()) {
      tool = new SwitchCreateTool(_.extend({}, this.commonOptions()));
    } else {
      tool = new CreateTool(_.extend({
        handler: this.getDrawHandler(),
      }, this.commonOptions()));
    }

    return tool;
  },

  _editTool() {
    return new EditTool(_.extend({}, this.commonOptions()));
  },

  _reverseTool() {
    const type = this.model.getMeta().getGeometryType();

    return new ReverseTool(_.extend({
      disabled: this.isEmpty() || type !== 'line',
    }, this.commonOptions()));
  },

  onClear() {
    this.model.destroyFeature();
    this.setFeature(null);
    this.model.set('geojson', null);
    this._additional = null;
    this.model.trigger('geom_unlock', this);
    this.render();
    this._syncViews();
  },

  _clearTool() {
    return new ClearTool(_.extend({
      disabled: this.isEmpty(),
      onClear: this.onClear,
    }, this.commonOptions()));
  },

  _copyTool() {
    const me = this;

    function onCopy(feature) {
      me.setFeature(feature.clone());
      me.model.setAttributes(me.getFeature());
      feature.style = me.model.getStyle(); // eslint-disable-line no-param-reassign
      me._layer.addFeatures(me.getFeature());
      me.trigger('geom_changed');
    }

    return new CopyTool(_.extend({
      onCopy,
      disabled: !this.isEmpty(),
    }, this.commonOptions()));
  },

  // eslint-disable-next-line valid-jsdoc
  /** Создание вьюхи для занесения геометрии сдвигом полилинии на 0.5м. в заданном растоянии. */
  _createCopyShiftTool() {
    return new CreateCopyShiftTool(_.extend({ disabled: !this.isEmpty() }, this.commonOptions()));
  },

  // eslint-disable-next-line valid-jsdoc
  /** Создаем инструменты для занесения. Если какой-либо из инструментов может использоваться при выполнении
    * определенного условия, то при невыполнении этого условия вместо инструмента помещаем в итоговый массив
    * инструментов null, а затем фильтрует этот массив на ненулевые значения.
    */
  createTools() {
    this.toolCreate = this._createTool();
    this.toolCopyShift = this.model.getMeta().getGeometryType() === 'line' ? this._createCopyShiftTool() : null;
    this.toolEdit = this._editTool();

    if (AIS.featureEnabled('disable_pano')) {
      this.triangulate = null;
      this.roadPointLocate = null;
    } else {
      this.triangulate = new TriangulateTool(_.extend({
        disabled: !this.isEmpty(),
      }, this.commonOptions()));
      this.roadPointLocate = new RoadTriangulationTool(_.extend({
        disabled: !this.isEmpty(),
      }, this.commonOptions()));
    }

    this.toolCopy = this._copyTool();

    this.reverseTool = this._reverseTool();

    this.removeTool = this._clearTool();
    return [
      this.toolCreate, this.toolEdit, this.triangulate,
      this.roadPointLocate, this.toolCopy, this.toolCopyShift,
      this.reverseTool, this.removeTool,
    ].filter(tool => tool !== null);
  },
  createFromMapTools() {
    /** Создаем инстансы для инструментов нестандартного занесения геометрии */
    this.toolCreateDefault = new CreateDefaultTool(_.extend({}, this.commonOptions()));
    this.toolCreateAxisAndWidth = new CreateAxisAndWidthTool(_.extend({}, this.commonOptions()));
    this.toolCreateAdjacentPolygon = new CreateAdjacentPolygonTool(_.extend({}, this.commonOptions()));
    this.toolCreatePaintBucketTool = new CreatePaintBucketTool(_.extend({}, this.commonOptions()));
    return [
      this.toolCreateDefault,
      this.toolCreateAxisAndWidth,
      this.toolCreateAdjacentPolygon,
      this.toolCreatePaintBucketTool,
    ];
  },
});


GeometryField.Measure = GeometryField.Generic.extend({
  events: {
    'click .tool': 'toggleButton',
    'click .field-save-button': 'clickSave',
    'click .field-cancel-button': 'clickCancel',
    'click .field-input-button': 'clickInput',
    'change .measure-input-value': 'changeInput',
    'hover .compact-value': 'compactValueHover',
  },
  CLASS_NAME: 'Geometry.Measure',
  initialize: function () {
    GeometryField.Generic.prototype.initialize.apply(this, arguments);
    this._feature = null;
    this.on('geom_changed', function () {
      AIS.trigger('geometry:measure:update');
      this._syncViews();
    }, this);
    if (this.model.isNew() && !_.isUndefined(this.options.inheritedValue)) {
      this.model.set(this.options.property.getName(), this.options.inheritedValue);
    }
  },
  commitValue() {
    this.model.set(this.options.property.getName(), this.getValue());
  },
  /**
    * Показывать ли геометрию этого поля на панораме.
    * @returns {Boolean} true если показывать
    */
  showOnPano() {
    return this.getFeature();
  },
  /**
    * Получить текущее значение.
    * @returns {number} текущее значение
    */
  getValue() {
    return (
      this.isEmpty()
        ? parseFloat(this.$el.find('.measure-input-value').val())
        : this._shape.getMeasure(this.getFeature())
    );
  },
  /**
    * Обновить представление.
    */
  specificRender() {
    this.$el.find('.measure-input-value').show().val(parseFloat(
      this.isEmpty()
        ? this.model.get(this.options.property.getName())
        : this.getValue(),
    ).toFixed(3));
  },
  getFeature() {
    return this._feature;
  },
  setFeature(feature) {
    this._feature = feature;
  },
  /**
    * Удалить текущую фичу.
    */
  clearFeature() {
    const feature = this.getFeature();
    if (feature) {
      feature.destroy();
    }
    this.setFeature(null);
    this._additional = null;
  },
  /**
    * Откатить изменения фичи.
    */
  rollback() {
    this.clearFeature();
  },
  /**
    * Колбек, вызывается по изменению значения в поле ввода.
    * Сохраняет значение из поля ввода.
    */
  changeInput() {
    this.clearFeature();
    this.setValue(this.getValue());
  },
  /**
    * Обновляет состояние заблокированности объекта.
    * @param {bool} disabled флаг - заблокированность объекта
    */
  setInputDisabled(disabled) {
    this.$el.find('.measure-input-value').prop('disabled', disabled);
  },
  /**
    * Заблокрировать данное поле.
    */
  lock() {
    GeometryField.Generic.prototype.lock.apply(this, arguments);
    this.setInputDisabled(true);
  },
  /**
    * Разрблокировать данное поле.
    */
  unlock() {
    GeometryField.Generic.prototype.unlock.apply(this, arguments);
    this.setInputDisabled(false);
  },
  /**
    * Колбек, вызывается при выборе инструмена занесения геометрии.
    * Блокирует ручной ввод значения.
    */
  toggleButton() {
    GeometryField.Generic.prototype.toggleButton.apply(this, arguments);
    this.setInputDisabled(true);
  },
  /**
    * Деактивирует поле.
    * Откатывает изменения фичи и разблокирует ручной ввод значения.
    */
  deactivate() {
    _.each(this.tools, (tool) => {
      tool.deactivate();
    });
    this.rollback();
    this.layerToDraw().redraw();
    $(document).off('keyup', this.boundKeyUp);
    this.setInputDisabled(false);
  },
  getDrawHandler() {
    return this._shape.getDrawHandler();
  },
  /**
    * Создать бекап текущего значения для восстановления по кнопке "отмена".
    */
  createReserve() {
    this._backup = this.getValue();
  },
  restoreReserve() {
    const name = this.options.property.getName();

    const f = this.getFeature();
    if (f) f.destroy();

    this.model.set(name, this._backup);
  },
  dropReserve() {
    this._backup = null;
  },
  _syncViews() {
    this.specificRender();
    this.layerToDraw().redraw();
  },

  /* Create Tools */
  onCreate(feature) {
    this.setFeature(feature);
    this.layerToDraw().drawFeature(feature);
    this.trigger('geom_changed', true);
  },

  createTools() {
    this.toolCreate = new CreateTool(_.extend({
      handler: this.getDrawHandler(),
    }, this.commonOptions()));

    this.toolEdit = this._editTool();
    this.triangulate = new TriangulateTool(this.commonOptions());
    this.roadPointLocate = new RoadTriangulationTool(this.commonOptions());

    return [this.toolCreate, this.toolEdit, this.triangulate,
      this.roadPointLocate];
  },
});

GeometryField.Azimuth = GeometryField.Measure.extend({
  /** Угол поворота. Содержит инпут и инструмент для занесения поворота по карте. */
  events: {
    'click #from-map-button': 'addAzimuthPoint',
    'click .field-cancel-button': 'clickCancel',
    'change .measure-input-value': 'changeInput',
  },
  geometryFieldTemplate: _.template($('#tmpl-azimuth-field').html()),
  /**
    * Инициализация.
    *
    * Проверяем, что добавляется новый объект, и в этом случае блокируем поле с вводом азимута до тех пор, пока
    * не добавится основная геометрия объекта.
    * Сохраняем значение азимута с предыдущей формы.
    * Добавляем событие для запроса азимута при сохранении геометрии.
    *
    */
  initialize: function () {
    GeometryField.Measure.prototype.initialize.apply(this, arguments);
    const inheritedAzimuthValue = this.model.get(this.options.property.getName());
    if (inheritedAzimuthValue) {
      this.options.fieldState.set('dirty', true);
      this.options.fieldState.set('value', inheritedAzimuthValue);
    }
    this._feature = null;
    this.model.on('geometrySaved', this.requestAzimuthIfNeeded, this);
  },

  /** Деактивация поля. Отписываемся от события */
  deactivate() {
    GeometryField.Measure.prototype.deactivate.apply(this, arguments);
    this.model.off('geometrySaved');
  },
  /**
    * Коллбек, вызывается при выборе инструмена занесения геометрии точки, по которой будет считаться азимут.
    *
    * Блокирует ручной ввод значения, прячет кнопку инструмента занесения, отображает кнопки с подтверждением и отменой.
    */
  addAzimuthPoint() {
    GeometryField.Generic.prototype.toggleButton.apply(this, arguments);
    this.toolCreate.$el.hide();
  },
  /** При сохранении или отмене азимута по точке, отобразим кнопку инструмента занесения. */
  _setClean() {
    GeometryField.Generic.prototype._setClean.apply(this, arguments);
    this.toolCreate.$el.show();
  },

  onCreate() {
    GeometryField.Measure.prototype.onCreate.apply(this, arguments);
    this.clickSave();
  },
  /**
    * При сохранении высчитанного азимута по добавленной точке, сохраняем в бекап значение азимута и удаляем
    * фичу второй точки, по которой считался азимут.
    */
  dropReserve() {
    this._backup = this.getValue();
    this.restoreReserve();
  },
  /** Изменение значения инпута. */
  changeInput() {
    this._clickSave();
  },
  /**
    * Получить текущее значение.
    * Проверяем, есть ли основная фича, и в этом случае высчитываем азимут по двум точкам: точке, добавленной
    * с помощью этого инструмента и точке самой фичи. Находим тангенс угла наклона относительно оси на север,
    * после определяем сам угол в градусах.
    *
    * @returns {?number} угол поворота
    */
  getValue() {
    if (this.isEmpty()) {
      const parsedValue = parseInt(this.$el.find('.measure-input-value').val(), 10);
      return Number.isNaN(parsedValue) ? null : parsedValue;
    }
    const pointEnd = this._shape.getPointList(this.getFeature())[0];
    const featureGeom = this.model.feature.geometry;
    const pointStart = featureGeom.CLASS_NAME === 'OpenLayers.Geometry.Polygon'
      ? featureGeom.getCentroid()
      : featureGeom;
    const dy = pointEnd.y - pointStart.y;
    const dx = pointEnd.x - pointStart.x;
    return Math.round(-Math.atan2(dy, dx) * 180 / Math.PI + 90 + 360) % 360;
  },

  // eslint-disable-next-line valid-jsdoc
  /**
    * Инструменты, с помощью которых заносится азимут.
    *
    * 1. Инструмент для создания точки на карте
    * 2. Инструмент для поворота на 90
    * 3. Инструмент для запроса с сервера
    */
  createTools() {
    this.toolCreate = new AzimuthCreateTool(
      _.extend(
        {
          handler: this.getDrawHandler(),
        },
        this.commonOptions(),
      ),
    );
    this.toolTurn = new AzimuthTurnTool(this.commonOptions());
    this.toolGuess = new AzimuthGuessTool(this.commonOptions());
    return [this.toolCreate, this.toolTurn, this.toolGuess];
  },

  /**
    * Если азимут не задан, но такое поле есть в meta.json, запрашивает с сервера подходящий азимут.
    */
  requestAzimuthIfNeeded() {
    if (this.model.feature.geometry.CLASS_NAME === 'OpenLayers.Geometry.Point') {
      const azimuth = this.getValue();
      if (azimuth == null || Number.isNaN(azimuth)) {
        this.toolGuess.requestAzimuth();
      }
    }
  },

  /** Установка флага, что поле было изменено. */
  _setDirty() {
    if (!this._dirty) {
      this._dirty = true;
      this.$el.find('.field-button').show();
    }
  },
  /** Обновить представление. */
  specificRender() {
    this.$el.find('.measure-input-value').show().val(
      this.isEmpty()
        ? this.model.get(this.options.property.getName())
        : this.getValue(),
    );
  },

  // eslint-disable-next-line valid-jsdoc
  /**
    * Рендер инструмента.
    * Дисейблим кнопку с занесением азимута через карту в случае, если нет геометрии основной фичи.
    * Дисейблим кнопку разворота, если нет геометрии или не задан азимут.
    */
  render() {
    this.$el.html(this.geometryFieldTemplate());

    this.$el.find('.field-button').hide();

    const tools = this.$el.find('.input-append');
    _.each(this.tools, (tool) => {
      tools.append(tool.render().el);
    }, this);
    this.specificRender();

    this.toolCreate.$el.show();

    const mainFeature = this.model.feature;
    const featureHasGeometry = mainFeature && mainFeature.geometry;
    const azimuthNotAvailable = this.model.get('azimuth') === undefined && this.model.get('azimuth') == null;
    const geomIsPoint = featureHasGeometry ? mainFeature.geometry.CLASS_NAME === 'OpenLayers.Geometry.Point' : false;
    this.toolCreate.setDisabled(!featureHasGeometry);
    this.toolTurn.setDisabled(!featureHasGeometry && azimuthNotAvailable);
    this.toolGuess.setDisabled(!featureHasGeometry || !geomIsPoint);
    this.model.onChange(this.model, null);
    AIS.panoPanel.showPanoFeatures();

    return this;
  },
});


GeometryField.Highness = GeometryField.Measure.extend({
  /**
    * Получить текущее значение.
    * @returns {number} текущее значение
    */
  getValue() {
    if (this.isEmpty()) {
      return parseFloat(this.$el.find('.measure-input-value').val());
    }
    const points = this._shape.getPointList(this.getFeature());
    const iteratee = point => point.alt || 0;
    const min = _.min(points, iteratee).alt || 0;
    const max = _.max(points, iteratee).alt || 0;
    return max - min;
  },
});

export default GeometryField;
