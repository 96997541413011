import _ from 'underscore';
import RBush from 'rbush';
import OpenLayers from 'lib/OpenLayers-2.12/OpenLayers.debug';


const RTreeVector = OpenLayers.Class(OpenLayers.Layer.Vector, {
  renderers: ['SVG'],
  rtree: null,
  visiblefeatures: [],

  initialize: function (name, options) {
    if (options.displayInLayerSwitcher === undefined) {
      // eslint-disable-next-line no-param-reassign
      options.displayInLayerSwitcher = false;
    }
    OpenLayers.Layer.Vector.prototype.initialize.apply(this, [name, options]);
    this.rtree = new RBush();
  },

  destroy: function () {
    this.rtree = null;
    this.visiblefeatures = null;
    OpenLayers.Layer.Vector.prototype.destroy.apply(this, arguments);
  },

  rtreeItemFromFeature: function (feature) {
    if (!feature._rtree_vector_item) {
      const bounds = feature.geometry.getBounds();
      // eslint-disable-next-line no-param-reassign
      feature._rtree_vector_item = {
        minY: bounds.bottom,
        maxY: bounds.top,
        minX: bounds.left,
        maxX: bounds.right,
        feature,
      };
    }
    return feature._rtree_vector_item;
  },

  rtreeRemove: function (features) {
    if (features === this.features) {
      this.rtree.data = undefined;
      this.rtree = new RBush();
      this.visiblefeatures = [];
    } else {
      for (let i = 0; i < features.length; i += 1) {
        const featureindex = this.visiblefeatures.indexOf(features[i]);
        if (featureindex !== -1) {
          this.visiblefeatures.splice(featureindex, 1);
        }

        if (features[i].geometry) {
          this.rtree.remove(this.rtreeItemFromFeature(features[i]));
          // eslint-disable-next-line no-param-reassign
          delete features[i]._rtree_vector_item;
        }
      }
    }
  },

  rtreeAdd: function (features) {
    this.rtree.load(
      _.map(
        _.filter(
          features,
          f => !!f.geometry,
        ),
        this.rtreeItemFromFeature,
      ),
    );
  },

  /**
   * Обновить RTree для указанных фич.
   * @param {Array<*>} features массив фич
   */
  rtreeUpdate: function (features) {
    this.rtreeRemove(features);
    this.rtreeAdd(features);
  },

  rtreeSearch: function (extent) {
    return _.map(this.rtree.search(extent), item => item.feature);
  },

  removeAllFeatures: function (options) { // eslint-disable-line no-unused-vars
    this.rtreeRemove(this.features);

    OpenLayers.Layer.Vector.prototype.removeAllFeatures.apply(this, arguments);
  },

  removeFeatures: function (features, options) {
    if (!features || features.length === 0) {
      return;
    }
    if (features === this.features) {
      return this.removeAllFeatures(options);
    }
    if (!(OpenLayers.Util.isArray(features))) {
      // eslint-disable-next-line no-param-reassign
      features = [features];
    }
    if (features === this.selectedFeatures) {
      // eslint-disable-next-line no-param-reassign
      features = features.slice();
    }

    this.rtreeRemove(features);

    OpenLayers.Layer.Vector.prototype.removeFeatures.apply(this, arguments);
  },

  drawFeature: function (feature, style) {
    // don't try to draw the feature with the renderer if the layer is not
    // drawn itself
    if (!this.drawn) {
      return;
    }
    if (typeof style !== 'object') {
      if (!style && feature.state === OpenLayers.State.DELETE) {
        // eslint-disable-next-line no-param-reassign
        style = 'delete';
      }
      const renderIntent = style || feature.renderIntent;
      // eslint-disable-next-line no-param-reassign
      style = feature.style || this.style;
      if (!style) {
        // eslint-disable-next-line no-param-reassign
        style = this.styleMap.createSymbolizer(feature, renderIntent);
      }
    }
    if (style && style.CLASS_NAME === 'OpenLayers.Style') {
      // eslint-disable-next-line no-param-reassign
      style = style.createSymbolizer(feature);
    }

    const drawn = this.renderer.drawFeature(feature, style);
    // TODO remove the check for null when we get rid of Renderer.SVG
    if (drawn === false || drawn === null) {
      this.unrenderedFeatures[feature.id] = feature;
    } else {
      delete this.unrenderedFeatures[feature.id];
    }
  },

  addFeaturesRaw: function (features, options, skip_drawing) {
    if (!(OpenLayers.Util.isArray(features))) {
      // eslint-disable-next-line no-param-reassign
      features = [features];
    }

    const notify = !options || !options.silent;
    if (notify) {
      const event = { features: features };
      const ret = this.events.triggerEvent('beforefeaturesadded', event);
      if (ret === false) {
        return false;
      }
      // eslint-disable-next-line no-param-reassign, prefer-destructuring
      features = event.features;
    }

    // Track successfully added features for featuresadded event, since
    // beforefeatureadded can veto single features.
    const featuresAdded = [];
    for (let i = 0, len = features.length; i < len; i += 1) {
      if (i !== (features.length - 1)) {
        this.renderer.locked = true;
      } else {
        this.renderer.locked = false;
      }
      const feature = features[i];

      if (this.geometryType
        && !(feature.geometry instanceof this.geometryType)) {
        throw new TypeError(`addFeatures: component should be an ${
          this.geometryType.prototype.CLASS_NAME}`);
      }

      // give feature reference to its layer
      feature.layer = this;

      if (!feature.style && this.style) {
        feature.style = OpenLayers.Util.extend({}, this.style);
      }

      if (notify) {
        if (this.events.triggerEvent('beforefeatureadded',
          { feature: feature }) === false) {
          continue;
        }
        this.preFeatureInsert(feature);
      }

      featuresAdded.push(feature);
      this.features.push(feature);
      if (!skip_drawing) {
        /* Мы выключаем drawFeature при добавлении фич через addFeatures, потому что
          * в он будет вызываться для каждой добавленной фичи,
          * что приводит к массе проверок при первоначальном добавлении их большого кол-ва,
          * что в свою очередь снижает производительность.
          * Мы нарисуем их все после добавления вызвав moveTo (redraw) который только один раз с помощью индексов
          * выберет фичи попадающие в область видимости.
          * В остальных случаях мы оставляем этот метод включенным потому что он используется в других частях OL.
          */
        this.drawFeature(feature);
      }

      if (notify) {
        this.events.triggerEvent('featureadded', {
          feature: feature,
        });
        this.onFeatureInsert(feature);
      }
    }

    if (notify) {
      this.events.triggerEvent('featuresadded', { features: featuresAdded });
    }

    return true;
  },

  addFeatures: function (features, options) {
    if (!features || features.length === 0) {
      return;
    }

    const features_before_add_index = this.features.length;
    if (this.addFeaturesRaw(features, options, true)) {
      this.rtreeAdd(this.features.slice(features_before_add_index));
      this.redraw();
    }
  },

  moveTo: function (bounds, zoomChanged, dragging) {
    OpenLayers.Layer.prototype.moveTo.apply(this, arguments);

    let coordSysUnchanged = true;
    if (!dragging) {
      this.renderer.root.style.visibility = 'hidden';

      const viewSize = this.map.getSize();
      const viewWidth = viewSize.w;
      const viewHeight = viewSize.h;
      let offsetLeft = (viewWidth / 2 * this.ratio) - viewWidth / 2;
      let offsetTop = (viewHeight / 2 * this.ratio) - viewHeight / 2;
      offsetLeft += parseInt(this.map.layerContainerDiv.style.left, 10);
      offsetLeft = -Math.round(offsetLeft);
      offsetTop += parseInt(this.map.layerContainerDiv.style.top, 10);
      offsetTop = -Math.round(offsetTop);

      this.div.style.transform = `translate(${offsetLeft}px,${offsetTop}px)`;

      const extent = this.map.getExtent().scale(this.ratio);
      coordSysUnchanged = this.renderer.setExtent(extent, zoomChanged);

      this.renderer.root.style.visibility = 'visible';

      // Force a reflow on gecko based browsers to prevent jump/flicker.
      // This seems to happen on only certain configurations; it was originally
      // noticed in FF 2.0 and Linux.
      if (OpenLayers.IS_GECKO === true) {
        this.div.scrollLeft = this.div.scrollLeft;
      }
    }
    if (!this.drawn || zoomChanged || !coordSysUnchanged) {
      this.drawn = true;
      this.visiblefeatures = [];
      this.renderer.clear();
    }

    const mapext = this.map.getExtent();
    let visiblefeatures;
    if (this.rtree) {
      visiblefeatures = this.rtreeSearch({
        minY: mapext.bottom,
        maxY: mapext.top,
        minX: mapext.left,
        maxX: mapext.right,
      });
    } else {
      visiblefeatures = this.features.slice();
    }

    const event = {
      zoomChanged: zoomChanged,
      visiblefeatures: visiblefeatures,
    };
    const ret = this.events.triggerEvent('beforefeaturesdrawn', event);
    if (ret === false) {
      return;
    }
    // eslint-disable-next-line prefer-destructuring
    visiblefeatures = event.visiblefeatures;

    this.renderer.locked = true;

    for (let i = 0; i < this.visiblefeatures.length; i += 1) {
      if (visiblefeatures.indexOf(this.visiblefeatures[i]) === -1) {
        this.renderer.eraseFeatures(this.visiblefeatures[i]);
      }
    }

    for (let i = 0; i < visiblefeatures.length; i += 1) {
      if (this.visiblefeatures.indexOf(visiblefeatures[i]) === -1) {
        this.drawFeature(visiblefeatures[i]);
      }
    }

    this.renderer.locked = false;
    this.visiblefeatures = visiblefeatures;
  },

  CLASS_NAME: 'RTreeVector',
});

export default RTreeVector;
