import $ from 'jquery';
import _ from 'underscore';
import Backbone from 'js/backbone';


/**
 * Модальное окно с информацией о горячих клавишах.
 */
const HotkeysHelpModal = Backbone.View.extend({
  el: $('#hotkeys-help-window'),
  events: {
    'click .cancel': 'remove',
  },
  windowTemplate: _.template($('#tmpl-hotkeys-help-window').html()),
  /**
   * Инициализировать
   */
  initialize() {
    Backbone.View.prototype.initialize.apply(this, arguments);
    this.setElement(document.getElementById('hotkeys-help-window'));
    const { hotkeys } = this.options;
    this.hotkeys = hotkeys;
    this.render();
  },
  /**
   * Удалить модальное окно.
   */
  remove() {
    this.undelegateEvents();
    this.stopListening();
    this.$el.modal('hide');
  },
  /**
   * Содержимое модального окна.
   * @returns {String} строка с html
   */
  content() {
    return `<ul class="hotkeys-list">${this.hotkeys.reduce((str, hotkey) => (
      `${str}<li class="hotkeys-list-unit">
        <kbd class="hotkey">
          <span>${hotkey.key}</span>
        </kbd><span class="hotkey-def">${hotkey.description}</span>
      </li>`
    ), '')}</ul>`;
  },
  /**
   * Отрендерить модальное окно с контентом.
   * @returns {Object} текущий объект, как того требует Backbone
   */
  render() {
    this.$el.html(this.windowTemplate({}));
    this.$el.modal();
    this.$el.find('.modal-body').html(
      `<div>${this.content()}</div>`,
    );
    return this;
  },
});

/**
 * Показать модальное окно с информацией о горячих клавишах.
 * @returns {Object} инстанс HotkeysHelpModal
 */
const showHotkeysModal = () => new HotkeysHelpModal({
  hotkeys: Object.values(AIS.hotkeyContexts).flatMap(value => (
    Object.keys(value.keys).map(hotkey => (
      {
        key: hotkey,
        description: value.keys[hotkey].description,
      }
    )))),
});

export default showHotkeysModal;
