export default [
  {
    name: 'Пешеходные светофоры',
    items: {
      P1: {
        icon: '/icon/semaforo/P1.png',
        name: 'П.1',
      },
      P2: {
        icon: '/icon/semaforo/P2.png',
        name: 'П.2',
      },
    },
  },
  {
    name: 'Основные транспортные светофоры',
    items: {
      T1: {
        icon: '/icon/semaforo/T1.png',
        name: 'Т.1',
      },
      T1g: {
        icon: '/icon/semaforo/T1g.png',
        name: 'Т.1.г',
      },
      T1l: {
        icon: '/icon/semaforo/T1l.png',
        name: 'Т.1.л',
      },
      T1p: {
        icon: '/icon/semaforo/T1p.png',
        name: 'Т.1.п',
      },
      T1pl: {
        icon: '/icon/semaforo/T1pl.png',
        name: 'Т.1.пл',
      },
      T2l: {
        icon: '/icon/semaforo/T2l.png',
        name: 'Т.2.л',
      },
      T2r: {
        icon: '/icon/semaforo/T2r.png',
        name: 'Т.2.п',
      },
      T2s: {
        icon: '/icon/semaforo/T2s.png',
        name: 'Т.2 (прямо)',
      },
      T2sl: {
        icon: '/icon/semaforo/T2sl.png',
        name: 'Т.2 (прямо и налево)',
      },
      T2sr: {
        icon: '/icon/semaforo/T2sr.png',
        name: 'Т.2 (прямо и направо)',
      },
    },
  },
  {
    name: 'Прочие транспортные светофоры',
    items: {
      T3: {
        icon: '/icon/semaforo/T3.png',
        name: 'Т.3',
      },
      T3l: {
        icon: '/icon/semaforo/T3l.png',
        name: 'Т.3.л',
      },
      T3p: {
        icon: '/icon/semaforo/T3p.png',
        name: 'Т.3.п',
      },
      T4: {
        icon: '/icon/semaforo/T4.png',
        name: 'Т.4',
      },
      T4zh: {
        icon: '/icon/semaforo/T4zh.png',
        name: 'Т.4.ж',
      },
      T5: {
        icon: '/icon/semaforo/T5.png',
        name: 'Т.5',
      },
      T6: {
        icon: '/icon/semaforo/T6.png',
        name: 'Т.6',
      },
      T6d: {
        icon: '/icon/semaforo/T6d.png',
        name: 'Т.6.д',
      },
      T6dT10: {
        icon: '/icon/semaforo/T6dT10.png',
        name: 'Т.6.д с Т.10',
      },
      T7: {
        icon: '/icon/semaforo/T7.png',
        name: 'Т.7',
      },
      T8: {
        icon: '/icon/semaforo/T8.png',
        name: 'Т.8',
      },
      T9: {
        icon: '/icon/semaforo/T9.png',
        name: 'Т.9',
      },
      T10: {
        icon: '/icon/semaforo/T10.png',
        name: 'Т.10',
      },
    },
  },
  {
    name: 'Информационные секции',
    items: {
      ISl: {
        icon: '/icon/semaforo/ISl.png',
        name: 'ИС.л',
      },
      ISp: {
        icon: '/icon/semaforo/ISp.png',
        name: 'ИС.п',
      },
    },
  },
];
