import $ from 'jquery';
import _ from 'underscore';
import Backbone from 'js/backbone';
import AIS from 'js/AIS';
import api from 'js/api';
import KrpanoAnglesSolver from 'js/view/pano/getKrpanoAngles';
import PanoView from 'js/view/pano/Pano';


const PanoPanel = Backbone.View.extend({
  events: {
    'click #pano-adjust-button': 'adjust',
    'click .pano-close-button': 'closeExtra',
  },
  template: _.template($('#tmpl-pano-panel').html()),
  triangulationErrorTemplate: _.template($('#tmpl-triangulation-error').html()),
  initialize: function (options) {
    options.pano2.on('change:active', function (model, active) {
      if (active) {
        this.$el.find('#pano-adjust-button').prop('disabled', false);
        this.$el.find('.pano-close-button').prop('disabled', true);
      } else {
        this.$el.find('#pano-adjust-button').prop('disabled', true);
        this.$el.find('.pano-close-button').prop('disabled', false);
      }
    }, this);

    this.updatePermalink = _.debounce(this.updatePermalink, 200);

    this._ephemeras = new Backbone.Collection();
  },
  adjust() {
    const a = this.panoView.getPanoAngles();
    const rotate = KrpanoAnglesSolver.getKrpanoAngles(
      a,
      this.options.pano1.getRotations(),
      this.options.pano1.getTranslations(),
      this.options.pano2.getRotations(),
      this.options.pano2.getTranslations(),
    );
    this.panoView2.setPanoAngles(rotate);
  },
  closeExtra() {
    // If pano without 'url' field, assume it state to 'empty'
    this.options.pano2.set('url', null);
    this.options.pano2.set('visible', false);
  },
  createLayout() {
    this.panoLayout = this.$el.layout({
      north: {
        size: 40,
        resizable: false,
        closeable: false,
        spacing_open: 0,
        autoResize: false,
      },
      south: {
        size: 0.5,
        slidable: false,
        fxName: 'none',
        initClosed: true,
        autoResize: false,
      },
    });

    this.options.pano1.on('change:url', function () {
      AIS.layout.centerLayout.open('west');

      if (this.options.pano2.get('active')) {
        this.panoLayout.open('south');
      }

      this.updatePermalink();
    }, this);

    this.options.pano1.on('change:visible', (model, value) => {
      if (value) {
        AIS.layout.centerLayout.open('west');
      } else {
        AIS.layout.centerLayout.close('west');
      }
    }, this);

    this.options.pano2.on('change:visible', function (model, value) {
      if (value) {
        this.panoLayout.open('south');
      } else {
        this.panoLayout.close('south');
      }
    }, this);

    this.panoView.on('rotate', function () {
      this.updatePermalink();
    }, this);
  },
  /**
   * Обновить адресную строку в соответствии с активной панорамой.
   * Если активная панорама -- историческая, то обновление происходить не должно.
   */
  updatePermalink() {
    if (this.panoView.model.get('session') == null || this.panoView.model.get('is_archive_pano')) {
      return;
    }
    const link = `pano=${this.panoView.hashState()}`;
    AIS.router.navigate(link);
  },
  panoTrigger(name, event) {
    try {
      const view = this.pano(name);
      if (view) view.trigger(event);
    } catch (e) {
      console.error(e); // eslint-disable-line no-console
      throw e;
    }
  },
  panoTrigger1(name, event, arg) {
    try {
      const view = this.pano(name);
      if (view) view.trigger(event, arg);
    } catch (e) {
      console.error(e); // eslint-disable-line no-console
      throw e;
    }
  },
  panoTrigger2(name, event, arg1, arg2) {
    try {
      const view = this.pano(name);
      if (view) view.trigger(event, arg1, arg2);
    } catch (e) {
      console.error(e); // eslint-disable-line no-console
      throw e;
    }
  },
  pano(name) {
    if (name === 'main') {
      return this.panoView;
    }
    if (name === 'extra') {
      return this.panoView2;
    }
  },
  handleClickOnPanoHotspot(name, original_id, session) {
    const view = this.pano(name);
    if (view) view.navigate(parseInt(original_id, 10), session);
  },
  render() {
    this.$el.empty().append(this.template());
    this.$el.find('#pano-adjust-button').prop('disabled', true);

    this.panoView = new PanoView({
      el: $('.red-border'),
      model: this.options.pano1,
      workset: this.options.workset,
      isMain: true,
    });

    this.panoView2 = new PanoView({
      el: $('.blue-border'),
      model: this.options.pano2,
      workset: this.options.workset,
      isMain: false,
    });

    return this;
  },
  getEphCollection() {
    return this._ephemeras;
  },
  showError(message) {
    $('#triangulation-error').html(this.triangulationErrorTemplate({ message }));
    $('#triangulation-error').modal();
  },
  handleTriangulation(shape, feature, value) {
    if (!_.isObject(value)) {
      return feature;
    }

    if (value.error) {
      this.showError(value.error);
    } else if (value.result) {
      const point = AIS.transformReaderGeoJSON.read(value.result, 'Geometry');

      if (!feature || !feature.geometry) {
        return shape.createFeatureFromPoint(point);
      }
      shape.addComponent(feature, point);
    }
    return feature;
  },
  handleRoadPointLocate(shape, feature, pointNumber, points, value) {
    if (!_.isObject(value)) {
      return feature;
    }

    if (value.error) {
      this.showError(value.error);
    } else if (value.result) {
      const point = AIS.transformReaderGeoJSON.read(value.result, 'Geometry');
      points[pointNumber] = point;

      // Для линий и полигонов пересоздаём всю фичу заново на основе полученных точек.
      // Таким образом, даже если из-за тормозов при проецировании на бэкенде
      // точки будут получены в неправильном порядке, фича всё равно будет создана правильная.
      if (['Line', 'Polygon'].includes(shape.shapeName())) {
        if (points.includes(null)) {
          // Если ещё есть неспроецированные точки, то не будем рисовать фичу, дождёмся пока точки спроецируются.
          return feature;
        }
        const [firstPoint, ...nextPoints] = points.filter(point => point);
        const newFeature = shape.createFeatureFromPoint(firstPoint);
        nextPoints.forEach((point) => {
          shape.addComponent(newFeature, point);
        });
        return newFeature;
      }

      // для всех остальных фич действуем по старой схеме
      if (!feature || !feature.geometry) {
        return shape.createFeatureFromPoint(point);
      }
      shape.addComponent(feature, point);
      return feature;
    }
  },
  /**
   * Получить точку в плоскости дороги.
   * @param {{id: number, hlookat: number, vlookat: number}} panoIdAndAngles идентификатор панорамы и направление взгляда
   * @param {Object} shape форма
   * @param {Object} feature фича
   * @param {number} pointNumber номер точки по порядку
   * @param {Array<Object>} points массив точек
   * @param {Function} callback колбек
   */
  roadPointLocate(panoIdAndAngles, shape, feature, pointNumber, points, callback) {
    api.panoRoadPointLocate(
      panoIdAndAngles.id,
      panoIdAndAngles.hlookat,
      panoIdAndAngles.vlookat,
    ).then((data) => {
      callback(this.handleRoadPointLocate(shape, feature, pointNumber, points, data));
    }).catch(() => {
      this.showError('Неизвестная ошибка запроса');
    });
  },
  /**
   * Получить точку в плоскости дороги.
   * @param {{id: number, hlookat: number, vlookat: number}} panoIdAndAngles идентификатор панорамы и направление взгляда
   * @param {Object} shape форма
   * @param {Object} feature фича
   * @param {Function} callback колбек
   */
  roadPointLocateMeasure(panoIdAndAngles, shape, feature, callback) {
    api.panoRoadPointLocate(
      panoIdAndAngles.id,
      panoIdAndAngles.hlookat,
      panoIdAndAngles.vlookat,
    ).then((data) => {
      callback(this.handleTriangulation(shape, feature, data));
    }).catch(() => {
      this.showError('Неизвестная ошибка запроса');
    });
  },
  /**
   * Получить точку триангуляцией.
   * @param  {Object}   shape    форма
   * @param  {Object}   feature  фича
   * @param  {Function} callback колбек
   */
  triangulate(shape, feature, callback) {
    const pano1 = this.pano('main').getPanoIdAndAngles();
    const pano2 = this.pano('extra').getPanoIdAndAngles();
    if (pano1.id === pano2.id) {
      $('#notice-same-pano').modal();
      return;
    }
    api.panoTriangulate(
      pano1.id,
      pano1.hlookat,
      pano1.vlookat,
      pano2.id,
      pano2.hlookat,
      pano2.vlookat,
    ).then((data) => {
      callback(this.handleTriangulation(shape, feature, data));
    }).catch(() => {
      this.showError('Неизвестная ошибка запроса');
    });
  },
  getMeasureFeatures() {
    return this.panoView.getMeasureFeatures().concat(this.panoView2.getMeasureFeatures());
  },
  /**
   * Получить фичи измерения для отображения на панорамах.
   */
  showPanoFeatures() {
    this.panoView.showPanoFeatures();
    this.panoView2.showPanoFeatures();
  },
});

export default PanoPanel;
