import $ from 'jquery';
import _ from 'underscore';
import Backbone from 'js/backbone';


const Field = {};
// TODO: Field.render() almost same in every field. multi/differs same too
/**
 * Поле редактора - абстрактный класс.
 */
const AbstractField = Backbone.View.extend({
  copyButtonTemplate: _.template($('#tmpl-copy-button').html()),
  initialize: function () {
    this.options.fieldState.on('change:multi', function () {
      this.render();
      const field = this.options.property.getName();
      const value = this.model.get(field);
      this.showValue(value);
    }, this);
    this.options.fieldState.on('change:differs', function () {
      if (this.options.fieldState.get('multi')) {
        this.render();
      }
    }, this);
    this.options.fieldState.on('change:value', function (state, value) {
      this.showValue(value);
    }, this);

    this.options.fieldState.set('dirty', this.model.isNew());
  },
  copySwitch(evt) {
    const val = !$(evt.currentTarget).hasClass('active');
    this.options.fieldState.set('copy', val);
    $(evt.currentTarget).toggleClass('active');
  },
  getValue() {
    return this.$el.find('input').val();
  },
  updateValue() {
    const value = this.getValue();
    this.setValue(value);
    this.$el.find('.input').removeClass('default');
  },
  onChange(event) {
    this.options.fieldState.set('dirty', true);
    event.stopPropagation();
    this.updateValue();
  },
  lock() {
    this.$el.find('input').prop('disabled', true);
  },
  unlock() {
    this.$el.find('input').prop('disabled', false);
  },
  copyButton() {
    return this.copyButtonTemplate(this.options.fieldState.toJSON());
  },
  showValue(value) {
    const input = this.$el.find('.input');
    if (input.val() !== value) {
      input.val(value);
    }
  },
  /**
   * Установить новое значение поля.
   * @param {any} value новое значение
   * @param {boolean} doShowPanoFeatures флаг - перерисовывать ли фичу на панораме
   */
  setValue(value, doShowPanoFeatures = true) {
    const fieldName = this.options.property.getName();
    this.model.set(fieldName, value);

    this.options.fieldState.set('value', value);
    this.options.fieldState.set('differs', true);
    this.model.onChange(this.model, null);
    if (doShowPanoFeatures) {
      AIS.panoPanel.showPanoFeatures();
    }
  },
  /**
   * Установить начальное значение поля.
   */
  setInitialValue() {
    let value;
    const field = this.options.property.getName();
    if (this.model.isNew()) {
      value = this.options.inheritedValue;
      if (_.isUndefined(value) && ('getDefaultValue' in this.options.property)) {
        value = this.options.property.getDefaultValue();
      }
    } else {
      value = this.model.get(field);
    }
    this.setValue(value, false);
  },
});

/**
 * Поле выбора из нескольких вариантов.
 */
Field.Select = AbstractField.extend({
  events: {
    'change select, input': 'onChange',
    'click .copy-switch': 'copySwitch',
  },
  /**
   * Заблокировать ввод.
   */
  lock() {
    this.$el.find('select, input').prop('disabled', true);
  },
  /**
   * Разблокировать ввод.
   */
  unlock() {
    this.$el.find('select, input').prop('disabled', false);
  },
  /**
   * Получить текущее значение поля если оно отображается как Селект.
   * @returns {any} значение
   */
  getSelectValue() {
    return this.$el.find('select').val();
  },
  /**
   * Получить текущее значение поля если оно отображается как Радио.
   * @returns {any} значение
   */
  getRadioValue() {
    const fieldName = this.options.fieldState.get('fieldName');
    return this.$el.find(`input[name="field_edit_input_${fieldName}"]:checked`).val();
  },
  /**
   * Получить текущее значение поля.
   * @returns {any} значение
   */
  getValue() {
    return (
      this.options.property.get('radio')
        ? this.getRadioValue()
        : this.getSelectValue()
    ) || [];
  },
  /**
    * Обновить значение поля если оно отображается как Радио.
    * @param {any} value значение
    */
  showRadioValue(value) {
    const fieldName = this.options.fieldState.get('fieldName');
    this.$el.find(`input[name="field_edit_input_${fieldName}"]:not([value=${value}])`).prop('checked', false);
    this.$el.find(`input[name="field_edit_input_${fieldName}"][value=${value}]`).prop('checked', true);
  },

  // eslint-disable-next-line valid-jsdoc
  /**
    * Обновить значение поля.
    * @param {any} value значение
    */
  showValue(value) {
    return (
      this.options.property.get('radio')
        ? this.showRadioValue(value)
        : AbstractField.prototype.showValue.apply(this, arguments)
    );
  },
  /**
    * Отобразить поле как Селект.
    * @param  {any}    select список вариантов выбора
    * @returns {string}        `HTML` как строка
    */
  renderAsSelect(select) {
    return `
      <select id="field-${this.options.index}" class="scalar input input-medium">
      ${
  _.map(
    select,
    value => `<option value="${value.id}">${value.text}</option>`,
    this,
  ).join('\n')
}
      </select>
      ${this.copyButton()}`;
  },
  /**
    * Отобразить поле как Радио.
    * @param  {any}    select список вариантов выбора
    * @returns {string}        `HTML` как строка
    */
  renderAsRadio(select) {
    const fieldName = this.options.fieldState.get('fieldName');
    return `
      <div class="radio-input">
        ${
  _.map(
    select,
    value => `
      <label class="radio">
        <input
          type="radio"
          name="field_edit_input_${fieldName}"
          value="${value.id}"
        >
        ${value.text}
      </label>`,
    this,
  ).join('\n')
}
      </div>
      ${this.copyButton()}`;
  },

  // eslint-disable-next-line valid-jsdoc
  /**
    * Отобразить поле.
    */
  render() {
    const select = this.options.property.get('select');
    const input = (
      this.options.property.get('radio')
        ? this.renderAsRadio(select)
        : this.renderAsSelect(select)
    );
    this.$el.html(input);
    this.setInitialValue();
    return this;
  },
});

/**
  * Поле выбора `да`/`нет`/`н/д`.
  */
Field.Boolean = AbstractField.extend({
  events: {
    change: 'onChange',
    'click .copy-switch': 'copySwitch',
  },
  /**
    * Заблокировать ввод.
    */
  lock() {
    this.$el.find('input').prop('disabled', true);
  },
  /**
    * Разблокировать ввод.
    */
  unlock() {
    this.$el.find('input').prop('disabled', false);
  },
  /**
    * Получить текущее значение поля.
    * @returns {?boolean} значение
    */
  getValue() {
    const fieldName = this.options.fieldState.get('fieldName');
    const inputValue = this.$el.find(`input[name="field_edit_input_${fieldName}"]:checked`).val();
    const booleanMap = {
      '-1': null,
      0: false,
      1: true,
    };

    return booleanMap[inputValue];
  },

  /**
    * Обновить значение поля.
    * @param {?boolean} value значение
    */
  showValue(value) {
    const fieldName = this.options.fieldState.get('fieldName');
    const reverseBooleanMap = {
      null: '-1',
      false: '0',
      true: '1',
    };
    const inputValue = reverseBooleanMap[value];

    this.$el.find(`input[name="field_edit_input_${fieldName}"]:not([value="${inputValue}"])`).prop('checked', false);
    this.$el.find(`input[name="field_edit_input_${fieldName}"][value="${inputValue}"]`).prop('checked', true);
  },

  // eslint-disable-next-line valid-jsdoc
  /**
    * Отобразить поле.
    */
  render() {
    const fieldName = this.options.fieldState.get('fieldName');
    const input = `
      <div class="radio-input">
          <label class="radio inline">
              <input
                  type="radio"
                  name="field_edit_input_${fieldName}"
                  value="1"
              >
              да
          </label>
          <label class="radio inline">
              <input
                  type="radio"
                  name="field_edit_input_${fieldName}"
                  value="0"
              >
              нет
          </label>
          <label class="radio inline">
              <input
                  type="radio"
                  name="field_edit_input_${fieldName}"
                  value="-1"
                  checked
              >
              н/д
          </label>
      </div>
      ${this.copyButton()}`;
    this.$el.html(input);
    this.setInitialValue();
    return this;
  },
});

/**
 * Поле редактора - текст.
 */
Field.Text = AbstractField.extend({
  events: {
    'input textarea': 'onChange',
    'click .copy-switch': 'copySwitch',
  },
  getValue() {
    return this.$el.find('textarea').val();
  },
  /**
    * Заблокировать данное поле.
    */
  lock() {
    this.$el.find('textarea').prop('disabled', true);
  },
  /**
    * Разблокировать данное поле.
    */
  unlock() {
    this.$el.find('textarea').prop('disabled', false);
  },
  render() {
    const input = `<textarea id="field-${this.options.index}" class="filter input input-large" rows="2"/> ${
      this.copyButton()}`;
    this.$el.html(input);
    this.setInitialValue();
    return this;
  },
});

/**
 * Поле редактора - дата.
 */
Field.Date = AbstractField.extend({
  events: {
    'input input': 'onInputChange',
    'changeDate .date': 'onChangeDate',
    'clearDate .date': 'onClearDate',
    'hide .date': 'onHide',
    'click .copy-switch': 'copySwitch',
    'click .field-date-clear': 'onClear',
  },
  isValid(value) {
    if (value !== '' && value != null) {
      const m = value.match(/^(\d{1,2})\.(\d{1,2})\.(\d{4})$/);
      if (m === null || typeof m !== 'object') {
        return false;
      }
      if (m[1] < 1 || m[1] > 31) {
        return false;
      }
      if (m[2] < 1 || m[2] > 12) {
        return false;
      }
    }

    return true;
  },
  onInputChange(event) {
    const input = this.$el.find('input');
    if (this.isValid(input.val())) {
      input.css('background-color', '#fff');
      this.onChange(event);
    } else {
      input.css('background-color', 'rgba(255, 0, 0, 0.28)');
    }
  },
  onChangeDate(event) {
    this.onInputChange(event);
  },
  onClearDate(event) {
    this.$el.find('input').val('');
    this.onChangeDate(event);
  },
  onClear(event) {
    this.$el.find('.date').datepicker('hide');
    this.onClearDate(event);
  },
  onHide(event) {
    const input = this.$el.find('input');
    if (!this.isValid(input.val())) {
      input.val('');
      this.onChangeDate(event);
    }
  },
  // returns null if field is empty or utc timestamp in seconds
  getValue() {
    let val = null;
    const raw = this.$el.find('input').val();

    if (raw) {
      const parts = raw.split('.');
      const dt = new Date(Date.UTC(
        parseInt(parts[2], 10),
        parseInt(parts[1], 10) - 1,
        parseInt(parts[0], 10),
      ));
      val = dt.getTime() / 1000;
    }
    return val;
  },
  showValue(value) {
    const converted = this.options.property.asString(value);
    this.$el.find('.input').val(converted);
  },
  render() {
    const html = `${'<div class="input-append date">'
                // eslint-disable-next-line no-template-curly-in-string
                + '<input id="field-${this.options.index}" class="filter input input-small" type="text">'
                + '<span class="add-on"><i class="icon-calendar"></i></span>'
                + '<span class="add-on field-date-clear"><i class="icon-remove"></i></span>'
                + '</div>'}${
      this.copyButton()}`;
    this.$el.html(html);
    this.setInitialValue();
    this.$el.find('.date').datepicker({
      language: 'ru',
      format: 'd.mm.yyyy',
      forceParse: false,
      weekStart: 1,
    });
    return this;
  },
});

Field.Integer = AbstractField.extend({
  events: {
    'input input': 'onChange',
    'click .copy-switch': 'copySwitch',
  },
  getValue() {
    const value = parseInt(Math.round(parseFloat(this.$el.find('input').val())), 10);
    return Number.isNaN(value) ? null : value;
  },
  render() {
    const input = `<input id="field-${this.options.index}" class="filter input input-large" type="number" step="1"/> ${
      this.copyButton()}`;
    this.$el.html(input);
    this.setInitialValue();
    return this;
  },
});

/**
 * Поле редактора - число с плавающей запятой.
 */
Field.Float = Field.Integer.extend({
  getValue() {
    const value = parseFloat(this.$el.find('input').val());
    return Number.isNaN(value) ? null : value;
  },
  render() {
    const input = `<input id="field-${this.options.index}" class="filter input input-large" type="number" step="any" /> ${
      this.copyButton()}`;
    this.$el.html(input);
    this.setInitialValue();
    return this;
  },
});

/**
 * Поле редактора - автовычисляемое.
 */
Field.Calculation = AbstractField.extend({
  render() {
    const input = `<div>${this.options.property.extractFrom(this.model)}</div>`;
    this.$el.html(input);
    return this;
  },
});

/**
 * Поле редактора - ручные связи один-ко-многим.
 */
Field.OneToManyManualRelations = AbstractField.extend({
  /**
   * Инициализация.
   *
   * Получаем асинхронно список всех потенциальных родителей в связи для данного объекта,
   * записываем в `this.relations`, при помощи `this.ready` отслеживаем готовность асинхронной операции.
   */
  initialize: function () {
    AbstractField.prototype.initialize.apply(this);
    this.relations = null;
    this.state = {};
    this.ready = false;
    this.options.property.extractFrom(this.model)
      .then((result) => {
        this.relations = result;
        this.ready = true;
        this.render();
      });
  },
  /**
   * Показать значение в инпуте.
   *
   * Т.к. в этом поле есть несколько инпутов, мы переопределяем этот метод так, чтобы он ничего не делал.
   */
  showValue() {},
  /**
   * Получить текущее значение поля.
   *
   * @returns {Object} json, который должен быть в запросе серверу для редактирования связей
   */
  getValue() {
    return Object.entries(this.state).map(([relationName, { selectedId, enabled }]) => ({
      name: relationName,
      parent_id: selectedId,
      enabled: enabled,
    }));
  },
  /**
   * Обновить значение поля в состоянии родительского объекта.
   */
  updateValue() {
    this.options.fieldState.set('value', this.getValue());
    this.options.fieldState.set('differs', true);
  },
  /**
   * Сохранить значение поля в состоянии родительского объекта.
   */
  commitValue() {
    this.options.fieldState.set('value', this.getValue());
    this.options.fieldState.set('differs', true);
  },
  /**
   * Коллбэк, вызываемый при изменении значения поля.
   * @param {Event} event событие
   */
  onChange(event) {
    const validEvent = event == null ? new CustomEvent('') : event;
    AbstractField.prototype.onChange.call(this, validEvent);
  },
  /**
   * Заблокировать данное поле.
   */
  lock() {
    this.$el.find('select').prop('disabled', true);
  },
  /**
   * Разблокировать данное поле.
   */
  unlock() {
    this.$el.find('select').prop('disabled', false);
  },
  /**
   * Отрендерить компонент.
   *
   * Отображаем для каждой мануальной связи селект, в котором даем выбор из потенциальных родителей.
   * Действительного на данный момент родителя отмечаем символом "✔".
   * Изменения выбора в селектах запоминаем в `this.state`.
   * @returns {string} строка с отрендереным html
   */
  render() {
    /**
     * Конструктор коллбеков, вызываемых при каждом изменении одного из селекторов.
     * @param {Object} select JQuery-объект селектора
     * @param {string} relationName название связи
     */
    const onChange = (select, relationName) => {
      const value = select.val();
      let selectedId;
      let enabled = true;
      if (value === 'null') {
        const [currentlyEnabledParent] = this.relations[relationName].filter(({ enabled }) => enabled === true);
        if (currentlyEnabledParent) {
          selectedId = currentlyEnabledParent.id;
          enabled = false;
        } else {
          selectedId = null;
        }
      } else {
        selectedId = parseInt(value, 10);
      }
      if (selectedId !== null) {
        this.state[relationName] = {
          enabled,
          selectedId,
        };
        this.onChange();
      }
    };

    /**
     * Создать html опцию, представляющую потенциального родителя в связи.
     * @param {?string} name имя родителя или null
     * @param {number} id идентификатор родителя
     * @param {boolean} enabled включена ли связь с этим родителем
     * @returns {string} готовый html
     */
    const makeRelationOption = (name, id, enabled) => {
      const optionText = name || `#${id}`;
      const optionCheckedText = `${enabled === true ? '✔ ' : ''}${optionText}`;
      const selectedAttribute = enabled === true ? ' selected' : '';
      return `<option${selectedAttribute} value="${id}">
        ${optionCheckedText}
       </option>`;
    };

    /**
     * Создать html селекта с потенциальными родителями для связи.
     * @param {string} id идентификатор элемента в DOM
     * @param {string} label метка для инпута
     * @param {string[]} options список шаблонов опций селекта
     * @returns {string} готовый html
     */
    const makeSelectElement = (id, label, options) => (
      `<label for="${id}">
         <span class="edit-name">${label}</span>
       </label>
       <select id="${id}" class="scalar input input-medium">
         <option value="null"> </option>
         ${options.join('\n')}
       </select>`
    );

    if (this.ready === false) {
      return this;
    }

    const selectElements = Object.entries(this.relations)
      .map(([relationName, candidates]) => {
        const options = candidates
          .sort(({ name: nameA }, { name: nameB }) => (nameA || '').localeCompare(nameB || ''))
          .map(
            ({ name, id, enabled }) => makeRelationOption(name, id, enabled),
          );
        const selectElementId = `field-${this.options.index}-${relationName}`;
        return {
          relation: relationName,
          selectId: selectElementId,
          element: makeSelectElement(selectElementId, relationName, options),
        };
      });

    // добавляем элементы селектов в DOM
    this.$el.html(selectElements.map(({ element }) => element).join('\n'));

    // теперь, когда селекты уже добавлены, можно повесить на их изменения обработчики
    selectElements.forEach(({ relation, selectId }) => {
      const selectElement = $(`#${selectId}`);
      selectElement.change(() => onChange(selectElement, relation));
    });

    return this;
  },
});

export default Field;
