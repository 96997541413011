import OpenLayers from 'lib/OpenLayers-2.12/OpenLayers.debug';


const CompactPopup = OpenLayers.Class(OpenLayers.Popup.FramedCloud, {
  contentDisplayClass: 'olCompactPopupContent',
  relativePosition: 'tr',
  fixedRelativePosition: true,
  minSize: new OpenLayers.Size(84, 10),
  propagateMouse: false,

  positionBlocks: {
    tr: {
      offset: new OpenLayers.Pixel(-35, 0),
      padding: new OpenLayers.Bounds(5, 10, 5, 5),
      blocks: [
        { // stem
          size: new OpenLayers.Size(81, 35),
          anchor: new OpenLayers.Bounds(-10, -15, null, null),
          position: new OpenLayers.Pixel(-215, -702),
        },
      ],
    },
  },

  onmousedown(evt) {
    this.mousedown = true;
    if (!this.propagateMouse) {
      OpenLayers.Event.stop(evt, true);
    }
  },

  onmouseup(evt) {
    if (this.mousedown) {
      this.mousedown = false;
      if (!this.propagateMouse) {
        OpenLayers.Event.stop(evt, true);
      }
    }
  },

  onclick(evt) {
    if (!this.propagateMouse) {
      OpenLayers.Event.stop(evt, true);
    }
  },

  ondblclick(evt) {
    if (!this.propagateMouse) {
      OpenLayers.Event.stop(evt, true);
    }
  },

  setMousePropogation(propagateMouse) {
    this.propagateMouse = Boolean(propagateMouse);
  },

  setOpacity(opacity) {
    if (opacity !== undefined) {
      this.opacity = opacity;
    }

    if (this.div != null) {
      // for Mozilla and Safari
      this.div.style.opacity = this.opacity;

      // for IE
      this.div.style.filter = `alpha(opacity=${this.opacity * 100})`;
    }
  },

  setBackgroundColor(color) {
    if (color !== undefined) {
      this.backgroundColor = color;
    }

    if (this.div != null) {
      this.contentDiv.style.backgroundColor = this.backgroundColor;
    }
  },

  setContentHTML(contentHTML) {
    if (contentHTML != null) {
      this.contentHTML = contentHTML;
    }

    if ((this.contentDiv != null)
                && (this.contentHTML != null)
                    && (
                      (typeof this.contentHTML === 'string' || this.contentHTML !== this.contentDiv.innerHTML)
                        || (this.contentDiv.childNodes.length === 1 && this.contentDiv.childNodes[0] !== this.contentHTML)
                    )
    ) {
      if (typeof this.contentHTML === 'string') {
        this.contentDiv.innerHTML = this.contentHTML;
      } else {
        // Без очистки от всех child элемента новый элемент, переданный в данный момент,
        // будет просто добавляться к остальным элементам. Непонятно, почему этот метод тогда setContentHTML.
        // И тогда вроде как выглядит разумной верхняя проверка на количество чаилдов в элементе.
        this.contentDiv.innerHTML = '';
        this.contentDiv.appendChild(this.contentHTML);
      }

      if (this.autoSize) {
        // if popup has images, listen for when they finish
        // loading and resize accordingly
        this.registerImageListeners();

        // auto size the popup to its current contents
        this.updateSize();
      }
    }
  },

  destroy() {
    if (this.events) {
      this.events.triggerEvent('destroy');
      OpenLayers.Popup.FramedCloud.prototype.destroy.apply(this, arguments);
    }
  },

  CLASS_NAME: 'CompactPopup',
});

export default CompactPopup;
