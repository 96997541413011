import $ from 'jquery';
import _ from 'underscore';
import Backbone from 'js/backbone';


const DateFilter = Backbone.View.extend({
  className: 'panel',
  template: _.template($('#tmpl-date-filter').html()),
  events: {
    'changeDate .date': 'onChangeDate',
    'click .icon-chevron-down': 'expand',
    'click .icon-chevron-left': 'expand',
    'click .commit-button': 'commit',
    'click .clear-button': 'clear',
    'click .field-date-clear': 'onClear',
  },
  onChangeDate() {
    this.$el.find('.date').datepicker('hide');
  },
  onClear() {
    this.$el.find('.date').datepicker('hide');
    this.$el.find('input').val('');
  },
  expand() {
    if (!this.expanded) {
      this.$el.find('.content').show();
    } else {
      this.$el.find('.content').hide();
    }

    if (this.expanded) {
      this.$el.find('.icon-chevron-down').removeClass('icon-chevron-down').addClass('icon-chevron-left');
    } else {
      this.$el.find('.icon-chevron-left').removeClass('icon-chevron-left').addClass('icon-chevron-down');
    }
    this.expanded = !this.expanded;
  },
  commit() {
    this.model.submit('date_filter', this.$el.find('.input').val());
  },
  clear() {
    this.$el.find('.input').val(null);
    this.model.submit('date_filter', {});
  },
  render() {
    this.$el.empty().append(this.template());

    this.$el.find('.date').datepicker({
      language: 'ru',
      format: 'yyyy-mm-dd',
      weekStart: 1,
    });

    if (!this.expanded) {
      this.$el.find('.content').hide();
    }
    return this;
  },
});

export default DateFilter;
