import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button, Icon, Menu, Dropdown,
} from 'antd';

import styles from './style.less';


const QuickCreationButton = (props) => {
  const {
    workset,
    meta,
    onQuickCreationClick,
    isUserReadOnly,
  } = props;

  const [isDropdownVisible, setDropdownVisibility] = useState(false);

  const visibleLayers = workset.entities.filter(layer => layer.visible);
  const disabled = !visibleLayers.length || isUserReadOnly;

  /**
   * Скрыть дропдаун и открыть редактор для создания фичи.
   * @param {Object} meta объект с информацией о слое
   */
  const onClick = (meta) => {
    setDropdownVisibility(false);
    onQuickCreationClick(meta);
  };

  // Слой может не иметь группы.
  // Для каждого слоя без группы будет создана своя "безымянная" группа.
  const unnamedGroupPrefix = '__unnamedGroup';
  let unnamedGroupCounter = 0;

  const groupedVisibleLayers = visibleLayers.reduce((groupedLayers, layer) => {
    let groupName = meta.by_id[layer.id].group;
    if (groupName == null) {
      groupName = `${unnamedGroupPrefix}_${unnamedGroupCounter}`;
      unnamedGroupCounter += 1;
    }
    groupedLayers[groupName] = groupedLayers[groupName] || [];
    groupedLayers[groupName].push(layer);
    return groupedLayers;
  }, {});

  const layersDropdown = (
    <Menu>
    {
      Object.entries(groupedVisibleLayers).map(([groupName, groupLayers]) => {
        // Слои безымянной группы должны отображаться будто у них нет группы
        if (groupName.startsWith(unnamedGroupPrefix)) {
          return groupLayers.map(layer => (
            <Menu.Item
              key={layer.id}
              onClick={() => onClick(layer.meta)}
            >
              <span>{meta.by_id[layer.id].objectName}</span>
            </Menu.Item>
          ));
        }
        // Слои именованной группы отображаются сгруппированными
        return (
          <Menu.ItemGroup key={groupName} title={groupName} className={styles.quickCreationDropdownGroup}>
            {groupLayers.map(layer => (
              <Menu.Item
                key={layer.id}
                className={styles.quickCreationDropdownItem}
                onClick={() => onClick(layer.meta)}
              >
                <span>{meta.by_id[layer.id].objectName}</span>
              </Menu.Item>
            ))}
          </Menu.ItemGroup>
        );
      })
    }
    </Menu>
  );

  return (
    <Dropdown
      overlayClassName={styles.quickCreationDropdown}
      overlay={layersDropdown}
      disabled={disabled}
      // дропдаун сворачивается только при уходе курсора из него,
      // а мы хотим, чтобы он скрывался при клике на пункт, поэтому приходится
      // вручную управлять его скрытием/показыванием
      visible={isDropdownVisible}
      onVisibleChange={setDropdownVisibility}
    >
      <Button type="primary">Создать <Icon type="caret-down"/></Button>
    </Dropdown>
  );
};

QuickCreationButton.propTypes = {
  workset: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  isUserReadOnly: PropTypes.bool.isRequired,
  onQuickCreationClick: PropTypes.func.isRequired,
};

export default QuickCreationButton;
