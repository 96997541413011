import Backbone from 'js/backbone';


const PointCoordinates = Backbone.View.extend({
  tagName: 'li',
  className: 'no-decorate',
  getGeometry() {
    return this.options.point;
  },
  highlight(highlight) {
    if (highlight) this.$el.addClass('bold');
    else this.$el.removeClass('bold');
  },
  render() {
    const g = this.options.point.clone();
    g.transform('EPSG:900913', this.options.proj || 'EPSG:4326');

    const text = `<code class="point">${this.formatCoordinates(g)}</code>`;

    this.$el.html(text);
    return this;
  },
  formatCoordinates(g) {
    if (this.options.local) {
      return `${Math.abs(g.y).toFixed(2)} ${
        Math.abs(g.x).toFixed(2)} ${
        g.alt ? (`${g.alt.toFixed(2)} м.`) : ''}`;
    }
    return Math.abs(g.y).toFixed(7)
      + (g.y > 0 ? 'N ' : 'S ')
      + Math.abs(g.x).toFixed(7)
      + (g.x > 0 ? 'E ' : 'W ')
      + (g.alt ? (`${g.alt.toFixed(2)} м.`) : '');
  },
});

export default PointCoordinates;
