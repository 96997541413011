import $ from 'jquery';
import _ from 'underscore';
import Backbone from 'js/backbone';
import ClassFilter from 'js/view/filter/ClassFilter';


const ClassFilterGroup = Backbone.View.extend({
  template: _.template($('#tmpl-class-filter-group').html()),
  views: null,
  events: {
    'change .group-layer-trigger': 'filterToggle',
    'click .group-expander': 'toggle',
    'click .group-close': 'close',
  },
  initialize: function (groupName) {
    this.views = [];
    this.groupName = groupName;
  },
  viewsVisible() {
    return _.find(this.views, view => view.getVisible()) !== undefined;
  },
  updateVisible() {
    this.$el.find('.group-layer-trigger').prop('checked', this.viewsVisible());
  },
  updateFiltered() {
    const filtered = _.find(this.views, view => view.getFiltered()) !== undefined;
    if (this.filteredMarkActive === filtered) return;
    if (filtered) {
      this.$el.find('.group-expander').append('<i class="icon-filter"></i>');
    } else {
      this.$el.find('.group-expander i').remove();
    }
    this.filteredMarkActive = filtered;
  },
  addView(view) {
    this.views.push(view);
    this.$el.find('.group-extra-panel').append(view.el);
    this.updateVisible();
    this.listenTo(view.model, 'change:visible', this.updateVisible);
    this.listenTo(view.model, 'change:filtered', this.updateFiltered);
  },
  rmView(view) {
    this.views = _.without(this.views, view);
    this.stopListening(view.model, 'change:visible');
    this.stopListening(view.model, 'change:filtered');
  },
  size() {
    return _.size(this.views);
  },
  close() {
    _.each(this.views, (view) => {
      view.close();
    });
  },
  setVisible(_visible) {
    const visible = Boolean(_visible);
    this.$el.find('.group-layer-trigger').prop('checked', visible);
    _.each(this.views, (view) => {
      view.setVisible(visible);
    });
  },
  filterToggle() {
    const total = this.views.filter(view => !view.isClassEdited()).length;
    const visible = this.views.filter(view => !view.isClassEdited()).filter(view => view.getVisible()).length;
    this.setVisible(visible < (total / 2.0));
  },
  toggle() {
    this.$el.find('.group-extra-panel').toggle();
  },
  render() {
    this.$el.html(this.template({
      visible: this.viewsVisible(),
      groupName: this.groupName,
    }));
    const selector = this.$el.find('.group-extra-panel');
    _.each(this.views, (view) => {
      selector.append(view.el);
    }, this);
    return this;
  },
});

const FilterForm = Backbone.View.extend({
  className: 'panel',
  views: null,
  groups: null,
  filteredMarkActive: null,
  events: {
    'click .toggle-all': 'toggleAll',
    'click .close-all': 'closeAll',
    'click .reset-all': 'resetAll',
  },
  initialize: function () {
    this.views = {};
    this.groups = {};

    this.collection.on('reset', this.render, this);
    this.collection.on('add', this.addItem, this);
    this.collection.on('remove', this.rmItem, this);
    this.options.meta.on('reset', this.render, this);

    this.collection.on('add', (classFilter) => {
      classFilter.connect();
    });
    this.collection.on('remove', (classFilter) => {
      classFilter.disconnect();
    });
    AIS.on('editor:close editor:started', this.updateDisabled, this);
  },
  /**
   * Подписаться на уведомления о загрузке данных.
   */
  subscribeLoading() {
    AIS.layersEvents.on('notify', ({ payload }) => this.updateLoading(payload), this);
  },
  viewsVisible() {
    const total = Object.values(this.views).filter(view => !view.isClassEdited()).length;
    const visibleCount = Object.values(this.views)
      .filter(view => !view.isClassEdited())
      .filter(view => view.getVisible()).length;
    return (visibleCount > (total / 2.0));
  },
  /**
   * Включить/выключить индикатор загрузки.
   *
   * @param {Boolean} isLoading состояние индикатора
   */
  updateLoading(isLoading) {
    if (isLoading) {
      this.$el.find('.spinner').show();
    } else {
      this.$el.find('.spinner').hide();
    }
  },
  updateVisible() {
    this.$el.find('.toggle-all').text(this.viewsVisible() ? 'Скрыть все' : 'Показать все');
  },
  closeAll() {
    _.each(this.views, (view) => {
      view.close();
    });
  },
  toggleAll() {
    const visible = !this.viewsVisible();
    _.each(this.views, (view) => {
      view.setVisible(visible);
    });
  },
  /**
   * Сбросить все фильтры по полям всех слоёв.
   */
  resetAll() {
    this.collection.resetFilters();
  },
  reset() {
    _.each(this.views, (view) => {
      view.remove();
    });
    _.each(this.groups, (view) => {
      view.remove();
    });
    this.views = {};
    this.groups = {};
  },
  addItem(filter) {
    const classFilter = (new ClassFilter({ model: filter })).render();
    const group = filter.getMeta().getGroup();
    const groupCreated = Boolean(this.groups[group]);
    let groupView = null;
    if (group) {
      groupView = (
        (groupCreated)
          ? this.groups[group]
          : (this.groups[group] = (new ClassFilterGroup(group)).render())
      );
    }
    this.listenTo(filter, 'change:visible', this.updateVisible);
    this.views[filter.getMeta().getClassName()] = classFilter;
    if (groupView) {
      if (!groupCreated) {
        this.$el.append(groupView.el);
      }
      groupView.addView(classFilter);
    } else {
      this.$el.append(classFilter.el);
    }
  },
  rmItem(filter) {
    const group = filter.getMeta().getGroup();
    const groupView = this.groups[group];
    const view = this.views[filter.getMeta().getClassName()];
    if (view.isClassEdited()) {
      return;
    }
    this.stopListening(filter, 'change:visible');
    if (group && groupView) {
      groupView.rmView(view);
      if (groupView.size() < 1) {
        groupView.remove();
        delete this.groups[group];
      }
    }
    view.remove();
  },
  /**
   * Обновить свойство `disabled` у всех чекбоксов для скрытия слоёв.
   */
  updateDisabled() {
    Object.values(this.views).forEach(view => view.updateDisabled());
  },
  render() {
    this.$el.html(`
      <div class="panel-name">
          <span>Слои</span>
          <img src="/icon/ajax-loader.gif" class="spinner">
      </div>
      <div class="btn-group">
          <button class="btn btn-mini toggle-all" style="width: 100px">Скрыть все</button>
          <button class="btn btn-mini close-all">Удалить все</button>
          <button class="btn btn-mini reset-all">Сбросить фильтры</button>
      </div>
    `);
    this.reset();
    this.collection.each(function (filter) {
      this.addItem(filter);
    }, this);
    this.updateLoading();
    return this;
  },
});

export default FilterForm;
