import React from 'react';
import PropTypes from 'prop-types';
import { Button, Icon } from 'antd';

import styles from './style.less';


const UserActionsButton = (props) => {
  const { username, onLogout } = props;

  return (
    <Button.Group>
      <Button id={styles.username} title="Имя пользователя">
        <Icon type="user"/> {username}
      </Button>
      <Button
        title="Выйти"
        type="danger"
        onClick={onLogout}
      >
        <Icon type="logout"/>
      </Button>
    </Button.Group>
  );
};

UserActionsButton.propTypes = {
  username: PropTypes.string.isRequired,
  onLogout: PropTypes.func.isRequired,
};

export default UserActionsButton;
