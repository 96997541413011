import _ from 'underscore';
import Backbone from 'js/backbone';
import User from 'js/model/User';


const SelectOption = Backbone.View.extend({
  tagName: 'option',
  render() {
    this.$el.text(this.model.get('objectName'));
    this.$el.attr('value', this.model.id);
    return this;
  },
});

const SelectOptionGroup = Backbone.View.extend({
  tagName: 'optgroup',
  groupName: null,
  views: null,
  initialize: function (groupName) {
    this.groupName = groupName;
    this.views = [];
  },
  addView(view) {
    this.views.push(view);
    this.render();
  },
  render() {
    this.$el.empty().attr('label', this.groupName);
    _.each(this.views, function (view) {
      this.$el.append(view.render().el);
    }, this);
    if (_.size(this.views) > 1) {
      this.$el.append($('<option></option>')
        .text(`Все в группе "${this.groupName}"`)
        .val(_.map(this.views, view => view.model.get('id')).join()));
    }
    return this;
  },
});

const ClassSelectTool = Backbone.View.extend({
  className: 'panel',
  views: null,
  events: {
    'click .add-one': 'addFilter',
    'click .add-all': 'addAllFilters',
  },
  initialize: function () {
    this.views = [];
    this.collection.on('reset', this.render, this);
    User.on('change:layers', this.render, this);
  },
  getAvailable() {
    return this.collection.filter(model => (
      !model.get('hiderestricted')
    ) && (
      User.get('layers') === null
      || User.get('layers').indexOf(model.getClassName()) !== -1
    ));
  },
  addFilter() {
    _.each(this.$el.find('select').val().toString().split(','), function (classId) {
      const meta = this.collection.get(classId);

      this.options.workset.appendMeta(meta);
    }, this);
  },
  addAllFilters() {
    _.each(this.getAvailable(), function (meta) {
      this.options.workset.appendMeta(meta);
    }, this);
  },
  reset() {
    _.each(this.views, (view) => {
      view.remove();
    });
    this.views = [];
  },
  render() {
    this.$el.html('<label><span class="panel-name">Добавление слоя</span><select></select></label>'
                + '<button class="btn add-one">Добавить</button>'
                + '<button class="btn add-all">Добавить все</button>');
    this.reset();
    const select = this.$el.find('select');
    const groups = {};
    _.each(this.getAvailable(), function (model) {
      const view = new SelectOption({ model });
      const group = model.getGroup();
      const groupCreated = Boolean(groups[group]);
      let groupView = null;
      if (group) {
        groupView = (
          (groupCreated)
            ? groups[group]
            : (groups[group] = new SelectOptionGroup(group))
        );
      }
      if (groupView) {
        if (!groupCreated) {
          select.append(groupView.render().el);
        }
        this.views.push(groupView);
        groupView.addView(view);
      } else {
        this.views.push(view);
        select.append(view.render().el);
      }
    }, this);
    return this;
  },
});

export default ClassSelectTool;
