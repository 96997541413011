import _ from 'underscore';
import {
  DoubleGIS, Astana, Default,
  Google, LocalOSM, Spb,
  OnlineOSM, Eeko,
} from './base_layers';

const layersMapping = {
  '2gis': DoubleGIS,
  Astana,
  Default,
  Google,
  LocalOSM,
  Spb,
  OnlineOSM,
  Eeko,
};

const getBaseLayers = (mapConfig) => {
  const baseLayers = (!mapConfig) ? ['Default'] : mapConfig.map(layerName => layerName.trim());
  let layers = [];
  baseLayers.forEach((layerName) => {
    const layer = layersMapping[layerName];
    if (_.isArray(layer)) {
      layers = layers.concat(layer);
    } else {
      layers.push(layer);
    }
  });
  return layers;
};

export default getBaseLayers;
