import * as actionTypes from 'js/constants/actionTypes';
import AIS from 'js/AIS';

const editorMiddleware = () => next => (action) => {
  if (action.type === actionTypes.EDITOR_CREATE) {
    AIS.trigger('editor:blank', action.meta);
  }

  next(action);
};

export default editorMiddleware;
