import $ from 'jquery';
import _ from 'underscore';
import OpenLayers from 'lib/OpenLayers-2.12/OpenLayers.debug';
import OpenLayersTheme from 'lib/OpenLayers-2.12/theme/default/style.css';
import Backbone from 'js/backbone';
import AIS from 'js/AIS';
import api from 'js/api';
import * as consts from 'js/consts';
import PointLayer from 'js/view/map/PointLayer';
import MultipleSelectFeature from 'js/view/map/MultipleSelectFeature';
import PolygonOrLineLayer from 'js/view/map/PolygonOrLineLayer';
import PropertiesTable from 'js/view/PropertiesTable';
import SelectFeature from 'js/view/map/SelectFeature';
import CompactPopup from 'js/view/map/CompactPopup';
import SwitchablePopup from 'js/view/map/SwitchablePopup';
import RTreeSnappingControl from 'js/view/map/RTreeSnappingControl';
import { declOfNum } from 'js/utils';
import 'js/view/map/sld.xml';


const CustomOLMap = OpenLayers.Class(OpenLayers.Map, {
  isValidZoomLevel(zoomLevel) {
    return ((zoomLevel != null)
      && (zoomLevel >= consts.MAP_MIN_ZOOM)
      && (zoomLevel < this.getNumZoomLevels()));
  },
  moveTo(lonlat, zoom) { // eslint-disable-line no-unused-vars
    /* Не выполняем данный метод в случае, если зум, при котором он выполняется не меньше минимального зума.
        Фиксим баг с тем, что при минимальном зуме при попытке "отдалить" карту, она начинает скакать. */
    if (zoom && zoom < consts.MAP_MIN_ZOOM) {
      return;
    }
    OpenLayers.Map.prototype.moveTo.apply(this, arguments);
  },

  setLayerZIndex(layer, zIdx) {
    const zindex = this.Z_INDEX_BASE[layer.isBaseLayer ? 'BaseLayer' : 'Overlay'] + zIdx;
    if (layer.isBaseLayer && zindex > this.Z_INDEX_BASE.Overlay) {
      console.error('Openlayers BaseLayer: z-index overflow'); // eslint-disable-line no-console
    }
    layer.setZIndex(zindex);
  },
});

const PanoLayerSwitcher = OpenLayers.Class(OpenLayers.Control, {
  CLASS_NAME: 'OpenLayers.Control.PanoLayerSwitcher',
  autoActivate: true,
  draw() {
    OpenLayers.Control.prototype.draw.apply(this, arguments);

    $(this.div).html('<label><input type="checkbox" checked>Панорамы</label>').addClass('olButton');

    return this.div;
  },
  togglePanoLayer(enable) {
    this.status = enable;
    $(this.div).find('input:checkbox').prop('checked', this.status);
  },
  onButtonClick(event) {
    if (event.buttonElement === this.div) {
      AIS.trigger('togglePanoLayer', !this.status);
    }
  },
  activate() {
    if (OpenLayers.Control.prototype.activate.apply(this, arguments)) {
      AIS.on('togglePanoLayer', this.togglePanoLayer, this);
      this.map.events.register('buttonclick', this, this.onButtonClick);
    }
  },
  deactivate() {
    if (OpenLayers.Control.prototype.deactivate.apply(this, arguments)) {
      AIS.off('togglePanoLayer', this.togglePanoLayer, this);
      this.map.events.unregister('buttonclick', this, this.onButtonClick);
    }
  },
});

/** Крутой компонент "Расчет площади уборки" */
const MeasureCleaningAreasSwitcher = OpenLayers.Class(OpenLayers.Control, {
  CLASS_NAME: 'OpenLayers.Control.MeasureCleaningAreasSwitcher', /** Передаем класс для стилей */
  autoActivate: true,
  enabled: false, // Состояние компонента
  draw() {
    OpenLayers.Control.prototype.draw.apply(this, arguments);
    /** Чекбокс для взаимодействия */
    $(this.div).html('<label><input type="checkbox">Расчет площади уборки</label>').addClass('olButton');

    return this.div;
  },
  toggleMeasureCleaning(enable) {
    /** При нажатии чекбокса изменим его состояние на нажатый/отжатый в соответствии со status */
    this.enabled = enable;
    $(this.div).find('input:checkbox').prop('checked', this.enabled);
  },
  onButtonClick(event) {
    if (event.buttonElement === this.div) {
      /** Вызываем этот евент каждый раз, когда чекбокс был нажат */
      AIS.trigger('toggleMeasureCleaning', !this.enabled);
    }
  },
  activate() {
    if (OpenLayers.Control.prototype.activate.apply(this, arguments)) {
      /** Создаем новый евент с колбеком toggleMeasureClean */
      AIS.on('toggleMeasureCleaning', this.toggleMeasureCleaning, this);
      /** Регистрируем эвент нажатия клавиши, на который вешаем метод onButtonClick */
      this.map.events.register('buttonclick', this, this.onButtonClick);
    }
  },
  deactivate() {
    if (OpenLayers.Control.prototype.deactivate.apply(this, arguments)) {
      AIS.off('toggleMeasureCleaning', this.toggleMeasureCleaning, this);
      this.map.events.unregister('buttonclick', this, this.onButtonClick);
    }
  },
});

const WidthInput = Backbone.View.extend({
  /**
    Инпут для ввода ширины с кнопкой подверждения. При нажатии на нее отправляем запрос на сервер,
    где формируем геометрию, а затем отправляем обратно
    */
  events: {
    'input input': 'onInputChange',
    'click button': 'getFeature',
    keyup: 'keyUp',
  },

  template: _.template($('#tmpl-width-input').html()),

  getInputVal() {
    const input = this.$el.find('input');
    return input.val();
  },
  render() {
    this.$el.empty().append(this.template());
    this.$el.find('.btn').prop('disabled', true);
    return this;
  },
  /** При нажатии на кнопку "ОК" передаем ширину из инпута в функцию, переданную при создании инстанса этой вьюхи */
  getFeature() {
    const inputVal = this.getInputVal();
    if (inputVal) {
      this.options.getFeatureFromServer(this.getInputVal());
    }
  },
  onInputChange() {
    /** Дисейблим кнопку, если данные не были введены */
    if (this.getInputVal()) {
      this.$el.find('.btn').prop('disabled', false);
    } else {
      this.$el.find('.btn').prop('disabled', true);
    }
  },
  /** Отлавливаем нажатия кнопок.
   *
   * Если внутри инпута нажат Enter, то не пропускаем это событие дальше родительским элементам
   * и вызываем getFeature.
   *
   * @param {Event} event - вызванное событие
   */
  keyUp(event) {
    if ((event.keyCode === 13 || event.keyCode === 10)) {
      event.stopPropagation();
      this.getFeature();
    }
  },
});

const MapView = Backbone.View.extend({
  initialize: function (options) {
    const me = this;

    this.registerPrioritySelectControl = this.registerPrioritySelectControl.bind(this);

    OpenLayers.ImgPath = '/img/'; // eslint-disable-line no-param-reassign

    this.map = new CustomOLMap({
      div: this.el,
      zoom: 17,
      theme: OpenLayersTheme,
      controls: [
        new OpenLayers.Control.Zoom(),
        new OpenLayers.Control.Attribution(),
        new OpenLayers.Custom.LayerSwitcher(),
      ],
    });

    const startMoving = (function () {
      this.$el.addClass('moving moving_simple');
    }).bind(this);
    const endMoving = (function () {
      this.$el.removeClass('moving');
    }).bind(this);
    const endMovingSimple = _.debounce(() => {
      this.$el.removeClass('moving_simple');
    }, 2000);
    this.map.events.on({
      movestart() {
        requestAnimationFrame(startMoving);
      },
      moveend() {
        requestAnimationFrame(endMoving);
        requestAnimationFrame(endMovingSimple);
      },
    });

    this.map.addLayers(this.options.layers);

    if (!AIS.featureEnabled('disable_pano')) {
      // eslint-disable-next-line no-template-curly-in-string
      this.panoLayer = new OpenLayers.Layer.OSM('Панорамы', '/tiles/pano/${z}/${y}/${x}.png', {
        displayInLayerSwitcher: false,
        isBaseLayer: false,
        attribution: null,
        numZoomLevels: 21,
      });
      this.map.addLayer(this.panoLayer);
      this.panoLayerActive = true;
    } else {
      this.panoLayerActive = false;
    }

    const canvasStyle = {
      fillColor: '#ee9900',
      fillOpacity: 0,
      pointRadius: 6,
      strokeColor: '#ee9900',
      strokeOpacity: 0.8,
      strokeWidth: 3,
    };

    this.canvas = new OpenLayers.Layer.Vector('Временный', {
      displayInLayerSwitcher: false,
      rendererOptions: { zIndexing: true },
      styleMap: new OpenLayers.StyleMap({
        default: canvasStyle,
        temporary: {
          ...canvasStyle,
          strokeWidth: 1,
          fillOpacity: 0.5,
        },
      }),
    });

    this.radar = new OpenLayers.Layer.Vector('Радар', {
      displayInLayerSwitcher: false,
      rendererOptions: { zIndexing: true },
      styleMap: new OpenLayers.StyleMap({
        graphicZIndex: 1000,
      }),
    });

    this.sectors = new OpenLayers.Layer.Vector('Радар (сектор)', {
      displayInLayerSwitcher: false,
      rendererOptions: { zIndexing: true },
      styleMap: new OpenLayers.StyleMap({
        graphicZIndex: 999,
      }),
    });


    this.map.addLayers([this.radar, this.sectors, this.canvas]);
    this.map.setLayerIndex(this.radar, 10000);
    this.map.setLayerIndex(this.sectors, 9999);

    this.radar.events.register('beforefeatureselected', this, () => false);

    this.addClickControl();
    this.addSelectControl();
    this.addMeasurePanel();

    this.addRadar(options.pano1);

    options.workset.on('add', function (filter) {
      const meta = filter.getMeta();
      let layerView;
      if (meta.getGeometryType() === 'point') {
        layerView = new PointLayer({
          map: this,
          collection: filter.getCollection(),
          model: filter,
        });
      } else {
        layerView = new PolygonOrLineLayer({
          map: this,
          collection: filter.getCollection(),
          model: filter,
        });
      }

      this.acceptLayer(layerView.layer);
      filter.layerView = layerView; // eslint-disable-line no-param-reassign
      $.when(filter.getCollection().ready).done(
        () => {
          layerView.render();
        },
      );
    }, this);

    options.workset.on('reset', function (models, options) {
      _.each(options.previousModels, this.removeRelatedLayer, this);
    }, this);

    options.workset.on('destroy', this.removeRelatedLayer, this);

    /**
     * Хэндлер, отвечающий за скрытие и показывание слоёв на карте.
     */
    options.workset.on('change:visible', (layer, newVisibility) => {
      layer.layerView.layer.setVisibility(newVisibility);
    });

    this.options.pano2.on('change:active', function (pano, value) {
      if (value) {
        this.addRadar(pano);
      }
    }, this);

    this.options.pano2.on('hide', function () {
      this.options.pano2.feature.destroy();
    }, this);

    this.radarDrag = new OpenLayers.Control.DragFeature(this.radar, { // обработчик события "перетаскивания" панорамы по карте
      onComplete(feature, pixel) {
        const lonlat = me.map.getLonLatFromPixel(pixel);
        lonlat.transform('EPSG:900913', 'EPSG:4326');
        if (feature.model.isMain()) { // при перетаскивании "красной" панорамы
          feature.model.set('focus_both', lonlat); // запускаем перемещение обеих панорам
        } else { // при перетаскиваниии "синей" панорамы
          feature.model.set('focus_to', lonlat); // запускаем перемещение только текущей "синей" панорамы
        }
      },
    });

    this.acceptLayer(this.radar);
    this.map.addControl(this.radarDrag);
    this.radarDrag.activate();

    AIS.on('editor:unselect:all', function () {
      this.selectControl.unselectAll();
    }, this);
    AIS.on('layout:center:resize', function () {
      this.map.updateSize();
    }, this);
    AIS.on('editor:multiple:begin', function (layer) {
      this.editorMultipleSelectBegin(layer);
    }, this);
    AIS.on('editor:multiple:end', function () {
      this.editorMultipleSelectEnd();
    }, this);
    AIS.on('editor:modify:begin', function (layer, feature) {
      this.editorModifyControlActivate(layer, feature);
    }, this);
    AIS.on('editor:modify:end', function (layer) {
      this.editorModifyControlDeactivate(layer);
    }, this);
    AIS.on('editor:move:begin', function (layer, feature) {
      this.editorMoveControlActivate(layer, feature);
    }, this);
    AIS.on('editor:move:end', function (layer, feature) {
      this.editorMoveControlDeactivate(layer, feature);
    }, this);
    AIS.on('editor:copy:begin', function (layers) {
      this.editorCopySelectBegin(layers);
    }, this);
    AIS.on('editor:copy:end', function () {
      this.editorCopySelectEnd();
    }, this);
    AIS.on('editor:draw:begin', function (layer, handler) {
      this.editorDrawBegin(layer, handler);
      this.clickControl.deactivate();
    }, this);
    AIS.on('editor:draw:end', function () {
      this.editorDrawEnd();
      this.clickControl.activate();
    }, this);
    AIS.on('editor:drawShiftCopy:begin', function (layer, layers) {
      this.editorDrawShiftCopySelectBegin(layer, layers);
      this.clickControl.deactivate();
    }, this);
    AIS.on('editor:drawAxisAndWidth:begin', function (layer) {
      this.editorDrawAxisAndWidthBegin(layer);
      this.clickControl.deactivate();
    }, this);
    AIS.on('editor:drawAdjacentPolygon:begin', function (layer, layers) {
      this.editorDrawAdjacentPolygonBegin(layer, layers);
      this.clickControl.deactivate();
    }, this);
    AIS.on('editor:drawPaintBucketPoint:begin', function (layer) {
      this.editorDrawPaintBucketPointBegin(layer);
      this.clickControl.deactivate();
    }, this);
    AIS.on('map:center', function (lon, lat, zoom) {
      this.centerTo(lon, lat, 'EPSG:4326');
      if (zoom) {
        this.map.zoomTo(zoom);
      }
    }, this);
    if (!AIS.featureEnabled('disable_pano')) {
      AIS.on('togglePanoLayer', function (enable) {
        this.panoLayerActive = enable;
        if (this.panoLayer.visible === !!(enable)) {
          return;
        }
        this.panoLayer.setVisibility(enable);
        if (!enable) {
          this.options.pano1.set('visible', false);
          this.options.pano2.set('visible', false);
        }
      }, this);
    }
    AIS.on('select_model', function (model) {
      if (!this.tabActive()) {
        return;
      }
      const lon = model.popupLon();
      const lat = model.popupLat();
      this.centerTo(lon, lat);
      const ll = new OpenLayers.LonLat(lon, lat);
      this.propertiesPopup(model.feature, ll);
    }, this);

    AIS.on('layout:center:resize', function () {
      this.map.updateSize();
    }, this);

    if (!AIS.featureEnabled('disable_pano')) {
      this.addPanoLayerSwitcher();
    }

    AIS.on('editor:modify:begin editor:draw:end editor:multiple:end editor:copy:end',
      this.registerPrioritySelectControl);
  },
  tabActive() {
    return this.$el.hasClass('active');
  },
  acceptLayer(layer) {
    this.map.addLayer(layer);
    this.map.setLayerIndex(layer, 0);

    const layers = _.clone(this.selectControl.layers);
    layers.push(layer);
    this.selectControl.setLayer(layers);
  },
  removeRelatedLayer(filter) {
    const layer = filter.getLayer();

    if (this.selectedModel && this.selectedModel.feature
                  && this.selectedModel.feature.layer === layer) {
      this.destroyPopup();
    }

    const layer_index = this.selectControl.layers.indexOf(layer);
    if (layer_index > -1) {
      this.selectControl.layers.splice(layer_index, 1);
    }

    this.map.removeLayer(layer);
    filter.layerView.destroy();
    filter.layerView = null; // eslint-disable-line no-param-reassign
    filter.getCollection().off();
  },
  addRadar(pano) {
    pano.set('visible', true);
    pano.on('change:url', this.drawLocator, this);
    pano.on('change:azimuth', this.drawLocator, this);
    this.drawLocator(pano);

    pano.on('change:visible', function (pano, visible) {
      if (visible) {
        this.drawLocator(pano);
      } else {
        if (pano && pano.feature) {
          pano.feature.destroy();
          delete pano.feature; // eslint-disable-line no-param-reassign
        }
        if (pano && pano.point) {
          pano.point.destroy();
          delete pano.point; // eslint-disable-line no-param-reassign
        }
      }
    }, this);
  },
  addClusterEventsToControl(control) {
    const me = this;
    control.events.on({
      beforefeatureselected(event) {
        return event.feature.CLASS_NAME !== 'RTreeClusterFeature';
      },
      over(event) {
        if (event.feature.CLASS_NAME === 'RTreeClusterFeature') {
          me.addClusterPopup(...arguments);
        }
      },
      out(event) {
        if (event.feature.CLASS_NAME === 'RTreeClusterFeature') {
          me.removeClusterPopup(...arguments);
        }
      },
      scope: this,
    });
  },
  addSelectControl() {
    const layers = AIS.workset.map(filter => filter.getLayer());

    this.selectControl = new MultipleSelectFeature(layers, {
      /** Коллбек выбора фич на карте.
       *
       * Проверяем количество выбранных фич. В случае, если есть только одна фича, то отображаем сразу
       * попап с ее свойствами. Если их несколько, отображаем попап со списком фич.
       *
       * @param {Array} features - фичи, которые находятся под выбранной областью
       * @param {Object} lonlat - позиция начала попапа
       */
      onSelect: (features, lonlat) => {
        if (features.length > 1) {
          this.featuresPopup(features, lonlat);
        } else if (features.length === 1) {
          this.propertiesPopup(features[0], lonlat);
        }
      },
      /** Отмена выбора. Удаляем попап с фичами в случае, если он существует. */
      onUnselect: () => {
        this.destroyPopup();
      },
    });
    this.addClusterEventsToControl(this.selectControl);

    // разрешаем таскать карту даже если клик пришелся на фичу
    this.selectControl.handlers.feature.stopDown = false;

    this.map.addControl(this.selectControl);
    this.selectControl.activate();
  },
  addClickControl() {
    const me = this;
    this.clickControl = new OpenLayers.Custom.Click({
      handlerOptions: {
        single: true,
        double: false,
        stopDouble: false,
        stopSingle: false,
      },
      onClick(evt) {
        me.sendMapClickFromEvent(evt);
      },
    });
    this.map.addControl(this.clickControl);
    this.clickControl.activate();
  },
  sendMapClickFromEvent(evt) {
    const lonlat = this.map.getLonLatFromPixel(evt.xy);
    this.sendMapClick(lonlat.transform('EPSG:900913', 'EPSG:4326'));
  },
  sendMapClick(lonlat) {
    if (this.panoLayerActive) {
      AIS.trigger('map:click', lonlat);
    }
  },
  addClusterPopup(event) {
    let lonlat;
    const cluster = event.feature;
    if (this.popup_cluster === cluster && cluster.popup) {
      cluster.featureIn();
      return;
    }
    if (cluster.geometry.CLASS_NAME === 'OpenLayers.Geometry.Point') {
      lonlat = new OpenLayers.LonLat(cluster.geometry.x, cluster.geometry.y);
    } else {
      lonlat = this.map.getLonLatFromPixel(event.xy);
    }

    if (this.popup_cluster) {
      this.popup_cluster.destroyPopup();
      this.popup_cluster = null;
    }

    cluster.createPopup(lonlat, event.control);
    this.popup_cluster = cluster;
  },
  removeClusterPopup(event) {
    const cluster = event.feature;
    if (cluster.popup) {
      cluster.featureOut();
    }
  },

  // eslint-disable-next-line valid-jsdoc
  /** Добавление на экран попапа для просмотра списка выбранных фич.
   *
   * @param {Array} features - выбранные фичи
   * @param {Object} lonlat  - координаты начала попапа
   */
  featuresPopup(features, lonlat) {
    if (this.popup) {
      this.destroyPopup();
    }

    this.popup = new OpenLayers.Popup.FramedCloud('popup',
      lonlat, null,
      `
                  <div class="balloon multiple-features">
                      <h5>Выберите объект: </h5>
                      <table></table>
                  </div>
                  `,
      null, true,

      // eslint-disable-next-line valid-jsdoc
      /** Удаление попапа при его закрытии. */
      (event) => {
        this.destroyPopup();
        event.stopPropagation();
        this.selectControl.unselectAll();
      });

    this.map.addPopup(this.popup);

    /** Добавляем каждую из фич в таблицу попапа. */
    features.forEach((feature) => {
      const rootElement = $(this.popup.div).find('table');
      const template = _.template($('#tmpl-popup-features').html());

      const { id } = feature.model;
      const layer = feature.layer.name;

      rootElement.append(template({ id, layer }));
      /** При нажатии на фичу в попапе отобразим отмеченной только эту фичу на карте и отобразим попап с
       * данными об этой фиче.
       */
      rootElement.find(`#popup-select-feature-${id}`).click(() => {
        this.selectControl.select(feature);
        this.propertiesPopup(feature, lonlat);
      });
    });

    this.popup.autoSize = true;
    this.popup.updateSize();

    return this.popup;
  },

  // eslint-disable-next-line valid-jsdoc
  /** Добавление на экран попапа для просмотра свойств фичи.
   *
   * @param {Feature} feature - выбранная фича
   * @param {Object} lonlat  - координаты начала попапа
   */
  propertiesPopup(feature, lonlat) {
    if (this.popup) {
      this.destroyPopup();
    }
    this.selectedModel = feature.model;

    this.popup = new SwitchablePopup('popup',
      lonlat, null,
      '<div class="balloon"></div>',
      null, true,

      // eslint-disable-next-line valid-jsdoc
      /** Удаление попапа при его закрытии. */
      (event) => {
        this.destroyPopup();
        event.stopPropagation();
        this.selectControl.unselectAll();
      });

    this.map.addPopup(this.popup);

    const propertiesForm = new PropertiesTable({
      model: this.selectedModel,
      mapView: this,
    });

    $(this.popup.div).find('.balloon').html(propertiesForm.render().el);

    this.popup.autoSize = true;
    this.popup.updateSize();

    return this.popup;
  },
  /** Удаление попапа с фичами и их свойствами с карты. */
  destroyPopup() {
    if (this.popup) {
      this.popup.destroy();
      this.popup = null;
    }
    this.selectedModel = null;
  },
  drawLocator(pano) {
    if (!pano.get('visible')) {
      return;
    }
    if (!pano.get('x') || !pano.get('y')) {
      return;
    }
    const geometry = new OpenLayers.Geometry.Point(pano.get('x'), pano.get('y'));
    geometry.transform('EPSG:4326', 'EPSG:900913');
    const lonlat = new OpenLayers.LonLat(geometry.x, geometry.y);

    const sectorStyle = {
      externalGraphic: '/icon/sector.png',
      graphicWidth: 60,
      graphicHeight: 90,
      rotation: pano.get('azimuth'),
      graphicYOffset: -80,
    };

    if (pano.feature) {
      pano.feature.style = sectorStyle; // eslint-disable-line no-param-reassign
      pano.feature.move(lonlat);
    } else {
      const feature = new OpenLayers.Feature.Vector(geometry, {}, sectorStyle);
      this.sectors.addFeatures([feature]);

      pano.feature = feature; // eslint-disable-line no-param-reassign
      feature.model = pano;
    }

    if (pano.point) {
      pano.point.move(lonlat);
    } else {
      const pointStyle = {
        externalGraphic: pano.get('icon'),
        graphicHeight: 20,
      };

      const point = new OpenLayers.Feature.Vector(geometry.clone(), {}, pointStyle);
      this.radar.addFeatures([point]);
      point.model = pano;
      pano.point = point; // eslint-disable-line no-param-reassign
    }
  },
  centerTo(lon, lat, projection) {
    const point = new OpenLayers.LonLat(lon, lat);
    if (projection) {
      point.transform(projection, 'EPSG:900913');
    }
    this.map.updateSize();
    this.map.setCenter(point);
  },
  addMeasurePopup(contentHTML, lonlat, optionalId, autoHideOff) {
    const id = optionalId || 'measure';
    const popup = new CompactPopup(id,
      lonlat,
      new OpenLayers.Size(0, 0),
      contentHTML, null, false, null);
    if (!autoHideOff) {
      popup.events.on({
        mouseover() {
          _.each(this.map.popups, (popup) => {
            popup.setOpacity(0.3);
          });
        },
        mouseout() {
          _.each(this.map.popups, (popup) => {
            popup.setOpacity(1);
          });
        },
        scope: this,
      });
    }
    popup.setMousePropogation(true);
    popup.autoSize = true;
    this.map.addPopup(popup);
    popup.updateSize();
    return popup;
  },

  _togglePanoLayerSwitcher() {
    this.map.addControl(this._panolayerswitcher);
    AIS.trigger('togglePanoLayer', true);
  },

  addPanoLayerSwitcher() {
    if (this._panolayerswitcher === undefined) {
      this._panolayerswitcher = new PanoLayerSwitcher();
    }

    this._togglePanoLayerSwitcher();
  },

  addMeasurePanel() {
    const me = this;
    const pathMeasureControl = new OpenLayers.Custom.Measure(OpenLayers.Custom.NotPersistedPathHandler, {
      displayClass: 'olControlDrawFeaturePath',
      geodesic: true,
      persist: true,
      immediate: true,
      partialDelay: 50,
    });

    const polygonMeasureControl = new OpenLayers.Control.Measure(OpenLayers.Custom.NotPersistedPolygonHandler,
      {
        displayClass: 'olControlDrawFeaturePolygon',
        geodesic: true,
        persist: true,
      });

    const navigation = new OpenLayers.Control.Navigation({
      zoomBoxEnabled: false, // Конфликтует с занесением геометрии через трейсинг
    });

    const measurePanel = new OpenLayers.Control.Panel({
      displayClass: 'olControlEditingToolbar',
      defaultControl: navigation,
    });

    measurePanel.addControls([
      navigation,
      pathMeasureControl,
      polygonMeasureControl,
    ]);

    const unitsTranslation = {
      m: 'м',
      km: 'км',
    };

    function measureResult(event) {
      const units = unitsTranslation[event.units];
      const { order, measure } = event;
      let out = '';
      if (order === 1) {
        out += `${measure.toFixed(3)} ${units}`;
      } else {
        out += `${measure.toFixed(3)} ${units}<sup>2</sup>`;
      }
      return out;
    }

    let moving_popup = null;

    function handleLineImmediate(event) {
      const out = measureResult(event);
      // Очень хрупко, со сменой версии OL может сломаться
      const verts = event.geometry.components;
      const point = verts[verts.length - 1];
      const ll = new OpenLayers.LonLat(point.x, point.y);
      if (moving_popup === null) {
        moving_popup = this.addMeasurePopup(out, ll, 'measure_moving_popup', true);
        moving_popup.setBackgroundColor('orange');
      } else {
        moving_popup.lonlat = ll;
        moving_popup.updatePosition();
        moving_popup.setContentHTML(out);
      }
    }

    function removeMovingPopup() {
      if (moving_popup !== null) {
        this.map.removePopup(moving_popup);
        moving_popup = null;
      }
    }

    function handleLineMeasurements(event) {
      if (event.type === 'measure') {
        removeMovingPopup.call(this, event);
      }
      const out = measureResult(event);
      // Очень хрупко, со сменой версии OL может сломаться
      const verts = event.geometry.components;
      const point = verts[verts.length - 1];
      this.addMeasurePopup(out, new OpenLayers.LonLat(point.x, point.y), `measure_${verts.length}`);
    }

    function handleAreaMeasurement(event) {
      /**
       * Измеряем площадь полигона, проверяем, требуется ли посчитать площадь "уборочных" слоев,
       * и если требуется, то добавляем в попап эту и общую площадь.
       * Очень хрупко, со сменой версии OL может сломаться
       */
      const point = event.geometry.getCentroid();
      const popup = this.addMeasurePopup('Рассчитываем площадь...', new OpenLayers.LonLat(point.x, point.y));
      const resultElement = $('<div class="AreaMeasurementPopup"></div>');
      const cleaning_areas_enabled = this._measure_cleaning_areas_switcher.enabled;
      api.getMeasureAreas(event.geometry, cleaning_areas_enabled).then(
        (measures) => {
          for (let i = 0; i < measures.length; i += 1) {
            const measure = measures[i];
            const htmlText = `${measure.name}: ${measure.value.toFixed(1)} м<sup>2</sup>`;
            resultElement.append($('<div></div>').html(htmlText));
          }
          popup.setContentHTML(resultElement[0]);
        },
        () => {
          resultElement.append($('<div></div>').html(
            `При расчете площади${cleaning_areas_enabled ? ' уборки ' : ' '}возникла ошибка.
            Попробуйте повторить расчет.`,
          ));
          popup.setContentHTML(resultElement[0]);
        },
      );
    }

    pathMeasureControl.events.on({
      measure: handleLineMeasurements,
      measurepartial: handleLineMeasurements,
      measureimmediate: handleLineImmediate,
      deactivate: removeMovingPopup,
      scope: this,
    });

    pathMeasureControl.events.on({
      activate() {
        me.addSnappingControl(this.handler.getLayer());
      },
      deactivate() {
        me.removeSnappingControl();
      },
    });

    polygonMeasureControl.events.on({
      measure: handleAreaMeasurement,
      scope: this,
    });

    polygonMeasureControl.events.on({
      activate() {
        me.addSnappingControl(this.handler.getLayer());
        // Создаем компонент с чекбоксом "Расчет площади уборки" при нажатии на кнопку расчета полигона
        me.addMeasureCleanAreasSwitcher();
      },
      deactivate() {
        me.removeSnappingControl();
        me.removeMeasureCleanAreasSwitcher();
      },
    });

    this.map.addControl(measurePanel);

    // Naughty hacks
    navigation.handlers.click = this.clickControl.handler;

    navigation.events.register('activate', this, this.registerPrioritySelectControl);
    navigation.activate();
  },

  addMeasureCleanAreasSwitcher() {
    /** Создаем новый объект c чекбоксом "Расчет площади уборки" и добавляем его в контролы карты */
    this._measure_cleaning_areas_switcher = new MeasureCleaningAreasSwitcher();
    this.map.addControl(this._measure_cleaning_areas_switcher);
  },

  removeMeasureCleanAreasSwitcher() {
    if (this._measure_cleaning_areas_switcher) {
      /** Удаляем объект с чекбоксом "Расчет площади уборки" */
      this._measure_cleaning_areas_switcher.deactivate();
      this.map.removeControl(this._measure_cleaning_areas_switcher);
      this._measure_cleaning_areas_switcher = null;
    }
  },

  addSnappingControl(layer) {
    const targets = [];
    let layer_geomtype = null;
    AIS.workset.each((model) => {
      const geomtype = model.getMeta().getGeometryType();
      if (geomtype !== 'point') {
        targets.push(model.getLayer());
      }
      if (model.getLayer() === layer) {
        layer_geomtype = geomtype;
      }
    });

    if (layer_geomtype !== 'point') {
      this.removeSnappingControl();
      this._snapControl = new RTreeSnappingControl({
        layer,
        targets,
      });
      this.map.addControl(this._snapControl);
      this._snapControl.activate();
    }
  },

  removeSnappingControl() {
    if (this._snapControl) {
      this._snapControl.deactivate();
      this.map.removeControl(this._snapControl);
      this._snapControl = null;
    }
  },

  editorMultipleSelectBegin(layer) {
    this.editorMultiSelectControl = new SelectFeature([layer, this.radar], {
      multiple: true,
      toggle: true,
      clickout: false,
      onSelect(feature) {
        AIS.trigger('map:multiple:select', feature);
      },
      onUnselect(feature) {
        AIS.trigger('map:multiple:unselect', feature);
      },
    });
    this.addClusterEventsToControl(this.editorMultiSelectControl);
    this.map.addControl(this.editorMultiSelectControl);
    this.selectControl.deactivate();
    this.editorMultiSelectControl.activate();
  },

  editorMultipleSelectEnd() {
    if (this.editorMultiSelectControl) {
      this.editorMultiSelectControl.unselectAll();
      this.editorMultiSelectControl.deactivate();
      this.map.removeControl(this.editorMultiSelectControl);
      this.editorMultiSelectControl = null;
    }
  },

  _onModify(feature) {
    AIS.trigger('map:featuremodified', feature);
    if (feature.feature.layer && feature.feature.layer.rtree) {
      feature.feature.layer.rtreeUpdate([feature.feature]);
    }
  },

  editorModifyControlActivate(layer, feature) {
    this.editorModifyControlDeactivate(layer);
    this.modifyControl = new OpenLayers.Custom.ModifyFeature(layer, {
      standalone: true,
      vertexRenderIntent: 'modify',
      mode:
        OpenLayers.Control.ModifyFeature.RESHAPE // eslint-disable-line no-bitwise
        | OpenLayers.Control.ModifyFeature.DRAG
        | OpenLayers.Control.ModifyFeature.ROTATE,
    });

    layer.events.register('featuremodified', this, this._onModify);

    if (this.editorMultiSelectControl) {
      this.editorMultiSelectControl.unselectAll();
      this.editorMultiSelectControl.deactivate();
    }
    this.map.addControl(this.modifyControl);
    this.modifyControl.selectFeature(feature);
    this.modifyControl.activate();
    this.addSnappingControl(layer);
  },
  editorModifyControlDeactivate(layer) {
    if (this.modifyControl) {
      this.modifyControl.unselectFeature(this.modifyControl.feature);
      this.modifyControl.deactivate();
      this.map.removeControl(this.modifyControl);
      this.modifyControl = null;
    }
    layer.events.unregister('featuremodified', this, this._onModify);
    if (this.editorMultiSelectControl) {
      this.editorMultiSelectControl.deactivate();
      this.editorMultiSelectControl.activate();
    }
    this.removeSnappingControl();
  },

  editorMoveControlActivate(layer, feature) {
    this.moveControl = new OpenLayers.Custom.DragFeature(layer, {
      onComplete: this._onModify,
    });

    if (this.editorMultiSelectControl) {
      this.editorMultiSelectControl.unselectAll();
      this.editorMultiSelectControl.deactivate();
    }
    this.map.addControl(this.moveControl);
    this.moveControl.setFeature(feature);
    this.moveControl.activate();
  },
  editorMoveControlDeactivate() {
    if (this.moveControl) {
      this.moveControl.deactivate();
      this.map.removeControl(this.moveControl);
      this.moveControl = null;
    }
    if (this.editorMultiSelectControl) {
      this.editorMultiSelectControl.deactivate();
      this.editorMultiSelectControl.activate();
    }
  },

  editorCopySelectBegin(layers) {
    const me = this;
    this.selectControl.deactivate();
    // this.map.removeControl(this.selectControl);

    this.copySelectControl = new SelectFeature(layers, {
      onSelect(feature) {
        me.copySelectControl.unselect(feature);
        AIS.trigger('editor:copy:select', feature);
        me.copySelectControl.deactivate();
      },
    });
    this.addClusterEventsToControl(this.copySelectControl);

    this.map.addControl(this.copySelectControl);
    this.copySelectControl.activate();
  },
  editorCopySelectEnd() {
    if (this.copySelectControl) {
      this.copySelectControl.deactivate();
      this.map.removeControl(this.copySelectControl);
      this.copySelectControl = null;
    }
    // this.map.addControl(this.selectControl);
  },
  editorDrawSketchModified(event) {
    AIS.trigger('map:sketchmodified', event);
  },
  editorDrawBegin(layer, handler) {
    const me = this;
    this.createDrawControl = new OpenLayers.Control.DrawFeature(
      layer, handler, {
        featureAdded(feature) {
          me.editorDrawEnd();
          AIS.trigger('map:featureadded', feature);
          // Переключиться в режим редактирования только что созданной фичи
          AIS.trigger('map:editafteradd');
        },
      },
    );
    this.map.addControl(this.createDrawControl);
    this.createDrawControl.layer.events.on({ sketchmodified: this.editorDrawSketchModified });
    this.createDrawControl.activate();
    this.addSnappingControl(layer);
  },
  editorDrawEnd() {
    if (this.createDrawControl) {
      this.createDrawControl.layer.events.un({ sketchmodified: this.editorDrawSketchModified });
      this.createDrawControl.deactivate();
      this.map.removeControl(this.createDrawControl);
      this.createDrawControl = null;
    }
    this.removeSnappingControl();
  },
  addWidthPopup(lonlat, getFeatureFromServer) {
    /** Добавление попапа с вводом ширины.

                  lonlat: OpenLayers.LonLat - координата попапа
                  getFeatureFromServer - коллбек, вызывающийся при нажатии на кнопку ОК
                */
    const me = this;

    const widthPopup = new CompactPopup(
      'inputHeightPopup', lonlat, null, '<div class="balloon"></div>', null, false, null,
    );
    const widthInput = new WidthInput({
      getFeatureFromServer,
    });
    me.map.addPopup(widthPopup);

    $(widthPopup.div).find('.balloon').html(widthInput.render().el);
    widthPopup.autoSize = true;
    widthPopup.updateSize();
    $(widthPopup.div).find('input').focus();
    return widthPopup;
  },
  editorDrawAxisAndWidthBegin(layer) {
    /** Создаем контроллер для рисования оси, по которой в дальнейшем будет сформирован требуемый полигон.

        layer - слой, в который добавляем объект
        handler - функция, вызываемая в последний момент после создания геометрии
      */
    const me = this;
    this.createDrawControl = new OpenLayers.Control.DrawFeature(
      layer, OpenLayers.Handler.Path, {
        featureAdded(feature) {
          /** Коллбек, вызывающийся после ввода линии на карте.

              Получаем точки нарисованной геометрии, затем извлекаем последнюю. Там мы отобразим наш попап.
              Рисуем попап, добавляем евент, в котором подменяем геометрии создаваемого слоя с переданной Line на Polygon.
              После того, как элемент был добавлен, удалим все попапы.

              feature - ось
            */
          const points = feature.geometry.components;
          const lastPoint = points[points.length - 1];

          me.widthPopup = me.addWidthPopup(
            new OpenLayers.LonLat(lastPoint.x, lastPoint.y),

            // eslint-disable-next-line valid-jsdoc
            /** После ввода ширины в попап пытаемся получить итоговую геометрию полигона. */
            async (width) => {
              try {
                const geojson = await api.drawAxisAndWidth(
                  AIS.transformReaderGeoJSON.write(feature.geometry),
                  parseFloat(width),
                );

                if (geojson) {
                  /* Подменяем предыдущую геометрию на новую. */
                  me.widthPopup.destroy();
                  const resultFeature = new OpenLayers.Feature.Vector(
                    AIS.transformReaderGeoJSON.read(geojson, 'Geometry'),
                  );
                  layer.addFeatures([resultFeature]);
                  AIS.trigger('map:featureadded', resultFeature);
                  feature.destroy();
                  me.editorDrawEnd();
                }
              } catch (e) {
                alert('Произошла ошибка при попытке построить полигон по оси и ширине.'); // eslint-disable-line no-alert
              }
            },
          );
          // Удаление всего, относящееся к префиче. Самый беспонтовый участок наверное.
          // eslint-disable-next-line no-param-reassign
          feature.destroyAll = function () {
            if (me.widthPopup) {
              me.widthPopup.destroy();
              me.widthPopup = null;
            }
            this.destroy();
          };
          // Вызываем евент, что префича была добавлена.
          AIS.trigger('map:pre_featureadded', feature);
        },
      },
    );
    this.map.addControl(this.createDrawControl);
    this.createDrawControl.activate();
    this.addSnappingControl(layer);
  },

  /** Контроллер для создания фичи линии перпендикулярным копированием со сдвигом.
   *
   * @param {Array} layer - слой фичи
   * @param {Array} layers - слои, которые могут копироваться со сдвигом (полилинии)
   */
  editorDrawShiftCopySelectBegin(layer, layers) {
    const me = this;
    this.selectControl.deactivate();
    /** Контрол для выбора родительского линии. */
    this.drawShiftCopySelectControl = new SelectFeature(layers, {
      /** Коллбек, срабатывающий при выборе одной из линий на карте.
       * Данная фича используется в качестве родительской линии.
       *
       * @param {Object} parentLine - feature выбранной линии
       */
      onSelect: (parentLine) => {
        let pointFeature;
        me.createDrawControl = new OpenLayers.Control.DrawFeature(
          /** Контрол для занесения по карте точки выбора направления. */
          layer, OpenLayers.Handler.Point, {
            /** Коллбек, срабатывающий при добавлении точки на карту.
             * Отправляем данные на сервер, возвращаем сдвинутую на 0.5м. в сторону этой точки линию.
             *
             * @param {Object} feature - feature введенной точки
             */
            featureAdded: async (feature) => {
              me.createDrawControl.deactivate(); // Останавливаем рисование точек
              pointFeature = feature;
              try {
                const form = $('.line-shift-distance');
                const rawLength = form.find('.line-shift-distance-input').val();
                const length = parseFloat(rawLength);
                const geojson = await api.shiftCopyLine(
                  AIS.transformReaderGeoJSON.write(parentLine.geometry),
                  AIS.transformReaderGeoJSON.write(pointFeature.geometry),
                  length,
                );
                if (geojson) {
                  const resultFeature = new OpenLayers.Feature.Vector(
                    AIS.transformReaderGeoJSON.read(geojson, 'Geometry'),
                  );
                  layer.addFeatures([resultFeature]);
                  AIS.trigger('map:featureadded', resultFeature);
                }
              } catch (e) {
                alert('Произошла ошибка при попытке построить геометрию копированием со сдвигом.'); // eslint-disable-line no-alert
              }
              parentLine.destroyAll();
              me.editorDrawEnd();
            },
          },
        );
        me.map.addControl(me.createDrawControl);
        me.createDrawControl.activate();
        me.addSnappingControl(layer);

        // eslint-disable-next-line no-param-reassign
        parentLine.destroyAll = function () {
          if (me.createDrawControl) {
            me.createDrawControl.deactivate();
          }
          if (me.drawShiftCopySelectControl) {
            me.drawShiftCopySelectControl.unselectAll();
            me.drawShiftCopySelectControl.deactivate();
            me.map.removeControl(me.drawShiftCopySelectControl);
            me.drawShiftCopySelectControl = null;
          }
          if (pointFeature) {
            pointFeature.destroy();
          }
        };
      },
    });
    this.addClusterEventsToControl(this.drawShiftCopySelectControl);
    this.map.addControl(this.drawShiftCopySelectControl);
    this.drawShiftCopySelectControl.activate();
  },

  editorDrawAdjacentPolygonBegin(layer, layers) {
    /** Создаем контроллер для рисования примыкающего полигона к родительскому полигону по двум точкам.

        Сперва выбираем полигон, после чего указываем на нем две точки (автоматически выбирается ближайшая точка
        к нажатой на границе полигона).
        После этого запросом к серверу высчитывается геометрия наименьшего участок по границе полигона между
        этими точками. Делаем feature с этой геометрией.После этого выводим попап с инпутом для ввода ширины.
        Когда ширина введена, на сервере высчитывается примыкающий полигон и возвращается его геометрия.
        Полученная геометрия и будет геометрией примыкающего полигона.

        layer - слой, в который добавляем объект
        layers - слои, которые могут участвовать в выборке для родительского полигона
      */
    const me = this;
    this.selectControl.deactivate();

    this.drawAdjacentPolygonSelectControl = new SelectFeature(layers, {
      // eslint-disable-next-line valid-jsdoc
      /** Контрол для выбора родительского полигона. */
      onSelect(parentPolygon) {
        /** Коллбек, срабатывающий при выборе одного из полигонов на карте.
            У данного полигона изменяется стили и в дальнейшем он используется в качестве родительского полигона.

            parentPolygon - feature выбранного полигона
          */
        const preFeatures = [];
        me.createDrawControl = new OpenLayers.Control.DrawFeature(
          /** Контрол для занесения двух точек на карту. */
          layer, OpenLayers.Handler.Point, {
            featureAdded: async (feature) => {
              /** Коллбек, срабатывающий при добавлении точки на карту.
                  Находим ближайшую к выбранной точке точку на границе родительского полигона,
                  подменяем координаты выбранной точки на координаты этой точки, и отрисовываем заново,
                  в противном случае изменение координаты отобразится только при следующем перерендере.

                  Полученную точку добавляем в массив preFeatures.
                  В случае, если было занесено две точки, остановим рисование точек и найдем
                  меньшую линию между ними по границе родительского полигона.

                  feature - выбранная точка
                */
              const pointGeometry = feature.geometry;
              const onPolygonPointGeometry = pointGeometry.distanceTo(parentPolygon.geometry, { details: true });

              pointGeometry.x = onPolygonPointGeometry.x1;
              pointGeometry.y = onPolygonPointGeometry.y1;
              feature.layer.drawFeature(feature);

              preFeatures.push(feature);
              if (preFeatures.length === 2) {
                me.createDrawControl.deactivate(); // Останавливаем рисование точек

                try {
                  const polygon_geom = AIS.transformReaderGeoJSON.write(parentPolygon.geometry);
                  const point_one = AIS.transformReaderGeoJSON.write(preFeatures[0].geometry);
                  const point_two = AIS.transformReaderGeoJSON.write(preFeatures[1].geometry);
                  const borderGeojson = await api.polygonBorderByTwoPoints(
                    polygon_geom, point_one, point_two,
                  );

                  if (borderGeojson) {
                    /* Ожидаем, что от сервера пришла геометрия меньшей линии по границе полигона.
                                                Отрисовываем ее. Выводим попап с вводом ширины.
                                                Полученную линию добавляем в массив preFeatures.
                                                */
                    const border = new OpenLayers.Feature.Vector(
                      AIS.transformReaderGeoJSON.read(borderGeojson, 'Geometry'),
                    );
                    preFeatures.push(border);
                    layer.addFeatures([border]); // отрисовываем фичу

                    me.widthPopup = me.addWidthPopup(
                      /** Попап с вводом ширины. */
                      new OpenLayers.LonLat(pointGeometry.x, pointGeometry.y),
                      async (width) => {
                        /** Функция, запускаемая при нажатии на подверждение. */
                        try {
                          const geojson = await api.adjacentPolygon(
                            AIS.transformReaderGeoJSON.write(border.geometry),
                            parseFloat(width),
                          );

                          if (geojson) {
                            /** Если получили геометрию примыкающего полигона:
                                1. Отрисовываем ее.
                                2. Вызываем event map:featureadded, который
                                отрабатываемся в модуле GeometryField.
                                3. Удаляем все feature в массиве preFeatures, а
                                также попап и контролы.

                              adjacentPolygonGeom - геометрия полигона.
                              */
                            const resultFeature = new OpenLayers.Feature.Vector(
                              AIS.transformReaderGeoJSON.read(geojson, 'Geometry'),
                            );
                            layer.addFeatures([resultFeature]);
                            AIS.trigger('map:featureadded', resultFeature);
                          }
                        } catch (e) {
                          alert('Произошла ошибка при попытке построить примыкающий полигон.'); // eslint-disable-line no-alert
                        }

                        parentPolygon.destroyAll();
                        me.editorDrawEnd();
                      },
                    );
                  }
                } catch (e) {
                  alert('Произошла ошибка при попытке получить границу.'); // eslint-disable-line no-alert
                }
              }
            },
          },
        );
        me.map.addControl(me.createDrawControl);
        me.createDrawControl.activate();
        me.addSnappingControl(layer);

        // eslint-disable-next-line no-param-reassign
        parentPolygon.destroyAll = function () {
          /** Удаление всего, относящегося к префиче. */
          if (me.createDrawControl) {
            me.createDrawControl.deactivate();
          }
          if (me.drawAdjacentPolygonSelectControl) {
            me.drawAdjacentPolygonSelectControl.deactivate();
            me.map.removeControl(me.drawAdjacentPolygonSelectControl);
            me.drawAdjacentPolygonSelectControl = null;
          }
          if (me.widthPopup) {
            me.widthPopup.destroy();
            me.widthPopup = null;
          }
          for (const i in preFeatures) {
            preFeatures[i].destroy();
          }
        };
        // Вызываем евент, что префича была добавлена.
        AIS.trigger('map:pre_featureadded', parentPolygon);
      },
    });
    this.addClusterEventsToControl(this.drawAdjacentPolygonSelectControl);
    this.map.addControl(this.drawAdjacentPolygonSelectControl);
    this.drawAdjacentPolygonSelectControl.activate();
  },
  editorDrawPaintBucketPointBegin(layer) {
    /** Создаем контроллер для рисования.

        * рисуем точку на карте
        * отправляем точку на сервер
        * получаем от сервера полигон
        * рисуем полигон на карте и уведомляем всех об этом

        layer - слой, в который добавляем объект
      */
    const me = this;
    this.createDrawControl = new OpenLayers.Control.DrawFeature(
      layer, OpenLayers.Handler.Point, {
        featureAdded: async (feature) => {
          /** Обработчик добавления фичи.

              feature: OpenLayers.Feature.Vector - фича, точка
            */
          layer.removeFeatures([feature]);

          try {
            const geojson = await api.paintBucket(
              AIS.transformReaderGeoJSON.write(feature.geometry),
              AIS.workset
                .filter(classFilter => classFilter.getVisible())
                .map(classFilter => classFilter.getClassName()),
            );
            if (geojson && !geojson.error) {
              const feature = new OpenLayers.Feature.Vector(
                AIS.transformReaderGeoJSON.read(geojson, 'Geometry'),
              );
              layer.addFeatures([feature]);
              AIS.trigger('map:featureadded', feature);
            } else {
              throw new Error();
            }
            me.editorDrawEnd();
          } catch (e) {
            alert('Произошла ошибка при попытке построить полигон заливкой.'); // eslint-disable-line no-alert
          }
        },
      },
    );
    this.map.addControl(this.createDrawControl);
    this.createDrawControl.activate();
  },

  /** Разделение объекта на несколько частей.
   *
   * @param {Object} model модель
   * @param {Number} lock лок
   */
  doSplit(model, lock) {
    if (model && model.feature && model.feature.layer) {
      const pathSplitControl = new OpenLayers.Custom.PathHandler(this, {
        /** Коллбек, вызываемый при добавлении линии разрезания.
         * Проверяем api запросом, что разрезание валидно, и разрезаем после подтверждения.
         *
         * @param {string} lineString - линия, по которой разрезаем фичу
         */
        done: async (lineString) => {
          const parent_geom = AIS.transformReaderGeoJSON.write(model.feature.geometry);
          const separator_geom = AIS.transformReaderGeoJSON.write(lineString);
          const parts = declOfNum(['часть', 'части', 'частей']);
          try {
            const result = await api.checkCut(parent_geom, separator_geom);
            const { is_simple, total } = result;
            if (total < 2) {
              alert('Линия не пересекает объект'); // eslint-disable-line no-alert
            } else if (total > 200) {
              alert(`Слишком много новых частей: ${total}`); // eslint-disable-line no-alert
            } else {
              // eslint-disable-next-line no-alert, no-restricted-globals
              const confirmed = confirm(
                `${!is_simple ? 'ВНИМАНИЕ: Исходная геометрия также будет разделена в местах самопересечений.\n' : ''
                }Разрезать объект на ${total} ${parts(total)}?`,
              );
              if (confirmed) {
                await api.cut(model.id, separator_geom);
              }
            }
          } catch (e) {
            alert('Произошла ошибка при попытке разрезать геометрию'); // eslint-disable-line no-alert
          }

          lock.release();
          this.removeSnappingControl();
          pathSplitControl.deactivate();
          this.selectControl.unselectAll();
        },
      });
      pathSplitControl.activate();
      this.addSnappingControl(pathSplitControl.getLayer());
    }
  },

  /** activate любого Handler'а или Control'а приводит к тому что он
   * перемещается наверх списка событий. В случае selectControl
   * мы не хотим в определенных случаях прокидывать события дальше
   * карте. Таким образом на каждый activate мы должны вызывать
   * эту функцию чтобы она была первой в списке.
   */
  registerPrioritySelectControl() {
    this.selectControl.deactivate();
    this.selectControl.activate();
  },
});

export default MapView;
