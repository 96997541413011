import $ from 'jquery';
import _ from 'underscore';
import Backbone from 'js/backbone';
import AIS from 'js/AIS';
import FieldWrapper from 'js/view/editor/FieldWrapper';
import api, { FeatureError } from 'js/api';
import GeometryField from 'js/view/editor/GeometryField';
import { declOfNum, fixTextFields, checkTextFields } from 'js/utils';
import * as consts from 'js/consts';


const FeatureForm = Backbone.View.extend({
  id: 'object-form',
  className: 'creation-form',
  events: {
    'change .scalar': 'change',
    'click .save-button': function () {
      this.save();
    },
    'click .save-and-reopen-button': function () {
      this.save(true);
    },
    'click .cancel-button': 'cancel',
    'click .add-all-filtered-features-to-batch-edit': 'addAllFilteredFeaturesToBatchList',
  },
  template: _.template($('#tmpl-editor-form').html()),
  saveActive: false,

  initialize: function () {
    this._batchEdit = false;
    this.batchObjects = [];
    this._fields = [];
    this._properties = new Backbone.Collection();

    this.model.on('change', this.setDirty, this);

    this.model.on('geom_lock', (field) => {
      this.formLockActive(true, field);
    });

    this.model.on('geom_unlock', (field) => {
      this.formLockActive(false, field);
    });

    this.emptyGeometry = true;
    if (this.model.getMeta().getGeometryType() !== 'empty') {
      this.emptyGeometry = false;
      this.geometryField = this.createField(
        this.model.getMeta().getGeometryProperty(),
        'geom',
      );
    }

    this.model.getMeta().getProperties().each((property, index) => {
      if (property.editable()) {
        const view = this.createField(property, index);
        this._properties.add(
          view.getFieldState(),
        );
      }
    });

    AIS.trigger('editor:start', this.model);

    if (!this.model.isNew()) {
      const filter = this.model.getFilter();
      const layer = filter.getLayer();
      AIS.on('map:multiple:select', this.addToBatchList, this);
      AIS.on('map:multiple:unselect', this.removeFromBatchList, this);
      AIS.trigger('editor:multiple:begin', layer);
    }

    this._reserve = this.model.getAttributes();

    this.boundKeyUp = _.bind(this.keyUp, this);
    $(document).on('keyup', this.boundKeyUp);
  },

  keyUp(event) {
    if (this.$el.is(':visible')) {
      switch (event.keyCode) {
        case 10:
        case 13:
          if (event.ctrlKey) {
            this.save();
          } else if (event.shiftKey) {
            this.save(true);
          }
          break;
        default:
      }
    }
  },
  setSaveButtonsActive(active) {
    this.saveActive = !!active;
    this.$el.find('.save-button').prop('disabled', !active);
    this.$el.find('.save-and-reopen-button').prop('disabled', !active);
  },
  formLockActive(active, except) {
    const otherFields = _.reject(this._fields, f => f.field.cid === except.cid);
    _.each(otherFields, (f) => {
      if (active) {
        f.lock();
      } else {
        f.unlock();
      }
    }, this);

    if (active) {
      this.setSaveButtonsActive(false);
      this.$el.find('.cancel-button').prop('disabled', true);
    } else {
      this.setSaveButtonsActive(this.validate());
      this.$el.find('.cancel-button').prop('disabled', false);
    }
  },

  /**
   * Добавить модель с локом в список редактируемых объектов.
   * @param {GeoObject} model модель
   * @param {Lock}      lock  лок
   */
  _addToBatchList(model, lock) {
    this._batchEdit = true;
    this.batchObjects.push({ model, lock });
    this.updateIds();

    this._properties.each((c) => {
      c.set('multi', true);
      const f = c.get('field');
      if (!c.get('differs') && (model.get(f) !== this.model.get(f))) {
        c.set('differs', true);
      }
    });
  },
  /**
   * Добавить фичу в список редактируемых объектов.
   * @param  {Object} feature фича
   */
  addToBatchList(feature) {
    const { model } = feature;

    if (!model || model === this.model) {
      return;
    }

    if ((this.batchObjects.length + 1) >= consts.BATCH_EDIT_MAX) {
      $('#notice-batch-edit-too-many-objects').modal();
      return;
    }

    api.lock(model.id).then((lock) => {
      if (lock) {
        this._addToBatchList(model, lock);

        // возможность изменять связи при множественном редактировании отключается
        const [relationsField] = this._fields.filter(({ id }) => id === 'relations-editor');
        if (relationsField != null) {
          relationsField.lock();
        }
      } else {
        // Не выводить сообщение, если фича заблокирована нами же
        const found = this.batchObjects.filter(({ model: filterModel }) => (filterModel.get('id') === model.id));
        if (found.length === 0) {
          AIS.lockAlert();
        }
      }
    });
  },

  /**
   * Добавить все видимые фичи в текущем слое в список редактируемых объектов.
   * Делаем select() на всех фичах в текущем слое на карте, что вызывает this.addToBatchList()
   * для каждой такой фичи.
   *
   * В данном варианте не работает для слоёв с большим количеством фич (>250), потому что
   * на каждую фичу пытаемся взять лок, а каждый лок -- это открытый WebSocket. У Chrome есть
   * ограничение на 250 открытых WebSocket'ов.
   * Так же не работает для точечных слоёв, потому что они кластеризуются.
   */
  addAllFilteredFeaturesToBatchList() {
    const selectControl = AIS.map.editorMultiSelectControl;
    if (!selectControl) {
      return;
    }

    const [featureLayerMeta] = AIS.meta.filter(
      layer => (layer.get('class') === this.model.get('layer')),
    );
    if (featureLayerMeta.get('geometry').geometry === 'point') {
      $('#notice-batch-edit-point-layer').modal();
      return;
    }

    let tooMany = false;
    selectControl.layers.forEach((layer) => {
      if (layer.features != null && this.batchObjects.length + layer.features.length > consts.BATCH_EDIT_MAX) {
        tooMany = true;
      }
    });
    if (tooMany) {
      $('#notice-batch-edit-too-many-objects').modal();
      return;
    }

    selectControl.layers.forEach((layer) => {
      layer.features.forEach((feature) => {
        selectControl.select(feature);
      });
    });

    // возможность изменять связи при множественном редактировании отключается
    const [relationsField] = this._fields.filter(({ id }) => id === 'relations-editor');
    if (relationsField != null) {
      relationsField.lock();
    }
  },

  /**
    * Удалить фичу из списка редактируемых объектов.
    * @param  {Object} feature фича
    */
  removeFromBatchList(feature) {
    const { model } = feature;
    const notDeleted = [];
    this.batchObjects.forEach((item) => {
      if (item.model.id === model.id) {
        item.lock.release();
      } else {
        notDeleted.push(item);
      }
    });
    this.batchObjects = notDeleted;
    this.updateIds();
    if (this.batchObjects.length === 0) {
      this._batchEdit = false;
      this._properties.each((c) => {
        c.set('multi', false);
      });

      // если множественное редактирование прекратилось, включаем назад поле для редактирования связей
      const [relationsField] = this._fields.filter(({ id }) => id === 'relations-editor');
      if (relationsField != null) {
        relationsField.unlock();
      }
    }
  },

  /**
    * Обновить список идентификаторов на панели создания/редактивания объекта.
    * При создании новой фичи отображает <новый>.
    * При редактировании отображает идентификатор редактируемого объекта.
    * При множественном редактировании показывает количество редактируемых объектов.
    */
  updateIds() {
    let text;

    const batchCount = this.batchObjects.length;

    if (batchCount > 0) {
      const obj = declOfNum(['объект', 'объекта', 'объектов']);
      text = `(${batchCount + 1} ${obj(batchCount + 1)})`;
    } else {
      text = `# ${this.model.id.toString()}`;
    }

    this.$el.find('.identify').text(text);
  },

  /**
    * Создать редактируемое поле для свойства.
    * @param   {Backbone.Model}  property  свойство
    * @param   {Number}          index     индекс редактируемого поля по порядку
    * @returns {Backbone.View}             представление редактируемого поля
    */
  createField(property, index) {
    let inheritedValue;
    if (this.options.inheritedValues) {
      const fieldName = property.get('field');
      inheritedValue = this.options.inheritedValues.get(fieldName);
    }

    const view = new FieldWrapper({
      model: this.model,
      property,
      inheritedValue,
      index,
      id: `${property.get('id')}-editor`,
    });

    this._fields.push(view);
    return view;
  },

  render() {
    this.$el.html(this.template({
      objectName: this.model.getMeta().get('objectName'),
      id: this.model.id,
      isNew: this.model.isNew(),
    }));

    _.each(this._fields, function (view) {
      this.$el.find('.form').append(view.render().el);
    }, this);

    this.setSaveButtonsActive(this.validate());

    return this;
  },
  additional() {
    return {};
  },
  /**
   * Получаем все поля, в которых были изменения. Добавляем к этим полям id фичи.
   * @returns {Object} объект с изменениями
   */
  changes() {
    const hash = {};
    _.each(this._fields, (f) => {
      const state = f.getFieldState();
      if (state.get('dirty')) {
        hash[state.get('fieldName')] = state.get('value');
      }
    });
    return hash;
  },
  geometryChanged() {
    return _.has(this.changes(), 'geojson');
  },
  _tearDown() {
    this.deactivateFields();

    if (this.options.lock) {
      this.options.lock.release();
    }

    this.batchObjects.forEach(({ lock }) => {
      lock.release();
    });

    if (!this.model.isNew()) {
      AIS.trigger('editor:multiple:end');
      AIS.off('map:multiple:select', this.addToBatchList, this);
      AIS.off('map:multiple:unselect', this.removeFromBatchList, this);
    }
    AIS.trigger('editor:close', this.model);
  },
  cancel() {
    if (this.model.isNew()) {
      this.model.destroyFeature();
      this.model.destroy();
    } else {
      this.model.set(this._reserve);
    }
    this._tearDown();
  },
  /**
   * Сохранить фичу.
   * @param {boolean} andReopen следует ли открыть новую форму со значениями из предыдущего редактирования
   */
  async save(andReopen = false) {
    if (this.saveActive) {
      this.saveActive = !this.saveActive;
      try {
        const saveValid = await this.saveFeature();
        if (saveValid) {
          this.closeForm(andReopen);
        }
      } catch (error) {
        console.error(error); // eslint-disable-line no-console
      }
      this.saveActive = !this.saveActive;
    }
  },
  /**
   * Закрытие формы.
   * @param {boolean} andAdd следует ли открыть новую форму со значениями из предыдущего редактирования
   */
  closeForm(andAdd) {
    const me = this;
    this.$el.html('<div>Изменения сохранены</div>');

    setTimeout(() => {
      me._tearDown();
      if (andAdd) {
        const meta = me.model.getMeta();
        AIS.trigger('editor:blank', meta, me.model);
      }
    }, 500);
  },
  /**
   * Применить изменения к коллекции фич.
   * @param {Backbone.Collection} collection коллекция фич
   * @param {Number} modelId идентификатор изменяемой модели
   * @param {Object} changes изменения
   * @returns {boolean} true, если получилось, false, если произошла известная ошибка обработки
   */
  async applyChanges(collection, modelId, changes) {
    try {
      await collection._change(modelId, changes);
      return true;
    } catch (error) {
      if (error instanceof FeatureError) {
        return false;
      }
      throw error;
    }
  },
  /**
   * Сохранение фичи.
   * Валидируем фичу (проверяем, что у нее есть геометрия).
   * @returns {boolean} результат проверки полей
   */
  async saveFeature() {
    const fieldsCheckStatus = this.fixAndValidateFields();
    const isFeatureValid = this.validate() && fieldsCheckStatus;
    if (!isFeatureValid) {
      return false;
    }

    _.each(this._fields, (f) => {
      f.commitValue();
    });

    this.deactivateFields();
    const filter = this.model.getFilter();
    const pointList = filter.getCollection();
    let saveSuccess;

    if (this.model.isNew()) {
      // создание новой фичи
      await pointList._add(this.changes());
      this.model.destroyFeature();
      saveSuccess = true;
    } else if (!this._batchEdit) {
      // редактирование одной фичи
      saveSuccess = await this.applyChanges(pointList, this.model.id, this.changes());
    } else {
      // групповое редактирование
      // у основной фичи обновляем помеченные (copy) и измененные (dirty) поля
      let needSync = false;
      const updatedModel = {};
      _.each(this._fields, (field) => {
        const state = field.getFieldState();
        if (state.get('dirty') && state.get('copy')) {
          needSync = true;
          updatedModel[state.get('fieldName')] = state.get('value');
        }
      });
      if (needSync) {
        saveSuccess = await this.applyChanges(pointList, this.model.id, updatedModel);
      }

      // у остальных фич обновляем помеченные (copy) поля
      const fieldsToUpdate = this._properties.filter(fieldState => fieldState.get('copy'));
      saveSuccess = true;
      this.batchObjects.forEach(async ({ model }) => {
        const batchObjectChangeSuccess = await this.updateAdditionalObject(fieldsToUpdate, model);
        if (!batchObjectChangeSuccess) {
          saveSuccess = batchObjectChangeSuccess;
        }
      });
    }
    if (!saveSuccess) {
      return false;
    }
    AIS.lastSavedFeatureLayer = this.model.get('layer');
    return true;
  },
  async updateAdditionalObject(toUpdate, model) {
    const filter = this.model.getFilter();
    const pointList = filter.getCollection();
    let needSync = false;
    const updatedModel = {};

    _.each(toUpdate, function (fieldState) {
      const fieldName = fieldState.get('fieldName');
      if (model.get(fieldName) !== this.model.get(fieldName)) {
        needSync = true;
        updatedModel[fieldName] = this.model.get(fieldName);
      }
    }, this);

    if (needSync) {
      return this.applyChanges(pointList, model.id, updatedModel);
    }
  },

  /**
    * Проверяем, что у добавляемой/изменяемой фичи имеется поле с геометрией.
    * @returns {*}   значение геометрии фичи
    */
  validate() {
    return this.emptyGeometry || this.geometryField.field.getValue();
  },
  /**
    * Проверяем, что у добавляемой/изменяемой фичи поля удовлетворяют требованиям.
    * @returns {bool} результат проверки полей
    */
  fixAndValidateFields() {
    let result = true;
    this._fields.forEach((modelfield) => {
      const schemaLayer = this.getSchemaLayer();
      if (schemaLayer !== undefined) {
        const [field] = schemaLayer.fields.filter(
          field => field.field === modelfield.options.property.attributes.field,
        );
        if (field !== undefined) {
          if (field.type === 'text') {
            result = result ? this.validateTextField(modelfield, field) : result;
          }
        }
      }
    });
    return result;
  },
  /**
    * Получает слой редактируемого объекта из схемы слоёв.
    * @returns {Object} слой из схемы
    */
  getSchemaLayer() {
    const schemaToMetaMapping = {
      sign: 'roadsign',
      sign_project: 'roadsign_project',
    };
    const metaLayerName = this.model.getFilter().id;
    const layerName = metaLayerName in schemaToMetaMapping ? schemaToMetaMapping[metaLayerName] : metaLayerName;
    return AIS.meta.schema.layers[layerName];
  },
  /**
    * Проверяет значение текстового поля по списку валидаторов.
    *
    * В случае ошибок добавляет описание ошибки к полю.
    * @param {Object} modelfield поле из модели
    * @param {string} fieldValue исправленное значение поля
    * @param {array} validators массив валидаторов
    * @returns {boolean} результат проверки
    */
  checkTextFieldValue(modelfield, fieldValue, validators) {
    let checkResult = true;
    const messages = document.createElement('div');
    messages.className = 'error-messages';
    const [unwantedErrors] = modelfield.el.getElementsByClassName('error-messages');
    if (unwantedErrors !== undefined) {
      modelfield.el.removeChild(unwantedErrors);
    }
    for (const validator of validators) {
      if (!checkTextFields(fieldValue, validator.regex)) {
        checkResult = false;
        modelfield.el.classList.add('control-group', 'error');
        const message = document.createElement('span');
        message.className = 'help-block';
        message.innerText = validator.name;
        messages.appendChild(message);
      }
    }
    modelfield.el.appendChild(messages);
    return checkResult;
  },
  /**
    * Проверяет текстовое поле.
    * @param {Object} modelfield поле из модели
    * @param {Object} field поле из схемы
    * @returns {boolean} результат проверки
    */
  validateTextField(modelfield, field) {
    let result = true;
    const fieldName = field.field;
    const fieldValue = this.model.get(fieldName);
    if (fieldValue !== null && fieldValue !== undefined) {
      const fixedFieldValue = fixTextFields(fieldValue);
      if ('validators' in field && !this.checkTextFieldValue(modelfield, fixedFieldValue, field.validators)) {
        result = false;
      }
      if (fixedFieldValue !== fieldValue) {
        modelfield.field.setValue(fixedFieldValue);
        modelfield.getFieldState().setDirty();
      }
    }
    return result;
  },
  /**
    * Пометить фичу, как отредактированную.
    */
  setDirty() {
    this.model.dirty = true;
  },
  deactivateFields() {
    _.each(this._fields, (fieldView) => {
      if (fieldView.field.deactivate) fieldView.field.deactivate();
    });
  },
  /**
    * Получить фичи для отображения на панорамах.
    * @returns {array} фичи для отображения на панорамах
    */
  getPanoFeatures() {
    return this._fields.filter(
      fieldWrapper => (
        fieldWrapper.field instanceof GeometryField.Generic
        && fieldWrapper.field.showOnPano()
      ),
    ).map(
      fieldWrapper => fieldWrapper.field.getFeature(),
    ).filter(
      feature => feature,
    );
  },
});

export default FeatureForm;
