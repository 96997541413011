/** API взаимодействия с сервером. */
import $ from 'jquery';
import _ from 'underscore';
import ReconnectingWebSocket from 'lib/reconnecting-websocket.min';
import Backbone from 'js/backbone';
import * as consts from 'js/consts';
import AIS from 'js/AIS';
import { SEARCH_TYPE_AIS, SEARCH_TYPE_GEOCODING } from 'js/constants/searchTypes';

// Получаем переменные окружения проекта, сделав синхронный запрос
// на /env_variables (обрабатывается прямо на nginx).
AIS.environment = $.ajax({
  url: '/env_variables',
  dataType: 'json',
  method: 'GET',
  async: false,
}).responseJSON;

// Сохраняем название проекта в Cookie.
if (AIS.environment !== undefined && 'projectName' in AIS.environment) {
  document.cookie = `project=${AIS.environment.projectName}`;
} else {
  console.error('Не удалось загрузить переменные окружения проекта'); // eslint-disable-line no-console
}

/**
 * Взятая блокировка объекта.
 */
class Lock {
  /**
   * Конструктор.
   * @param  {WebSocket} ws подключение по websocker
   */
  constructor(ws) {
    this.ws = ws;
  }

  /**
   * Разблокировать взятую блокировку.
   */
  release() {
    this.ws.close();
  }
}

/**
 * Ошибка АПИ, связанная с обработкой фич.
 */
export class FeatureError extends Error {
  constructor(message) {
    super(message);
    this.name = 'FeatureError';
  }
}

/**
 * Пункт автодополнения в строке поиска.
 */
export class SearchSuggestionItem {
  /**
   * Конструктор.
   * @param {string} type тип варианта автодополнения: переход к координатам, поиск по АИС, геокодинг
   * @param {number} id идентификатор варианта автодополнения
   * @param {string} text основной текст пункта автодополнения
   * @param {string} help дополнительный текст
   * @param {number} lon долгота
   * @param {number} lat широта
   * @param {boolean} pano нужно ли открывать панораму в указанных координатах
   * @param {number?} featureId идентификатор найденной фичи
   */
  constructor(type, id, text, help, lon, lat, pano, featureId) {
    this.type = type;
    this.id = id;
    this.text = text;
    this.help = help;
    this.lon = lon;
    this.lat = lat;
    this.pano = pano;
    this.featureId = featureId;
  }
}

class API {
  constructor() {
    this.subscriptions = {};
    this.projectCache = [];
  }

  /**
   * Вызвать асинхронный метод апи, показав модальное окно в случае ошибки.
   * В случае ошибки в ответе сервера или при вызове метода выводит в консоль трейсбэк и показывает модалку
   * с текстом ответа сервера.
   * @param {Function} asyncApiFunction асинхронная функция вида `async () => await api.method(curried, args)`
   * @throws {FeatureError} рерэйзит ошибки, оборачивая их в нашу
   */
  async tryToCall(asyncApiFunction) {
    try {
      await asyncApiFunction();
    } catch (err) {
      const reason = err.responseText || err.message;
      $('#notice-api-call-error').html(
        _.template(
          $('#tmpl-notice-api-call-error').html(),
        )({
          reason,
        }),
      ).modal();
      console.trace(err); // eslint-disable-line no-console
      throw new FeatureError(reason);
    }
  }

  /**
   * Сохранить токен в куки.
   * @param  {string} token токен
   */
  token_cookie_set(token) {
    document.cookie = `token=${token}`;
  }

  /**
   * Получение конфигурации проекта.
   * @returns {Promise} конфигурация проекта
   */
  getConfig() {
    return Promise.resolve(
      $.ajax({
        url: '/ajax/config',
        dataType: 'json',
        method: 'GET',
      }),
    );
  }

  /** Проверка входа на сервере.
   *
   * @returns {Promise}       промис, который будет зарезолвлен когда
   *                          будет получен результат запроса на авторизацию
   */
  checkLogin() {
    return Promise.resolve(
      $.ajax({
        url: '/api/login',
        method: 'GET',
      }),
    );
  }

  /** Авторизация пользователя.
   *
   * Отправляем запрос на сервер с именем и паролем.
   *
   * @param {string} username имя пользователя.
   * @param {string} password пароль пользователя.
   * @returns {Promise}       промис, который будет зарезолвлен когда
   *                          будет получен результат запроса на авторизацию
   */
  login(username, password) {
    const login_form = new FormData();
    login_form.append('username', username);
    login_form.append('password', password);

    return Promise.resolve(
      $.ajax({
        url: '/api/login',
        dataType: 'json',
        data: login_form,
        processData: false,
        contentType: false,
        method: 'POST',
      }),
    );
  }

  /** Выход пользователя.
   *
   * @returns {Promise}       промис, который будет зарезолвлен когда
   *                          будет получен результат запроса выхода
   */
  logout() {
    return Promise.resolve(
      $.ajax({
        url: '/api/logout',
        method: 'POST',
      }),
    );
  }

  /** Получение слоёв (meta.json).
   *
   * Отправляем запрос на сервер.
   * @returns {Promise}  промис, который будет зарезолвлен когда
   *                    будет получен meta.json
   */
  getMeta() {
    return Promise.resolve(
      $.ajax({
        url: '/ajax/meta',
        dataType: 'json',
        processData: false,
        contentType: false,
        method: 'GET',
      }),
    );
  }

  /** Сравниваем текущую версию meta.json с указанной.
   *
   * Отправляем запрос на сервер.
   * @param {Number} revision текущая ревизия meta.json.
   * @param {Number} interval интервал с которым проверяем изменения.
   * @returns {Promise}  промис, который будет зарезолвлен когда
   *                    ревизия meta.json изменится
   */
  onMetaRevisionChange(revision, interval = 100000) {
    return new Promise(
      (resolve) => {
        const getMetaInterval = setInterval(
          () => {
            this.getMeta()
              .then(
                (response) => {
                  if (response.revision !== revision) {
                    clearInterval(getMetaInterval);
                    resolve(response.revision);
                  }
                },
              );
          },
          interval,
        );
      },
    );
  }

  /** Получение sld.xml.
   *
   * Отправляем запрос на сервер.
   * @returns {Promise}  промис, который будет зарезолвлен когда
   *                    будет получен sld.xml
   */
  getSLD() {
    return Promise.resolve(
      $.ajax({
        url: 'sld.xml',
        method: 'GET',
      }),
    );
  }

  /** Сгенерировать чертеж.
   *
   * @param  {string}  export_format 'dxf', 'pdf'
   * @param  {string}  region_wkt    wkt области для которой выгружаем чертеж
   * @returns {Promise}  промис, который будет зарезолвлен когда
   *                    будет получено название генерируемого чертежа
   */
  exportDXF(export_format, region_wkt) {
    return Promise.resolve(
      $.ajax({
        url: '/api/export_dxf',
        method: 'POST',
        dataType: 'json',
        contentType: 'application/json',
        data: JSON.stringify({
          export_format,
          region_wkt,
        }),
        processData: false,
      }),
    );
  }

  /**
   * Заблокировать объект.
   * @param  {number}  feature_id идентификатор объекта
   * @returns {Promise}            промис
   */
  lock(feature_id) {
    return new Promise((resolve) => {
      const { protocol } = window.location;
      const websocketProtocol = protocol === 'https:' ? 'wss:' : 'ws:';
      const ws = new WebSocket(
        `${websocketProtocol}//${document.location.host}/api/lock/${feature_id}`,
      );
      ws.addEventListener('error', () => resolve(false));
      ws.addEventListener('close', () => resolve(false));
      ws.addEventListener('message', (message) => {
        if (JSON.parse(message.data)) {
          resolve(new Lock(ws));
        } else {
          resolve(false);
        }
      });
    });
  }

  /**
   * Подключиться к WebSocket фильтра.
   * @param  {string}  type   тип подписки
   * @param  {Filter}  filter фильтр к которому мы подключаемся
   * @returns {Promise}       промис, который будет зарезолвлен когда
   *                          будет осуществлено подключение к серверу
   */
  _connectToFilter(type, filter) {
    const { protocol } = window.location;
    const websocketProtocol = protocol === 'https:' ? 'wss:' : 'ws:';
    return new Promise((resolve, reject) => {
      const ws = new ReconnectingWebSocket(
        `${websocketProtocol}//${document.location.host}/api/subscribe/${type}/${filter.id}`,
        null,
        {
          timeoutInterval: 1000 * 10, // 10 секунд
        },
      );
      ws.addEventListener('error', reject);
      ws.addEventListener('open', () => {
        ws.removeEventListener('error', reject);
        resolve(ws);
      });
    });
  }

  /**
   * Подписаться на изменения фильтра.
   * @param  {string}  type   тип подписки
   * @param  {Filter}  filter фильтр, на изменения которого мы подписываемся
   * @returns {Backbone.Model} модель, на которой будут триггерится евенты
   */
  async subscribe(type, filter) {
    if (!this.subscriptions[type]) {
      this.subscriptions[type] = {};
    }

    if (!this.subscriptions[type][filter.id]) {
      const events = new Backbone.Model({ ws: null });
      this.subscriptions[type][filter.id] = events;
      const ws = await this._connectToFilter(type, filter);

      events.set('ws', ws);
      ws.addEventListener('message', (raw_message) => {
        const message = JSON.parse(raw_message.data);
        events.trigger(message.type, message);
      });
    }

    return this.subscriptions[type][filter.id];
  }


  /**
   * Отписаться от изменений фильтра.
   * @param  {string} type   тип подписки
   * @param  {Filter} filter фильтр от изменений которого мы подписываемя
   */
  unsubscribe(type, filter) {
    const events = this.subscriptions[type] ? this.subscriptions[type][filter.id] : null;

    if (events) {
      events.get('ws').close();
      events.off();
    }
  }

  /**
   * Удаление фичи.
   * @param  {Number} id  -   идентификатор фичи
   * @returns {Promise}    -   промис, который будет зарезолвлен когда фича будет удалена на сервере
   */
  deleteFeature(id) {
    return Promise.resolve(
      $.ajax(`/api/feature/${id}`, {
        method: 'DELETE',
        processData: false,
      }),
    );
  }

  /**
   * Обновление фичи.
   * @param  {Number} id            -  идентификатор фичи
   * @param  {Object} changedFields -  измененные поля
   * @returns {Promise}              -  промис, который будет зарезолвлен когда фича будет удалена на сервере
   */
  updateFeature(id, changedFields) {
    return Promise.resolve(
      $.ajax(`/api/feature/${id}`, {
        method: 'PUT',
        dataType: 'json',
        contentType: 'application/json',
        data: JSON.stringify(changedFields),
        processData: false,
      }),
    );
  }

  /**
   * Создание фичи.
   * @param  {Object} fields -  поля фичи
   * @returns {Promise}       -  промис, который будет зарезолвлен когда фича будет удалена на сервере
   */
  createFeature(fields) {
    return Promise.resolve(
      $.ajax('/api/feature/', {
        method: 'POST',
        dataType: 'json',
        contentType: 'application/json',
        data: JSON.stringify(fields),
        processData: false,
      }),
    );
  }

  /** Отправляем на сервер полигон для расчета площади входящих в него площадок, предназначенных для уборки.
    * @param  {Object}  geometry       геометрия
    * @param  {Boolean} cleaning_areas флаг, если true то рассчитываем площадь уборки
    * @returns {Promise}                промис, в котором будет список с измеренными площадями
    */
  getMeasureAreas(geometry, cleaning_areas) {
    return Promise.resolve(
      $.ajax('/api/measure_areas', {
        method: 'POST',
        dataType: 'json',
        contentType: 'application/json',
        data: JSON.stringify({
          geojson: AIS.transformReaderGeoJSON.write(geometry),
          cleaning_areas,
        }),
        processData: false,
      }),
    );
  }

  /**
    * Проверка, что фича может быть разрезана.
    * @param {string} parent_geom    - геометрия разрезаемой фичи
    * @param {string} separator_geom - геометрия линии, по которой разрезаем фичу
    * @returns {Promise}              - промис, который будет зарезолвлен после получения результата
    */
  checkCut(parent_geom, separator_geom) {
    return Promise.resolve(
      $.ajax('/api/tools/check_cut', {
        method: 'POST',
        dataType: 'json',
        contentType: 'application/json',
        data: JSON.stringify({ parent_geom, separator_geom }),
        processData: false,
      }),
    );
  }

  /**
    * Разрезание фичи.
    * @param {number} feature_id    -  идентификатор разрезаемой фичи
    * @param {string} separator_geom - геометрия линии, по которой разрезаем фичу
    * @returns {Promise}              - промис, который будет зарезолвлен после получения результата
    */
  cut(feature_id, separator_geom) {
    return Promise.resolve(
      $.ajax('/api/tools/cut', {
        method: 'POST',
        dataType: 'json',
        contentType: 'application/json',
        data: JSON.stringify({ feature_id, separator_geom }),
        processData: false,
      }),
    );
  }

  /** Получение данных о панораме.
    * @param {Object} fields -  поля для выполнения запроса
    * @returns {Promise} - промис, который будет зарезолвлен после получения результата
    */
  panoLocate(fields) {
    return Promise.resolve($.ajax({
      url: '/api/pano/locate',
      data: JSON.stringify(fields),
      contentType: 'application/json',
      processData: false,
      dataType: 'json',
      method: 'POST',
    }));
  }

  /** Получение метрики и данных о дорожной оси для указанной панорамы.
    * @param {number} pano_id - идентификатор панорамы
    * @returns {Promise} промис, который будет зарезолвлен после получения результата
    */
  panoLinearCoord(pano_id) {
    return Promise.resolve($.ajax({
      url: '/api/pano/linear_coord',
      data: JSON.stringify({ pano_id }),
      contentType: 'application/json',
      processData: false,
      dataType: 'json',
      method: 'POST',
    }));
  }

  /**
    * Спроецировать геометрию на панораму.
    * @param    {number}        pano_id идентификатор панорамы
    * @param    {string|object} geojson GeoJSON
    * @param    {number}        distance расстояние обрезки GeoJSON
    * @returns  {Promise}       промис, который будет зарезолвлен с результатом проецирования
    */
  panoProject(pano_id, geojson, distance) {
    // try to find in cache
    const cacheFound = this.projectCache.filter(
      cacheEntry => (
        cacheEntry.pano_id === pano_id
        && cacheEntry.distance === distance
        && _.isEqual(cacheEntry.geojson, geojson)
      ),
    );

    if (cacheFound.length > 0) {
      const [cacheEntry] = cacheFound;
      return Promise.resolve(cacheEntry.response);
    }

    // cache not found
    const response = $.ajax({
      url: '/api/pano/project',
      data: JSON.stringify({
        pano_id,
        geojson: (
          typeof geojson === 'string'
            ? geojson
            : JSON.stringify(geojson)
        ),
        distance,
      }),
      contentType: 'application/json',
      processData: false,
      dataType: 'json',
      method: 'POST',
    });

    // update cache
    const cacheCopy = this.projectCache.slice();
    cacheCopy.push({
      pano_id,
      geojson,
      distance,
      response,
    });
    this.projectCache = cacheCopy.slice(-consts.PROJECT_CACHE_MAX_SIZE);

    return Promise.resolve(response);
  }


  /**
    * Получить точку в плоскости дороги.
    * @param  {number}         pano_id идентификатор панорамы
    * @param  {number}         hlookat взгляд панорамы по горизонтали
    * @param  {number}         vlookat взгляд панорамы по вертикали
    * @returns {Promise}                промис, который будет зарезолвлен с точкой в плоскости дороги
    */
  panoRoadPointLocate(pano_id, hlookat, vlookat) {
    return Promise.resolve($.ajax({
      url: '/api/pano/road_point_locate',
      data: JSON.stringify({
        pano_id,
        hlookat,
        vlookat,
      }),
      contentType: 'application/json',
      processData: false,
      dataType: 'json',
      method: 'POST',
    }));
  }


  /**
    * Получить точку триангуляцией.
    * @param  {number}         pano_id1 идентификатор панорамы
    * @param  {number}         hlookat1 взгляд панорамы по горизонтали
    * @param  {number}         vlookat1 взгляд панорамы по вертикали
    * @param  {number}         pano_id2 идентификатор панорамы
    * @param  {number}         hlookat2 взгляд панорамы по горизонтали
    * @param  {number}         vlookat2 взгляд панорамы по вертикали
    * @returns {Promise}                 промис, который будет зарезолвлен с точкой
    */
  panoTriangulate(pano_id1, hlookat1, vlookat1, pano_id2, hlookat2, vlookat2) {
    return Promise.resolve($.ajax({
      url: '/api/pano/triangulate',
      data: JSON.stringify({
        pano_id1,
        hlookat1,
        vlookat1,
        pano_id2,
        hlookat2,
        vlookat2,
      }),
      contentType: 'application/json',
      processData: false,
      dataType: 'json',
      method: 'POST',
    }));
  }

  /**
    * Изменение длины до заданной для линий.
    * @param {string} geom    - первоначальная геометрия
    * @param {number} length  - требуемая длина
    * @returns {Promise}  - промис, который будет зарезолвлен после получения результата
    */
  changeLineLength(geom, length) {
    return Promise.resolve(
      $.ajax('/api/draw/change_line_length', {
        method: 'POST',
        dataType: 'json',
        contentType: 'application/json',
        data: JSON.stringify({ geom, length }),
        processData: false,
      }),
    );
  }

  /**
    * Получение геометрии полигона по его оси и ширине.
    * @param {string} geom    - ось полигона
    * @param {number} width   - требуемая ширина
    * @returns {Promise}  - промис, который будет зарезолвлен после получения результата
    */
  drawAxisAndWidth(geom, width) {
    return Promise.resolve(
      $.ajax('/api/draw/axis_and_width', {
        method: 'POST',
        dataType: 'json',
        contentType: 'application/json',
        data: JSON.stringify({ geom, width }),
        processData: false,
      }),
    );
  }

  /**
    * Получение геометрии полигона по его оси и ширине.
    * @param {string} polygon_geom - исходный полигон
    * @param {number} point_one    - точка на границе полигона
    * @param {number} point_two    - точка на границе полигона
    * @returns {Promise}  - промис, который будет зарезолвлен после получения результата
    */
  polygonBorderByTwoPoints(polygon_geom, point_one, point_two) {
    return Promise.resolve(
      $.ajax('/api/draw/polygon_border_by_two_points', {
        method: 'POST',
        dataType: 'json',
        contentType: 'application/json',
        data: JSON.stringify({ polygon_geom, point_one, point_two }),
        processData: false,
      }),
    );
  }

  /**
    * Получение геометрии полигона, примыкающего к другому полигону по какому-то участку его границы.
    * @param {string} border_geom    - геометрия границы
    * @param {number} width          - требуемая ширина
    * @returns {Promise}  - промис, который будет зарезолвлен после получения результата
    */
  adjacentPolygon(border_geom, width) {
    return Promise.resolve(
      $.ajax('/api/draw/adjacent_polygon', {
        method: 'POST',
        dataType: 'json',
        contentType: 'application/json',
        data: JSON.stringify({ border_geom, width }),
        processData: false,
      }),
    );
  }

  /**
    * Получение геометрии полигона - заливки захардкоженным на сервере радиусом.
    * @param {string} geom   - геометрия точки - центра заливки
    * @param  {Array} layers - слои, которые заливка будет обходить ( если геометрия фичи этого слоя - полигон)
    * @returns {Promise}  - промис, который будет зарезолвлен после получения результата
    */
  paintBucket(geom, layers) {
    return Promise.resolve(
      $.ajax('/api/draw/paint_bucket', {
        method: 'POST',
        dataType: 'json',
        contentType: 'application/json',
        data: JSON.stringify({ geom, layers }),
        processData: false,
      }),
    );
  }

  /**
    * Копирование полилинии со сдвигом.
    * @param {string} geom            - геометрия копируемой полилинии
    * @param {string} direction_point - геометрия точки - выбора направления
    * @param {number} distance - геометрия точки - выбора направления
    * @returns {Promise}  - промис, который будет зарезолвлен после получения результата
    */
  shiftCopyLine(geom, direction_point, distance = 10.0) {
    return Promise.resolve(
      $.ajax('/api/draw/shift_copy_line', {
        method: 'POST',
        dataType: 'json',
        contentType: 'application/json',
        data: JSON.stringify({ geom, direction_point, distance }),
        processData: false,
      }),
    );
  }

  /**
    * Определение азимута объекта по ближайшей оси.
    * @param {string} point_geom   - геометрия точки в формате GeoJSON
    * @returns {Promise}  - промис, который будет зарезолвлен после получения результата
    */
  azimuth(point_geom) {
    return Promise.resolve(
      $.ajax('/api/azimuth', {
        method: 'POST',
        dataType: 'json',
        contentType: 'application/json',
        data: JSON.stringify({ point_geom }),
        processData: false,
      }),
    );
  }

  /**
    * Произвести геокодинг переданного запроса.
    * Используется геокодинг ArcGIS
    * https://developers.arcgis.com/rest/geocode/api-reference/geocoding-find-address-candidates.htm
    * @param  {string} query  запрос геокодинга
    * @returns {Promise}      координаты результата
    */
  geocode(query) {
    const { geocoding_location, center } = AIS.config;
    return Promise.resolve(
      $.getJSON(
        '/geo',
        {
          f: 'json',
          SingleLine: (
            geocoding_location
              ? `${geocoding_location}, ${query}`
              : query
          ),
          location: `${center.lon},${center.lat}`,
          maxLocations: 10,
        },
      ),
    ).then(
      /**
        * Колбек обработки на запрос геокодинга.
        * @param  {Object} data данные ответа на запрос геокодинга
        * @returns {Array<SearchSuggestionItem>} массив вариантов автодополнения
        */
      (data) => {
        if (
          data
          && data.candidates.length
        ) {
          const results = data.candidates.map((feature, index) => {
            const lon = Number.parseFloat(feature.location.x);
            const lat = Number.parseFloat(feature.location.y);
            return new SearchSuggestionItem(
              SEARCH_TYPE_GEOCODING,
              index,
              feature.address,
              '',
              lon,
              lat,
              false,
              null,
            );
          });
          return results;
        }
        return [];
      },
    );
  }

  /**
    * Получение минимального и максимального годов действия.
    * @returns {Promise} - промис, который будет зарезолвлен после получения результата
    */
  getValidYears() {
    return Promise.resolve(
      $.ajax({
        url: '/api/valid_years',
        dataType: 'json',
        method: 'GET',
      }),
    );
  }

  /**
   * Сделать запрос на поиск.
   * @param {string} query запрос для поиска
   * @returns {Promise} - промис, который будет зарезолвлен после получения результата
   */
  search(query) {
    return Promise.resolve(
      $.ajax({
        url: `/ajax/autocomplete/search?q=${encodeURIComponent(query)}`,
        dataType: 'json',
        method: 'GET',
      }),
    ).then(
      /**
       * Коллбек обработки результата поискового запроса в АИС.
       * @param {Object} results результат запроса
       * @param {Array<Object>} results.results список предложенных вариантов
       * @returns {Array<SearchSuggestionItem>} массив вариантов автодополнения
       */
      results => results.results.map((searchItem, index) => new SearchSuggestionItem(
        SEARCH_TYPE_AIS,
        index,
        searchItem.text,
        searchItem.help,
        searchItem.lon,
        searchItem.lat,
        searchItem.pano,
        searchItem.id,
      )),
    );
  }

  /**
   * Получить список связей.
   * @returns {Promise} промис, который будет зарезолвлен после получения результата
   */
  getRelations() {
    return Promise.resolve(
      $.ajax({
        url: '/api/relations',
        dataType: 'json',
        method: 'GET',
      }),
    );
  }

  /**
   * Получить список объектов, которые могут быть связаны с переданным мануальной связью.
   * @param {string} relationName имя связи
   * @param {Number} [childId] идентификатор объекта
   * @param {Boolean} [onlyEnabled] только активные связи
   * @returns {Promise} промис, который будет зарезолвлен после получения результата
   */
  getManualRelationParents(relationName, childId = null, onlyEnabled = null) {
    const params = { relation_name: relationName };
    if (childId != null) {
      params.child_id = childId;
    }
    if (onlyEnabled != null) {
      params.only_enabled = onlyEnabled;
    }
    return Promise.resolve(
      $.ajax({
        url: '/api/parents',
        dataType: 'json',
        method: 'GET',
        data: params,
      }),
    );
  }

  /**
   * Получить список фильтров, принадлежащих пользователю.
   * @returns {Promise} промис с объектом, где ключи — это названия фильтров, а значения — это идентификаторы фильтров
   */
  getUserFilters() {
    return Promise.resolve(
      $.ajax({
        url: '/api/user_filters',
        dataType: 'json',
        processData: false,
        contentType: false,
        method: 'GET',
      }),
    );
  }

  /**
   * Обновить список фильтров, принадлежащих пользователю.
   * @param {Object} userFilters новые фильтры, принадлежащие пользователю
   * @returns {Promise} промис с новыми фильтрами, принадлежащими пользователю
   */
  updateUserFilters(userFilters) {
    return Promise.resolve(
      $.ajax('/api/user_filters', {
        method: 'PUT',
        dataType: 'json',
        contentType: 'application/json',
        data: JSON.stringify(userFilters),
        processData: false,
      }),
    );
  }

  /**
   * Получить состояние фильтра — условие, переменные и прочее.
   *
   * @param {number} filterId идентификатор фильтра, состояние которого нужно получить
   * @returns {Promise} промис с состоянием фильтра
   */
  getFilterData(filterId) {
    return Promise.resolve(
      $.ajax({
        url: `/api/filter/${filterId}`,
        dataType: 'json',
        processData: false,
        contentType: false,
        method: 'GET',
      }),
    );
  }
}


export default new API();
