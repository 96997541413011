import $ from 'jquery';
import _ from 'underscore';
import ImageSelectModal from 'js/view/ImageSelectModal';


const SubtypeSelectModal = ImageSelectModal.extend({
  _getImgHtml(type, typeSubtype) {
    const icon = this.options.property.getIcon(type, typeSubtype, true);
    const subtype = (typeSubtype === null || typeSubtype === undefined) ? '' : String(typeSubtype);
    const name = (!this.options.type) ? (this.options.property.find(type).gost_name || type) : (subtype || '');
    const desc = (!this.options.type) ? `<div class="description">${type}</div>` : '';
    return `<div data-type="${type}" data-type-subtype="${subtype}" class="sign-icon">`
      + `<img title="${name}" alt="${name}" src="${icon}">${desc}</div>`;
  },
  select(evt) {
    const type = $(evt.currentTarget).attr('data-type');
    const typeSubtype = $(evt.currentTarget).attr('data-type-subtype');
    const subtype = (typeSubtype.length > 0) ? typeSubtype : null;
    this.options.callback((!this.options.type) ? type : subtype);
  },
  content() {
    let html = '';
    const groups = (!this.options.type)
      ? this.options.property.getData()
      : this.options.property.find(this.options.type).variants;

    _.each(groups, function (group) {
      let groupView;
      if (!this.options.type) {
        groupView = _.map(group.items, function (_, type) {
          return this._getImgHtml(type);
        }, this).join('');
      } else {
        groupView = this._getImgHtml(this.options.type)
                        + _.map(group.items, function (typeSubtype) {
                          return this._getImgHtml(this.options.type, typeSubtype);
                        }, this).join('');
      }
      html += `${'<div class="sector">'
                    + '<div class="sector-header">'}${group.name}</div>`
                    + `<div>${groupView}</div>`
                    + '</div>';
    }, this);

    return html;
  },
});

export default SubtypeSelectModal;
