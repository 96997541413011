import OpenLayers from 'lib/OpenLayers-2.12/OpenLayers.debug';


const map = new OpenLayers.Layer.OSM('Google Map',
  // eslint-disable-next-line no-template-curly-in-string
  'https://mts0.google.com/vt/lyrs=m@262000000&hl=ru&gl=RU&src=app&x=${x}&y=${y}&z=${z}&s=', {
    transitionEffect: 'resize',
    isBaseLayer: true,
    numZoomLevels: 20,
    tileOptions: { crossOriginKeyword: null },
  });
const sat_version = 'googlesatVersion' in AIS.environment ? AIS.environment.googlesatVersion : '865';
const sat = new OpenLayers.Layer.OSM('Google Satelite',
  // eslint-disable-next-line no-template-curly-in-string
  ''.concat('https://khms0.google.com/kh/v=', sat_version, '&x=${x}&y=${y}&z=${z}'), {
    transitionEffect: 'resize',
    isBaseLayer: true,
    numZoomLevels: 20,
    tileOptions: { crossOriginKeyword: null },
  });

export default [map, sat];
