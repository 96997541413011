import React from 'react';
import PropTypes from 'prop-types';
import { Menu } from 'antd';
import { TAB_MAP, TAB_OUTER_LIST, TAB_ROADS } from 'js/constants/tabs';
import AIS from 'js/AIS';

import styles from './style.less';


const TabSelector = (props) => {
  const { theme, activeTab, onTabChange } = props;

  return (
    <Menu
      theme={theme}
      mode="horizontal"
      id={styles.header_menu}
      selectedKeys={[activeTab]}
    >
      <Menu.Item
        id={TAB_MAP}
        key={TAB_MAP}
        onClick={() => onTabChange(TAB_MAP)}
      >Карта</Menu.Item>
      <Menu.Item
        id={TAB_OUTER_LIST}
        key={TAB_OUTER_LIST}
        onClick={() => onTabChange(TAB_OUTER_LIST)}
      >Список</Menu.Item>
      {
        AIS.featureEnabled('hide_roads_tab')
          ? null
          : (
            <Menu.Item
              id={TAB_ROADS}
              key={TAB_ROADS}
              onClick={() => onTabChange(TAB_ROADS)}
            >Дороги</Menu.Item>
          )
      }
    </Menu>
  );
};

TabSelector.propTypes = {
  theme: PropTypes.string.isRequired,
  activeTab: PropTypes.oneOf([TAB_MAP, TAB_OUTER_LIST, TAB_ROADS]).isRequired,
  onTabChange: PropTypes.func.isRequired,
};


export default TabSelector;
