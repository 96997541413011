// CSS
import 'lib/OpenLayers-2.12/theme/default/style.css';
import 'lib/bootstrap/css/scoped-bootstrap.less';
import 'css/main.css';
import 'css/jquery-ui-1.10.4.custom.css';
import 'lib/SlickGrid-2.02/slick.grid.css';
import 'lib/select2-3.4.6/select2.css';
import 'lib/select2-3.4.6/select2-bootstrap.css';
import 'lib/datepicker/css/bootstrap-datepicker.standalone.css';
import 'lib/jQuery-File-Upload-9.5.7/css/jquery.fileupload.css';
import 'css/jquery.fancybox.min.css';
import 'tippy.js/themes/translucent.css';

// JS
import $ from 'jquery';
import 'mousetrap';
import tippy from 'tippy.js';
import 'lib/jquery-ui.min';
import 'lib/jquery.layout-1.3.0.rc30.79';
import 'lib/jquery.event.drag-2.2';
import 'lib/jquery-migrate-1.2.1.min';
import 'slickgrid/slick.core';
import 'slickgrid/slick.grid';
import 'lib/select2-3.4.6/select2';
import 'lib/select2-3.4.6/select2_locale_ru';
import 'lib/jquery.fancybox.min';
import 'lib/jQuery-File-Upload-9.5.7/js/jquery.iframe-transport';
import 'lib/jQuery-File-Upload-9.5.7/js/jquery.fileupload';
import 'lib/bootstrap/js/bootstrap';
import 'lib/datepicker/js/bootstrap-datepicker';

import 'js/main';


tippy.setDefaults({
  trigger: 'manual',
  distance: 0,
  size: 'small',
  theme: 'translucent',
  arrow: true,
  appendTo: (element) => {
    // нельзя напихивать тултипы в родительскую ноду для элементов Antd,
    // иначе он начинает вести себя неадекватно, поэтому будем напихивать
    // их в отдельно отведённый div
    if (document.getElementById('antd-scope').contains(element)) {
      return document.getElementById('tippy-tooltips');
    }
    // но при этом тултипы для всего, что работает под бэкбоном
    // обязательно должны быть напиханы рядом с самими элементами,
    // потому что у нас в каком-то месте происходит утечка тултипов
    // и эти утекшие тултипы будет видно, но если их складывать рядом
    // с элементами, то они будут скрываться вместе с самими элементами
    return element.parentNode;
  },
  hideOnClick: false,
});
$('#splashscreen').css({ display: 'none' });
$('#app').css({ display: 'block' });
