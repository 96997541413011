import $ from 'jquery';
import _ from 'underscore';
import Backbone from 'js/backbone';


const FileItem = Backbone.View.extend({
  events: {
    'click .delete-button': 'removeAtt',
  },
  attTemplate: _.template('<code><%= att %></code> <span class="delete-button">×</span>'),

  tagName: 'li',
  initialize: function () {
    this.fullname = this.options.name;
  },
  removeAtt() {
    this.options.master.removeAtt(this.fullname);
  },
  start() {
    this.$el.append('<div id="progress"><div class="bar" style="width: 0%;"></div></div></div>');
  },
  progress(progress) {
    this.$el.find('.bar').css('width', `${progress}%`);
  },
  fail() {
    const me = this;
    console.log('Upload failed', arguments); // eslint-disable-line no-console
    this.$el.addClass('fail');
    setTimeout(() => {
      me.remove();
    }, 1000);
  },
  success(newName) {
    this.$el.find('#progress').remove();
    this.fullname = newName;
  },
  render() {
    this.$el.html(this.attTemplate({ att: this.options.shortName }));
    return this;
  },
});

const UploadManager = Backbone.View.extend({
  template: _.template($('#tmpl-upload-manager').html()),
  _attachments: [],
  attTemplate: _.template('<li><code><%= att %></code></li>'),
  initialize: function () {
    this._attachments = this.model.getAttachments();
    this._files = {};
  },
  getValue() {
    if (this._attachments.length > 0) {
      return JSON.stringify(this._attachments);
    }
  },
  onLoaded(data) {
    _.each(data.files, function (file) {
      this._attachments.push(file.name);
      this.updateFieldState();
    }, this);
  },
  commitValue() {
    const fieldName = this.options.property.getName();
    this.model.set(fieldName, this.getValue());
  },
  updateFieldState() {
    this.options.fieldState.set('value', JSON.stringify(this._attachments));
    this.options.fieldState.set('dirty', true);
  },
  removeAtt(name) {
    const idx = this._attachments.indexOf(name);
    if (idx === -1) {
      console.error('This element does not exist'); // eslint-disable-line no-console
    } else {
      this._attachments.splice(idx, 1);
      this.updateFieldState();
      this.render();
    }
  },
  render() {
    const me = this;
    this.$el.html(this.template());

    _.each(this._attachments, function (attachment) {
      const item = new FileItem({
        name: attachment,
        master: this,
        shortName: this.options.property.strip(attachment),
      });
      this._files[attachment] = item;
      this.$el.find('.attachments').append(item.render().el);
    }, this);

    this.$el.find('#fileupload').fileupload({
      dataType: 'json',
      add(e, data) {
        const file = data.files[0];

        me._files[file.name] = new FileItem({
          name: file.name,
          master: me,
          shortName: me.options.property.strip(file.name),
        });
        me.$el.find('.attachments').append(
          me._files[file.name].render().el,
        );

        data.submit();
        me._files[file.name].start();
      },
      done(e, data) {
        const { name } = data.files[0];
        me.onLoaded(data.result);
        me._files[name].success(data.result.files[0].name);
      },
      fail(e, data) {
        const { name } = data.files[0];
        me._files[name].fail(e, data);
      },
      progress(e, data) {
        const progress = parseInt(data.loaded / data.total * 100, 10);
        const { name } = data.files[0];
        me._files[name].progress(progress);
      },
    });

    return this;
  },
});

export default UploadManager;
