import OpenLayers from 'lib/OpenLayers-2.12/OpenLayers.debug';
import MetaCollection from 'js/model/MetaCollection';

const AIS0 = {};

AIS0.transformReaderGeoJSON = new OpenLayers.Custom.GeoJSON({
  internalProjection: 'EPSG:900913',
  externalProjection: 'EPSG:4326',
  ignoreExtraDims: false,
});

AIS0.meta = new MetaCollection();

AIS0.getMetaById = function (id) {
  return this.meta.get(id);
};
AIS0.getMetaByClassName = function (class_name) {
  // Получить MetaModel по имени класса (class_name)
  return this.meta.findWhere({ class: class_name });
};
AIS0.panoView = function (name) {
  return this.panoPanel.pano(name);
};
AIS0.arePanosActive = function () {
  return this.pano1.get('active') || this.pano2.get('active');
};

/**
 * Получить фичи для отображения на панорамах.
 * @returns {Array} фичи для отображения на панорамах
 */
AIS0.getPanoFeatures = function () {
  let features = (this.editorPanel.getPanoFeatures() || []).slice();
  features = features.concat(this.panoPanel.getMeasureFeatures() || []);
  return features;
};

export default AIS0;
