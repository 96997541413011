import { EDITOR_CREATE } from 'js/constants/actionTypes';

/**
 * Создать экшн для открытия редактора в режиме создания фичи.
 * @param {Object} meta объект Meta с описанием слоя, на котором создаётся фича
 * @returns {{type: string, meta: Object}} объект экшна
 */
export const editorCreate = meta => ({ // eslint-disable-line import/prefer-default-export
  type: EDITOR_CREATE,
  meta,
});

// TODO: удалить комментарий eslint-disable-..., когда в этом файле появятся ещё функции
