import OpenLayers from 'lib/OpenLayers-2.12/OpenLayers.debug';


const osm = new OpenLayers.Layer.OSM('AST',
  // eslint-disable-next-line no-template-curly-in-string
  '/basemaps/ru/z${z}/${y}/${x}.png', {
    attribution: null,
    transitionEffect: 'resize',
    isBaseLayer: true,
    numZoomLevels: 19,
    tileOptions: { crossOriginKeyword: null },
  });

export default [osm];
