import $ from 'jquery';
import _ from 'underscore';
import Backbone from 'js/backbone';
import api from 'js/api';
import GeoObject from 'js/model/GeoObjects';

export default Backbone.Collection.extend({
  model: GeoObject.Generic,

  initialize: function () {
    this.ready = $.Deferred();
    this.on('change', this.onChange, this);
  },

  containsModel(model) {
    return this.contains(model);
  },

  onChange(model, options) {
    if (this.containsModel(model)) {
      model.onChange(model, options);
    }
  },
  /**
   * Колбек вызываемый когда с сервера приходят новые данные.
   *
   * Удаляет из коллекции фичи, удаленные фильтром,
   * Добавляет все поля из fields в сам объект, добавляет модифицированные объекты в коллекцию.
   * Обновляет фильтр на сервере.
   *
   * @param {object} message данные с сервера
   */
  onUpdate(message) {
    const layerName = this.filter.getMeta().get('class');
    const rows = message.payload;

    if (rows && rows.length) {
      for (const row of rows) {
        if (row.remove) {
          this.remove(row.feature_id);
        } else if (row.data && row.data.layer === layerName) {
          const { fields, ...rowWithoutFields } = row.data;
          this.add({ ...fields, ...rowWithoutFields }, { merge: true });
        }
      }
      this.filter.trigger('update');
    }
  },
  /**
   * Подписаться на изменения в данном слое.
   */
  subscribe() {
    const layerName = this.filter.getMeta().get('class');

    AIS.layersFilter.addLayer(layerName);
    AIS.layersEvents.on('diff_update', this.onUpdate, this);
  },
  /**
   * Отписаться от изменений в данном слое.
   */
  unsubscribe() {
    const layerName = this.filter.getMeta().get('class');

    AIS.layersFilter.deleteLayer(layerName);
    AIS.layersEvents.off('diff_update', this.onUpdate, this);
  },

  getLength() {
    return this.length;
  },

  getItem(i) {
    const item = this.at(i);
    return item.toRow();
  },

  /**
   * Создать фичу.
   * Добавляем к заполненным полям layer (слой, к которому относится фича). Вызываем api метод создания фичи.
   * @param {Object} featureFields поля фичи
   */
  async _add(featureFields) {
    const fields = _.extend(
      featureFields,
      { layer: this.filter.getMeta().getClassName() },
    );
    await api.tryToCall(async () => {
      const createdId = await api.createFeature(fields);
      console.log('success create', createdId); // eslint-disable-line no-console
    });
  },

  /**
   * Удалить фичу.
   * @param {Number} id идентификатор удаляемой фичи
   */
  async _remove(id) {
    await api.tryToCall(async () => {
      const deletedId = await api.deleteFeature(id);
      console.log('success delete', deletedId); // eslint-disable-line no-console
    });
  },

  /** Изменить фичу.
   *
   * @param {Number} id идентификатор фичи
   * @param {Object} changedFields изменные поля
   */
  async _change(id, changedFields) {
    await api.tryToCall(async () => {
      const changedId = await api.updateFeature(id, changedFields);
      console.log('success change', changedId); // eslint-disable-line no-console
    });
  },
});
