import React from 'react';
import { Layout, Button } from 'antd';
import { connect } from 'react-redux';
import TabSelector from 'js/view/toolbar/TabSelector';
import SearchBox from 'js/view/toolbar/SearchBox';
import showHotkeysModal from 'js/view/modal/HotkeysHelpModal';
import UserActionsButton from 'js/view/toolbar/UserActionsButton';
import QuickCreationButton from 'js/view/toolbar/QuickCreationButton';
import { layoutChangeTab } from 'js/actions/layoutActions';
import { userLogout } from 'js/actions/userActions';
import { searchSelectSuggestion, searchUpdateQuery } from 'js/actions/searchActions';
import { editorCreate } from 'js/actions/editorActions';

import styles from './style.less';


const Toolbar = (props) => {
  const {
    theme,
    activeTab,
    onTabChange,
    username,
    onLogout,
    suggestions,
    onQueryUpdate,
    onSuggestionSelect,
    workset,
    meta,
    onQuickCreationClick,
    isUserReadOnly,
  } = props;

  return (
    <Layout.Header id={styles.header}>
      <QuickCreationButton
        workset={workset}
        meta={meta}
        isUserReadOnly={isUserReadOnly}
        onQuickCreationClick={onQuickCreationClick}
      />
      <TabSelector
        theme={theme}
        activeTab={activeTab}
        onTabChange={onTabChange}
      />
      <div id={styles.header_right}>
        <SearchBox
          suggestions={suggestions}
          onQueryUpdate={onQueryUpdate}
          onSuggestionSelect={onSuggestionSelect}
        />
        <Button
          id={styles.help_button}
          type="default"
          shape="circle"
          icon="question"
          size="small"
          onClick={showHotkeysModal}
          title="Горячие клавиши"
        />
        <UserActionsButton
          username={username}
          onLogout={onLogout}
        />
      </div>
    </Layout.Header>
  );
};

Toolbar.propTypes = {
  ...QuickCreationButton.propTypes,
  ...TabSelector.propTypes,
  ...SearchBox.propTypes,
  ...UserActionsButton.propTypes,
};

const mapStateToProps = state => ({
  theme: state.layout.theme,
  activeTab: state.layout.activeTab,
  username: state.user.username,
  suggestions: state.search.suggestions,
  workset: state.workset,
  meta: state.meta,
  isUserReadOnly: state.user.readOnly,
});

const mapDispatchToProps = dispatch => ({
  onTabChange: activeTab => dispatch(layoutChangeTab(activeTab)),
  onLogout: () => dispatch(userLogout()),
  onQueryUpdate: query => dispatch(searchUpdateQuery(query)),
  onSuggestionSelect: (_, chosenOptionComponent) => {
    const [type, id] = chosenOptionComponent.props.data;
    dispatch(searchSelectSuggestion(type, id));
  },
  onQuickCreationClick: meta => dispatch(editorCreate(meta)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Toolbar);
