import _ from 'underscore';
import OpenLayers from 'lib/OpenLayers-2.12/OpenLayers.debug';

const Shape = {
  byType(name) {
    if (name === 'point') return this.Point;
    if (name === 'line') return this.Line;
    if (name === 'polygon') return this.Polygon;
  },
};

Shape.Point = {
  shapeName() {
    return 'Point';
  },
  createFeatureFromPoint(point) {
    return new OpenLayers.Feature.Vector(point);
  },
  addComponent(feature, point) {
    _.extend(feature.geometry, _.pick(point, 'x', 'y', 'alt'));
  },
  getPointList(feature) {
    return [feature.geometry];
  },
  getDrawHandler() {
    return OpenLayers.Handler.Point;
  },
  copyFromBackup(backup, feature) {
    _.extend(feature.geometry, _.pick(backup, 'x', 'y', 'alt'));
    feature.geometry.clearBounds();
  },
  checkValidity() {
    return true;
  },
  saveTriangulationArguments(storage, data) {
    storage.type = 'Point';
    storage.points = data;
  },
};

Shape.Line = {
  shapeName() {
    return 'Line';
  },
  createFeatureFromPoint(point) {
    return new OpenLayers.Feature.Vector(
      new OpenLayers.Geometry.LineString([point]),
    );
  },
  getGeodesicLength(geometry, projection) {
    const gg = new OpenLayers.Projection('EPSG:4326');
    const geom = geometry.clone().transform(projection, gg);
    let length = 0.0;
    let delta;
    let highness;
    if (geom.components && (geom.components.length > 1)) {
      let p1;
      let p2;
      for (let i = 1, len = geom.components.length; i < len; i += 1) {
        p1 = geom.components[i - 1];
        p2 = geom.components[i];

        delta = OpenLayers.Util.distVincenty(
          { lon: p1.x, lat: p1.y }, { lon: p2.x, lat: p2.y },
        ) * 1000;

        if (p1.alt && p2.alt) highness = (p1.alt - p2.alt);
        else highness = 0.0;
        length += Math.sqrt(delta * delta + highness * highness);
      }
    }

    return length;
  },
  getMeasure(feature) {
    const prj = new OpenLayers.Projection('EPSG:900913');
    return this.getGeodesicLength(feature.geometry, prj);
  },
  addComponent(feature, point) {
    feature.geometry.addComponent(point, feature.geometry.components.length);
  },
  getPointList(feature) {
    return feature.geometry.components;
  },
  getDrawHandler() {
    return OpenLayers.Handler.Path;
  },
  copyFromBackup(backup, feature) {
    feature.geometry.components = backup.components.slice();
    feature.geometry.clearBounds();
  },
  checkValidity(feature) {
    return feature.geometry.components.length >= 2;
  },
  saveTriangulationArguments(storage, data) {
    storage.type = 'Line';
    storage.points.push(data);
  },
};

Shape.Polygon = {
  shapeName() {
    return 'Polygon';
  },
  createFeatureFromPoint(point) {
    const ring = new OpenLayers.Geometry.LinearRing([point]);
    return new OpenLayers.Feature.Vector(
      new OpenLayers.Geometry.Polygon(ring),
    );
  },
  getMeasure(feature) {
    const prj = new OpenLayers.Projection('EPSG:900913');
    return feature.geometry.getGeodesicArea(prj);
  },
  addComponent(feature, point) {
    const ring = feature.geometry.components[0];
    ring.addComponent(point, ring.components.length);
  },
  getPointList(feature) {
    return feature.geometry.components[0].components;
  },
  getDrawHandler() {
    return OpenLayers.Handler.Polygon;
  },
  copyFromBackup(backup, feature) {
    feature.geometry.components = backup.components.slice();
    feature.geometry.clearBounds();
  },
  checkValidity(feature) {
    return feature.geometry.components[0].components.length >= 4;
  },
  saveTriangulationArguments(storage, data) {
    storage.type = 'Polygon';
    storage.points.push(data);
  },
};

export default Shape;
