import _ from 'underscore';
import Backbone from 'js/backbone';


const LayerView = Backbone.View.extend({
  /** Мап id фич на их модели.
   *
   * @type {?Map<integer, object>}
   */
  prevState: null,

  initialize: function (options) { // eslint-disable-line no-unused-vars
    this.layer = this.createLayer();

    this.model.on('update', function () {
      this.updateFeatures();
    }, this);

    this.options.map.map.events.register('zoomend', this, this.onZoom);
  },
  /**
   * Спрятать все фичи в слое, которые удовлетворяют условию начиная с некоторого зума.
   *
   * @param {function} condition условие - функция от фичи
   * @param {number} minZoom зум, начиная с которого будут скрыты фичи
   */
  hideFeatures(condition, minZoom) {
    const currentZoom = this.layer.map.getZoom();
    this.layer.features.forEach(
      (feature) => {
        if (condition(feature)) {
          // eslint-disable-next-line no-param-reassign
          feature.style = (currentZoom <= minZoom) ? { display: 'none' } : null;
        }
      },
    );
    this.layer.redraw();
  },
  /**
   * Получить видимость слоя.
   *
   * Возвращает `false`, если слой скрыт.
   *
   * @returns {Boolean} видимость слоя
   */
  evaluateVisibility() {
    return this.model.getVisible();
  },
  /**
   * Колбек, вызываемый при зуминге.
   *
   * Скрывает все слои и фичи, которые должны быть скрыты на этом зуме.
   *
   * @param {OpenLayers.Event} event событие изменения зума
   */
  onZoom(event) { // eslint-disable-line no-unused-vars
    const on = this.evaluateVisibility();
    this.layer.setVisibility(on);

    const layerName = this.model.getClassName();
    const hiddenLayers = [
      'aircable_electro',
      'aircable_optic_electro',
      'aircable_radio_electro',
    ];
    if (hiddenLayers.includes(layerName)) {
      this.hideFeatures(
        feature => feature.attributes.level >= 2,
        16,
      );
    }
  },
  render() {
    const features = _.map(this.model.getFiltered(), (model) => {
      if (!model.feature) {
        model.createFeature();
      }
      return model.feature;
    });

    this.layer.addFeatures(features);
  },
  destroy() {
    this.collection = null;
    this.layer.destroyFeatures();

    this.model.off();
    this.options.map.map.events.unregister('zoomend', this, this.onZoom);

    this.layer.destroy();
    this.layer = null;
  },
  refreshFeatures() {
    this.prevState = null;

    if (this.layer) {
      this.layer.removeAllFeatures();
    }

    this.render();
  },
  /**
   * Колбек вызываемый когда обновляются фичи или их id сет.
   *
   * Фичи будут обновлены в слое тогда когда придут и их id и сами фичи.
   */
  updateFeatures() {
    const collection = this.model.getCollection();
    const currentState = new Map(
      [...this.model.idSet]
        .filter(id => !!collection.get(id))
        .map(id => [id, collection.get(id)]),
    );

    if (this.prevState) {
      const removed_models = [...this.prevState.keys()]
        .filter(id => !currentState.has(id))
        .map(id => this.prevState.get(id));
      const added_models = [...currentState.keys()]
        .filter(id => !this.prevState.has(id))
        .map(id => currentState.get(id));

      this.layer.removeFeatures(
        removed_models.map(
          model => model.feature,
        ),
      );
      removed_models.forEach(model => model.destroyFeature());

      this.layer.addFeatures(
        added_models.map((model) => {
          if (!model.feature) {
            model.createFeature();
          }
          return model.feature;
        }),
      );
    } else {
      this.refreshFeatures();
    }
    this.prevState = currentState;
  },
});

export default LayerView;
