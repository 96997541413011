import _ from 'underscore';
import OpenLayers from 'lib/OpenLayers-2.12/OpenLayers.debug';
import Backbone from 'js/backbone';
import api from 'js/api';
import Meta from 'js/model/Meta';


/* Парсер SLD фильтров приводит значения в теге Literal
  * через OpenLayers.String.numericIf и нет никакой возможности
  * это отключить. Это приводит к тому, что если у нас есть ГОСТ 1.10
  * он станет числом 1.1, что очевидно не то чего бы мы хотели.
  */
const format = new (OpenLayers.Class(OpenLayers.Format.SLD, {
  getParser() {
    const parser = new OpenLayers.Format.SLD.v1_0_0(); // eslint-disable-line new-cap
    parser.readers.ogc.Literal = (node, obj) => {
      obj.value = this.getChildValue(node); // eslint-disable-line no-param-reassign
    };
    return parser;
  },

  CLASS_NAME: 'OpenLayers.Custom.SLD',
}))();

/** "Meta.json" */
const MetaCollection = Backbone.Collection.extend({
  model: Meta,
  initialize: function () {
    this.cid = _.uniqueId();
  },

  /** Получение данных о слоях.
   *
   * Сперва получаем sld.xml. Парсим его, после чего пытаемся получить данные о слоях через api.
   * @returns {Promise}       промис, который будет зарезолвлен с версией meta.json
   */
  getMetaWithSLD() {
    const that = this;
    return new Promise(((resolve, reject) => {
      api.getSLD()
        .then((response) => {
          that.sld = format.read(response);
          api.getMeta()
            .then((response) => {
              that.schema = response.schema;
              that.onGetMetaWithSLDSuccess(response.meta);
              resolve(response.revision);
            });
        })
        .catch(() => {
          that.onGetMetaWithSLDError();
          reject();
        });
    }));
  },

  /** Функция, вызываемая при успешном получении meta.json.
   *
   * Проходим по всем слоям из meta.json и создаем модель Meta для каждого слоя.
   * Затем вызываем на коллекции reset, чтобы заменить данные на те, которые только что получили.
   *
   * @param {Object} meta - meta.json - словарь слоев
   */
  onGetMetaWithSLDSuccess(meta) {
    if (meta) {
      const data = _.map(meta, (hash, key) => {
        hash.id = key; // eslint-disable-line no-param-reassign
        return new Meta(hash);
      }, this);
      this.reset(data);
    } else {
      this.each((model) => {
        model.destroy();
      });
    }
    this.trigger('onGetMetaWithSLDSuccess');
  },

  /** Функция, вызываемая при зафейленной попытке получить meta.json. */
  onGetMetaWithSLDError() {
    this.trigger('onGetMetaWithSLDError');
  },

  // eslint-disable-next-line valid-jsdoc
  /** Поиск слоя по имени класса. */
  findByName(name) {
    return this.find(item => item.get('class') === name);
  },
});
export default MetaCollection;
