import * as actionTypes from 'js/constants/actionTypes';

const initialState = {
  isAuthenticated: false,
  username: undefined,
  token: undefined,
  readOnly: undefined,
  layers: undefined,
  filters: undefined,
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.USER_LOGIN:
      return {
        ...state,
        isAuthenticated: true,
        username: action.username,
        token: action.token,
        readOnly: action.readOnly,
        layers: action.layers,
        filters: action.filters,
      };
    case actionTypes.USER_LOGOUT:
      return {
        ...state,
        ...initialState,
      };
    default:
      return state;
  }
};

export default userReducer;
