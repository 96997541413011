import $ from 'jquery';
import _ from 'underscore';
import Backbone from 'js/backbone';
import AIS from 'js/AIS';


const ClassTrigger = Backbone.View.extend({
  events: {
    'click a': 'create',
  },
  template: _.template('<li><a href="#"><%= objectName %></a></li>'),
  create() {
    AIS.trigger('editor:blank', this.model);
  },
  render() {
    this.$el.html(this.template(this.model.toJSON()));
    return this;
  },
});

const FastCreationPanel = Backbone.View.extend({
  initialize: function () {
    this.collection.on('add', this.render, this);
    this.collection.on('remove', this.render, this);
    this.collection.on('reset', this.render, this);
    this.collection.on('change:visible', this.render, this);
  },
  render() {
    this.$el.html(
      '<div class="fast-panel"><p>Создать новый...</p><ul></ul></div>',
    );
    const ul = this.$el.find('ul');
    const groups = {};

    const visibleLayers = this.collection.filter(item => item.get('visible'));

    if (visibleLayers.length === 0) {
      ul.append($('<li><p>Нет видимых слоёв!</p></li>'));
      return this;
    }

    visibleLayers.forEach((item) => {
      const meta = item.getMeta();
      const group = meta.getGroup();
      const groupCreated = Boolean(groups[group]);
      let groupView = null;
      if (group) {
        groupView = (
          (groupCreated)
            ? groups[group]
            : (groups[group] = $(`<li><b>${group}</b><ul></ul></li>`))
        );
      }
      const view = new ClassTrigger({ model: item.getMeta() });
      if (groupView) {
        if (!groupCreated) {
          ul.append(groupView);
        }
        groupView.find('ul').append(view.render().el);
      } else {
        ul.append(view.render().el);
      }
    });
    return this;
  },
});

export default FastCreationPanel;
