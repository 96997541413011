import _ from 'underscore';
import Backbone from 'js/backbone';
import AIS from 'js/AIS';
import User from 'js/model/User';
import ClassFilter from 'js/model/ClassFilter';


const Workset = Backbone.Collection.extend({
  model: ClassFilter,
  /**
   * Сбросить все фильтры по полям всех слоёв.
   */
  resetFilters() {
    this.each(classFilter => classFilter.resetFilters());
  },
  isVisible(model) {
    const { collection } = model;
    if (!collection) return false;
    const { filter } = collection;
    return filter && filter.get('visible');
  },

  /**
   * Загружаем слои.
   * Список слоёв берётся из фильтра по слоям (там может быть и пустой массив слоёв).
   * Если для пользователя доступны только некоторые слои, фильтруем список слоев, отбирая те, что доступны.
   */
  loadInitialFilters() {
    const laysersList = User.get('username') === 'guest' ? User.get('layers') : [...AIS.layersFilter.layers];
    let toLoad = laysersList
      .map(className => AIS.getMetaByClassName(className))
      .filter(layer => layer !== undefined);

    if (User.get('layers') !== null) {
      toLoad = toLoad.filter(model => User.get('layers').indexOf(model.getClassName()) !== -1);
    }
    toLoad.forEach(meta => this.appendMeta(meta));
  },
  compileData() {
    const parts = _.map(this.where({ visible: true }), filter => filter.serializeForGrid(), this);

    return _.flatten(parts);
  },
  appendMeta(meta) {
    const classFilter = new ClassFilter({
      id: meta.id,
      meta,
    });
    this.add(classFilter);
  },
});

export default Workset;
