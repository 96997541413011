import _ from 'underscore';
import OpenLayers from 'lib/OpenLayers-2.12/OpenLayers.debug';
import Backbone from 'js/backbone';
import GeoObject from 'js/model/GeoObjects';
import Property from 'js/model/Property';
import roadSignData from 'js/data/RoadSignData';
import roadSignDataKz from 'js/data/KzRoadSignData';
import roadMarkSymbolData from 'js/data/RoadMarkSymbolData';
import roadMarkLineData from 'js/data/RoadMarkData';
import roadMarkPolygonData from 'js/data/RoadMarkPolygonData';
import semaforoData from 'js/data/SemaforoData';
import { relationUpdateModels } from 'js/consts';


const FieldCollection = Backbone.Collection.extend({
  model: Property.Generic,
});

const Meta = Backbone.Model.extend({
  layer: null,
  shapeMap: {
    point: GeoObject.Point,
    line: GeoObject.Line,
    polygon: GeoObject.Polygon,
  },
  initialize: function (options) {
    const shapeCode = this.getGeometryType();
    this._Shape = this.shapeMap[shapeCode] || GeoObject.Generic;
    this._Class = this._Shape.extend({
      _meta: this,
    });

    this._GeoObjectIconStyles = {};

    this._geometryProperty = new Property.Geometry(this.get('geometry'));
    this._propertyIndex = new FieldCollection();

    _.each(options.fields, function (hash) {
      let PropertyType;
      let f;
      const className = this.getClassName();
      /* eslint-disable no-param-reassign */
      if (hash.type !== 'special') {
        PropertyType = Property.find(hash.type);
        f = new PropertyType(hash);
      } else if ([
        'sign',
        'sign_project',
        'sign_run',
      ].includes(className)) {
        hash.flat = false;
        hash.data = roadSignData;
        f = new Property.RoadSign(hash);
      } else if ([
        'road_marking_symbol',
        'road_marking_symbol_project',
        'road_marking_symbol_run',
      ].includes(className)) {
        hash.flat = false;
        hash.data = roadMarkSymbolData;
        f = new Property.RoadMarkSymbol(hash);
      } else {
        if (className === 'roadsign-kz') {
          hash.flat = false;
          hash.data = roadSignDataKz;
        }
        if ([
          'road_marking_line',
          'road_marking_line_project',
          'road_marking_line_run',
        ].includes(className)) {
          hash.flat = true;
          hash.data = roadMarkLineData;
        }
        if ([
          'road_marking_polygon',
          'road_marking_polygon_project',
          'road_marking_polygon_run',
        ].includes(className)) {
          hash.flat = true;
          hash.data = roadMarkPolygonData;
        }
        if ([
          'semaforo',
          'semaforo_project',
          'semaforo_run',
        ].includes(className)) {
          hash.flat = false;
          hash.data = semaforoData;
        }
        f = new Property.Special(hash);
      }
      /* eslint-enable no-param-reassign */

      this._propertyIndex.add(f);
    }, this);

    const availableManualRelations = Object.entries(AIS.relations)
      .filter(([, relation]) => (
        relation.update_model === relationUpdateModels.MANUAL
        && relation.child === this.get('class')
      ))
      .map(([relationName]) => relationName);
    if (availableManualRelations.length !== 0) {
      this._propertyIndex.add(new Property.OneToManyManualRelations());
    }
  },
  getProperties() {
    return this._propertyIndex;
  },
  getGeometryProperty() {
    return this._geometryProperty;
  },
  getClass() {
    return this._Class;
  },
  getGeometryType() {
    const g = this.get('geometry');
    return g.geometry;
  },
  getClassName() {
    return this.get('class');
  },
  getGroup() {
    return this.get('group');
  },

  sldStyleExists(className) {
    return Boolean(
      this.collection.sld
                  && this.collection.sld.namedLayers
                  && this.collection.sld.namedLayers[className]
                  && this.collection.sld.namedLayers[className].userStyles
                  && this.collection.sld.namedLayers[className].userStyles.length > 0,
    );
  },

  getStyleMap() {
    let defaultStyle = _.extend({}, this._Shape.prototype.defaultStyle);
    let selectStyle = _.extend({}, this._Shape.prototype.selectStyle);
    const modifyStyle = _.extend({}, this._Shape.prototype.modifyStyle);
    let style;

    if (this.has('style')) {
      const metaStyle = this.get('style');
      const { fillColor, strokeColor } = metaStyle;
      defaultStyle = _.extend(defaultStyle, metaStyle,
        fillColor ? { fillColor: `#${fillColor}` } : null,
        strokeColor ? { strokeColor: `#${strokeColor}` } : null);
    }

    if (this.getGeometryType() === 'point') {
      style = new OpenLayers.Style(_.extend({}, defaultStyle, {
        pointRadius: '${radius}', // eslint-disable-line no-template-curly-in-string
        fillOpacity: '${opacity}', // eslint-disable-line no-template-curly-in-string
      }), {
        context: {
          radius(feature) {
            if (feature.cl || feature.CLASS_NAME === 'RTreeClusterFeature') {
              return Math.min(feature.attributes.count, 12) + 5;
            }
            return 10;
          },
          opacity(feature) {
            return feature.cluster ? 0.8 : 1.0;
          },
        },
      });
      selectStyle = _.extend({
        fillColor: '#8aeeef',
        strokeColor: '#32a8a9',
      }, selectStyle);
    } else {
      const endsWith = (str, suffix) => str.indexOf(suffix, str.length - suffix.length) !== -1;
      const _className = this.getClassName();
      let className = _className;

      /* Если у проектного слоя нет стилей в SLD используем стили для не проектного слоя.
                    */
      if (endsWith(_className, '_project') && !this.sldStyleExists(_className)) {
        className = _className.substring(0, _className.length - '_project'.length);
      }

      if (this.sldStyleExists(className)) {
        // eslint-disable-next-line prefer-destructuring
        style = this.collection.sld.namedLayers[className].userStyles[0];
        style.setDefaultStyle(defaultStyle);
        style.defaultsPerSymbolizer = false;
        /*
          By default defaultsPerSymbolizer is true for styles created from sld.
          If defaultsPerSymbolizer is true, only subset of `defaultStyle` keys are used.
          Property `graphicZIndex` is needed by AIS but it's not in subset used when defaultsPerSymbolizer is true.
        */
      } else {
        style = new OpenLayers.Style(defaultStyle);
      }

      selectStyle = _.extend({
        strokeWidth: 2,
        fillOpacity: 0.4,
        fillColor: 'blue',
        strokeColor: 'blue',
      }, selectStyle);
    }
    return new OpenLayers.StyleMap({
      default: style,
      select: selectStyle,
      modify: modifyStyle,
    });
  },
  _iconContext(geoObject) {
    return _.map(geoObject.keys(), key => function () {
      return geoObject.get(key);
    });
  },
  _getIcon(geoObject) {
    let property;
    if (this.getGeometryType() !== 'point') return null;

    let icon;
    const className = this.getClassName();
    if (className === 'sign' || className === 'sign_project') {
      property = this._propertyIndex.get('gost');
      icon = property.getIcon(
        geoObject.get('gost'),
        geoObject.get('gost_subtype'),
        false,
        className === 'sign_project',
        geoObject.get('to_remove'),
      );
      if (className === 'sign' && !geoObject.get('to_remove')) {
        icon = icon.replace('roadsigns-small', 'roadsigns-etalon');
      }
    } else if (className === 'sign_run') {
      const dir_name = this._dirnameAI(geoObject);
      if (dir_name !== 'undefined') {
        property = this._propertyIndex.get('gost');
        icon = property.getIcon(
          geoObject.get('gost'),
          geoObject.get('gost_subtype'),
          false,
          dir_name === 'project',
          dir_name === 'remove',
        );
      }
    } else if (className === 'roadsign-kz') {
      property = this._propertyIndex.get('gost');
      icon = property.getIcon(geoObject.get('gost'));
    } else if (className === 'road_marking_symbol' || className === 'road_marking_symbol_project') {
      property = this._propertyIndex.get('type');
      icon = property.getIcon(
        geoObject.get('type'),
        geoObject.get('type_subtype'),
        false,
        className === 'road_marking_symbol_project',
        geoObject.get('to_remove'),
      );
    } else if (className === 'road_marking_symbol_run') {
      const dir_name = this._dirnameAI(geoObject);
      if (dir_name !== 'undefined') {
        property = this._propertyIndex.get('type');
        icon = property.getIcon(
          geoObject.get('type'),
          geoObject.get('type_subtype'),
          false,
          dir_name === 'project',
          dir_name === 'remove',
        );
      }
    } else if (className === 'adv_stand_point') {
      icon = `/icon/adv_stand_point/adv_stand_point_${geoObject.get('type').toString()}.png`;
    } else if (className === 'transport_detector') {
      const type = geoObject.get('type');
      const map = {
        1: '/icon/other/video-detector.png',
        2: '/icon/other/IR-detector.png',
      };
      icon = map[type];
    } else if (className === 'pole') {
      const type = geoObject.get('type');
      const map = {
        1: '/icon/other/L-pole.png',
        2: '/icon/other/U-pole.png',
        3: '/icon/other/T-pole.png',
      };
      icon = map[type];
    } else if (className === 'semaforo' || className === 'semaforo_project') {
      let dir_name;
      if (className === 'semaforo') {
        if (geoObject.get('to_remove') === true) {
          dir_name = 'remove';
        } else {
          dir_name = 'original';
        }
      } else {
        dir_name = 'project';
      }
      icon = `/icon/semaforo-small/${dir_name}/${
        (geoObject.get('shape')) ? geoObject.get('shape') : 'unknown'
      }.png`;
    } else if (className === 'semaforo_run') {
      const dir_name = this._dirnameAI(geoObject);
      if (dir_name === 'undefined') {
        icon = '/icon/semaforo-small/project/unknown.png';
      } else {
        icon = `/icon/semaforo-small/${dir_name}/${
          (geoObject.get('shape')) ? geoObject.get('shape') : 'unknown'
        }.png`;
      }
    } else if (className === 'stoppav_run') {
      icon = `/icon/ai_layers/${this._dirnameAI(geoObject)}/bus_stop.png`;
    } else if (className === 'hatchway_run') {
      icon = `/icon/ai_layers/${this._dirnameAI(geoObject)}/hatchway_${geoObject.get('type')}.png`;
    } else if (className === 'info_display_run') {
      icon = `/icon/ai_layers/${this._dirnameAI(geoObject)}/tpi.png`;
    } else if (className === 'pillar_run') {
      icon = `/icon/ai_layers/${this._dirnameAI(geoObject)}/pillar.png`;
    } else if (className === 'street_lamp_run') {
      icon = `/icon/ai_layers/${this._dirnameAI(geoObject)}/street_lamp.png`;
    } else if (className === 'infotable_run') {
      icon = `/icon/ai_layers/${this._dirnameAI(geoObject)}/info.png`;
    } else if (className === 'hatchway') {
      icon = `/icon/hatchway/${geoObject.get('type')}.png`;
    } else if (className === 'camera_run') {
      icon = `/icon/ai_layers/${this._dirnameAI(geoObject)}/camera.png`;
    } else if (className === 'road_controller_run') {
      icon = `/icon/ai_layers/${this._dirnameAI(geoObject)}/controller.png`;
    } else if (className === 'parking_info_run') {
      icon = `/icon/ai_layers/${this._dirnameAI(geoObject)}/parking_info.png`;
    } else if (className === 'gate_run') {
      icon = `/icon/ai_layers/${this._dirnameAI(geoObject)}/gate.png`;
    } else if (className === 'street_furniture_run') {
      icon = `/icon/ai_layers/${this._dirnameAI(geoObject)}/maf_${geoObject.get('type')}.png`;
    } else if (className === 'street_lamp') {
      icon = '/icon/ai_layers/street_lamp.png';
    } else if (className === 'street_lamp_project') {
      icon = '/icon/other/street_lamp_project.png';
    } else if (className === 'prescription') {
      icon = '/icon/other/prescription.png';
    } else if (className === 'cable_case') {
      icon = '/icon/other/cable_case.png';
    } else if (className === 'advertshield') {
      icon = '/icon/other/advertshield.png';
    } else if (className === 'smallarchitectureshape') {
      icon = `/icon/smallarchitectureshape/${geoObject.get('type')}.png`;
    } else if (className === 'advertshield_electro') {
      icon = '/icon/electro/advertshield_18_0_18.png';
    } else if (className === 'cable_case_electro') {
      icon = '/icon/electro/cable_case_9_0_18.png';
    } else if (className === 'camera_electro') {
      icon = '/icon/electro/camera_0_0_24.png';
    } else if (className === 'contact_pole_electro') {
      icon = '/icon/electro/contact_pole_8_8_16.png';
    } else if (className === 'coupler_optic_electro') {
      icon = '/icon/electro/coupler_optic_9_18_22.png';
    } else if (className === 'decoration_electro') {
      icon = '/icon/electro/decoration_18_18_18.png';
    } else if (className === 'miscellaneous_electro') {
      icon = '/icon/electro/miscellaneous_18_18_18.png';
    } else if (className === 'pole_electro') {
      icon = '/icon/electro/pole_8_8_16.png';
    } else if (className === 'roadsign_electro') {
      icon = '/icon/electro/roadsign_nomagic_0_9_18.png';
    } else if (className === 'semaforo_electro') {
      icon = '/icon/electro/semaforo_nomagic_18_9_18.png';
    } else if (className === 'transport_detector_electro') {
      icon = '/icon/electro/transport_detector_0_18_18.png';
    } else if (className === 'electric_pole_electro') {
      icon = `/icon/electro/electric_pole_${geoObject.get('has_light') ? 'light' : 'nolight'}_8_8_16.png`;
    } else if (className === 'distribution_electro') {
      icon = '/icon/electro/distribution_12_12_24.png';
    } else if (className === 'signallednode') {
      const configuration = geoObject.get('configuration');
      if ([1, 2, 4].includes(configuration)) {
        icon = '/icon/other/SO-T-blue.png';
      } else if (configuration === 3) {
        icon = '/icon/other/SO-P-blue.png';
      }
    }

    if (icon) return icon;

    if (this.get('icon')) {
      return this.get('icon');
    }

    if (!icon) {
      const format = this.get('iconTemplate');
      if (format) {
        icon = OpenLayers.String.tokenRegEx.test(format) ? OpenLayers.String.format(format, geoObject.attributes) : format;
      }
    }
    return icon || '/icon/other/unknown.png';
  },
  getIcon() {
    // eslint-disable-next-line prefer-spread
    const icon = this._getIcon.apply(this, arguments);
    if (icon) {
      return encodeURI(icon);
    }
    return icon;
  },
  getIconStyle(geoObject) {
    const icon = this.getIcon(geoObject);
    if (!this._GeoObjectIconStyles.hasOwnProperty(icon)) {
      this._GeoObjectIconStyles[icon] = _.extend({}, this._Shape.prototype.defaultStyle, {
        graphic: true,
        graphicHeight: 37,
        graphicYOffset: -35,
        externalGraphic: icon,
      });
      if (this.getClassName() === 'adv_stand_point') {
        this._GeoObjectIconStyles[icon] = _.extend({}, this._GeoObjectIconStyles[icon], {
          graphicHeight: 15,
          graphicWidth: 15,
          graphicXOffset: undefined,
          graphicYOffset: undefined,
        });
      }
      if (icon.includes('/electro/')) {
        const [offsetX, offsetY, size] = icon.match(/_(\d+)/gi).map(i => parseInt(i.substring(1), 10));
        this._GeoObjectIconStyles[icon] = {
          ...this._Shape.prototype.defaultStyle,
          ...{
            graphic: true,
            graphicHeight: size,
            graphicWidth: size,
            externalGraphic: icon,
            graphicXOffset: -offsetX,
            graphicYOffset: -size + offsetY,
          },
        };
      }
    }
    // Если есть угол поворота (azimuth), то повернем иконку на этот угол
    const azimuth = geoObject.get('azimuth');
    if (azimuth != null) {
      return _.extend({}, this._GeoObjectIconStyles[icon], {
        rotation: 180 + azimuth,
      });
    }
    return this._GeoObjectIconStyles[icon];
  },
  drawArrows() {
    return this.getClassName() === 'road_axis';
  },
  _dirnameAI(geoObject) {
    // eslint-disable-next-line
    const statuses = { '1': 'project', '2': 'original', '3': 'remove' };
    return (
      geoObject.get('status_ai') in statuses
        ? statuses[geoObject.get('status_ai')]
        : null
    );
  },
});

export default Meta;
