import Backbone from 'js/backbone';
import Filter from 'js/view/filter/Filter';
import * as serverFilter from 'js/filter';

export default Backbone.View.extend({
  tagName: 'tr',
  className: 'extra',

  initialize: function () {
    this.model.on('change', this.onChange, this);
  },

  // eslint-disable-next-line valid-jsdoc
  /** Коллбек на выбор поля для фильтрации у слоя. */
  onChange(model) {
    const active = !model.isEmpty();
    if (active) {
      this.$el.find('label.filter_name').addClass('changed');
    } else {
      this.$el.find('label.filter_name').removeClass('changed');
    }
    this.showFilterMark(active);
  },

  /**
   * Отображение иконки фильтра, если фильтр есть и она еще не отображена.
   * @param {boolean} active   активен ли фильтр
   */
  showFilterMark(active) {
    if (this._markActive === active) return;
    if (active) {
      this.$el.find('label.filter_name ').append('<i class="icon-filter"></i>');
    } else {
      this.$el.find('label.filter_name i').remove();
    }
    this._markActive = active;
  },
  isEmpty() {
    return this.model.isEmpty();
  },
  render() {
    let filterView;

    if (this.model.get('type') === 'geometry') {
      return this;
    }
    if (this.model.get('type') === 'attachments') {
      return this;
    }

    if (this.model.get('type') === 'select') {
      filterView = new Filter.Select({
        model: this.model,
        column: this.model,
        condition: serverFilter.conditions.$eq_any,
      });
    }
    if (this.model.get('type') === 'boolean') {
      filterView = new Filter.Boolean({
        model: this.model,
        column: this.model,
        condition: serverFilter.conditions.$eq,
      });
    }
    if (this.model.get('type') === 'integer') {
      filterView = new Filter.IntegerRange({
        model: this.model,
        column: this.model,
        condition: serverFilter.conditions.$between_float,
      });
    }
    if (this.model.get('type') === 'float') {
      filterView = new Filter.FloatRange({
        model: this.model,
        column: this.model,
        condition: serverFilter.conditions.$between_float,
      });
    }
    if (this.model.get('type') === 'calculation') {
      filterView = new Filter.FloatRange({
        model: this.model,
        column: this.model,
        condition: serverFilter.conditions.$between_float,
      });
    }

    if (this.model.get('type') === 'measure') {
      filterView = new Filter.FloatRange({
        model: this.model,
        column: this.model,
        condition: serverFilter.conditions.$between_float,
      });
    }

    if (this.model.get('type') === 'date') {
      filterView = new Filter.Date({
        model: this.model,
        column: this.model,
      });
    }

    if (this.model.get('type') === 'special' && this.model.get('id') === 'shape') {
      filterView = new Filter.Special({
        model: this.model,
        column: this.model,
        condition: serverFilter.conditions.$eq_any,
      });
    }

    if (!filterView) {
      filterView = new Filter.Text({
        model: this.model,
        column: this.model,
        condition: serverFilter.conditions.$is_substring,
      });
    }
    const html = `<td><label class="filter_name">${this.model.get('name')}</label></td>`;
    this.$el.html(html);
    this.$el.find('td').append(filterView.render().el);
    return this;
  },
});
