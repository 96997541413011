import $ from 'jquery';
import _ from 'underscore';
import Backbone from 'js/backbone';
import { conditions } from 'js/filter';


const Filter = {};

const AbstractFilter = Backbone.View.extend({
  initialize: function () {
    this.model.on('change:value', function () {
      this.render();
    }, this);
  },
  render() {
    return this;
  },

  // eslint-disable-next-line valid-jsdoc
  /** Получение значения поля. */
  getValue() {
    return this.$el.find('input').val();
  },

  // eslint-disable-next-line valid-jsdoc
  /** Получение названия поля. */
  getFieldName() {
    return this.model.get('field');
  },

  // eslint-disable-next-line valid-jsdoc
  /**
   * Получение условия для фильтра по полю.
   * Используем метод условия, переданный при создании инстанса класса.
   * @param {*}  value   значение поля.
   */
  getCondition(value) {
    if (!this.options.condition || value == null || (value instanceof Array && 0 in value && value[0].length === 0)) {
      return null;
    }
    return this.options.condition(`fields->>'${this.getFieldName()}'`, value);
  },
  update(event) {
    const value = this.getValue();
    const condition = this.getCondition(value);
    this.model.set('value', value);
    this.model.set('condition', condition);

    if (event) {
      event.stopPropagation();
    }
  },
  lock() {
    this.$el.find('input').prop('disabled', true);
  },
  unlock() {
    this.$el.find('input').prop('disabled', false);
  },
});

Filter.Select = AbstractFilter.extend({
  tagName: 'table',
  events: {
    'change input': 'update',
    'click  .all': 'toggle',
  },
  rowTemplate: _.template($('#tmpl-select-filter-row').html()),
  toggle() {
    const inputs = this.$el.find('input');
    const total = inputs.length;
    const value = this.getValue();
    const checked = (value) ? value.length : 0;
    const newvalue = (checked < (total / 2.0));
    inputs.each(function () {
      $(this).prop('checked', (newvalue) ? 'checked' : '');
    });
    this.update();
  },

  /**
   * Получение условия для фильтра по полю.
   *
   * Обрабатываем отдельно вариант "не указано".
   *
   * @param {*}  value   значения, выбранные в селекторе.
   * @returns {Object} условие
   */
  getCondition(value) {
    // условие без учета варианта "не указано", получаем его из переопределяемого метода
    const superCondition = AbstractFilter.prototype.getCondition.call(
      this,
      value ? [value.filter(item => item !== 'null')] : null,
    );
    if (value instanceof Array && value.includes('null')) {
      const nullCondition = conditions.$is_null(`fields->>'${this.getFieldName()}'`);
      if (superCondition != null) {
        return conditions.$or(superCondition, nullCondition);
      }
      return nullCondition;
    }
    return superCondition;
  },

  // eslint-disable-next-line valid-jsdoc
  /** Получение выбранных значений в селекторе. */
  getValue() {
    const list = Array.from(this.$el.find('input'))
      .filter(el => $(el).prop('checked'))
      .map(el => $(el).prop('value'));
    return list.length !== 0 ? list : null;
  },
  render() {
    const select = _.union([{ text: 'не указано', id: 'null' }],
      this.options.column.get('select'));
    const filter = this.model.get('value');
    const options = _.map(select, function (hash) {
      const value = hash.text;
      const key = String(hash.id);

      return this.rowTemplate({
        on: _.indexOf(filter, key) > -1,
        key,
        value,
        elemid: _.uniqueId('filter_select_'),
      });
    }, this);

    const html = `
                <tr>
                    <td>
                        <button class="all btn btn-mini">все</button>
                    </td>
                    <td>&nbsp;</td>
                </tr>
                ${options.join('')}
            `;

    this.$el.empty().html(html);
    return this;
  },
});

Filter.Boolean = AbstractFilter.extend({
  events: {
    change: 'update',
  },
  input2value: {
    '-1': null,
    0: false,
    1: true,
  },
  value2input: {
    null: '-1',
    false: '0',
    true: '1',
  },
  getValue() {
    return this.input2value[this.$el.find('select').val()];
  },

  // eslint-disable-next-line valid-jsdoc
  /**
    * Получение условия для фильтра по полю.
    * @param {Boolean}  value   значение поля.
    */
  getCondition(value) {
    if (!this.options.condition || value == null) {
      return null;
    }

    return this.options.condition(`(features.fields->>'${this.getFieldName()}')::BOOLEAN`, value);
  },
  render() {
    const input = `
                <select class="input input-medium">
                    <option value="-1" selected>Неважно</option>
                    <option value="0">Нет</option>
                    <option value="1">Да</option>
                </select>
            `;

    this.$el.html(input);
    this.$el.find('.input').val(this.value2input[this.model.get('value')]);
    return this;
  },
});

Filter.Text = AbstractFilter.extend({
  getValue() {
    const value = this.$el.find('input').val();
    return (value.length > 0) ? value : null;
  },
  events: {
    'input input': 'lazyUpdate',
  },
  initialize: function () {
    this.lazyUpdate = _.debounce(this.update, 1000);
    AbstractFilter.prototype.initialize.apply(this, arguments);
  },
  render() {
    const input = '<input class="filter input input-medium" type="text">';
    this.$el.html(input);
    this.$el.find('.input').val(this.model.get('value'));
    return this;
  },
});

Filter.IntegerRange = AbstractFilter.extend({
  _step: 1,
  events: {
    'input .range-min': 'update',
    'input .range-max': 'update',
  },
  getValue() {
    const minvalue = parseInt(Math.round(parseFloat(this.$el.find('.range-min').val())), 10);
    const maxvalue = parseInt(Math.round(parseFloat(this.$el.find('.range-max').val())), 10);
    if (Number.isNaN(minvalue) && Number.isNaN(maxvalue)) {
      return null;
    }
    return { min: minvalue, max: maxvalue };
  },
  render() {
    const step = this._step || 1;
    const input = `
      От: <input class="filter input input-medium range-field range-min" type="number" step="${step.toString()}">
      До: <input class="filter input input-medium range-field range-max" type="number" step="${step.toString()}">
    `;
    this.$el.html(input);
    if (this.model.get('value')) {
      this.$el.find('.range-min').val(this.model.get('value').min);
      this.$el.find('.range-max').val(this.model.get('value').max);
    }
    return this;
  },
});

Filter.FloatRange = Filter.IntegerRange.extend({
  _step: 'any',
  getValue() {
    const minvalue = parseFloat(this.$el.find('.range-min').val());
    const maxvalue = parseFloat(this.$el.find('.range-max').val());
    if (Number.isNaN(minvalue) && Number.isNaN(maxvalue)) {
      return null;
    }
    return { min: minvalue, max: maxvalue };
  },
});

Filter.Date = AbstractFilter.extend({
  events: {
    'changeDate .date': 'onChangeDate',
    'click .field-mindate-clear': 'onClearMin',
    'click .field-maxdate-clear': 'onClearMax',
  },
  onChangeDate(event) {
    this.update(event);
    this.$el.find('.mindate').datepicker('hide');
    this.$el.find('.maxdate').datepicker('hide');
  },
  onClear(selector, event) {
    const el = this.$el.find(selector);
    el.find('input').val('');
    this.update(event);
    el.datepicker('hide');
  },
  onClearMin(event) { this.onClear('.mindate', event); },
  onClearMax(event) { this.onClear('.maxdate', event); },
  _getValue(selector) {
    let val = null;
    const raw = this.$el.find(selector).val();
    if (raw) {
      const parts = raw.split('.');
      const dt = new Date(Date.UTC(
        parseInt(parts[2], 10),
        parseInt(parts[1], 10) - 1,
        parseInt(parts[0], 10),
      ));
      val = dt.getTime() / 1000;
    }
    return val;
  },
  getValue() {
    const minvalue = this._getValue('.mindate input');
    const maxvalue = this._getValue('.maxdate input');
    if (!minvalue && !maxvalue) {
      return null;
    }
    return [minvalue, maxvalue];
  },
  render() {
    const input = `
      От:&nbsp;<div class="input-append date mindate">
          <input class="filter input input-small" type="text">
          <span class="add-on"><i class="icon-calendar"></i></span>
          <span class="add-on field-mindate-clear"><i class="icon-remove"></i></span>
      </div></br>
      До:&nbsp;<div class="input-append date maxdate">
          <input class="filter input input-small" type="text">
          <span class="add-on"><i class="icon-calendar"></i></span>
          <span class="add-on field-maxdate-clear"><i class="icon-remove"></i></span>
      </div>
    `;
    const datetimeoptions = {
      language: 'ru',
      format: 'd.mm.yyyy',
      weekStart: 1,
      todayBtn: 'linked',
    };
    this.$el.html(input);
    this.$el.find('.mindate').datepicker(datetimeoptions);
    this.$el.find('.maxdate').datepicker(datetimeoptions);
    if (this.model.get('value')) {
      this.$el.find('.mindate').datepicker('setUTCDate', this.model.get('value').min);
      this.$el.find('.maxdate').datepicker('setUTCDate', this.model.get('value').max);
    }
    return this;
  },
});

Filter.Special = Filter.Select.extend({
  renderItems(el, items) {
    _.map(items, function (item, key) {
      const elem = $('<label><input class="input" type="checkbox"></input></label>');
      const value = this.model.get('value');
      elem.find('input')
        .val(key)
        .prop('checked', value && value.indexOf(key) !== -1)
        .css('margin-left', '10px');
      elem.append(item.name || key
                    + (item.gost_name ? ` - ${item.gost_name}` : '')).appendTo(el);
    }, this);
  },
  render() {
    const property = this.model.get('property');
    const data = property.getData();
    const flat = property.get('flat');
    const html = $('<div></div>')
      .css('margin-left', '10px')
      .append('<button class="all btn btn-mini">все</button>');

    if (flat) {
      this.renderItems(html, data);
    } else {
      _.each(data, function (group) {
        this.renderItems(
          $('<div></div>')
            .append($('<strong></strong>').text(group.name))
            .appendTo(html),
          group.items,
        );
      }, this);
    }
    this.$el.html(html);
    return this;
  },
});

export default Filter;
