import OpenLayers from 'lib/OpenLayers-2.12/OpenLayers.debug';
import RTreeVectorRootContainer from 'js/view/map/RTreeVectorRootContainer';


/**
 * Модифицированная версия OpenLayers.Control.SelectFeature
 */
const SelectFeature = OpenLayers.Class(OpenLayers.Control.SelectFeature, {
  initialize: function (layers, options) {
    OpenLayers.Control.prototype.initialize.apply(this, [options]);

    if (this.scope === null) {
      this.scope = this;
    }
    this.initLayer(layers);
    const callbacks = {
      click: this.clickFeature,
      clickout: this.clickoutFeature,
      over: this.overFeature,
      out: this.outFeature,
    };

    this.callbacks = OpenLayers.Util.extend(callbacks, this.callbacks);
    this.handlers = {
      feature: new OpenLayers.Custom.Feature(
        this, this.layer, this.callbacks,
        { geometryTypes: this.geometryTypes },
      ),
    };

    if (this.box) {
      this.handlers.box = new OpenLayers.Handler.Box(
        this, { done: this.selectBox },
        { boxDivClassName: 'olHandlerBoxSelectFeature' },
      );
    }
  },

  initLayer(layers) {
    if (OpenLayers.Util.isArray(layers)) {
      this.layers = layers;
      this.layer = new RTreeVectorRootContainer(
        `${this.id}_container`, {
          layers,
        },
      );
    } else {
      this.layer = layers;
    }
  },

  /*
    * hack, we need this "advanced" layer finder
    * couse features in cluster not added directly to layer
    */
  getLayer(feature) {
    if (feature.layer) {
      return feature.layer;
    }
    if (feature.cluster && feature.cluster.layer) {
      return feature.cluster.layer;
    }
    let layer;
    if (this.layers) {
      for (let i = 0; i < this.layers.length; i += 1) {
        layer = this.layers[i];
        if (OpenLayers.Util.indexOf(layer.selectedFeatures, feature) > -1) {
          return layer;
        }
        // TODO: slowest case, make it rtree search?
        if (OpenLayers.Util.indexOf(layer.features, feature) > -1) {
          return layer;
        }
      }
    }
    return null;
  },

  clickFeature(feature) {
    const layer = this.getLayer(feature);
    if (!this.hover) {
      const selected = layer && (OpenLayers.Util.indexOf(
        layer.selectedFeatures, feature,
      ) > -1);
      if (selected) {
        if (this.toggleSelect()) {
          this.unselect(feature);
        } else if (!this.multipleSelect()) {
          this.unselectAll({ except: feature });
        }
      } else {
        if (!this.multipleSelect()) {
          this.unselectAll({ except: feature });
        }
        this.select(feature);
      }
    }
  },

  select(feature) {
    const { evt } = this.handlers.feature;
    const event = { feature };
    let cont = this.onBeforeSelect.call(this.scope, feature, evt);
    const layer = this.getLayer(feature);
    cont = (cont !== false) && this.events.triggerEvent('beforefeatureselected', event);
    if (cont !== false) {
      cont = !layer || layer.events.triggerEvent('beforefeatureselected', event);
      if (cont !== false) {
        if (layer) {
          layer.selectedFeatures.push(feature);
        }
        this.highlight(feature);
        // if the feature handler isn't involved in the feature
        // selection (because the box handler is used or the
        // feature is selected programatically) we fake the
        // feature handler to allow unselecting on click
        if (!this.handlers.feature.lastFeature) {
          // eslint-disable-next-line prefer-destructuring
          this.handlers.feature.lastFeature = layer.selectedFeatures[0];
        }
        if (layer) {
          layer.events.triggerEvent('featureselected', event);
        }
        this.onSelect.call(this.scope, feature, evt);
      }
    }
  },

  unselect(feature) {
    if (!feature) {
      return;
    }
    const layer = this.getLayer(feature);
    // Store feature style for restoration later
    this.unhighlight(feature);
    if (layer) {
      OpenLayers.Util.removeItem(layer.selectedFeatures, feature);
      layer.events.triggerEvent('featureunselected', { feature });
    }
    this.onUnselect.call(this.scope, feature);
  },

  overFeature(feature) {
    this.events.triggerEvent('over', { feature, control: this });
    OpenLayers.Control.SelectFeature.prototype.overFeature.apply(this, arguments);
  },

  outFeature(feature) {
    this.events.triggerEvent('out', { feature, control: this });
    OpenLayers.Control.SelectFeature.prototype.outFeature.apply(this, arguments);
  },

  highlight(feature) {
    if (feature.layer) {
      OpenLayers.Control.SelectFeature.prototype.highlight.apply(this, arguments);
      return true;
    }
    return false;
  },

  unhighlight(feature) {
    if (feature.layer) {
      OpenLayers.Control.SelectFeature.prototype.unhighlight.apply(this, arguments);
      return true;
    }
    return false;
  },
});

export default SelectFeature;
