import $ from 'jquery';
import _ from 'underscore';
import Backbone from 'js/backbone';
import AIS from 'js/AIS';

export default Backbone.View.extend({
  className: 'panel',
  template: _.template($('#tmpl-address-filter').html()),
  events: {
    'click i': 'expand',
    'click .commit-button': 'commit',
    'click .clear-button': 'clear',
  },
  expand() {
    if (!this.expanded) {
      this.$el.find('.content').show();
    } else {
      this.$el.find('.content').hide();
    }

    $('.district-field').select2({
      minimumInputLength: 1,
      placeholder: 'Выберите район',
      allowClear: true,
      ajax: {
        url: '/ajax/autocomplete/district',
        data(term) {
          return {
            q: term,
          };
        },
        results(data) {
          return data;
        },
      },
    });
    $('.street-field').select2({
      minimumInputLength: 1,
      placeholder: 'Выберите улицу',
      allowClear: true,
      ajax: {
        url: '/ajax/autocomplete/street',
        data(term) {
          return {
            q: term,
          };
        },
        results(data) {
          return data;
        },
      },
    });

    if (this.expanded) {
      this.$el.find('.icon-chevron-down').removeClass('icon-chevron-down').addClass('icon-chevron-left');
    } else {
      this.$el.find('.icon-chevron-left').removeClass('icon-chevron-left').addClass('icon-chevron-down');
    }
    this.expanded = !this.expanded;
  },
  /**
   * Установка фильтра по району и/или по улице.
   * @param {string} [street]    id улицы
   * @param {string} [district]  id района
   */
  setAddressFilter: ({ street, district } = {}) => {
    AIS.addressFilter.setAddressFilter({ district, street });
  },
  /** Фильтрация по выбранным району и/или улице. */
  commit() {
    this.setAddressFilter({
      district: $('.district-field').select2('val'),
      street: $('.street-field').select2('val'),
    });
  },
  /** Очистка значений в селекторах и обнуление фильтра. */
  clear() {
    $('.district-field').select2('val', null);
    $('.street-field').select2('val', null);
    this.setAddressFilter();
  },
  render() {
    this.$el.empty().append(this.template());
    if (!this.expanded) {
      this.$el.find('.content').hide();
    }
    return this;
  },
});
