import _ from 'underscore';
import $ from 'jquery';
import OpenLayers from 'lib/OpenLayers-2.12/OpenLayers.debug';
import Backbone from 'js/backbone';
import AIS from 'js/AIS';
import api from 'js/api';
import User from 'js/model/User';
import PointCoordinates from 'js/view/PointCoordinates';


const PropertiesTable = Backbone.View.extend({
  events: {
    'click #edit': 'onEdit',
    'click #split': 'onSplit',
    'click #delete': 'onDelete',
    'click #pano': 'onPano',
    'click .property-show-coords': 'toggleCoords',
  },

  extraFields: null,

  trTemplate: _.template(
    `
    <tr>
        <td class="balloon-table-header">
            <%= caption %>
        </td>
        <td class="property-text">
            <%= content %>
            <%= typeof unit !== "undefined" && unit ? unit : "" %>
        </td>
    </tr>
    `,
  ),
  toggleCoords(event) {
    const el = $(event.target).parent().find('.property-coords');
    el.css('display', el.css('display') === 'none' ? 'block' : 'none');
    $(event.target).text(el.css('display') === 'none' ? 'Показать' : 'Скрыть');
    this.updateSize();
  },
  renderCoords(text, proj, local) {
    const { geometry } = (this.model.feature || {});
    const points = geometry ? geometry.getVertices() : [];
    const el = $('<div><div>');
    const code = $('<ul class="property-coords no-decorate"></ul>');
    if (points.length > 0) {
      _.each(points, (point) => {
        $(code).append($('<li></li>').html(
          (new PointCoordinates({
            point,
            proj,
            local,
          })).render().el,
        ).addClass('no-decorate'));
      });
      if (points.length > 3) {
        $(code).css('display', 'none');
        el.append('<a class="property-show-coords">Показать</a>');
      }
      el.append(code);
      return this.trTemplate({
        caption: text || 'Координаты',
        content: el.html(),
      });
    }
  },
  render() {
    const content = this.$el;
    content.append('<div class="properties-table"><table></table></div>');

    const table = this.$el.find('table').first();
    const meta = this.model.getMeta();

    table.append(this.trTemplate({
      caption: 'Объект',
      content: meta.get('objectName'),
    }));
    table.append(this._asTable());
    if (meta.getGeometryType() !== 'empty') {
      table.append(this.renderCoords('Координаты WGS84'));
    }
    table.find('a.fancybox').fancybox({
      prevEffect: 'none',
      nextEffect: 'none',
      padding: 0,
      buttons: [
        'zoom',
        'thumbs',
        'close',
      ],
    });
    const buttons = $('<div class="btn-toolbar" style="width: 100%; margin: 0;"></div>');
    if (!User.get('readonly')) {
      const splitButton = '<button id="split" class="btn" title="Разрезать"><i class="icon-resize-small"></i></button>';
      buttons.append(
        `
        <div class="btn-group">
          <button id="edit" class="btn" title="Редактировать">
            <i class="icon-pencil"></i>
          </button>
          ${!['point', 'empty'].includes(meta.getGeometryType()) && splitButton}
          <button
            id="delete" type="button"
            class="btn btn-danger"
            data-toggle="modal"
            data-target="#deleteFeatureModal"
            title="Удалить"
          >
            <i class="icon-white icon-trash"></i>
          </button>
        </div>
        `,
      );
    }
    if (!AIS.featureEnabled('disable_pano') && meta.getGeometryType() !== 'empty') {
      buttons.append(
        `
        <div class="btn-group" style="float: right">
            <button id="pano" class="btn" title="Показать на панораме"><i class="icon-eye-open"></i></button>
        </div>
        `,
      );
    }
    content.append(buttons);
    this.updateSize();
    return this;
  },
  updateSize() {
    const { mapView } = this.options;
    if (mapView && mapView.popup && mapView.popup.autoSize) {
      mapView.popup.updateSize();
    }
  },
  /**
    * Метод, вызываемой при нажатии на кнопку "Редактировать".
    * Убираем попап с карты и передаем в панель редактирования текущую фичу.
    *
    * @param  {Event} event событие браузера
    */
  onEdit(event) {
    event.stopImmediatePropagation();
    this.withinLock((lock) => {
      AIS.map.destroyPopup(this.model.feature);
      AIS.editorPanel.addForEditing(this.model, null, lock);
    });
  },
  /**
    * Метод, вызываемой при нажатии на кнопку "Разрезать".
    * Убираем попап с карты и вызываем метод разрезания.
    *
    * @param  {Event} event событие браузера
    */
  onSplit(event) {
    event.stopImmediatePropagation();
    this.withinLock((lock) => {
      AIS.map.destroyPopup(this.model.feature);
      AIS.map.doSplit(this.model, lock);
    });
  },
  /**
   * Удаление фичи.
   * Вызываем метод удаления фичи из коллекции этой фичи. Передаем id удаляемой фичи.
   */
  deleteFeature() {
    const filter = this.model.getFilter();
    filter
      .getCollection()
      ._remove(this.model.id)
      .catch(console.error); // eslint-disable-line no-console
  },
  /**
    * Метод, вызываемый при нажатии на кнопку "Удалить" фичи.
    * Блокируем фичу и открываем модальное окно с подверждением удаления. После открытия подписываемся на
    * нажатие кнопки "Удалить", и в случае нажатия удаляем фичу и с сервера, и с клиента.
    * Если была нажата кнопка Отмена, то закрываем модальное окно.
    * При закрытии модального окна перестаем слушать нажатие кнопки "Удалить"
    *
    * @param  {Event} event событие браузера
    */
  onDelete(event) {
    const me = this;
    const modalElement = $('#deleteFeatureModal');
    const deleteButtonElement = $('#really-delete-button');

    event.stopImmediatePropagation();
    this.withinLock((lock) => {
      modalElement
        .modal()
        .one('hidden', this.model, () => {
          lock.release();
          modalElement.off('hidden');
          deleteButtonElement.off('click');
        });

      deleteButtonElement
        .one('click', () => {
          if (me.options.mapView) {
            me.options.mapView.destroyPopup();
          }
          me.deleteFeature();
          modalElement.modal('hide');
        });
    });
  },
  onPano() {
    const { feature } = this.model;
    let lonlat;
    if (feature.geometry.CLASS_NAME === 'OpenLayers.Geometry.Point') {
      lonlat = new OpenLayers.LonLat(feature.geometry.x, feature.geometry.y);
    } else {
      lonlat = feature.geometry.getBounds().getCenterLonLat();
    }
    AIS.map.sendMapClick(lonlat.transform('EPSG:900913', 'EPSG:4326'));
  },
  /**
    * Блокирование фичи.
    * @param  {Function} callback, вызываемый после того, как фича была залочена
    */
  async withinLock(callback) {
    const lock = await api.lock(this.model.id);
    if (lock) {
      callback.call(this, lock);
    } else {
      AIS.lockAlert();
    }
  },
  _asTable() {
    let reply = this.trTemplate({
      caption: 'Идентификатор',
      content: this.model.id,
    });

    this.model.getMeta().getProperties().each(function (prop) {
      if (prop.getName() !== 'relations') {
        reply += this.trTemplate(prop.toPropertiesTableRow(this.model));
      }
    }, this);

    return reply;
  },
});

export default PropertiesTable;
