import $ from 'jquery';
import _ from 'underscore';
import Backbone from 'js/backbone';
import AIS from 'js/AIS';
import api from 'js/api';


export default Backbone.View.extend({
  className: 'panel',
  template: _.template($('#tmpl-validyear-filter').html()),
  events: {
    'click i': 'expand',
    'change .valid-year-field > #select-valid-year': 'commit',
  },
  /**
   * Заполнить дропдаун значениями годов, получив их с сервера.
   */
  populateYearsList() {
    api.getValidYears().then(({ min_year, max_year }) => {
      this.$el.find('.valid-year-field').html(
        `
          <select id="select-valid-year" class="input input-medium select-valid-year">
            <option value="any" selected>Все годы</option>
          </select>
        `,
      );
      if (min_year !== null && min_year !== null) {
        for (let year = min_year; year <= max_year; year += 1) {
          $('#select-valid-year').append(
            $('<option></option>')
              .attr('value', year)
              .text(year),
          );
        }
      }
    });
  },
  /**
   * Свернуть/развернуть панель фильтра.
   */
  expand() {
    if (!this.expanded) {
      this.$el.find('.content').show();
    } else {
      this.$el.find('.content').hide();
    }

    if (this.expanded) {
      this.$el.find('.icon-chevron-down').removeClass('icon-chevron-down').addClass('icon-chevron-left');
    } else {
      this.$el.find('.icon-chevron-left').removeClass('icon-chevron-left').addClass('icon-chevron-down');
    }
    this.expanded = !this.expanded;
  },
  /**
   * Установить новое значение фильтра из селекта.
   */
  commit() {
    const newYear = $('#select-valid-year').val();
    AIS.validYearFilter.setValidYearFilter(newYear === 'any' ? null : newYear);
  },
  /**
   * Отрендерить элемент.
   * @returns {Backbone.View} отрендеренная вьюха
   */
  render() {
    this.$el.empty().append(this.template());
    this.populateYearsList();
    if (!this.expanded) {
      this.$el.find('.content').hide();
    }
    return this;
  },
});
