import $ from 'jquery';
import _ from 'underscore';

/**
 * Фильтр на сервере.
 */
export const Filter = class {
  /**
   * Создать фильтр на сервере.
   * @param  {*}   args     аргументы которые будут переданы в конструктор
   * @returns {Promise}      промис, который будет зарезолвлен
   *                        когда фильтр будет создан
   */
  static Create(...args) {
    return Promise.resolve($.post('/api/filter'))
      .then((data) => {
        const filter = new this(data.id, ...args);
        return filter.update().then(() => filter);
      });
  }

  constructor(id) {
    this.id = id;
  }

  /**
   * Набор условий фильтра.
   * @returns {object} набор условий
   */
  condition() {
    return null;
  }

  /** Переменные, хранимые и/или используемые в фильтре.
   * @returns {object} переменные, хранимые и/или используемые в фильтре
   */
  variables() {
    return null;
  }

  /**
   * Обновить фильтр на сервере.
   * @returns {Promise} промис, который будет зарезолвлен
   *                   когда фильтр будет обновлен на сервере
   */
  update() {
    const data = JSON.stringify(
      { condition: this.condition(), variables: this.variables() },
      // Заменяем все undefined значения на null, потому что в противном случае они просто удаляются из объектов
      (key, value) => (value === undefined ? null : value),
    );
    return Promise.resolve(
      $.ajax(`/api/filter/${this.id}`, {
        method: 'PUT',
        processData: false,
        data,
      }),
    );
  }

  /**
   * Восстановить объект фильтра, используя его условие и переменные.
   *
   * @param {Object} data данные фильтра, взятые из БД
   * @returns {Promise} промис, который будет зарезолвлен когда фильтр будет восстановлен
   */
  static deserialize(data) { // eslint-disable-line no-unused-vars
    return Promise.reject(Error('Not implemented'));
  }
};

/**
 * Объединяет условия оператором ИЛИ.
 * @param  {...object} args условия
 * @returns {object}         условие
 */
export const $or = (...args) => ({
  $or: args,
});


/**
 * Добавление переменной в фильтр.
 * @param  {string} variable поле
 * @returns {object}          условие
 */
export const $var = variable => ({
  $var: variable,
});

/**
 * Объединяет условия оператором И.
 * @param  {...object} args условия
 * @returns {object}         условие
 */
export const $and = (...args) => ({
  $and: args,
});

/**
 * Условие соответствия родительскому фильтру.
 * @param  {number|Filter} filterOrId   идентификатор фильтра или фильтр
 * @returns {object}                    условие
 */
export const $filter = (filterOrId) => {
  let filterId = filterOrId;
  if (filterOrId instanceof Filter) {
    filterId = filterOrId.id;
  }
  return {
    $filter: filterId,
  };
};

/**
 * Условие нахождения в связи.
 * @param  {string} name      название связи
 * @param  {number} parent_id идентификатор родительского объекта
 * @returns {object}           условие
 */
export const $relations = (name, parent_id) => ({
  $relations: [name, parent_id],
});

/**
 * Фильтр по близости к панораме
 * @param  {number} displayPanoId   идентификатор панорамы
 * @param  {number} displayDistance расстояние для всех фич, кроме архивных панорам
 * @param  {number} panoId          идентификатор панорамы, вокруг которой будет производиться поиск архивных
 * @param  {number} panoDistance    расстояние для архивных панорам
 * @returns {object}                условие
 */
export const $pano = (displayPanoId, displayDistance, panoId, panoDistance) => ({
  $pano: [displayPanoId, displayDistance, panoId, panoDistance],
});

/**
 * Условие равенства поля значению.
 * @param  {*} field       поле
 * @param  {*} value       значение
 * @returns {object}        условие
 */
export const $eq = (field, value) => ({
  $eq: [field, value],
});

/**
 * Условие нахождения поля в границах.
 * @param  {string} field поле
 * @param  {*} start      начальная граница (включительно)
 * @param  {*} end        конечная граница (включительно)
 * @returns {object}       условие
 */
export const $between = (field, { min: start, max: end }) => ({
  $between: [field, start, end],
});

/**
 * Условие нахождения поля в границах (кастует в float).
 * @param  {string} field поле
 * @param  {*} start      начальная граница (включительно)
 * @param  {*} end        конечная граница (включительно)
 * @returns {object}       условие
 */
export const $between_float = (field, { min: start, max: end }) => ({
  $between: [
    `(${field})::FLOAT`,
    parseFloat(start) || '-Infinity',
    parseFloat(end) || '+Infinity',
  ],
});

/**
 * Условия нахождения поля в списке значений.
 * @param  {string} field  поле
 * @param  {Array} values  список значений
 * @returns {object}        условие
 */
export const $eq_any = (field, values) => ({
  $eq_any: [field, values],
});

/**
 * Условия геометрического пересечения поля и геометрии.
 * @param  {string} field поле
 * @param  {string} wkt   геометрии в формате WKT
 * @returns {object}       условие
 */
export const $st_intersects = (field, wkt) => ({
  $st_intersects: [field, wkt],
});

/**
 * Инверсированное условие.
 * @param  {object} condition    условие
 * @returns {object}              инверсированное условие
 */
export const $not = condition => ({
  $not: [condition],
});

/**
 * Условия нахождения поля и геометрии на заданном расстоянии.
 * @param  {string} field    поле
 * @param  {string} wkt      геометрии в формате WKT
 * @param  {number} distance расстояние
 * @returns {object}          условие
 */
export const $st_dwithin = (field, wkt, distance) => ({
  $st_dwithin: [field, wkt, distance],
});

/**
 * Условие, объединяющее два слоя связью.
 * @param  {string} parent_layer родительский слой
 * @param  {string} child_layer  дочерний слой
 * @param  {string} relation     имя связи
 * @returns {object} условие
 */
export const $join_on_relation = (parent_layer, child_layer, relation) => ({
  $join_on_relation: [parent_layer, child_layer, relation],
});

/**
 * Условия вхождения подстроки в текст значения поля.
 * @param  {string} field    поле, в котором ищется вхождение
 * @param  {string} text     строка
 * @returns {object}          условие
 */
export const $is_substring = (field, text) => ({
  $is_substring: [field, text],
});

/**
 * Условие для фильтрации опор по линиям оптики.
 * @param  {number}   optic_visibility_filter_id  идентификатор фильтра видимости слоя оптики
 * @returns {object}                         условие
 */
export const $optic_pole_filter = optic_visibility_filter_id => ({
  $optic_pole_filter: [optic_visibility_filter_id],
});

/**
 * Условие равенства значения поля NULLу.
 * @param  {string}   field поле, для которого выполняется проверка
 * @returns {object}                         условие
 */
export const $is_null = field => ({
  $is_null: [field],
});

/**
 * Фильтр слоёв на сервере.
 *
 * Добавление и удаление слоев поддерживает дебаунс в 100мс, т.к.
 * эти операции часто имеют массовый характер.
 */
export const LayersFilter = class extends Filter {
  constructor(id) {
    super(id);
    this.layers = new Set();
    this._update_debounce = _.debounce(this.update, 100);
  }

  /**
   * Добавить слой в фильтр.
   * @param {string} layerName имя слоя
   */
  addLayer(layerName) {
    this.layers.add(layerName);
    this._update_debounce();
  }

  /**
   * Удалить слой из фильтра.
   * @param {string} layerName имя слоя
   */
  deleteLayer(layerName) {
    this.layers.delete(layerName);
    this._update_debounce();
  }

  /**
   * Сериализовать фильтр в набор условий для сервера.
   * @returns {object} набор условий для сервера
   */
  condition() {
    return $eq_any('layer', Array.from(this.layers));
  }

  /**
   * Восстановить объект `LayersFilter` на основе данных, полученных из БД.
   *
   * @param {Object} data состояние фильтра, полученное из БД
   * @returns {Promise<LayersFilter>} восстановленный объект фильтра по слоям
   */
  static deserialize(data) {
    const filter = this.Create().then(async (filter) => {
      filter.layers = new Set([...data.condition.$eq_any[1]]);
      return filter;
    });
    return filter;
  }
};

/**
 * TODO: implementation.
 */
export const FeaturesFilter = class extends Filter {
  /**
   * Инициализация фильтра.
   *
   * @param {number} id                 идентификатор этого фильтра
   * @param {Array} parentFiltersIds    id родительских фильтров
   */
  constructor(id, parentFiltersIds) {
    super(id);
    this.parentFiltersIds = parentFiltersIds;
  }

  condition() {
    return $and(
      ...this.parentFiltersIds.map(parentId => $filter(parentId)),
    );
  }
};

/**
 * Фильтр видимости слоя `aircable_optic_electro`.
 *
 * Идентичен `FeaturesFilter`, но действует только для одного слоя.
 */
export const OpticLayerFilter = class extends Filter {
  /**
   * Инициализация фильтра.
   *
   * @param {number} id                 идентификатор этого фильтра
   * @param {Array} parentFiltersIds    id родительских фильтров
   */
  constructor(id, parentFiltersIds) {
    super(id);
    this.parentFiltersIds = parentFiltersIds;
  }

  /**
   * Условие фильтра.
   * @returns {Object} условие фильтра
   */
  condition() {
    return $and(
      ...this.parentFiltersIds.map(parentId => $filter(parentId)),
      $eq('layer', 'aircable_optic_electro'),
    );
  }
};

/**
 * Фильтр панорам.
 */
export const PanoFilter = class extends Filter {
  /**
   * Конструктор фильтра панорам.
   * @param  {number} id              идентификатор фильтра
   * @param  {number} parentId        идентификатор родительского фильтра
   * @param  {number} displayPanoId   идентификатор панорамы
   * @param  {number} displayDistance расстояние привязки
   * @param  {number} panoId          идентификатор панорамы, вокруг которой будет производиться поиск исторических панорам
   * @param  {number} panoDistance    расстояние привязки исторических панорам
   */
  constructor(id, parentId, displayPanoId, displayDistance, panoId, panoDistance) {
    super(id);
    this.parentId = parentId;
    this.displayPanoId = displayPanoId;
    this.displayDistance = displayDistance;
    this.panoId = panoId;
    this.panoDistance = panoDistance;
  }

  /**
   * Изменить панораму.
   * @param {string} displayPanoId  идентификатор панорамы
   * @param {string} panoId         идентификатор панорамы, вокруг которой будет производиться поиск исторических панорам
   */
  changePanoId(displayPanoId, panoId) {
    this.displayPanoId = displayPanoId;
    this.panoId = panoId;
    this.update();
  }

  condition() {
    return $and(
      $or(
        $filter(this.parentId),
        $eq('layer', 'pano'),
        $eq('layer', 'pano_archive'),
      ),
      $pano(
        $var('displayPanoId'),
        $var('displayDistance'),
        $var('panoId'),
        $var('panoDistance'),
      ),
    );
  }

  variables() {
    return {
      displayPanoId: this.displayPanoId,
      displayDistance: this.displayDistance,
      panoId: this.panoId,
      panoDistance: this.panoDistance,
    };
  }
};

/**
 * Фильтр по региону.
 */
export const RegionFilter = class extends Filter {
  /**
   * Выбор региона для фильтрации по региону.
   * @param {string} [regionGeom] геометрия выбранного региона
   */
  setRegionFilter(regionGeom) {
    this.regionGeom = regionGeom;
    this.update();
  }

  condition() {
    // Фильтруем на пересечение геометрий только если эта геометрия указана
    return !this.regionGeom || $st_intersects('geog', $var('regionGeom'));
  }

  variables() {
    return {
      regionGeom: this.regionGeom,
    };
  }
};

/**
 * Фильтр по принадлежности к светофорному объекту.
 */
export const TrafficLightNodeFilter = class extends Filter {
  /**
   * Выбор светофорного объекта для фильтрации по светофорному объекту.
   * @param {string} [id]    идентификатор светофорного объекта
   */
  setTrafficLightNodeFilter(id) {
    this.trafficLightNode = id;
    this.update();
  }

  condition() {
    // Фильтруем по светофорному объекту только если он указан
    return !this.trafficLightNode || $relations('traffic_light_node', $var('trafficLightNode'));
  }

  variables() {
    return {
      trafficLightNode: this.trafficLightNode,
    };
  }
};

/**
 * Фильтр по улице и/или району.
 */
export const AddressFilter = class extends Filter {
  /**
   * Выбор улицы и/или района для фильтрации по улице и/или району.
   * @param {string} [street]    id улицы
   * @param {string} [district]  id района
   */
  setAddressFilter({ district, street }) {
    this.district = district;
    this.street = street;
    this.update();
  }

  condition() {
    return $and(
      !this.district || $relations('district', $var('district')), // Фильтруем по району только если он указан
      !this.street || $relations('road_axis_simple', $var('street')), // Фильтруем по улице только если она указана
    );
  }

  variables() {
    return {
      district: this.district,
      street: this.street,
    };
  }
};

/**
 * Фильтр по полям слоёв.
 */
export const LayersFieldsFilter = class extends Filter {
  constructor(id) {
    super(id);
    this.layersFields = {};
  }

  /**
   * Выбор полей для фильтрации по слою.
   * @param {string} layerName   название слоя
   * @param {Object} values      поля, по которым производится фильтрация для этого слоя
   */
  setLayerFieldsFilter(layerName, values) {
    this.layersFields[layerName] = values;
    this.update();
  }

  /**
   * Удаление слоя из фильтра
   * @param {string} layerName   название слоя
   */
  removeLayerFieldsFilter(layerName) {
    delete this.layersFields[layerName];
    this.update();
  }

  /**
   * Условие фильтра.
   * Итоговый запрос будет выглядеть следующим образом
   *  (NOT layer = 'layer1' OR (fields->>'field1' = 1) AND
   *  (NOT layer = 'layer2' OR (fields->>'field2' = 2)
   *  Таким образом условие фильтрации по какому-то полю будет использоваться только для определенного слоя.
   *  @returns {Object} условие фильтра
   */
  condition() {
    const condition = Object
      .entries(this.layersFields)
      .map(([layerName, layerConditions]) => $or(
        $not($eq('layer', layerName)),
        layerConditions.length ? $and(...layerConditions) : true,
      ));
    return condition.length > 0 ? $and(...condition) : true;
  }
};

/**
 * Фильтр дорог.
 */
export const RoadsFilter = class extends Filter {
  /**
   * Условие фильтра.
   * @returns {Object} условие фильтра
   */
  condition() {
    return $join_on_relation('road', 'road_axis', 'road');
  }
};

/**
 * Фильтр опор по линиям оптики.
 */
export const OpticPoleFilter = class extends Filter {
  /**
   * Конструктор.
   * @param {number} id   идентификатор родительского фильтра
   * @param {number} visibilityFilterId   идентификатор фильтра видимости линий оптики
   */
  constructor(id, visibilityFilterId) {
    super(id);
    this.visibilityFilterId = visibilityFilterId;
    this.isEnabled = false;
  }

  /**
   * Активировать или деактивировать фильтр.
   * @param {boolean} isEnabled   новое состояние
   */
  enabled(isEnabled) {
    this.isEnabled = isEnabled;
    this.update();
  }

  /**
   * Условие фильтра.
   * @returns {(Object|null)} условие фильтра
   */
  condition() {
    return this.isEnabled ? $optic_pole_filter(this.visibilityFilterId) : null;
  }
};

/**
 * Фильтр по году действия.
 */
export const ValidYearFilter = class extends Filter {
  /**
   * Конструктор.
   * @param {number} id   идентификатор родительского фильтра
   */
  constructor(id) {
    super(id);
    this.year = null;
  }

  /**
   * Выбор года действия для фильтрации.
   * @param {number|null} year   год
   */
  setValidYearFilter(year) {
    this.year = year;
    this.update();
  }

  /**
   * Условие фильтра.
   * @returns {(Object|null)} условие фильтра
   */
  condition() {
    if (this.year === null) {
      return null;
    }
    return $or(
      // слой или не содержит полей `valid_to`, `valid_from` в схеме
      $not(
        $eq_any(
          'layer',
          AIS.meta.filter(
            (layer) => {
              const fields = layer.get('fields').map(field => field.id);
              return fields.includes('valid_from') && fields.includes('valid_to');
            },
          ).map(layer => layer.class),
        ),
      ),
      // или в они должны удовлетворять условиям:
      // 1. valid_from <= year <= valid_to
      // 2. если valid_from или valid_to отсутствуют, то это считается открытым концом интервала
      $and(
        $or(
          $is_null("fields->>'valid_from'"),
          $between_float("fields->>'valid_from'", { max: this.year }),
        ),
        $or(
          $is_null("fields->>'valid_to'"),
          $between_float("fields->>'valid_to'", { min: this.year }),
        ),
      ),
    );
  }
};

export const conditions = {
  $or,
  $var,
  $and,
  $filter,
  $relations,
  $eq,
  $between,
  $between_float,
  $eq_any,
  $st_intersects,
  $not,
  $is_substring,
  $optic_pole_filter,
  $is_null,
};
