import _ from 'underscore';
import OpenLayers from 'lib/OpenLayers-2.12/OpenLayers.debug';


// ускоренная версия removeFeatures, которая удаляет фичи из this.features
// через Set, если их нужно удалить больше 100
// eslint-disable-next-line no-param-reassign
OpenLayers.Layer.Vector.prototype.removeFeatures = function (features, options) {
  if (!features || features.length === 0) {
    return;
  }
  if (features === this.features) {
    return this.removeAllFeatures(options);
  }
  if (!(OpenLayers.Util.isArray(features))) {
    // eslint-disable-next-line no-param-reassign
    features = [features];
  }
  if (features === this.selectedFeatures) {
    // eslint-disable-next-line no-param-reassign
    features = features.slice();
  }

  const notify = !options || !options.silent;
  const few_features = features.length < 100;

  if (notify) {
    this.events.triggerEvent(
      'beforefeaturesremoved', { features: features },
    );
  }

  let layer_feature_set;
  if (!few_features) {
    // если нужно удалить много фич, будем использовать Set, потому что это быстрее
    layer_feature_set = new Set(this.features);
  }

  for (let i = features.length - 1; i >= 0; i -= 1) {
    // We remain locked so long as we're not at 0
    // and the 'next' feature has a geometry. We do the geometry check
    // because if all the features after the current one are 'null', we
    // won't call eraseGeometry, so we break the 'renderer functions
    // will always be called with locked=false *last*' rule. The end result
    // is a possible gratiutious unlocking to save a loop through the rest
    // of the list checking the remaining features every time. So long as
    // null geoms are rare, this is probably okay.
    if (i !== 0 && features[i - 1].geometry) {
      this.renderer.locked = true;
    } else {
      this.renderer.locked = false;
    }

    const feature = features[i];
    delete this.unrenderedFeatures[feature.id];

    if (notify) {
      this.events.triggerEvent('beforefeatureremoved', {
        feature: feature,
      });
    }

    if (few_features) {
      this.features = OpenLayers.Util.removeItem(this.features, feature);
    } else {
      // удаляем из множества
      layer_feature_set.delete(feature);
    }

    // feature has no layer at this point
    feature.layer = null;

    if (feature.geometry) {
      this.renderer.eraseFeatures(feature);
    }

    // in the case that this feature is one of the selected features,
    // remove it from that array as well.
    if (OpenLayers.Util.indexOf(this.selectedFeatures, feature) !== -1) {
      OpenLayers.Util.removeItem(this.selectedFeatures, feature);
    }

    if (notify && few_features) {
      this.events.triggerEvent('featureremoved', {
        feature: feature,
      });
    }
  }

  if (!few_features) {
    // заполняем this.features значениями из множества
    this.features = Array.from(layer_feature_set.values());
  }

  if (notify) {
    if (!few_features) {
      // если удаляли через Set, то все события для каждой фичи отправляем
      // в отдельном цикле после всего, т.к. в предыдущем цикле изменения в
      // this.features еще не были внесены и событие некорректно
      for (let i = features.length - 1; i >= 0; i -= 1) {
        this.events.triggerEvent('featureremoved', {
          feature: features[i],
        });
      }
    }
    this.events.triggerEvent('featuresremoved', { features: features });
  }
};

// eslint-disable-next-line no-param-reassign
OpenLayers.Feature.prototype.initialize = function (layer, lonlat, data) {
  this.layer = layer;
  this.lonlat = lonlat;
  this.data = (data != null) ? data : {};
  this.id = OpenLayers.Util.createUniqueID(this.CLASS_NAME);
};

// eslint-disable-next-line no-param-reassign
OpenLayers.Geometry.prototype.initialize = function () {
  this.id = OpenLayers.Util.createUniqueID(this.CLASS_NAME);
};

// eslint-disable-next-line no-param-reassign
OpenLayers.Util.isArray = Array.isArray || function (a) {
  return toString.call(a) === '[object Array]';
};

//    Разница на слое с светофорами(22тыс.) примерно 4МБ, на дорожной разметке(линии) 20МБ
//    Проблема с этой оптимизацией заключается в том, что есть код который внутри
//    OpenLayers.Geometry.atPoint использует this.bounds напрямую,
//    а еще есть setBounds который выставляет любые баундсы у геометрии :)
// eslint-disable-next-line no-param-reassign
OpenLayers.Geometry.prototype.getBounds = function () {
  this.calculateBounds();
  const { bounds } = this;
  this.bounds = null;
  return bounds;
};

// eslint-disable-next-line no-param-reassign
OpenLayers.Custom = {};

const myup = function (evt) {
  const lonlat = new OpenLayers.LonLat(this.point.geometry.x, this.point.geometry.y);
  const xy = this.layer.getViewPortPxFromLonLat(lonlat);
  if (this.mouseDown && (!this.lastUp || !this.lastUp.equals(xy))) {
    if (this.stoppedDown && this.freehandMode(evt)) {
      if (this.persist) {
        this.destroyPersistedFeature();
      }
      this.removePoint();
      this.finalize();
    } else if (this.passesTolerance(this.lastDown, evt.xy,
      this.pixelTolerance)) {
      if (this.touch) {
        this.modifyFeature(xy);
      }
      if (this.lastUp == null && this.persist) {
        this.destroyPersistedFeature();
      }
      this.addPoint(xy);
      this.lastUp = xy;
      if (this.line.geometry.components.length === this.maxVertices + 1) {
        this.finishGeometry();
      }
    }
  }
  this.stoppedDown = this.stopDown;
  this.mouseDown = false;
  return !this.stopUp;
};
const mycallback = function (name, args) {
  if (this.layer) {
    if (name === 'done' || name === 'cancel') {
      const geometry = args[0];
      const feature = new OpenLayers.Feature.Vector(geometry);
      this.layer.events.triggerEvent(
        'sketchcomplete', { feature: feature },
      );
    } else if (name === 'modify' || name === 'point') {
      const vertex = args[0];
      const feature = args[1];
      this.layer.events.triggerEvent(
        'sketchmodified', { vertex: vertex, feature: feature },
      );
    } else if (name === 'create') {
      const vertex = args[0];
      const feature = args[1];
      this.layer.events.triggerEvent(
        'sketchstarted', { vertex: vertex, feature: feature },
      );
    }
  }
};

// eslint-disable-next-line no-param-reassign
OpenLayers.Custom.PathHandler = OpenLayers.Class(OpenLayers.Handler.Path, {
  up: myup,

  callback: function () {
    OpenLayers.Handler.Path.prototype.callback.apply(this, arguments);
    mycallback.apply(this, arguments);
  },

  getLayer: function () {
    return this.layer;
  },
});

// eslint-disable-next-line no-param-reassign
OpenLayers.Custom.PolygonHandler = OpenLayers.Class(OpenLayers.Handler.Polygon, {
  up: myup,

  callback: function () {
    OpenLayers.Handler.Polygon.prototype.callback.apply(this, arguments);
    mycallback.apply(this, arguments);
  },

  getLayer: function () {
    return this.layer;
  },
});

// eslint-disable-next-line no-param-reassign
OpenLayers.Custom.NotPersistedPathHandler = OpenLayers.Class(OpenLayers.Custom.PathHandler, {
  destroyPersistedFeature: function () {
    while (this.map.popups.length > 0) {
      const popup = this.map.popups.pop();
      this.map.removePopup(popup);
    }
    return OpenLayers.Handler.Path.prototype.destroyPersistedFeature.apply(this, arguments);
  },
  deactivate: function () {
    this.destroyPersistedFeature();
    return OpenLayers.Handler.Path.prototype.deactivate.apply(this, arguments);
  },
});

// eslint-disable-next-line no-param-reassign
OpenLayers.Custom.NotPersistedPolygonHandler = OpenLayers.Class(OpenLayers.Custom.PolygonHandler, {
  destroyPersistedFeature: function () {
    while (this.map.popups.length > 0) {
      const popup = this.map.popups.pop();
      this.map.removePopup(popup);
    }
    return OpenLayers.Handler.Polygon.prototype.destroyPersistedFeature.apply(this, arguments);
  },
  deactivate: function () {
    this.destroyPersistedFeature();
    return OpenLayers.Handler.Polygon.prototype.deactivate.apply(this, arguments);
  },
});

/**
 * Модифицированная версия OpenLayers.Control.Measure
 * Изменяет стандартное поведение при включенной опции immediate:
 *     Стандартное поведение:
 *         Вызывать event measurepartial при каждом изменении положения
 *     Новое поведение:
 *         Вызывать event measureimmediate при каждом изменении положения, но не чаще 20 раз в секунду
 */
OpenLayers.Custom.Measure = OpenLayers.Class(OpenLayers.Control.Measure, { // eslint-disable-line no-param-reassign
  measureImmediateThrottled: null,

  initialize: function () {
    OpenLayers.Control.Measure.prototype.initialize.apply(this, arguments);
    this.measureImmediateThrottled = _.throttle(this._measureImmediate, 50);
  },

  measureImmediate: function () {
    // eslint-disable-next-line prefer-spread
    if (this.measureImmediateThrottled !== null) this.measureImmediateThrottled.apply(this, arguments);
  },

  _measureImmediate: function (point, feature, drawing) {
    // Очень хрупко, со сменой версии OL может сломаться
    if (drawing && !this.handler.freehandMode(this.handler.evt)) {
      this.measure(feature.geometry, 'measureimmediate');
    }
  },
});

// eslint-disable-next-line no-param-reassign
OpenLayers.Custom.Click = OpenLayers.Class(OpenLayers.Control, {
  defaultHandlerOptions: {
    single: true,
    double: false,
    stopSingle: false,
    stopDouble: false,
  },
  initialize: function (options) {
    OpenLayers.Control.prototype.initialize.apply(this, arguments);
    this.handler = new OpenLayers.Handler.Click(
      this, {
        click: function () {
          // eslint-disable-next-line prefer-spread
          this.onClick.apply(this, arguments);
        },
      }, _.extend({}, this.defaultHandlerOptions, options),
    );
  },
});

// eslint-disable-next-line no-param-reassign
OpenLayers.Custom.DragFeature = OpenLayers.Class(OpenLayers.Control.DragFeature, {
  initialize: function (layer, options) {
    OpenLayers.Control.prototype.initialize.apply(this, [options]);
    this.layer = layer;
    this.handlers = {
      drag: new OpenLayers.Handler.Drag(
        this, OpenLayers.Util.extend({
          down: this.downFeature,
          move: this.moveFeature,
          up: this.upFeature,
          out: this.cancel,
          done: this.doneDragging,
        }, this.dragCallbacks), {
          documentDrag: this.documentDrag,
          geometryTypes: [],
        },
      ),
      feature: new OpenLayers.Handler.Feature(
        this, this.layer, OpenLayers.Util.extend({
          // 'click' and 'clickout' callback are for the mobile
          // support: no 'over' or 'out' in touch based browsers.
          click: this.clickFeature,
          clickout: this.clickoutFeature,
          over: this.overFeature,
          out: this.outFeature,
        }, this.featureCallbacks),
        { geometryTypes: this.geometryTypes },
      ),
    };
    this.handlers.feature.geometryTypeMatches = function (feature) {
      return this.features == null
        || OpenLayers.Util.indexOf(this.features,
          feature.id) > -1;
    };
  },
  setFeature: function (feature) {
    this.handlers.feature.features = [feature.id];
    this.handlers.feature.layer = feature.layer;
  },
});

// eslint-disable-next-line no-param-reassign
OpenLayers.Custom.GeoJSON = OpenLayers.Class(OpenLayers.Format.GeoJSON, {});
// eslint-disable-next-line no-param-reassign
OpenLayers.Custom.GeoJSON.prototype.parseCoords.point = function (array) {
  const point = new OpenLayers.Geometry.Point(array[0], array[1]);

  if (array.length === 3) {
    // eslint-disable-next-line prefer-destructuring
    point.alt = array[2];
  }
  return point;
};

// eslint-disable-next-line no-param-reassign
OpenLayers.Custom.GeoJSON.prototype.extract.point = function (point) {
  return [point.x, point.y, point.alt ? point.alt : 0];
};

// eslint-disable-next-line no-param-reassign
OpenLayers.Custom.ModifyFeature = OpenLayers.Class(OpenLayers.Control.ModifyFeature, {
  dragStyle: {},
  radiusStyle: {},

  /* https://github.com/openlayers/ol2/issues/766
    */
  initialize: function (layer, optionalOptions) {
    const options = optionalOptions || {};
    this.layer = layer;
    this.vertices = [];
    this.virtualVertices = [];

    let lstyle;
    if (this.layer.style) {
      lstyle = this.layer.style;
    } else {
      const fake = new OpenLayers.Feature.Vector();
      fake.layer = layer;
      lstyle = this.layer.styleMap.createSymbolizer(fake, options.vertexRenderIntent);
    }
    this.virtualStyle = OpenLayers.Util.extend({}, lstyle);
    this.dragStyle = OpenLayers.Util.extend({}, lstyle);
    this.radiusStyle = OpenLayers.Util.extend({}, lstyle);
    let graphicZIndex = parseFloat(lstyle.graphicZIndex);
    graphicZIndex = Number.isNaN(graphicZIndex) ? 998 : graphicZIndex;

    this.virtualStyle.graphicZIndex = graphicZIndex - 1 || 997;
    this.virtualStyle.fillOpacity = parseFloat(this.virtualStyle.fillOpacity) / 2 || 0.5;
    this.virtualStyle.pointRadius = parseFloat(this.virtualStyle.pointRadius) * 0.75 || 3;

    this.dragStyle.graphicZIndex = graphicZIndex - 1 || 997;
    this.dragStyle.externalGraphic = '/icon/move.png';
    this.dragStyle.graphicHeight = 16;
    this.dragStyle.graphicWidth = 16;

    this.radiusStyle.graphicZIndex = graphicZIndex - 1 || 997;
    this.radiusStyle.externalGraphic = '/icon/rotate.png';
    this.radiusStyle.graphicHeight = 16;
    this.radiusStyle.graphicWidth = 16;

    this.deleteCodes = [46, 68];
    this.mode = OpenLayers.Control.ModifyFeature.RESHAPE;
    OpenLayers.Control.prototype.initialize.apply(this, [options]);
    if (!(OpenLayers.Util.isArray(this.deleteCodes))) {
      this.deleteCodes = [this.deleteCodes];
    }
    const control = this;

    // configure the select control
    const selectOptions = {
      geometryTypes: this.geometryTypes,
      clickout: this.clickout,
      toggle: this.toggle,
      onBeforeSelect: this.beforeSelectFeature,
      onSelect: this.selectFeature,
      onUnselect: this.unselectFeature,
      scope: this,
    };
    if (this.standalone === false) {
      this.selectControl = new OpenLayers.Control.SelectFeature(
        layer, selectOptions,
      );
    }

    // configure the drag control
    const dragOptions = {
      geometryTypes: ['OpenLayers.Geometry.Point'],
      onStart: function (feature, pixel) {
        control.dragStart.apply(control, [feature, pixel]);
      },
      onDrag: function (feature, pixel) {
        control.dragVertex.apply(control, [feature, pixel]);
      },
      onComplete: function (feature) {
        control.dragComplete.apply(control, [feature]);
      },
      featureCallbacks: {
        over: function (feature) {
          /**
           * In normal mode, the feature handler is set up to allow
           * dragging of all points.  In standalone mode, we only
           * want to allow dragging of sketch vertices and virtual
           * vertices - or, in the case of a modifiable point, the
           * point itself.
           */
          if (control.standalone !== true || feature._sketch
            || control.feature === feature) {
            control.dragControl.overFeature.apply(
              control.dragControl, [feature],
            );
          }
        },
      },
    };
    this.dragControl = new OpenLayers.Control.DragFeature(
      layer, dragOptions,
    );

    // configure the keyboard handler
    const keyboardOptions = {
      keydown: this.handleKeypress,
    };
    this.handlers = {
      keyboard: new OpenLayers.Handler.Keyboard(this, keyboardOptions),
    };
  },

  activate: function () {
    const result = OpenLayers.Control.ModifyFeature.prototype.activate.apply(this, arguments);
    if (result) {
      this.map.events.register('zoomend', this, this.resetVertices);
    }
    return result;
  },

  deactivate: function () {
    const result = OpenLayers.Control.ModifyFeature.prototype.deactivate.apply(this, arguments);
    if (result) {
      this.map.events.unregister('zoomend', this, this.resetVertices);
    }
    return result;
  },
  collectDragHandle: function () {
    const { geometry } = this.feature;
    const center = this.map.getLonLatFromPixel(
      this.map.getPixelFromLonLat(
        geometry.getBounds().getCenterLonLat(),
      ).add(15, 0),
    );
    const originGeometry = new OpenLayers.Geometry.Point(
      center.lon, center.lat,
    );
    const origin = new OpenLayers.Feature.Vector(originGeometry);
    originGeometry.move = function (x, y) {
      OpenLayers.Geometry.Point.prototype.move.call(this, x, y);
      geometry.move(x, y);
    };
    origin._sketch = true;
    if (this.dragHandle) {
      this.layer.removeFeatures([this.dragHandle], { silent: true });
    }
    this.dragHandle = origin;
    this.dragHandle.style = this.dragStyle;
    this.layer.addFeatures([this.dragHandle], { silent: true });
  },

  collectRadiusHandle: function () {
    const { geometry } = this.feature;
    const bounds = geometry.getBounds();
    const center = bounds.getCenterLonLat();
    const originGeometry = new OpenLayers.Geometry.Point(
      center.lon, center.lat,
    );
    const radiusGeometryLonLat = this.map.getLonLatFromPixel(
      this.map.getPixelFromLonLat(new OpenLayers.LonLat(
        bounds.right, bounds.bottom,
      )).add(15, 15),
    );
    const radiusGeometry = new OpenLayers.Geometry.Point(
      radiusGeometryLonLat.lon,
      radiusGeometryLonLat.lat,
    );
    const radius = new OpenLayers.Feature.Vector(radiusGeometry);
    // eslint-disable-next-line no-bitwise
    const resize = (this.mode & OpenLayers.Control.ModifyFeature.RESIZE);
    // eslint-disable-next-line no-bitwise
    const reshape = (this.mode & OpenLayers.Control.ModifyFeature.RESHAPE);
    // eslint-disable-next-line no-bitwise
    const rotate = (this.mode & OpenLayers.Control.ModifyFeature.ROTATE);

    radiusGeometry.move = function (x, y) {
      OpenLayers.Geometry.Point.prototype.move.call(this, x, y);
      const dx1 = this.x - originGeometry.x;
      const dy1 = this.y - originGeometry.y;
      const dx0 = dx1 - x;
      const dy0 = dy1 - y;
      if (rotate) {
        const a0 = Math.atan2(dy0, dx0);
        const a1 = Math.atan2(dy1, dx1);
        let angle = a1 - a0;
        angle *= 180 / Math.PI;
        geometry.rotate(angle, originGeometry);
      }
      if (resize) {
        let scale;
        let ratio;
        // 'resize' together with 'reshape' implies that the aspect
        // ratio of the geometry will not be preserved whilst resizing
        if (reshape) {
          scale = dy1 / dy0;
          ratio = (dx1 / dx0) / scale;
        } else {
          const l0 = Math.sqrt((dx0 * dx0) + (dy0 * dy0));
          const l1 = Math.sqrt((dx1 * dx1) + (dy1 * dy1));
          scale = l1 / l0;
        }
        geometry.resize(scale, originGeometry, ratio);
      }
    };
    radius._sketch = true;
    if (this.radiusHandle) {
      this.layer.removeFeatures([this.radiusHandle], { silent: true });
    }
    this.radiusHandle = radius;
    this.radiusHandle.style = this.radiusStyle;
    this.layer.addFeatures([this.radiusHandle], { silent: true });
  },
});

// eslint-disable-next-line no-param-reassign
OpenLayers.Custom.LayerSwitcher = OpenLayers.Class(OpenLayers.Control.LayerSwitcher, {
  /**
   * Method: loadContents
   * Set up the labels and divs for the control
   */
  loadContents: function () {
    // layers list div
    this.layersDiv = document.createElement('div');
    this.layersDiv.id = `${this.id}_layersDiv`;
    OpenLayers.Element.addClass(this.layersDiv, 'layersDiv');

    this.baseLbl = document.createElement('div');
    this.baseLbl.innerHTML = 'Картографические подложки';
    this.baseLbl.className += 'baseLbl';

    this.baseLayersDiv = document.createElement('div');
    this.baseLayersDiv.className += 'baseLayersDiv';

    this.basePanel = document.createElement('div');
    this.basePanel.className += 'panel';
    this.basePanel.appendChild(this.baseLbl);
    this.basePanel.appendChild(this.baseLayersDiv);

    this.dataLbl = document.createElement('div');
    this.dataLbl.innerHTML = 'Дополнительные слои';
    this.dataLbl.className += 'dataLbl';

    this.dataLayersDiv = document.createElement('div');
    this.dataLayersDiv.className += 'dataLayersDiv';

    this.dataPanel = document.createElement('div');
    this.dataPanel.className += 'panel';
    this.dataPanel.appendChild(this.dataLbl);
    this.dataPanel.appendChild(this.dataLayersDiv);

    let switcherElements = [this.dataPanel, this.basePanel];
    if (this.ascending) {
      switcherElements = switcherElements.reverse();
    }
    switcherElements.forEach((element, i) => {
      this.layersDiv.appendChild(element);
      if (i < switcherElements.length - 1) {
        this.layersDiv.appendChild(document.createElement('hr'));
      }
    });

    this.div.appendChild(this.layersDiv);

    if (this.roundedCorner) {
      OpenLayers.Rico.Corner.round(this.div, {
        corners: 'tl bl',
        bgColor: 'transparent',
        color: this.roundedCornerColor,
        blend: false,
      });
      OpenLayers.Rico.Corner.changeOpacity(this.layersDiv, 0.75);
    }

    // maximize button div
    let img;
    img = OpenLayers.Util.getImageLocation('layer-switcher-maximize.png');
    this.maximizeDiv = OpenLayers.Util.createAlphaImageDiv(
      'OpenLayers_Control_MaximizeDiv',
      null,
      null,
      img,
      'absolute',
    );
    OpenLayers.Element.addClass(this.maximizeDiv, 'maximizeDiv olButton');
    this.maximizeDiv.style.display = 'none';

    this.div.appendChild(this.maximizeDiv);

    // minimize button div
    img = OpenLayers.Util.getImageLocation('layer-switcher-minimize.png');
    this.minimizeDiv = OpenLayers.Util.createAlphaImageDiv(
      'OpenLayers_Control_MinimizeDiv',
      null,
      null,
      img,
      'absolute',
    );
    OpenLayers.Element.addClass(this.minimizeDiv, 'minimizeDiv olButton');
    this.minimizeDiv.style.display = 'none';

    this.div.appendChild(this.minimizeDiv);
  },
});

// eslint-disable-next-line no-param-reassign
OpenLayers.Custom.Feature = OpenLayers.Class(OpenLayers.Handler.Feature, {
  click: function (evt) {
    if (evt.ctrlKey) {
      AIS.map.sendMapClickFromEvent(evt);
    } else {
      return OpenLayers.Handler.Feature.prototype.click.apply(this, arguments);
    }
    return false;
  },
});


export default OpenLayers.Custom;
