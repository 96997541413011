import englishToRussianLayoutMapping from 'js/data/LayoutMapping';

/**
 * Получить правильную форму существительного, согласованную с числительным.
 * Например: 1 яблоко, 2 яблока, 5 яблок.
 * @param {Array<String>} titles        массив с тремя формами существительного: ['яблоко', 'яблока', 'яблок']
 * @returns {function(Number): String}  функция, возвращающая правильную форму для числа
 */
export const declOfNum = (titles) => { // eslint-disable-line import/prefer-default-export
  const cases = [2, 0, 1, 1, 1, 2];
  return (number) => {
    const absNumber = Math.abs(number);
    return titles[
      (absNumber % 100 > 4 && absNumber % 100 < 20)
        ? 2
        : cases[(absNumber % 10 < 5) ? absNumber % 10 : 5]
    ];
  };
};


/**
 * Попытаться распарсить флоат из строки.
 *
 * @param {string} value строка из которой пытаемся распарсить флоат
 * @returns {(number|string)} флоат, если парсинг успешен, иначе исходную строку
 */
export const tryParseFloat = (value) => {
  if (/^(-|\+)?([0-9]+(\.[0-9]+)?|Infinity)$/.test(value)) {
    return Number(value);
  }
  return value;
};


/**
 * Вспомогательная функция, делает из строки с пикселями (например: "10px") число (например: 10)
 *
 * @param {string} pixels_str строка с пикселями
 * @returns {number} число пикселей
 */
export const get_pixels_int = (pixels_str) => {
  const pixels_str_without_px = pixels_str.replace('px', '');
  return parseInt(pixels_str_without_px, 10);
};


/**
 * Изменить клавиатурную раскладку в строке с английской на русскую.
 *
 * Английские буквы заменяются на соответствующие русские.
 * Русские буквы и любые другие неизвестные символы остаются нетронутыми.
 * Сохраняется регистр символов.
 *
 * @param {string} string строка в английской или смешанной раскладке
 * @returns {string} строка в русской раскладке
 */
export const changeStringLayoutFromEnglishToRussian = (string) => {
  const letters = string.split('').map((letter) => {
    const lower = letter.toLowerCase();
    if (!(lower in englishToRussianLayoutMapping)) {
      return letter;
    }
    if (letter !== lower) {
      return englishToRussianLayoutMapping[lower].toUpperCase();
    }
    return englishToRussianLayoutMapping[lower];
  });

  return letters.join('');
};

/**
  * Исправляет текстовую строку по заданным правилам.
  * @param {string} value значение для исправления
  * @returns {string} исправленное value
  */
export const fixTextFields = (value) => {
  let fixed = value.replace(RegExp('\\s+', 'gu'), ' ');
  fixed = fixed.trim();
  return fixed;
};
/**
  * Проверяет текстовую строку по заданным правилам.
  * @param {string} value значение для проверки
  * @param {string} validator regex для проверки
  * @returns {bool} результат проверок
  */
export const checkTextFields = (value, validator) => {
  const regexp = RegExp(validator, 'u');
  return value.match(regexp) !== null;
};
