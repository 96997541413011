import $ from 'jquery';
import Backbone from 'js/backbone';
import AIS from 'js/AIS';
import User from 'js/model/User';

export default Backbone.View.extend({
  events: {
    'click .trigger-left': 'closeLeft',
    'click .trigger-right': 'closeRight',
    'click .trigger-panos': 'closePanos',
  },
  initialize: function () {
    AIS.on('editor:start', function () {
      this.mainLayout.open('west');
    }, this);

    AIS.on('togglePanoLayer', function (enable) {
      if (!enable) {
        this.closePanos();
      }
    }, this);

    User.on('change:readonly', function (user, readonly) {
      if (readonly) {
        this.mainLayout.hide('west');
      } else {
        this.mainLayout.show('west', false);
      }
    }, this);
  },
  closeLeft() {
    this.mainLayout.close('west');
  },
  closeRight() {
    this.mainLayout.close('east');
  },
  closePanos() {
    if (!AIS.pano1.get('active') && !AIS.pano2.get('active')) {
      this.centerLayout.close('west');
    }
  },
  render() {
    this.mainLayout = $('#container').layout({
      west: {
        onopen_start() {
          return false;
        },
        size: 290,
        minSize: 290,
        resizable: false,
        spacing_open: 3,
        spacing_closed: 15,
        initClosed: true,
        initHidden: User.get('readonly'),
        slidable: false,
        togglerAlign_open: 'top',
        togglerAlign_closed: 'top',
        togglerLength_open: 0,
        togglerLength_closed: -1,
        fxName: 'none',
        autoResize: false,
      },
      east: {
        size: 330,
        minSize: 330,
        resizable: false,
        spacing_open: 3,
        spacing_closed: 15,
        fxName: 'none',
        slidable: false,
        togglerAlign_open: 'top',
        togglerAlign_closed: 'top',
        togglerLength_open: 0,
        togglerLength_closed: -1,
        autoResize: false,
      },
      center: {
        slidable: false,
        autoResize: false,
        fxName: 'none',
      },
    });

    this.centerLayout = $('#main').layout({
      center: {
        onresize(name, el, state) { // eslint-disable-line no-unused-vars
          AIS.trigger('layout:center:resize', state.innerHeight, state.innerWidth);
        },
        autoResize: false,
        fxName: 'none',
        slidable: false,
      },
      west: {
        initHidden: AIS.featureEnabled('disable_pano'),
        size: '0.4',
        slidable: false,
        fxName: 'none',
        initClosed: true,
        spacing_open: 8,
        spacing_closed: 15,
        togglerAlign_open: 'top',
        togglerAlign_closed: 'top',
        togglerLength_open: 0,
        togglerLength_closed: -1,
        autoResize: false,
        onopen() {
          if (AIS.pano1.get('url')) {
            AIS.panoPanel.updatePermalink();
            AIS.pano1.set('visible', true);
          }
          if (AIS.pano2.get('url')) {
            AIS.pano2.set('visible', true);
          }
        },
        onclose() {
          AIS.router.navigate('');
          AIS.pano1.set('visible', false);
          AIS.pano2.set('visible', false);
        },
      },
    });

    return this;
  },
});
