import * as actionTypes from 'js/constants/actionTypes';
import AIS from 'js/AIS';
import OpenLayers from 'lib/OpenLayers-2.12/OpenLayers.debug';

const mapMiddleware = () => next => (action) => {
  if (action.type === actionTypes.MAP_CENTER) {
    AIS.trigger('map:center', action.lon, action.lat, action.zoom);
  } else if (action.type === actionTypes.MAP_SHOW_PANO) {
    AIS.trigger(
      'map:click',
      new OpenLayers.LonLat(action.lon, action.lat),
    );
  } else if (action.type === actionTypes.MAP_OPEN_POPUP_FOR_ID) {
    const layer = AIS.workset.find(m => m.idSet.has(action.featureId));
    if (!layer) {
      // если слой сейчас не добавлен, то попап показать невозможно
      console.warn('Невозможно отобразить объект, найденный поиском на скрытом слое'); // eslint-disable-line no-console
      return;
    }
    const model = layer.getCollection().get(action.featureId);
    AIS.trigger('select_model', model);
  }

  next(action);
};

export default mapMiddleware;
