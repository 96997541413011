import Mousetrap from 'mousetrap';
import 'mousetrap/plugins/global-bind/mousetrap-global-bind';
import tippy from 'tippy.js';
import AIS from 'js/AIS';
import { layoutChangeTab } from 'js/actions/layoutActions';
import * as tabs from 'js/constants/tabs';
import showHotkeysModal from 'js/view/modal/HotkeysHelpModal';
import englishToRussianLayoutMapping from 'js/data/LayoutMapping';


/**
 * Получить вариант хоткея в русской раскладке.
 *
 * Заменяем клавиши на соответствующие им в русской раскладке. Некоторые сочетания могут не измениться,
 * в этом случае вернем исходный переданный хотекй.
 *
 * @param {String} hotkey хоткей, как строка
 * @returns {String} хоткей с клавишами, измененными по соответствию раскладок, если это возможно
 */
const convertHotkeyToRussianLayout = (hotkey) => {
  const splitted = hotkey.split('+');
  const remapped = splitted.map(key => (
    (key.toLowerCase() in englishToRussianLayoutMapping) ? englishToRussianLayoutMapping[key.toLowerCase()] : key));
  return remapped.join('+');
};

/**
 * Сделать клик по элементу, если он существует.
 * @param {String} selector css-селектор
 */
const clickOnElementIfPresent = (selector) => {
  const element = document.querySelector(selector);
  if (element != null) {
    element.click();
  }
};

/**
 * Зарегистрировать новый хоткей.
 * @param {String} hotkeyName сочетание клавиш, как строка
 * @param {boolean} bindInputs хоткей работает даже когда активно текстовое поле
 * @param {CallableFunction} callback функция, которая будет вызвана при нажатии хоткея
 * @param {String} action действие, которое необходимо обработать - keypress|keydown|keyup.
 *  Для дефолтного значения может быть undefined.
 */
const registerHotkey = (hotkeyName, bindInputs, callback, action) => {
  if (callback == null) {
    throw new Error(`Hotkey registration failed. ${hotkeyName} should have callback.`);
  }
  const hotkeyMappedToRussian = convertHotkeyToRussianLayout(hotkeyName);
  const bindMethod = bindInputs ? Mousetrap.bindGlobal : Mousetrap.bind;
  bindMethod(
    hotkeyMappedToRussian === hotkeyName ? hotkeyName : [hotkeyName, hotkeyMappedToRussian],
    callback,
    action,
  );
};

/**
 * Зарегистрировать несколько хоткеев.
 * @param {Object[]} hotkeys объекты с описанием хоткеев
 */
const registerHotkeys = (hotkeys) => {
  Object.entries(hotkeys).forEach(
    ([hotkeyName, { bindInputs, callback, action }]) => registerHotkey(hotkeyName, bindInputs, callback, action),
  );
};

/**
 * Удалить зарегистрированный хоткей.
 * @param {String} hotkeyName строка, переданная при регистрации хоткея
 * @param {String} action экшен, переданный при регистрации хоткея
 */
const unregisterHotkey = (hotkeyName, action) => {
  const hotkeyMappedToRussian = convertHotkeyToRussianLayout(hotkeyName);
  Mousetrap.unbind(hotkeyName, action);
  if (hotkeyName !== hotkeyMappedToRussian) {
    Mousetrap.unbind(hotkeyMappedToRussian);
  }
};

/**
 * Удалить несколько зарегистрированных хоткеев.
 * @param {Object[]} hotkeys объекты с описанием хоткеев
 */
const unregisterHotkeys = (hotkeys) => {
  Object.entries(hotkeys).forEach(([hotkeyName, { action }]) => unregisterHotkey(hotkeyName, action));
};

/**
 * Сделать контекст временно неактивным.
 *
 * Отключаем хоткеи контекста. Если он был эксклюзивным, активируем все контексты, которые он временно отключил
 * на время своей работы.
 *
 * @param {String} contextName имя деактивируемого контекста
 */
const pauseContext = (contextName) => {
  const context = AIS.hotkeyContexts[contextName];
  unregisterHotkeys(context.keys);
  context.paused = true;
  if (context.exclusive === true) {
    Object.entries(AIS.hotkeyContexts)
      .filter(([name, { enabled, paused }]) => (
        name !== contextName
        && enabled === true
        && paused === true
      ))
      .forEach(([name, { keys }]) => {
        registerHotkeys(keys);
        AIS.hotkeyContexts[name].paused = false;
      });
  }
};


/**
 * Активировать контекст.
 *
 * В случае, если контекст эксклюзивный, временно деактивируем все остальные контексты, кроме глобальных.
 *
 * @param {String} contextName имя активируемого контекста
 */
const startContext = (contextName) => {
  const exclusives = Object.keys(AIS.hotkeyContexts)
    .filter(context => (
      AIS.hotkeyContexts[context].exclusive === true
      && AIS.hotkeyContexts[context].paused !== true
      && AIS.hotkeyContexts[context].enabled === true
    ));
  if (exclusives.length !== 0) {
    throw new Error(`Exclusive context ${exclusives[0]} is started, can't start another context unless exclusive is deactivated`);
  }
  if (AIS.hotkeyContexts[contextName].exclusive === true) {
    // деактивируем все, кроме глобальных контекстов
    Object.keys(AIS.hotkeyContexts)
      .filter(context => (AIS.hotkeyContexts[context].global !== true))
      .forEach((context) => {
        unregisterHotkeys(AIS.hotkeyContexts[context].keys);
        AIS.hotkeyContexts[context].paused = true;
      });
  }
  registerHotkeys(AIS.hotkeyContexts[contextName].keys);
  AIS.hotkeyContexts[contextName].paused = false;
};

/**
 * Включить контекст.
 * @param {String} contextName имя контекста
 */
export const enableContext = (contextName) => {
  if (!(contextName in AIS.hotkeyContexts)) {
    throw new Error(`Context ${contextName} is not known`);
  }
  if (AIS.hotkeyContexts[contextName].enabled !== true) {
    startContext(contextName);
    AIS.hotkeyContexts[contextName].enabled = true;
  }
};

/**
 * Выключить контекст.
 * @param {String} contextName имя контекста
 */
export const disableContext = (contextName) => {
  if (!(contextName in AIS.hotkeyContexts)) {
    throw new Error(`Context ${contextName} is not known`);
  }
  if (AIS.hotkeyContexts[contextName].enabled === true) {
    pauseContext(contextName);
    AIS.hotkeyContexts[contextName].enabled = false;
  }
};

/**
 * Получить элементы, к которым нужно прикрепить всплывающие подсказки.
 * @param {Object[]} hotkeys массив объектов горячих клавиш
 * @returns {Array.<Object[]>} массив массивов с элементами вида: селектор, элемент, горячая клавиша
 */
const getTooltips = hotkeys => hotkeys
  .map(({ context, key, tooltipElementSelectors }) => (tooltipElementSelectors || [])
    .map(selector => [
      selector,
      document.querySelector(selector),
      key,
      context,
    ])).flat();


/**
 * Объект, содержащий инстансы тултипов.
 */
const tooltipInstances = {};

/**
 * Показать все всплывающие подсказки.
 */
export const updateAllTooltips = () => {
  const allContexts = Object.keys(AIS.hotkeyContexts)
    .flatMap(context => (
      Object.keys(AIS.hotkeyContexts[context].keys).map(key => (
        {
          context,
          key,
          tooltipElementSelectors: AIS.hotkeyContexts[context].keys[key].tooltipElementSelectors,
        }
      ))));
  const allTooltips = getTooltips(allContexts);
  allTooltips
    .forEach(([selector, element, key, context]) => {
      const isActiveContext = (
        AIS.hotkeyContexts[context].enabled === true
        && AIS.hotkeyContexts[context].paused !== true
      );
      if (element == null) {
        if (tooltipInstances[selector] != null) {
          tooltipInstances[selector].hide();
        }
      } else {
        if (element._tippy == null) {
          const [tippyInstance] = tippy(selector, { content: key });
          tooltipInstances[selector] = tippyInstance;
        }
        if (AIS.allTooltipsShown === true && isActiveContext) {
          element._tippy.show();
        } else {
          element._tippy.hide();
        }
      }
    });
};

/**
 * Хоткеи редактора объектов.
 *
 * Хоткеи описываются в виде объекта, где ключ - это те клавиши, которые должны быть нажаты,
 * а значение - объект вида:
 *   {
 *     description: человекочитаемое описание,
 *     tooltipElementSelectors: селекторы элементов, к которым будут применены всплывающие подсказки,
 *       разрешаются по порядку - если элемент, описываемый первым селектором найден, то подсказка будет прицеплена к нему,
 *     callback: действие, которое должно произойти при нажатии хоткея,
 *     actions: опциональный ключ, определяет на какое именно из нажатий keypress/keydown/keyup привязывается действие
 *     bindInputs: опциональный ключ, хоткей должен работать даже когда активно текстовое поле
 *   }
 */
const editorHotkeys = {
  esc: {
    description: 'Прекратить редактирование',
    tooltipElementSelectors: ['.field-cancel-button:not([style=\'display: none;\'])', '.cancel-button'],
    callback: () => {
      clickOnElementIfPresent('.field-cancel-button:not([style=\'display: none;\']), .cancel-button');
    },
  },
  z: {
    description: 'Редактировать геометрию по карте',
    tooltipElementSelectors: [
      '#geojson-editor #from-map-button:not([disabled]):not(.active)',
      '#geojson-editor #modify-button:not([disabled]):not(.active)',
    ],
    callback: () => {
      clickOnElementIfPresent('#geojson-editor #from-map-button:not([disabled]), #geojson-editor #modify-button:not([disabled])');
    },
  },
  x: {
    description: 'Редактировать геометрию по двум панорамам',
    tooltipElementSelectors: ['#geojson-editor #triangulate-button:not([disabled]):not(.active)'],
    callback: () => {
      clickOnElementIfPresent('#geojson-editor #triangulate-button');
    },
  },
  c: {
    description: 'Редактировать геометрию в плоскости дороги',
    tooltipElementSelectors: ['#geojson-editor #road-triangulate-button:not([disabled]):not(.active)'],
    callback: () => {
      clickOnElementIfPresent('#geojson-editor #road-triangulate-button');
    },
  },
  v: {
    description: 'Скопировать геометрию',
    tooltipElementSelectors: ['#geojson-editor #copy-button:not([disabled]):not(.active)'],
    callback: () => {
      clickOnElementIfPresent('#geojson-editor #copy-button:not([disabled])');
    },
  },
  q: {
    description: 'Полигон: обычное занесение',
    tooltipElementSelectors: ['#geojson-editor #from-map-default-button:not([disabled]):not(.active)'],
    callback: () => {
      clickOnElementIfPresent('#geojson-editor #from-map-default-button:not([disabled])');
    },
  },
  w: {
    description: 'Полигон: занесение по оси и ширине',
    tooltipElementSelectors: ['#geojson-editor #from-map-axis-and-width-button:not([disabled]):not(.active)'],
    callback: () => {
      clickOnElementIfPresent('#geojson-editor #from-map-axis-and-width-button:not([disabled])');
    },
  },
  e: {
    description: 'Полигон: занесение примыкающего полигона',
    tooltipElementSelectors: ['#geojson-editor #adjacent-polygon-button:not([disabled]):not(.active)'],
    callback: () => {
      clickOnElementIfPresent('#geojson-editor #adjacent-polygon-button:not([disabled])');
    },
  },
  r: {
    description: 'Полигон: заполнение пространства',
    tooltipElementSelectors: ['#geojson-editor #paint-bucket-button:not([disabled]):not(.active)'],
    callback: () => {
      clickOnElementIfPresent('#geojson-editor #paint-bucket-button:not([disabled])');
    },
  },
  'ctrl+s': {
    description: 'Сохранить',
    tooltipElementSelectors: ['.save-button'],
    bindInputs: true,
    callback: () => {
      clickOnElementIfPresent('.save-button');
      return false;
    },
  },
  'ctrl+shift+s': {
    description: 'Сохранить и добавить',
    tooltipElementSelectors: ['.save-and-reopen-button'],
    bindInputs: true,
    callback: () => {
      clickOnElementIfPresent('.save-and-reopen-button');
      return false;
    },
  },
  'shift+space': {
    description: 'Редактирование геометрии: готово, сохранить геометрию',
    tooltipElementSelectors: ['.field-save-button'],
    callback: () => {
      clickOnElementIfPresent('.field-save-button');
      return false;
    },
  },
  'ctrl+alt+space': {
    description: 'Автоповорот синей панорамы',
    tooltipElementSelectors: ['#pano-adjust-button:not([disabled])'],
    callback: () => {
      clickOnElementIfPresent('#pano-adjust-button');
      return false;
    },
    action: 'keyup',
  },
  'alt+1': {
    description: 'Тип по ГОСТ',
    tooltipElementSelectors: ['#gost-type-button:not([disabled])'],
    callback: () => {
      clickOnElementIfPresent('#gost-type-button');
      return false;
    },
  },
  'alt+2': {
    description: 'Подтип типа по ГОСТ',
    tooltipElementSelectors: ['#gost-subtype-button:not([disabled])'],
    callback: () => {
      clickOnElementIfPresent('#gost-subtype-button');
      return false;
    },
  },
  'ctrl+a': {
    description: 'Добавить объект в последний измененный слой',
    callback: () => {
      if (AIS.lastSavedFeatureLayer) {
        AIS.trigger('editor:blank', AIS.getMetaByClassName(AIS.lastSavedFeatureLayer));
      }
      return false;
    },
  },
};

/**
 * Хоткеи для модальных окон.
 */
const modalHotkeys = {
  esc: {
    description: 'Закрыть модальное окно',
    tooltipElementSelectors: ['.modal.in .cancel-button'],
    callback: () => {
      clickOnElementIfPresent('.modal.in .cancel-button');
    },
  },
};

/**
 * Хоткеи переключения между вкладками.
 */
const tabHotkeys = {
  'ctrl+1': {
    description: 'Карта',
    tooltipElementSelectors: [`#${tabs.TAB_MAP}`],
    callback: () => {
      AIS.store.dispatch(layoutChangeTab(tabs.TAB_MAP));
      return false;
    },
  },
  'ctrl+2': {
    description: 'Список',
    tooltipElementSelectors: [`#${tabs.TAB_OUTER_LIST}`],
    callback: () => {
      AIS.store.dispatch(layoutChangeTab(tabs.TAB_OUTER_LIST));
      return false;
    },
  },
  'ctrl+3': {
    description: 'Дороги',
    tooltipElementSelectors: [`#${tabs.TAB_ROADS}`],
    callback: () => {
      AIS.store.dispatch(layoutChangeTab(tabs.TAB_ROADS));
      return false;
    },
  },
};

/**
 * Хоткей быстрой подсказки горячих клавиш.
 */
const globalHelpHotkeys = {
  f1: {
    description: 'Подписать горячие клавиши',
    callback: () => {
      AIS.allTooltipsShown = !AIS.allTooltipsShown;
      updateAllTooltips();
      return false;
    },
  },
};


/**
 * Контексты.
 *
 * Должны быть описаны в виде объекта, где ключи - это названия контекстов,
 * значения - это объекты вида:
 *  {
 *    keys: объекты с описанием горячих клавиш,
 *    exclusive: является ли контекст эксклюзивным,
 *    global: является ли контекст глобальным
 *  }
 */
const hotkeyContexts = {
  tabs: {
    keys: tabHotkeys,
  },
  modal: {
    keys: modalHotkeys,
    exclusive: true,
  },
  editor: {
    keys: editorHotkeys,
  },
  globalHelp: {
    keys: globalHelpHotkeys,
    global: true,
  },
};

/**
 * Хоткей, вызывающий список горячих клавиш.
 */
const questionMarkHotkeys = {
  '?': {
    description: 'Список горячих клавиш',
    callback: () => {
      showHotkeysModal();
      return false;
    },
  },
};

// добавляем все контексты на глобальный объект AIS
AIS.hotkeyContexts = {
  questionMark: { keys: questionMarkHotkeys },
  ...hotkeyContexts,
};
