import OpenLayers from 'lib/OpenLayers-2.12/OpenLayers.debug';


const gis = new OpenLayers.Layer.OSM('2GIS',
  // eslint-disable-next-line no-template-curly-in-string
  'https://tile0.maps.2gis.com/tiles?x=${x}&y=${y}&z=${z}&v=1.2', {
    transitionEffect: 'resize',
    isBaseLayer: true,
    numZoomLevels: 19,
    tileOptions: { crossOriginKeyword: null },
  });

export default [gis];
