import $ from 'jquery';
import _ from 'underscore';
import Backbone from 'js/backbone';


/**
 * Фильтр опор по ВОЛС.
 */
const OpticPoleFilter = Backbone.View.extend({
  className: 'panel',
  template: _.template($('#tmpl-optic-pole-filter').html()),
  events: {
    'click .enable-button': 'enable',
    'click .disable-button': 'disable',
  },
  /**
   * Функция, переключающая состояние фильтра.
   *
   * @param {boolean} isEnabled новое состояние (включен или выключен)
   */
  switchEnabledState(isEnabled) {
    AIS.opticPoleFilter.enabled(isEnabled);
    this.$el.find('.enable-button').prop('disabled', isEnabled);
    this.$el.find('.disable-button').prop('disabled', !isEnabled);
  },
  /**
   * Активировать фильтр.
   */
  enable() {
    this.switchEnabledState(true);
  },
  /**
   * Деактивировать фильтр.
   */
  disable() {
    this.switchEnabledState(false);
  },

  // eslint-disable-next-line valid-jsdoc
  /**
   * Отрендерить фильтр.
   */
  render() {
    this.$el.empty().append(this.template());
    return this;
  },
});

export default OpticPoleFilter;
