/**
 * Минимальный зум карты.
 * @type {Number}
 */
export const MAP_MIN_ZOOM = 11;

/**
 * Дефолтный зум карты при переходе к координатам.
 * @type {number}
 */
export const MAP_DEFAULT_ZOOM = 19;

/**
 * Расстояние на котором объект отображаются на панорамах.
 * @type {Number}
 */
export const PANO_DISPLAY_DISTANCE = 30;

/**
 * Расстояние, на котором исторические панорамы отображаются на панорамах.
 */
export const PANO_ARCHIVE_DISPLAY_DISTANCE = 20;

/**
 * Максимальное количество фич, которые можно редактировать одновременно.
 * Из-за ограничения на количество одновременных WebSocket'ов в Chrome (250 штук).
 * @type {Number}
 */
export const BATCH_EDIT_MAX = 200;

/**
 * Максимальный размер кэша для проецирования фич на панораму.
 * @type {Number}
 */
export const PROJECT_CACHE_MAX_SIZE = 2;

/**
 * Возможные значения модели обновления связей.
 * @type {{AUTO: string, AUTOIFNORELATIONS: string, MANUAL: string}}
 */
export const relationUpdateModels = {
  MANUAL: 'MANUAL',
  AUTO: 'AUTO',
  AUTOIFNORELATIONS: 'AUTOIFNORELATIONS',
};

/**
 * Возможные значения типа отношений слоёв связи.
 * @type {{MANYTOMANY: string, ONETOMANY: string}}
 */
export const relationModels = {
  ONETOMANY: 'ONETOMANY',
  MANYTOMANY: 'MANYTOMANY',
};
