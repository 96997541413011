import OpenLayers from 'lib/OpenLayers-2.12/OpenLayers.debug';


// Чтобы иметь возможность вызывать оригинальные функции, внутри нашей измененной реализации.
OpenLayers.Handler.OLPath = OpenLayers.Handler.Path;


/**
 * Реализация OpenLayers.Handler.Path с трейсингом (умеет заносить точки вдоль полигонов и полилиний).
 *
 * При занесении линии(полигон унаследован от нее, так что для него все работает аналогично),
 * можно зажать traceKey('shiftKey') и между текущей и предыдущей занесенной точкой будет найден
 * путь по полигону или полилинии, если таковые находились в непосредственной близости от этих двух
 * точек.
 *
 * @type {OpenLayers.Class}
 */
OpenLayers.Handler.Path = OpenLayers.Class(OpenLayers.Handler.OLPath, {

  /**
   * Зажимая freehandToggle следующая занесенная точка завершит занесение фичи.
   * По умолчанию 'shiftKey', но теперь он занят трейсингом.
   *
   * @type {Optional[String]}
   */
  freehandToggle: null,

  /**
   * Зажимая traceKey мы включаем занесение фичи трейсингом.
   *
   * @type {String}
   */
  traceKey: 'shiftKey',

  /**
   * Заносим точки трейсингом.
   *
   * @param {OpenLayers.Pixel} pixel пиксельная координата новой точки
   */
  addPoints(pixel) {
    const vertices = this.line.geometry.getVertices();

    if (vertices.length >= 2) {
      const lonlat = this.layer.getLonLatFromViewPortPx(pixel);

      $.ajax('/api/draw/tracing', {
        /**
         * Запрос на получение полилинии между текущей и предыдущей занесенными точками.
         * По границе ближайшего к этим точкам полигона или полилинии из видимых слоев.
         */
        async: false, // Потому что если делать это асинхронно в success this.layer может уже быть undefined
        data: JSON.stringify({
          point_one: AIS.transformReaderGeoJSON.write(vertices[vertices.length - 2]),
          point_two: AIS.transformReaderGeoJSON.write(new OpenLayers.Geometry.Point(lonlat.lon, lonlat.lat)),
          layers: AIS.workset.where({ visible: true }).map(classFilter => classFilter.getClassName()),
        }),
        method: 'POST',
        dataType: 'json',
        success: (line_geojson) => {
          const line = AIS.transformReaderGeoJSON.read(line_geojson, 'Geometry');
          let undo = true;

          // Выкидываем первую точку, она уже есть в нашей геометрии
          for (const point of line.getVertices().slice(1)) {
            this.addPoint(
              this.layer.getViewPortPxFromLonLat(
                new OpenLayers.LonLat(point.x, point.y),
              ),
            );

            // На момент вызова addPoints последняя точка геометрии это уже точка,
            // где мы кликнули по карте. Чтобы использовать уже существующие методы
            // мы делаем undo после добавления первой точки, потому что undo удаляет
            // предпоследнюю точку в полилинии и предпредпоследнюю точку в полигоне.
            if (undo) {
              this.undo();
              undo = false;
            }
          }
          // Последняя точка дублирует предпоследнюю, потому что это точка за
          // которую мы будем дальше редактировать нашу геометрию.
          this.addPoint(pixel);
        },
      });
    } else {
      this.addPoint(pixel);
    }
  },

  /**
   * Добавим вызов addPoints, если был зажат traceKey.
   *
   * @param  {Event} evt событие браузера
   * @returns {Boolean} пропускает событие дальше
   */
  up(evt) {
    if (this.mouseDown && (!this.lastUp || !this.lastUp.equals(evt.xy))) {
      if (this.stoppedDown && this.freehandMode(evt)) {
        if (this.persist) {
          this.destroyPersistedFeature();
        }
        this.removePoint();
        this.finalize();
      } else if (this.passesTolerance(this.lastDown, evt.xy,
        this.pixelTolerance)) {
        if (this.touch) {
          this.modifyFeature(evt.xy);
        }
        if (this.lastUp == null && this.persist) {
          this.destroyPersistedFeature();
        }

        if (evt[this.traceKey]) {
          this.addPoints(evt.xy);
        } else {
          this.addPoint(evt.xy);
        }

        this.lastUp = evt.xy;
        if (this.line.geometry.components.length === this.maxVertices + 1) {
          this.finishGeometry();
        }
      }
    }
    this.stoppedDown = this.stopDown;
    this.mouseDown = false;
    return !this.stopUp;
  },

  CLASS_NAME: 'OpenLayers.Handler.Path',
});


// Наследуем Polygon от нашего Path
OpenLayers.Handler.Polygon.prototype.__proto__ = OpenLayers.Handler.Path.prototype;

export default OpenLayers;
