import Backbone from 'js/backbone';
import ImageSelect from 'js/view/editor/ImageSelect';
import Field from 'js/view/editor/Field';
import GeometryField from 'js/view/editor/GeometryField';
import UploadManager from 'js/view/editor/UploadManager';


const FieldState = Backbone.Model.extend({
  idAttribute: 'fieldName',
  defaults: {
    multi: false, // показывать кнопку "Обновить во всех выбранных объектах"
    differs: false,
    value: null,
    dirty: false, // было ли поле изменено
    copy: false, // обновить во всех выбранных объектах
  },
  isEmpty() {
    return !this.get('value');
  },
  setDirty() {
    this.set('dirty', true);
  },
});

/**
 * Компонент-обертка для поля.
 */
const FieldWrapper = Backbone.View.extend({
  tagName: 'div',
  initialize: function () {
    this.fabricMap = {
      select: this.createSelectField,
      boolean: this.createBooleanField,
      integer: this.createIntegerField,
      float: this.createFloatField,
      date: this.createDateField,
      oneToManyManualRelations: this.createOneToManyManualRelationsField,
    };
    this._state = new FieldState({
      fieldName: this.options.property.getName(),
    });
    this._state.wrapper = this;
  },
  /**
   * Создать вьюху Field - селект на основе переданной модели Property.
   * @param {Property.Select} property модель
   * @returns {Field.Select} вьюха
   */
  createSelectField(property) {
    return new Field.Select({
      model: this.model,
      fieldState: this._state,
      inheritedValue: this.options.inheritedValue,
      blank: true,
      property,
      index: this.options.index,
    });
  },
  /**
   * Создать вьюху Field - флаг на основе переданной модели Property.
   * @param {Property.Boolean} property модель
   * @returns {Field.Boolean} вьюха
   */
  createBooleanField(property) {
    return new Field.Boolean({
      model: this.model,
      fieldState: this._state,
      inheritedValue: this.options.inheritedValue,
      property,
    });
  },
  /**
   * Создать вьюху Field - целое число на основе переданной модели Property.
   * @param {Property.Integer} property модель
   * @returns {Field.Integer} вьюха
   */
  createIntegerField(property) {
    return new Field.Integer({
      model: this.model,
      fieldState: this._state,
      inheritedValue: this.options.inheritedValue,
      property,
      index: this.options.index,
    });
  },
  /**
   * Создать вьюху Field - число с плавающей запятой на основе переданной модели Property.
   * @param {Property.Float} property модель
   * @returns {Field.Float} вьюха
   */
  createFloatField(property) {
    return new Field.Float({
      model: this.model,
      fieldState: this._state,
      inheritedValue: this.options.inheritedValue,
      property,
      index: this.options.index,
    });
  },
  /**
   * Создать вьюху Field - дата на основе переданной модели Property.
   * @param {Property.Date} property модель
   * @returns {Field.Date} вьюха
   */
  createDateField(property) {
    return new Field.Date({
      model: this.model,
      fieldState: this._state,
      inheritedValue: this.options.inheritedValue,
      property,
      index: this.options.index,
    });
  },
  /**
   * Создать вьюху Field - ручные связи на основе переданной модели Property.
   * @param {Property.OneToManyManualRelations} property модель
   * @returns {Field.OneToManyManualRelations} вьюха
   */
  createOneToManyManualRelationsField(property) {
    return new Field.OneToManyManualRelations({
      model: this.model,
      fieldState: this._state,
      inheritedValue: this.options.inheritedValue,
      property,
      index: this.options.index,
    });
  },
  /**
   * Создать вьюху Field на основе переданной модели Property.
   * @param {Property.Generic} property модель
   * @param {Object} fieldState состояние поля
   * @returns {Object} вьюха
   */
  createField(property, fieldState) {
    const fieldType = property.get('type');
    const fieldName = property.get('field');

    let field;
    if (fieldType in this.fabricMap) {
      const f = this.fabricMap[fieldType];
      field = f.call(this, property);
    }
    if (fieldType === 'special') {
      const meta_class = this.model.getMeta().get('class');
      if (['sign', 'sign_project', 'sign_run'].includes(meta_class)
                          && (fieldName === property.typeField || fieldName === property.typeSubField)) {
        field = new ImageSelect.Subtype({
          model: this.model,
          fieldState,
          blank: true,
          property,
          header: 'Выберите знак',
          extraClass: 'road-sign',
          modalWindowClass: 'road-sign-modal-wide',
          buttonId: fieldName === property.typeField ? 'gost-type-button' : 'gost-subtype-button',
        });
      }
      if (meta_class === 'roadsign-kz' && fieldName === 'gost') {
        field = new ImageSelect.Generic({
          model: this.model,
          fieldState,
          blank: true,
          property,
          header: 'Выберите знак',
          extraClass: 'road-sign',
          modalWindowClass: 'road-sign-modal-wide',
          buttonId: 'gost-type-button',
        });
      }
      if (['road_marking_symbol', 'road_marking_symbol_project', 'road_marking_symbol_run'].includes(meta_class)
                          && (fieldName === property.typeField || fieldName === property.typeSubField)) {
        field = new ImageSelect.Subtype({
          model: this.model,
          fieldState,
          blank: true,
          property,
          header: 'Выберите тип разметки',
          extraClass: 'road-sign',
          buttonId: fieldName === property.typeField ? 'gost-type-button' : 'gost-subtype-button',
        });
      }
      if (['road_marking_line', 'road_marking_line_project', 'road_marking_line_run'].includes(meta_class)
                      && fieldName === 'type') {
        field = new ImageSelect.Generic({
          model: this.model,
          fieldState,
          blank: true,
          property,
          header: 'Выберите тип разметки',
          buttonId: 'gost-type-button',
        });
      }
      if (['road_marking_polygon', 'road_marking_polygon_project', 'road_marking_polygon_run'].includes(meta_class)
                      && fieldName === 'type') {
        field = new ImageSelect.Generic({
          model: this.model,
          fieldState,
          blank: true,
          property,
          header: 'Выберите тип разметки',
          buttonId: 'gost-type-button',
        });
      }
      if (['semaforo', 'semaforo_project', 'semaforo_run'].includes(meta_class)
                      && fieldName === 'shape') {
        field = new ImageSelect.Generic({
          model: this.model,
          fieldState,
          blank: true,
          property,
          header: 'Выберите тип светофора',
          modalWindowClass: 'road-sign-modal-wide',
          buttonId: 'gost-type-button',
        });
      }
    }

    if (fieldType === 'geometry') {
      field = new GeometryField.Generic({
        model: this.model,
        fieldState,
        property,
      });
    }
    if (fieldType === 'measure') {
      field = new GeometryField.Measure({
        model: this.model,
        fieldState,
        inheritedValue: this.options.inheritedValue,
        property,
      });
    }
    if (fieldType === 'highness') {
      field = new GeometryField.Highness({
        model: this.model,
        fieldState,
        inheritedValue: this.options.inheritedValue,
        property,
      });
    }
    if (fieldType === 'azimuth') {
      field = new GeometryField.Azimuth({
        model: this.model,
        fieldState,
        inheritedValue: this.options.inheritedValue,
        property,
      });
    }

    if (fieldType === 'attachments') {
      field = new UploadManager({
        model: this.model,
        fieldState,
        property,
      });
    }

    if (!field) {
      field = new Field.Text({
        model: this.model,
        fieldState,
        inheritedValue: this.options.inheritedValue,
        property,
        index: this.options.index,
      });
    }
    return field;
  },
  /**
   * Отрендерить компонент.
   * @returns {string} строка с отрендереным html
   */
  render() {
    this.field = this.createField(this.options.property, this._state, this.options.index);
    const html = `
        <label for="field-${this.options.index}">
            <span class="edit-name">
                ${this.options.property.get('name')}
            </span>
        </label>
        <div class="edit-value"></div>
    `;
    this.$el.html(html);
    this.$el.find('.edit-value').append(this.field.render().el);
    return this;
  },
  lock() {
    if ('lock' in this.field) this.field.lock();
  },
  unlock() {
    if ('unlock' in this.field) this.field.unlock();
  },
  getFieldState() {
    return this._state;
  },
  commitValue() {
    if ('commitValue' in this.field) {
      this.field.commitValue();
    }
  },
});

export default FieldWrapper;
