import saveAs from 'file-saver';
import OpenLayers from 'lib/OpenLayers-2.12/OpenLayers.debug';


/**
* Экспорт в KML.
*/
class KMLWriter {
  /*
  * Конструктор.
  *
  * @param {Object} data      данные для экспорта
  * @param {string} filename  имя файла в который сохраняются результаты
  */
  constructor(data, filename) {
    this.olKML = new OpenLayers.Format.KML({
      internalProjection: 'EPSG:900913',
      externalProjection: 'EPSG:4326',
    });
    this.data = data;
    this.filename = filename;
  }

  /*
  * Экспортирует данные в KML и инициирует скачивание файла.
  */
  write() {
    const features = this.data.map(item => ({ ...item.self.feature, layerName: item.class }));
    const distinctLayers = new Set(...[features.map(feature => feature.layerName)]);
    const ns = 'http://earth.google.com/kml/2.0';
    const xml = document.implementation.createDocument('', '');
    const kml = document.createElementNS(ns, 'kml');
    const kml_document = document.createElementNS(ns, 'Document');

    xml.appendChild(kml);
    kml.appendChild(kml_document);

    distinctLayers.forEach((layerName) => {
      const folder = this.olKML.createFolderXML();
      folder.getElementsByTagName('name')[0].childNodes[0].nodeValue = layerName;
      folder.getElementsByTagName('description')[0].childNodes[0].nodeValue = '';

      features
        .filter(feature => feature.layerName === layerName)
        .filter(feature => !Number.isNaN(feature.geometry.x))
        .forEach((feature) => {
          const placemark = this.olKML.createPlacemarkXML(feature);
          placemark.getElementsByTagName('name')[0].childNodes[0].nodeValue = `#${feature.model.get('id')}`;
          placemark.getElementsByTagName('description')[0].childNodes[0].nodeValue = '';
          folder.appendChild(placemark);
        });

      kml_document.appendChild(folder);
    });

    const serialized = new XMLSerializer().serializeToString(xml);
    const out = new Blob([serialized], { type: 'application/vnd.google-earth.kml+xml' });

    saveAs(out, this.filename || 'data.kml');
  }
}

export default KMLWriter;
