import OpenLayers from 'lib/OpenLayers-2.12/OpenLayers.debug';
import { get_pixels_int } from 'js/utils';


/**
 *  Список всех возможных относительных позиций попапа.
 *
 * Относительная позиция - это позиция относительно "якоря" (anchor), от которого был вызван этот попап.
 * Относительная позиция бывает одной из:
 *  - 'tr' (top-right == сверху справа)
 *  - 'br' (bottom-right == снизу справа)
 *  - 'bl' (bottom-left == снизу слева)
 *  - 'tl' (top left == сверху слева)
 *
 * @type {string[]}
 */
const relative_positions = ['tr', 'br', 'bl', 'tl'];


/**
 * Класс, унаследованный от OpenLayers.Popup.FramedCloud.
 *
 * Добавляет стрелочки, позволяющие менять позицию попапа.
 */
export default OpenLayers.Class(OpenLayers.Popup.FramedCloud, {

  /**
   * Конструктор класса.
   *
   * Все параметры передаются в конструктор родительского класса.
   * Описание параметров скопировано из документации к родительским классам.
   *
   * @param {String} id -  a unique identifier for this popup.  If null is passed
   *                       an identifier will be automatically generated.
   * @param {OpenLayers.LonLat} lonlat -  The position on the map the popup will
   *                                      be shown.
   * @param {OpenLayers.Size} contentSize - The size of the content.
   * @param {String} contentHTML - An HTML string to display inside the
   *                                 popup.
   * @param {Object} anchor - Object to which we'll anchor the popup. Must expose
   *                          a 'size' (<OpenLayers.Size>) and 'offset' (<OpenLayers.Pixel>)
   *                          (Note that this is generally an <OpenLayers.Icon>).
   * @param {Boolean} closeBox - Whether to display a close box inside
   *                             the popup.
   * @param {Function} closeBoxCallback - Function to be called on closeBox click.
   */
  // eslint-disable-next-line no-unused-vars
  initialize: function (id, lonlat, contentSize, contentHTML, anchor, closeBox, closeBoxCallback) {
    OpenLayers.Popup.FramedCloud.prototype.initialize.apply(this, arguments);
    if (closeBox) {
      // создаём переключатели только если была создана кнопка закрытия,
      // т. к. позиция переключателей считается относительно него.
      this.addSwitchers();
    }
  },


  /**
   *  Рассчитываем позиции переключателей внутри родительского div'а.
   *
   *  Идея в том, что каждый переключатель такого же размера, как и кнопка закрытия (17*17)
   *      и каждый из них на 17 пикселей ниже предыдущего.
   *
   * @private
   */
  _updateSwitchersPositions() {
    if (!this.switchDivs) {
      // если переключателей нет, то обновлять их позиции не надо.
      return;
    }
    let shift = 0;

    const close_div_right = get_pixels_int(this.closeDiv.style.right);
    const close_div_top = get_pixels_int(this.closeDiv.style.top);

    for (const position of relative_positions) {
      shift += 17; // сдвигаем каждый переключатель на 17 пикселей ниже предыдущего

      const switchDiv = this.switchDivs[position];
      switchDiv.style.right = `${close_div_right.toString()}px`;
      switchDiv.style.top = `${(close_div_top + shift).toString()}px`;
    }
  },


  /**
   * Переключаем относительную позицию (relativePosition) попапа.
   *
   * Относительная позиция - это позиция относительно "якоря" (anchor), от которого был вызван этот попап.
   * Относительная позиция бывает одной из:
   *  - 'tr' (top-right == сверху справа)
   *  - 'br' (bottom-right == снизу справа)
   *  - 'bl' (bottom-left == снизу слева)
   *  - 'tl' (top left == сверху слева)
   *
   * @param {String} position - относительная позиция, одна из: 'tr', 'br', 'bl', 'tl'
   */
  switchPosition(position) {
    this.relativePosition = position;

    const px = this.map.getLayerPxFromLonLat(this.lonlat);
    const newPx = this.calculateNewPx(px);

    const newArguments = new Array(newPx);
    OpenLayers.Popup.prototype.moveTo.apply(this, newArguments);

    this.updateRelativePosition();
  },


  /**
   * Создаём переключатели позиции.
   */
  addSwitchers() {
    this.switchDivs = {};

    let rotation = 45;

    for (const position of relative_positions) {
      const switchDiv = OpenLayers.Util.createDiv(
        `${this.id}_${position}`, null, { w: 17, h: 17 },
      );
      switchDiv.innerHTML = `<i class="icon-arrow-up" style="transform: rotate(${rotation}deg)"></i>`;
      switchDiv.style.zIndex = 1;
      switchDiv.style.cursor = 'pointer';

      this.groupDiv.appendChild(switchDiv);
      this.switchDivs[position] = switchDiv;

      rotation += 90;

      /**
       *  Обработчик клика по переключателю. Вызывает метод, переключающий позицию попапа.
       *
       * @param {Event} e - событие клика или прикосновения ("click" или "touchend").
       */
      const switchDivClick = (e) => {
        this.switchPosition(position);
        OpenLayers.Event.stop(e);
      };


      OpenLayers.Event.observe(switchDiv, 'touchend',
        OpenLayers.Function.bindAsEventListener(switchDivClick, this));
      OpenLayers.Event.observe(switchDiv, 'click',
        OpenLayers.Function.bindAsEventListener(switchDivClick, this));
    }
    this._updateSwitchersPositions();
  },


  /**
   *  Переопределяем метод, обновляющий относительную позицию попапа,
   *      т. к. нам ещё нужно подвинуть переключатели.
   */
  updateRelativePosition() {
    OpenLayers.Popup.FramedCloud.prototype.updateRelativePosition.apply(this);
    if (this.switchDivs) {
      this._updateSwitchersPositions();
    }
  },


  CLASS_NAME: 'SwitchablePopup',
});
