import _ from 'underscore';
import Field from 'js/view/editor/Field';
import ImageSelectModal from 'js/view/ImageSelectModal';
import SubtypeSelectModal from 'js/view/SubtypeSelectModal';


const ImageSelect = {};

ImageSelect.Generic = Field.Select.extend({
  events: {
    'click .sign-select': 'iconWindow',
    'click .copy-switch': 'copySwitch',
  },
  modalClass: ImageSelectModal,
  cancel() {
    if (this.modal) {
      this.modal.remove();
      this.modal = null;
    }
  },
  updateValue() {
    const res = Field.Select.prototype.updateValue.apply(this, arguments);
    const icon = this.getIcon();
    this.$el.find('#current').attr('src', icon);
    return res;
  },
  select(signCode) {
    this._value = signCode;

    const fieldName = this.options.property.getName();
    this.model.set(fieldName, signCode);

    this.options.fieldState.set('dirty', true);
    this.updateValue();

    this.cancel();
  },
  getValue() {
    return this._value;
  },
  _iconWindow(options) {
    const { modalClass } = this;
    const optionsMerged = _.extend({}, this.options, options);
    this.cancel();
    this.modal = new modalClass(optionsMerged); // eslint-disable-line new-cap
  },
  iconWindow() {
    return this._iconWindow({
      callback: _.bind(this.select, this),
    });
  },
  getIcon() {
    const fieldName = this.options.property.getName();
    return this.options.property.find(
      this.model.get(fieldName),
    ).icon || '/icon/other/unknown.png';
  },
  content() {
    return `<img class="field-icon" id="current" src="${
      this.getIcon()
    }"><br><button class="btn sign-select" id="${this.options.buttonId}">`
                    + 'Выбрать <span class="caret"></span>'
                    + `</button> ${
                      this.copyButton()}</div>`;
  },
  render() {
    const fieldName = this.options.property.getName();
    const html = `<div class="dropdown image-field">${this.content()}</div>`;

    this.$el.html(html);
    this.$el.find('.input').val(this.model.get(fieldName));

    return this;
  },
  lock() {
    this.$el.find('button').prop('disabled', true);
  },
  unlock() {
    this.$el.find('button').prop('disabled', false);
  },
});


ImageSelect.Subtype = ImageSelect.Generic.extend({
  modalClass: SubtypeSelectModal,
  iconWindow() {
    const { property } = this.options;
    const fieldName = property.getName();
    return this._iconWindow({
      callback: _.bind(this.select, this),
      type: (fieldName === property.typeSubField) ? this.model.get(property.typeField) : null,
    });
  },
  content() {
    const { property } = this.options;
    const fieldName = property.getName();
    if (fieldName === property.typeSubField
                    && !this.options.property.hasSubtypes(this.model.get(property.typeField))) {
      return 'Не предусмотрено';
    }
    return ImageSelect.Generic.prototype.content.apply(this, arguments);
  },
  updateValue() {
    const res = ImageSelect.Generic.prototype.updateValue.apply(this, arguments);
    this.render();
    return res;
  },
  getIcon() {
    const { property } = this.options;
    const fieldName = property.getName();
    const { typeField } = property;
    if (fieldName === typeField) {
      return this.options.property.getIcon(this.model.get(typeField), null, true);
    }
    return this.options.property.getIcon(this.model.get(typeField), this.model.get(property.typeSubField), true);
  },
  select(signCode) {
    const { property } = this.options;
    const { typeSubField } = property;
    ImageSelect.Generic.prototype.select.call(this, signCode);
    if (this.options.property.getName() === property.typeField) {
      const subtype_field = this.options.fieldState.collection.get(typeSubField).wrapper.field;
      if (this.options.property.hasSubtypes(signCode)) {
        if (!this.options.property.hasSubtype(signCode, this.model.get(typeSubField))) {
          subtype_field.select(null);
        }
        subtype_field.iconWindow();
      } else {
        subtype_field.select(null);
      }
    }
  },
});

export default ImageSelect;
