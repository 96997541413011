import { LAYOUT_CHANGE_TAB } from 'js/constants/actionTypes';
import * as tabType from 'js/constants/tabs';

const initialState = {
  theme: 'dark',
  activeTab: tabType.TAB_MAP,
};

const layoutReducer = (state = initialState, action) => {
  switch (action.type) {
    case LAYOUT_CHANGE_TAB:
      return {
        ...state,
        activeTab: action.activeTab,
      };
    default:
      return state;
  }
};

export default layoutReducer;
