import $ from 'jquery';
import _ from 'underscore';
import OpenLayers from 'lib/OpenLayers-2.12/OpenLayers.debug';
import Backbone from 'js/backbone';
import AIS from 'js/AIS';


export default Backbone.View.extend({
  className: 'panel',
  template: _.template($('#tmpl-region-filter').html()),
  events: {
    'click #draw-button': 'startDrawing',
    'click #clear-button': 'clear',
  },
  /**
   * Установка фильтра по региону.
   * Отключаем кнопку "очистить", если регион не выбран. Изменяем глобальный фильтр видимости в соответствии
   * с переданным значением.
   * @param {string} [regionGeom] геометрия выбранного региона
   */
  setRegionFilter(regionGeom) {
    AIS.regionFilter.setRegionFilter(regionGeom);
    this.$el.find('#clear-button').prop('disabled', _.isNull(regionGeom));
  },
  startDrawing() {
    if (this.feature) this.feature.destroy();

    AIS.map.editorDrawBegin(AIS.map.canvas, OpenLayers.Handler.Polygon);
    AIS.on('map:featureadded', this.done, this);
  },
  /**
   * Колбек,  вызываемый после выбора региона на карте.
   * @param {OpenLayers.Feature} feature    регион (объект с геометрией)
   */
  done(feature) {
    this.feature = feature;
    AIS.off('map:featureadded', this.done, this);

    const clone = feature.clone();
    clone.geometry.transform('EPSG:900913', 'EPSG:4326');
    this.setRegionFilter(clone.geometry.toString());
  },
  clear() {
    if (this.feature) this.feature.destroy();
    this.setRegionFilter();
  },
  render() {
    this.$el.empty().append(this.template());
    return this;
  },
});
