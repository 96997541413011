import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { AutoComplete, Icon } from 'antd';
import { SearchSuggestionItem } from 'js/api';
import { SEARCH_TYPE_AIS, SEARCH_TYPE_GEOCODING, SEARCH_TYPE_COORDINATES } from 'js/constants/searchTypes';

import styles from './style.less';


const { OptGroup, Option } = AutoComplete;


const searchTypeToDescription = {
  [SEARCH_TYPE_AIS]: {
    text: 'Поиск по базе АИС',
    icon: <Icon type="database" theme="filled" />,
  },
  [SEARCH_TYPE_GEOCODING]: {
    text: 'Поиск по адресу',
    icon: <Icon type="compass" />,
  },
  [SEARCH_TYPE_COORDINATES]: {
    text: 'Перейти к координатам',
    icon: <Icon type="environment" />,
  },
};


const SearchBox = (props) => {
  const { suggestions, onQueryUpdate, onSuggestionSelect } = props;

  const [isFocused, setIsFocused] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const suggestionsCount = Object.values(suggestions).reduce((count, items) => count + items.length, 0);

  const children = Object.entries(suggestions).map(([groupType, items]) => {
    if (!items.length) {
      return;
    }
    const { text: groupText, icon: groupIcon } = searchTypeToDescription[groupType];
    const groupItems = items.map((item) => {
      const { id, text, help } = item;
      const optionText = text + (` ${help}` || '');
      return (
        <Option
          key={`${groupType};${id}`}
          data={[groupType, id]}
          title={(optionText.length > 30) ? optionText : null}
        >
          {optionText}
        </Option>
      );
    });
    const groupLabel = (
      <span>{groupIcon} {groupText}</span>
    );
    return (
      <OptGroup key={groupType} label={groupLabel}>
        {groupItems}
      </OptGroup>
    );
  });

  if (suggestionsCount > 0 && !isVisible) {
    setIsVisible(true);
  }

  return (
    <AutoComplete
      id={styles.search_box}
      placeholder="Адрес или координаты..."
      onSearch={onQueryUpdate}
      onSelect={onSuggestionSelect}
      allowClear={true}
      // Список вариантов некрасиво захлопывается, если просто пропали варианты,
      // но если устанавливать свойство `open`, то он появляется и исчезает с красивой анимацией.
      // Поэтому приходится контролировать это свойство вручную и следить за фокусом в строке поиска.
      open={suggestionsCount > 0 && isFocused && isVisible}
      onFocus={() => setIsFocused(true)}
      onBlur={() => setIsFocused(false)}
      onDropdownVisibleChange={open => setIsVisible(open)}
    >
      {children}
    </AutoComplete>
  );
};

SearchBox.propTypes = {
  suggestions: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.instanceOf(SearchSuggestionItem))),
  onQueryUpdate: PropTypes.func.isRequired,
  onSuggestionSelect: PropTypes.func.isRequired,
};

export default SearchBox;
