import Backbone from 'js/backbone';
import api from 'js/api';
import AIS from 'js/AIS';
import { userLogin } from 'js/actions/userActions';


/** Модель пользователя.
 *
 * В одном сеансе может быть только один пользователь. */
const User = Backbone.Model.extend({
  defaults: {
    readonly: true,
    layers: null,
    username: null,
    password: null,
    token: null,
  },

  // eslint-disable-next-line valid-jsdoc
  /** Проверяем, нет ли у нас уже имени пользователя и пароля. */
  isEmpty() {
    return !(this.get('username') && this.get('password'));
  },

  // eslint-disable-next-line valid-jsdoc
  /** Проверяем, залогинен ли пользователь */
  isLogged() {
    return !(this.isEmpty()) && this.get('token');
  },

  // eslint-disable-next-line valid-jsdoc
  /** Авторизация пользователя.
   *
   * Если `check`, то проверяем не вошли ли мы уже на сервере.
   * Пытаемся войти через api с именем и паролем.
   */
  async login(check = true) {
    // eslint-disable-next-line no-console
    console.assert(!this.isLogged(), 'Вход уже произведен');
    let response = null;
    if (check) {
      try {
        response = await api.checkLogin();
      } catch (error) {
        response = null;
      }
    }
    if (response === null) {
      if (this.isEmpty()) {
        return false;
      }
      try {
        response = await api.login(this.get('username'), this.get('password'));
      } catch (error) {
        return false;
      }
    }
    // eslint-disable-next-line no-console
    console.assert(response !== null, 'Неожиданная ошибка: сервер не вернул информацию о входе');
    api.token_cookie_set(response.token);

    // сохраним информацию о пользователе ещё и в Redux
    AIS.store.dispatch(userLogin(
      response.username,
      response.token,
      response.readonly,
      response.layers,
      response.filters,
    ));

    this.set(response);
    this.trigger('onLoginSuccess');
    return true;
  },

  /** Дождаться входа.
   *
   * @returns {Promise} промис, который будет зарезолвлен после входа
   */
  waitForLogin() {
    return new Promise((resolve) => {
      this.once('onLoginSuccess', () => {
        resolve();
      });
    });
  },
});

export default new User();
