import { createStore, applyMiddleware } from 'redux';
import { syncCollections } from 'backbone-redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import {
  editorMiddleware, layoutMiddleware, mapMiddleware, searchMiddleware, userMiddleware,
} from 'js/middlewares';
import { layoutReducer, searchReducer, userReducer } from 'js/reducers';

/**
 * Создать хранилище Redux.
 * @param {Object} collectionMap маппинг ключей в хранилище на коллекции Backbone
 * @returns {Object} хранилище Redux
 */
export default (collectionMap) => {
  // Создаем хранилище Redux с пустым редьюсером,
  // потому что `backbone-redux` заменит редьюсер на свой.
  const store = createStore(() => {}, composeWithDevTools(
    applyMiddleware(
      mapMiddleware,
      layoutMiddleware,
      searchMiddleware,
      userMiddleware,
      editorMiddleware,
    ),
  ));

  // Используем автоматическую синхронизацию необходимых коллекций Backbone
  // с хранилищем Redux. Здесь же указываем наши собственные редюсеры для хранилища.
  syncCollections(
    {
      ...collectionMap,
    },
    store,
    {
      layout: layoutReducer,
      search: searchReducer,
      user: userReducer,
    },
  );

  return store;
};
