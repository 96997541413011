import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ConfigProvider, Layout } from 'antd';
import AIS from 'js/AIS';
import Toolbar from 'js/view/toolbar';


export default () => {
  ReactDOM.render(
    (
      <Provider store={AIS.store}>
        <ConfigProvider
          getPopupContainer={() => document.getElementById('react-header-app-body')}
        >
          <Layout>
            <Toolbar/>
          </Layout>
        </ConfigProvider>
      </Provider>
    ),
    document.getElementById('react-header-app'),
  );
};
