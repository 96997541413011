import _ from 'underscore';
import Backbone from 'js/backbone';
import AIS from 'js/AIS';
import ClassCollection from 'js/model/ClassCollection';

const FilterState = Backbone.Model.extend({
  defaults: {
    visible: true,
    value: null,
    condition: null,
  },
  isEmpty() {
    const value = this.get('value');
    const property = this.get('property');
    return property.filterIsEmpty(value);
  },
});

export default Backbone.Model.extend({
  defaults: {
    visible: true,
    filtered: false,
  },
  initialize: function () {
    this._filterStates = this.getMeta().getProperties().map(
      (property) => {
        const hash = property.toJSON();
        hash.property = property;
        return new FilterState(hash);
      },
    );

    const Collection = ClassCollection.extend({
      model: this.getMeta().getClass(),
      filter: this,
    });
    this._collection = new Collection();

    this.idSet = new Set();
  },
  /** Значения полей для фильтрации данного слоя.
   * @returns {Array} - значения полей фильтров
   */
  getFilterStates() {
    return this._filterStates;
  },
  /**
   * Сбросить все фильтры по полям.
   */
  resetFilters() {
    _.each(this._filterStates, (state) => {
      state.set('value', null);
      state.set('condition', null);
    });
  },
  getMeta() {
    return this.get('meta');
  },
  /** Получить тип геометрии.
   * @returns {string} - тип геометрии
   */
  getGeometryType() {
    return this.getMeta().getGeometryType();
  },
  /** Является ли этот слой видимым.
   * @returns {Boolean} - видимость слоя
   */
  getVisible() {
    return this.get('visible');
  },
  /** Получить имя класса.
   * @returns {string} - имя класса
   */
  getClassName() {
    return this.getMeta().getClassName();
  },
  getLayer() {
    if (this.layerView) return this.layerView.layer;
  },
  getCollection() {
    return this._collection;
  },
  // eslint-disable-next-line valid-jsdoc
  /** Получение условий для фильтра по полям этого слоя. */
  getFieldConditions() {
    return (
      this.getFilterStates()
        .filter(filterState => filterState.get('condition') != null)
        .map(filterState => filterState.get('condition'))
    ); // Выбираем поля, для которых есть условие
  },
  /** Добавляем фильтр по выбранным полям для слоя. */
  setLayerFieldsFilter() {
    const layerName = this.getClassName();
    const conditions = this.getFieldConditions();
    AIS.layersFieldsFilter.setLayerFieldsFilter(layerName, conditions);
  },
  /** Удаляем фильтр по выбранным полям для слоя. */
  removeLayerFieldsFilter() {
    AIS.layersFieldsFilter.removeLayerFieldsFilter(this.getClassName());
  },
  /**
   * Подписываемся на изменения полей, по которым будет производится фильтрация.
   * @param  {Function}  callback    колбек, вызываемый при изменении значения поля
   */
  addListener(callback) {
    this.getFilterStates().forEach(
      filterState => filterState.on('change:condition', callback, this),
    );
  },
  /** Отписываемся от изменений полей, по которым проводилась фильтрация. */
  removeListener() {
    this.removeLayerFieldsFilter();
    this.getFilterStates().forEach(
      filterState => filterState.stopListening(),
    );
  },
  // eslint-disable-next-line valid-jsdoc
  /** Получение полей c типом calculation. */
  getCalculationStates() {
    return this.getFilterStates().filter(
      filterState => filterState.get('type') === 'calculation',
    );
  },
  // eslint-disable-next-line valid-jsdoc
  /** Получение полей, по которым можно будет фильтровать фичи в слое. */
  getFilterable() {
    return this.getFilterStates().filter(
      filterState => (
        !filterState.get('no_filter')
                    && (filterState.get('type') !== 'calculation' || filterState.get('store'))
      ),
    );
  },

  /**
   * Колбек вызываемый когда с сервера приходят новые данные.
   * @param  {object}  message данные с сервера
   */
  onUpdate(message) {
    const layerName = this.getMeta().get('class');
    const rows = message.payload;

    if (rows && rows.length) {
      for (const row of rows) {
        if (row.remove) {
          this.idSet.delete(row.feature_id);
        } else if (row.data && row.data.layer === layerName) {
          this.idSet.add(row.feature_id);
        }
      }

      this.trigger('update');
    }
  },
  connect() {
    const cl = this.getCollection();

    AIS.featuresEvents.on('diff_update', this.onUpdate, this);

    cl.subscribe();

    this.addListener(this.setLayerFieldsFilter);
  },
  disconnect() {
    AIS.featuresEvents.off('diff_update', this.onUpdate, this);
    this.removeListener();
    const cl = this.getCollection();
    cl.unsubscribe();
    cl.reset();
  },
  /**
   * Получить количество отфильтрованных объектов.
   * @returns {number} количество отфильтрованных объектов
   */
  getLength() {
    return this.idSet.size;
  },
  /**
   * Получить отфильтрованные объекты.
   * @returns {Array} отфильтрованные объекты
   */
  getFiltered() {
    const collection = this.getCollection();
    const features = [];
    this.idSet.forEach((id) => {
      const model = collection.get(id);
      if (model) {
        features.push(model);
      }
    });
    return features;
  },
  serializeForGrid() {
    return _.map(this.getFiltered(), model => model.toRow());
  },
});
