import $ from 'jquery';
import _ from 'underscore';
import Backbone from 'js/backbone';


const ImageSelectModal = Backbone.View.extend({
  events: {
    'click .sign-icon': 'select',
    'click .cancel': 'remove',
  },
  windowTemplate: _.template($('#tmpl-sign-select-window-wrapper').html()),
  initialize: function () {
    Backbone.View.prototype.initialize.apply(this, arguments);
    this.setElement(document.getElementById('sign-select-window-wrapper'));
    this.render();
  },
  remove() {
    this.undelegateEvents();
    this.stopListening();
    this.$el.find('#sign-select-window').modal('hide');
  },
  _getImgHtml(value1, key1) {
    let icon;
    let name;

    if (value1 instanceof Object) {
      icon = value1.bigIcon || value1.icon;
      name = value1.name || key1;
    } else {
      icon = value1;
      name = key1;
    }
    const html = `<div id="${key1}" class="sign-icon">`
                + `<div class="icon"><img src="${icon}">`
                + `</div><div class="description">${name}</div></div>`;

    return html;
  },
  select(evt) {
    const signCode = $(evt.currentTarget).attr('id');
    this.options.callback(signCode);
  },
  content() {
    let options;
    if (this.options.property.get('flat')) {
      options = _.map(this.options.property.getData(),
        this._getImgHtml, this);
    } else {
      options = _.map(this.options.property.getData(), function (group) {
        const groupView = _.map(group.items, this._getImgHtml, this).join('');

        const html = `${'<div class="sector">'
                        + '<div class="sector-header">'}${group.name}</div>`
                        + `<div>${groupView}</div>`
                        + '</div>';

        return html;
      }, this);
    }
    return options.join('');
  },
  render() {
    this.$el.html(this.windowTemplate({
      name: this.options.header,
      extraClass: this.options.extraClass,
      modalWindowClass: this.options.modalWindowClass,
    }));
    this.$el.find('#sign-select-window').modal();

    this.$el.find('.modal-body').html(
      `<div>${this.content()}</div>`,
    );

    this.$el.find('img').one('load',
      function () {
        if ($(this).width() >= $(this).height()) {
          $(this).addClass('sign-horizontal');
        } else {
          $(this).addClass('sign-vertical');
        }
      }).each(function () {
      if (this.complete) {
        $(this).load();
      }
    });
    return this;
  },
});

export default ImageSelectModal;
