import OpenLayers from 'lib/OpenLayers-2.12/OpenLayers.debug';


const osm = new OpenLayers.Layer.OSM('Local',
  // eslint-disable-next-line no-template-curly-in-string
  '/basemap/${z}/${y}/${x}.png', {
    transitionEffect: 'resize',
    isBaseLayer: true,
    numZoomLevels: 22,
    serverResolutions: [
      156543.03390625,
      78271.516953125,
      39135.7584765625,
      19567.87923828125,
      9783.939619140625,
      4891.9698095703125,
      2445.9849047851562,
      1222.9924523925781,
      611.4962261962891,
      305.74811309814453,
      152.87405654907226,
      76.43702827453613,
      38.218514137268066,
      19.109257068634033,
      9.554628534317017,
      4.777314267158508,
      2.388657133579254,
      1.194328566789627,
    ],
    tileOptions: {
      crossOriginKeyword: null,
    },
    attribution: '',
  });

export default [osm];
