export default [
  {
    name: '1. Предупреждающие знаки',
    items: {
      1.1: {
        icon: '/icon/kz_roadsigns/kz_1_1.png',
        bigIcon: '/icon/kz_roadsigns-big/kz_zn1_1.png',
        name: '1.1',
      },

      1.2: {
        icon: '/icon/kz_roadsigns/kz_1_2.png',
        bigIcon: '/icon/kz_roadsigns-big/kz_zn1_2.png',
        name: '1.2',
      },

      '1.3.1': {
        icon: '/icon/kz_roadsigns/kz_1_3_1.png',
        bigIcon: '/icon/kz_roadsigns-big/kz_zn1_3_1.png',
        name: '1.3.1',
      },

      '1.3.2': {
        icon: '/icon/kz_roadsigns/kz_1_3_2.png',
        bigIcon: '/icon/kz_roadsigns-big/kz_zn1_3_2.png',
        name: '1.3.2',
      },

      '1.4.1': {
        icon: '/icon/kz_roadsigns/kz_1_4_1.png',
        bigIcon: '/icon/kz_roadsigns-big/kz_zn1_4_1.png',
        name: '1.4.1',
      },

      '1.4.2': {
        icon: '/icon/kz_roadsigns/kz_1_4_2.png',
        bigIcon: '/icon/kz_roadsigns-big/kz_zn1_4_2.png',
        name: '1.4.2',
      },

      '1.4.3': {
        icon: '/icon/kz_roadsigns/kz_1_4_3.png',
        bigIcon: '/icon/kz_roadsigns-big/kz_zn1_4_3.png',
        name: '1.4.3',
      },

      '1.4.4': {
        icon: '/icon/kz_roadsigns/kz_1_4_4.png',
        bigIcon: '/icon/kz_roadsigns-big/kz_zn1_4_4.png',
        name: '1.4.4',
      },

      '1.4.5': {
        icon: '/icon/kz_roadsigns/kz_1_4_5.png',
        bigIcon: '/icon/kz_roadsigns-big/kz_zn1_4_5.png',
        name: '1.4.5',
      },

      '1.4.6': {
        icon: '/icon/kz_roadsigns/kz_1_4_6.png',
        bigIcon: '/icon/kz_roadsigns-big/kz_zn1_4_6.png',
        name: '1.4.6',
      },

      1.5: {
        icon: '/icon/kz_roadsigns/kz_1_5.png',
        bigIcon: '/icon/kz_roadsigns-big/kz_zn1_5.png',
        name: '1.5',
      },

      1.6: {
        icon: '/icon/kz_roadsigns/kz_1_6.png',
        bigIcon: '/icon/kz_roadsigns-big/kz_zn1_6.png',
        name: '1.6',
      },

      1.7: {
        icon: '/icon/kz_roadsigns/kz_1_7.png',
        bigIcon: '/icon/kz_roadsigns-big/kz_zn1_7.png',
        name: '1.7',
      },

      1.8: {
        icon: '/icon/kz_roadsigns/kz_1_8.png',
        bigIcon: '/icon/kz_roadsigns-big/kz_zn1_8.png',
        name: '1.8',
      },

      1.9: {
        icon: '/icon/kz_roadsigns/kz_1_9.png',
        bigIcon: '/icon/kz_roadsigns-big/kz_zn1_9.png',
        name: '1.9',
      },

      '1.10': {
        icon: '/icon/kz_roadsigns/kz_1_10.png',
        bigIcon: '/icon/kz_roadsigns-big/kz_zn1_10.png',
        name: '1.10',
      },

      '1.11.1': {
        icon: '/icon/kz_roadsigns/kz_1_11_1.png',
        bigIcon: '/icon/kz_roadsigns-big/kz_zn1_11_1.png',
        name: '1.11.1',
      },

      '1.11.2': {
        icon: '/icon/kz_roadsigns/kz_1_11_2.png',
        bigIcon: '/icon/kz_roadsigns-big/kz_zn1_11_2.png',
        name: '1.11.2',
      },

      '1.12.1': {
        icon: '/icon/kz_roadsigns/kz_1_12_1.png',
        bigIcon: '/icon/kz_roadsigns-big/kz_zn1_12_1.png',
        name: '1.12.1',
      },

      '1.12.2': {
        icon: '/icon/kz_roadsigns/kz_1_12_2.png',
        bigIcon: '/icon/kz_roadsigns-big/kz_zn1_12_2.png',
        name: '1.12.2',
      },

      1.13: {
        icon: '/icon/kz_roadsigns/kz_1_13.png',
        bigIcon: '/icon/kz_roadsigns-big/kz_zn1_13.png',
        name: '1.13',
      },

      1.14: {
        icon: '/icon/kz_roadsigns/kz_1_14.png',
        bigIcon: '/icon/kz_roadsigns-big/kz_zn1_14.png',
        name: '1.14',
      },

      1.15: {
        icon: '/icon/kz_roadsigns/kz_1_15.png',
        bigIcon: '/icon/kz_roadsigns-big/kz_zn1_15.png',
        name: '1.15',
      },

      1.16: {
        icon: '/icon/kz_roadsigns/kz_1_16.png',
        bigIcon: '/icon/kz_roadsigns-big/kz_zn1_16.png',
        name: '1.16',
      },

      '1.16.1': {
        icon: '/icon/kz_roadsigns/kz_1_16_1.png',
        bigIcon: '/icon/kz_roadsigns-big/kz_zn1_16_1.png',
        name: '1.16.1',
      },

      1.17: {
        icon: '/icon/kz_roadsigns/kz_1_17.png',
        bigIcon: '/icon/kz_roadsigns-big/kz_zn1_17.png',
        name: '1.17',
      },

      '1.18.1': {
        icon: '/icon/kz_roadsigns/kz_1_18_1.png',
        bigIcon: '/icon/kz_roadsigns-big/kz_zn1_18_1.png',
        name: '1.18.1',
      },

      '1.18.2': {
        icon: '/icon/kz_roadsigns/kz_1_18_2.png',
        bigIcon: '/icon/kz_roadsigns-big/kz_zn1_18_2.png',
        name: '1.18.2',
      },

      '1.18.3': {
        icon: '/icon/kz_roadsigns/kz_1_18_3.png',
        bigIcon: '/icon/kz_roadsigns-big/kz_zn1_18_3.png',
        name: '1.18.3',
      },

      1.19: {
        icon: '/icon/kz_roadsigns/kz_1_19.png',
        bigIcon: '/icon/kz_roadsigns-big/kz_zn1_19.png',
        name: '1.19',
      },

      '1.20': {
        icon: '/icon/kz_roadsigns/kz_1_20.png',
        bigIcon: '/icon/kz_roadsigns-big/kz_zn1_20.png',
        name: '1.20',
      },

      1.21: {
        icon: '/icon/kz_roadsigns/kz_1_21.png',
        bigIcon: '/icon/kz_roadsigns-big/kz_zn1_21.png',
        name: '1.21',
      },

      1.22: {
        icon: '/icon/kz_roadsigns/kz_1_22.png',
        bigIcon: '/icon/kz_roadsigns-big/kz_zn1_22.png',
        name: '1.22',
      },

      1.23: {
        icon: '/icon/kz_roadsigns/kz_1_23.png',
        bigIcon: '/icon/kz_roadsigns-big/kz_zn1_23.png',
        name: '1.23',
      },

      1.24: {
        icon: '/icon/kz_roadsigns/kz_1_24.png',
        bigIcon: '/icon/kz_roadsigns-big/kz_zn1_24.png',
        name: '1.24',
      },

      1.25: {
        icon: '/icon/kz_roadsigns/kz_1_25.png',
        bigIcon: '/icon/kz_roadsigns-big/kz_zn1_25.png',
        name: '1.25',
      },

      1.26: {
        icon: '/icon/kz_roadsigns/kz_1_26.png',
        bigIcon: '/icon/kz_roadsigns-big/kz_zn1_26.png',
        name: '1.26',
      },

      1.27: {
        icon: '/icon/kz_roadsigns/kz_1_27.png',
        bigIcon: '/icon/kz_roadsigns-big/kz_zn1_27.png',
        name: '1.27',
      },

      1.28: {
        icon: '/icon/kz_roadsigns/kz_1_28.png',
        bigIcon: '/icon/kz_roadsigns-big/kz_zn1_28.png',
        name: '1.28',
      },

      1.29: {
        icon: '/icon/kz_roadsigns/kz_1_29.png',
        bigIcon: '/icon/kz_roadsigns-big/kz_zn1_29.png',
        name: '1.29',
      },

      '1.30': {
        icon: '/icon/kz_roadsigns/kz_1_30.png',
        bigIcon: '/icon/kz_roadsigns-big/kz_zn1_30.png',
        name: '1.30',
      },

      '1.31.1': {
        icon: '/icon/kz_roadsigns/kz_1_31_1.png',
        bigIcon: '/icon/kz_roadsigns-big/kz_zn1_31_1.png',
        name: '1.31.1',
      },

      '1.31.2': {
        icon: '/icon/kz_roadsigns/kz_1_31_2.png',
        bigIcon: '/icon/kz_roadsigns-big/kz_zn1_31_2.png',
        name: '1.31.2',
      },

      '1.31.3': {
        icon: '/icon/kz_roadsigns/kz_1_31_3.png',
        bigIcon: '/icon/kz_roadsigns-big/kz_zn1_31_3.png',
        name: '1.31.3',
      },

      '1.31.4': {
        icon: '/icon/kz_roadsigns/kz_1_31_4.png',
        bigIcon: '/icon/kz_roadsigns-big/kz_zn1_31_4.png',
        name: '1.31.4',
      },

      '1.31.5': {
        icon: '/icon/kz_roadsigns/kz_1_31_5.png',
        bigIcon: '/icon/kz_roadsigns-big/kz_zn1_31_5.png',
        name: '1.31.5',
      },

      '1.32.1': {
        icon: '/icon/kz_roadsigns/kz_1_32_1.png',
        bigIcon: '/icon/kz_roadsigns-big/kz_zn1_32_1.png',
        name: '1.32.1',
      },

      '1.32.2': {
        icon: '/icon/kz_roadsigns/kz_1_32_2.png',
        bigIcon: '/icon/kz_roadsigns-big/kz_zn1_32_2.png',
        name: '1.32.2',
      },

      '1.32.3': {
        icon: '/icon/kz_roadsigns/kz_1_32_3.png',
        bigIcon: '/icon/kz_roadsigns-big/kz_zn1_32_3.png',
        name: '1.32.3',
      },
    },
  },
  {
    name: '2. Знаки приоритета',
    items: {
      2.1: {
        icon: '/icon/kz_roadsigns/kz_2_1.png',
        bigIcon: '/icon/kz_roadsigns-big/kz_zn2_1.png',
        name: '2.1',
      },

      2.2: {
        icon: '/icon/kz_roadsigns/kz_2_2.png',
        bigIcon: '/icon/kz_roadsigns-big/kz_zn2_2.png',
        name: '2.2',
      },

      '2.3.1': {
        icon: '/icon/kz_roadsigns/kz_2_3_1.png',
        bigIcon: '/icon/kz_roadsigns-big/kz_zn2_3_1.png',
        name: '2.3.1',
      },

      '2.3.2': {
        icon: '/icon/kz_roadsigns/kz_2_3_2.png',
        bigIcon: '/icon/kz_roadsigns-big/kz_zn2_3_2.png',
        name: '2.3.2',
      },

      '2.3.3': {
        icon: '/icon/kz_roadsigns/kz_2_3_3.png',
        bigIcon: '/icon/kz_roadsigns-big/kz_zn2_3_3.png',
        name: '2.3.3',
      },

      2.4: {
        icon: '/icon/kz_roadsigns/kz_2_4.png',
        bigIcon: '/icon/kz_roadsigns-big/kz_zn2_4.png',
        name: '2.4',
      },

      2.5: {
        icon: '/icon/kz_roadsigns/kz_2_5.png',
        bigIcon: '/icon/kz_roadsigns-big/kz_zn2_5.png',
        name: '2.5',
      },

      2.6: {
        icon: '/icon/kz_roadsigns/kz_2_6.png',
        bigIcon: '/icon/kz_roadsigns-big/kz_zn2_6.png',
        name: '2.6',
      },

      2.7: {
        icon: '/icon/kz_roadsigns/kz_2_7.png',
        bigIcon: '/icon/kz_roadsigns-big/kz_zn2_7.png',
        name: '2.7',
      },
    },
  },
  {
    name: '3. Запрещающие знаки',
    items: {
      3.1: {
        icon: '/icon/kz_roadsigns/kz_3_1.png',
        bigIcon: '/icon/kz_roadsigns-big/kz_zn3_1.png',
        name: '3.1',
      },

      3.2: {
        icon: '/icon/kz_roadsigns/kz_3_2.png',
        bigIcon: '/icon/kz_roadsigns-big/kz_zn3_2.png',
        name: '3.2',
      },

      3.3: {
        icon: '/icon/kz_roadsigns/kz_3_3.png',
        bigIcon: '/icon/kz_roadsigns-big/kz_zn3_3.png',
        name: '3.3',
      },

      3.4: {
        icon: '/icon/kz_roadsigns/kz_3_4.png',
        bigIcon: '/icon/kz_roadsigns-big/kz_zn3_4.png',
        name: '3.4',
      },

      3.5: {
        icon: '/icon/kz_roadsigns/kz_3_5.png',
        bigIcon: '/icon/kz_roadsigns-big/kz_zn3_5.png',
        name: '3.5',
      },

      3.6: {
        icon: '/icon/kz_roadsigns/kz_3_6.png',
        bigIcon: '/icon/kz_roadsigns-big/kz_zn3_6.png',
        name: '3.6',
      },

      3.7: {
        icon: '/icon/kz_roadsigns/kz_3_7.png',
        bigIcon: '/icon/kz_roadsigns-big/kz_zn3_7.png',
        name: '3.7',
      },

      3.8: {
        icon: '/icon/kz_roadsigns/kz_3_8.png',
        bigIcon: '/icon/kz_roadsigns-big/kz_zn3_8.png',
        name: '3.8',
      },

      3.9: {
        icon: '/icon/kz_roadsigns/kz_3_9.png',
        bigIcon: '/icon/kz_roadsigns-big/kz_zn3_9.png',
        name: '3.9',
      },

      '3.10': {
        icon: '/icon/kz_roadsigns/kz_3_10.png',
        bigIcon: '/icon/kz_roadsigns-big/kz_zn3_10.png',
        name: '3.10',
      },

      3.11: {
        icon: '/icon/kz_roadsigns/kz_3_11.png',
        bigIcon: '/icon/kz_roadsigns-big/kz_zn3_11.png',
        name: '3.11',
      },

      3.12: {
        icon: '/icon/kz_roadsigns/kz_3_12.png',
        bigIcon: '/icon/kz_roadsigns-big/kz_zn3_12.png',
        name: '3.12',
      },

      3.13: {
        icon: '/icon/kz_roadsigns/kz_3_13.png',
        bigIcon: '/icon/kz_roadsigns-big/kz_zn3_13.png',
        name: '3.13',
      },

      3.14: {
        icon: '/icon/kz_roadsigns/kz_3_14.png',
        bigIcon: '/icon/kz_roadsigns-big/kz_zn3_14.png',
        name: '3.14',
      },

      3.15: {
        icon: '/icon/kz_roadsigns/kz_3_15.png',
        bigIcon: '/icon/kz_roadsigns-big/kz_zn3_15.png',
        name: '3.15',
      },

      3.16: {
        icon: '/icon/kz_roadsigns/kz_3_16.png',
        bigIcon: '/icon/kz_roadsigns-big/kz_zn3_16.png',
        name: '3.16',
      },

      '3.17.1': {
        icon: '/icon/kz_roadsigns/kz_3_17_1.png',
        bigIcon: '/icon/kz_roadsigns-big/kz_zn3_17_1.png',
        name: '3.17.1',
      },

      '3.17.2': {
        icon: '/icon/kz_roadsigns/kz_3_17_2.png',
        bigIcon: '/icon/kz_roadsigns-big/kz_zn3_17_2.png',
        name: '3.17.2',
      },

      '3.18.1': {
        icon: '/icon/kz_roadsigns/kz_3_18_1.png',
        bigIcon: '/icon/kz_roadsigns-big/kz_zn3_18_1.png',
        name: '3.18.1',
      },

      '3.18.2': {
        icon: '/icon/kz_roadsigns/kz_3_18_2.png',
        bigIcon: '/icon/kz_roadsigns-big/kz_zn3_18_2.png',
        name: '3.18.2',
      },

      3.19: {
        icon: '/icon/kz_roadsigns/kz_3_19.png',
        bigIcon: '/icon/kz_roadsigns-big/kz_zn3_19.png',
        name: '3.19',
      },

      '3.20': {
        icon: '/icon/kz_roadsigns/kz_3_20.png',
        bigIcon: '/icon/kz_roadsigns-big/kz_zn3_20.png',
        name: '3.20',
      },

      3.21: {
        icon: '/icon/kz_roadsigns/kz_3_21.png',
        bigIcon: '/icon/kz_roadsigns-big/kz_zn3_21.png',
        name: '3.21',
      },

      3.22: {
        icon: '/icon/kz_roadsigns/kz_3_22.png',
        bigIcon: '/icon/kz_roadsigns-big/kz_zn3_22.png',
        name: '3.22',
      },

      3.23: {
        icon: '/icon/kz_roadsigns/kz_3_23.png',
        bigIcon: '/icon/kz_roadsigns-big/kz_zn3_23.png',
        name: '3.23',
      },

      3.24: {
        icon: '/icon/kz_roadsigns/kz_3_24.png',
        bigIcon: '/icon/kz_roadsigns-big/kz_zn3_24.png',
        name: '3.24',
      },

      3.25: {
        icon: '/icon/kz_roadsigns/kz_3_25.png',
        bigIcon: '/icon/kz_roadsigns-big/kz_zn3_25.png',
        name: '3.25',
      },

      3.26: {
        icon: '/icon/kz_roadsigns/kz_3_26.png',
        bigIcon: '/icon/kz_roadsigns-big/kz_zn3_26.png',
        name: '3.26',
      },

      3.27: {
        icon: '/icon/kz_roadsigns/kz_3_27.png',
        bigIcon: '/icon/kz_roadsigns-big/kz_zn3_27.png',
        name: '3.27',
      },

      3.28: {
        icon: '/icon/kz_roadsigns/kz_3_28.png',
        bigIcon: '/icon/kz_roadsigns-big/kz_zn3_28.png',
        name: '3.28',
      },

      3.29: {
        icon: '/icon/kz_roadsigns/kz_3_29.png',
        bigIcon: '/icon/kz_roadsigns-big/kz_zn3_29.png',
        name: '3.29',
      },

      '3.30': {
        icon: '/icon/kz_roadsigns/kz_3_30.png',
        bigIcon: '/icon/kz_roadsigns-big/kz_zn3_30.png',
        name: '3.30',
      },

      3.31: {
        icon: '/icon/kz_roadsigns/kz_3_31.png',
        bigIcon: '/icon/kz_roadsigns-big/kz_zn3_31.png',
        name: '3.31',
      },

      3.32: {
        icon: '/icon/kz_roadsigns/kz_3_32.png',
        bigIcon: '/icon/kz_roadsigns-big/kz_zn3_32.png',
        name: '3.32',
      },

      3.33: {
        icon: '/icon/kz_roadsigns/kz_3_33.png',
        bigIcon: '/icon/kz_roadsigns-big/kz_zn3_33.png',
        name: '3.33',
      },
    },
  },
  {
    name: '4. Предписывающие знаки',
    items: {
      '4.1.1': {
        icon: '/icon/kz_roadsigns/kz_4_1_1.png',
        bigIcon: '/icon/kz_roadsigns-big/kz_zn4_1_1.png',
        name: '4.1.1',
      },

      '4.1.2': {
        icon: '/icon/kz_roadsigns/kz_4_1_2.png',
        bigIcon: '/icon/kz_roadsigns-big/kz_zn4_1_2.png',
        name: '4.1.2',
      },

      '4.1.3': {
        icon: '/icon/kz_roadsigns/kz_4_1_3.png',
        bigIcon: '/icon/kz_roadsigns-big/kz_zn4_1_3.png',
        name: '4.1.3',
      },

      '4.1.4': {
        icon: '/icon/kz_roadsigns/kz_4_1_4.png',
        bigIcon: '/icon/kz_roadsigns-big/kz_zn4_1_4.png',
        name: '4.1.4',
      },

      '4.1.5': {
        icon: '/icon/kz_roadsigns/kz_4_1_5.png',
        bigIcon: '/icon/kz_roadsigns-big/kz_zn4_1_5.png',
        name: '4.1.5',
      },

      '4.1.6': {
        icon: '/icon/kz_roadsigns/kz_4_1_6.png',
        bigIcon: '/icon/kz_roadsigns-big/kz_zn4_1_6.png',
        name: '4.1.6',
      },

      '4.2.1': {
        icon: '/icon/kz_roadsigns/kz_4_2_1.png',
        bigIcon: '/icon/kz_roadsigns-big/kz_zn4_2_1.png',
        name: '4.2.1',
      },

      '4.2.2': {
        icon: '/icon/kz_roadsigns/kz_4_2_2.png',
        bigIcon: '/icon/kz_roadsigns-big/kz_zn4_2_2.png',
        name: '4.2.2',
      },

      '4.2.3': {
        icon: '/icon/kz_roadsigns/kz_4_2_3.png',
        bigIcon: '/icon/kz_roadsigns-big/kz_zn4_2_3.png',
        name: '4.2.3',
      },

      4.3: {
        icon: '/icon/kz_roadsigns/kz_4_3.png',
        bigIcon: '/icon/kz_roadsigns-big/kz_zn4_3.png',
        name: '4.3',
      },

      4.4: {
        icon: '/icon/kz_roadsigns/kz_4_4.png',
        bigIcon: '/icon/kz_roadsigns-big/kz_zn4_4.png',
        name: '4.4',
      },

      4.5: {
        icon: '/icon/kz_roadsigns/kz_4_5.png',
        bigIcon: '/icon/kz_roadsigns-big/kz_zn4_5.png',
        name: '4.5',
      },

      4.6: {
        icon: '/icon/kz_roadsigns/kz_4_6.png',
        bigIcon: '/icon/kz_roadsigns-big/kz_zn4_6.png',
        name: '4.6',
      },

      4.7: {
        icon: '/icon/kz_roadsigns/kz_4_7.png',
        bigIcon: '/icon/kz_roadsigns-big/kz_zn4_7.png',
        name: '4.7',
      },

      4.8: {
        icon: '/icon/kz_roadsigns/kz_4_8.png',
        bigIcon: '/icon/kz_roadsigns-big/kz_zn4_8.png',
        name: '4.8',
      },

      '4.9.1': {
        icon: '/icon/kz_roadsigns/kz_4_9_1.png',
        bigIcon: '/icon/kz_roadsigns-big/kz_zn4_9_1.png',
        name: '4.9.1',
      },

      '4.9.2': {
        icon: '/icon/kz_roadsigns/kz_4_9_2.png',
        bigIcon: '/icon/kz_roadsigns-big/kz_zn4_9_2.png',
        name: '4.9.2',
      },

      '4.9.3': {
        icon: '/icon/kz_roadsigns/kz_4_9_3.png',
        bigIcon: '/icon/kz_roadsigns-big/kz_zn4_9_3.png',
        name: '4.9.3',
      },
    },
  },
  {
    name: '5. Информационно-указательные знаки',
    items: {
      5.1: {
        icon: '/icon/kz_roadsigns/kz_5_1.png',
        bigIcon: '/icon/kz_roadsigns-big/kz_zn5_1.png',
        name: '5.1',
      },

      5.2: {
        icon: '/icon/kz_roadsigns/kz_5_2.png',
        bigIcon: '/icon/kz_roadsigns-big/kz_zn5_2.png',
        name: '5.2',
      },

      5.3: {
        icon: '/icon/kz_roadsigns/kz_5_3.png',
        bigIcon: '/icon/kz_roadsigns-big/kz_zn5_3.png',
        name: '5.3',
      },

      5.4: {
        icon: '/icon/kz_roadsigns/kz_5_4.png',
        bigIcon: '/icon/kz_roadsigns-big/kz_zn5_4.png',
        name: '5.4',
      },

      5.5: {
        icon: '/icon/kz_roadsigns/kz_5_5.png',
        bigIcon: '/icon/kz_roadsigns-big/kz_zn5_5.png',
        name: '5.5',
      },

      5.6: {
        icon: '/icon/kz_roadsigns/kz_5_6.png',
        bigIcon: '/icon/kz_roadsigns-big/kz_zn5_6.png',
        name: '5.6',
      },

      '5.7.1': {
        icon: '/icon/kz_roadsigns/kz_5_7_1.png',
        bigIcon: '/icon/kz_roadsigns-big/kz_zn5_7_1.png',
        name: '5.7.1',
      },

      '5.7.2': {
        icon: '/icon/kz_roadsigns/kz_5_7_2.png',
        bigIcon: '/icon/kz_roadsigns-big/kz_zn5_7_2.png',
        name: '5.7.2',
      },

      '5.8.1': {
        icon: '/icon/kz_roadsigns/kz_5_8_1.png',
        bigIcon: '/icon/kz_roadsigns-big/kz_zn5_8_1.png',
        name: '5.8.1',
      },

      '5.8.2': {
        icon: '/icon/kz_roadsigns/kz_5_8_2.png',
        bigIcon: '/icon/kz_roadsigns-big/kz_zn5_8_2.png',
        name: '5.8.2',
      },

      '5.8.3': {
        icon: '/icon/kz_roadsigns/kz_5_8_3.png',
        bigIcon: '/icon/kz_roadsigns-big/kz_zn5_8_3.png',
        name: '5.8.3',
      },

      '5.8.4': {
        icon: '/icon/kz_roadsigns/kz_5_8_4.png',
        bigIcon: '/icon/kz_roadsigns-big/kz_zn5_8_4.png',
        name: '5.8.4',
      },

      '5.8.5': {
        icon: '/icon/kz_roadsigns/kz_5_8_5.png',
        bigIcon: '/icon/kz_roadsigns-big/kz_zn5_8_5.png',
        name: '5.8.5',
      },

      '5.8.6': {
        icon: '/icon/kz_roadsigns/kz_5_8_6.png',
        bigIcon: '/icon/kz_roadsigns-big/kz_zn5_8_6.png',
        name: '5.8.6',
      },

      '5.8.7': {
        icon: '/icon/kz_roadsigns/kz_5_8_7.png',
        bigIcon: '/icon/kz_roadsigns-big/kz_zn5_8_7.png',
        name: '5.8.7',
      },

      '5.8.8': {
        icon: '/icon/kz_roadsigns/kz_5_8_8.png',
        bigIcon: '/icon/kz_roadsigns-big/kz_zn5_8_8.png',
        name: '5.8.8',
      },

      5.9: {
        icon: '/icon/kz_roadsigns/kz_5_9.png',
        bigIcon: '/icon/kz_roadsigns-big/kz_zn5_9.png',
        name: '5.9',
      },

      '5.10.1': {
        icon: '/icon/kz_roadsigns/kz_5_10_1.png',
        bigIcon: '/icon/kz_roadsigns-big/kz_zn5_10_1.png',
        name: '5.10.1',
      },

      '5.10.2': {
        icon: '/icon/kz_roadsigns/kz_5_10_2.png',
        bigIcon: '/icon/kz_roadsigns-big/kz_zn5_10_2.png',
        name: '5.10.2',
      },

      '5.10.3': {
        icon: '/icon/kz_roadsigns/kz_5_10_3.png',
        bigIcon: '/icon/kz_roadsigns-big/kz_zn5_10_3.png',
        name: '5.10.3',
      },

      '5.10.4': {
        icon: '/icon/kz_roadsigns/kz_5_10_4.png',
        bigIcon: '/icon/kz_roadsigns-big/kz_zn5_10_4.png',
        name: '5.10.4',
      },

      '5.11.1': {
        icon: '/icon/kz_roadsigns/kz_5_11_1.png',
        bigIcon: '/icon/kz_roadsigns-big/kz_zn5_11_1.png',
        name: '5.11.1',
      },

      '5.11.2': {
        icon: '/icon/kz_roadsigns/kz_5_11_2.png',
        bigIcon: '/icon/kz_roadsigns-big/kz_zn5_11_2.png',
        name: '5.11.2',
      },

      5.12: {
        icon: '/icon/kz_roadsigns/kz_5_12.png',
        bigIcon: '/icon/kz_roadsigns-big/kz_zn5_12.png',
        name: '5.12',
      },

      5.13: {
        icon: '/icon/kz_roadsigns/kz_5_13.png',
        bigIcon: '/icon/kz_roadsigns-big/kz_zn5_13.png',
        name: '5.13',
      },

      5.14: {
        icon: '/icon/kz_roadsigns/kz_5_14.png',
        bigIcon: '/icon/kz_roadsigns-big/kz_zn5_14.png',
        name: '5.14',
      },

      5.15: {
        icon: '/icon/kz_roadsigns/kz_5_15.png',
        bigIcon: '/icon/kz_roadsigns-big/kz_zn5_15.png',
        name: '5.15',
      },

      '5.16.1': {
        icon: '/icon/kz_roadsigns/kz_5_16_1.png',
        bigIcon: '/icon/kz_roadsigns-big/kz_zn5_16_1.png',
        name: '5.16.1',
      },

      '5.16.2': {
        icon: '/icon/kz_roadsigns/kz_5_16_2.png',
        bigIcon: '/icon/kz_roadsigns-big/kz_zn5_16_2.png',
        name: '5.16.2',
      },

      '5.17.1': {
        icon: '/icon/kz_roadsigns/kz_5_17_1.png',
        bigIcon: '/icon/kz_roadsigns-big/kz_zn5_17_1.png',
        name: '5.17.1',
      },

      '5.17.2': {
        icon: '/icon/kz_roadsigns/kz_5_17_2.png',
        bigIcon: '/icon/kz_roadsigns-big/kz_zn5_17_2.png',
        name: '5.17.2',
      },

      '5.17.3': {
        icon: '/icon/kz_roadsigns/kz_5_17_3.png',
        bigIcon: '/icon/kz_roadsigns-big/kz_zn5_17_3.png',
        name: '5.17.3',
      },

      '5.17.4': {
        icon: '/icon/kz_roadsigns/kz_5_17_4.png',
        bigIcon: '/icon/kz_roadsigns-big/kz_zn5_17_4.png',
        name: '5.17.4',
      },

      5.18: {
        icon: '/icon/kz_roadsigns/kz_5_18.png',
        bigIcon: '/icon/kz_roadsigns-big/kz_zn5_18.png',
        name: '5.18',
      },

      '5.19.1': {
        icon: '/icon/kz_roadsigns/kz_5_19_1.png',
        bigIcon: '/icon/kz_roadsigns-big/kz_zn5_19_1.png',
        name: '5.19.1',
      },

      '5.19.2': {
        icon: '/icon/kz_roadsigns/kz_5_19_2.png',
        bigIcon: '/icon/kz_roadsigns-big/kz_zn5_19_2.png',
        name: '5.19.2',
      },

      '5.19.3': {
        icon: '/icon/kz_roadsigns/kz_5_19_3.png',
        bigIcon: '/icon/kz_roadsigns-big/kz_zn5_19_3.png',
        name: '5.19.3',
      },

      '5.20.1': {
        icon: '/icon/kz_roadsigns/kz_5_20_1.png',
        bigIcon: '/icon/kz_roadsigns-big/kz_zn5_20_1.png',
        name: '5.20.1',
      },

      '5.20.2': {
        icon: '/icon/kz_roadsigns/kz_5_20_2.png',
        bigIcon: '/icon/kz_roadsigns-big/kz_zn5_20_2.png',
        name: '5.20.2',
      },

      '5.20.3': {
        icon: '/icon/kz_roadsigns/kz_5_20_3.png',
        bigIcon: '/icon/kz_roadsigns-big/kz_zn5_20_3.png',
        name: '5.20.3',
      },

      '5.21.1': {
        icon: '/icon/kz_roadsigns/kz_5_21_1.png',
        bigIcon: '/icon/kz_roadsigns-big/kz_zn5_21_1.png',
        name: '5.21.1',
      },

      '5.21.2': {
        icon: '/icon/kz_roadsigns/kz_5_21_2.png',
        bigIcon: '/icon/kz_roadsigns-big/kz_zn5_21_2.png',
        name: '5.21.2',
      },

      5.22: {
        icon: '/icon/kz_roadsigns/kz_5_22.png',
        bigIcon: '/icon/kz_roadsigns-big/kz_zn5_22.png',
        name: '5.22',
      },

      5.23: {
        icon: '/icon/kz_roadsigns/kz_5_23.png',
        bigIcon: '/icon/kz_roadsigns-big/kz_zn5_23.png',
        name: '5.23',
      },

      5.24: {
        icon: '/icon/kz_roadsigns/kz_5_24.png',
        bigIcon: '/icon/kz_roadsigns-big/kz_zn5_24.png',
        name: '5.24',
      },

      5.25: {
        icon: '/icon/kz_roadsigns/kz_5_25.png',
        bigIcon: '/icon/kz_roadsigns-big/kz_zn5_25.png',
        name: '5.25',
      },

      5.26: {
        icon: '/icon/kz_roadsigns/kz_5_26.png',
        bigIcon: '/icon/kz_roadsigns-big/kz_zn5_26.png',
        name: '5.26',
      },

      5.27: {
        icon: '/icon/kz_roadsigns/kz_5_27.png',
        bigIcon: '/icon/kz_roadsigns-big/kz_zn5_27.png',
        name: '5.27',
      },

      5.28: {
        icon: '/icon/kz_roadsigns/kz_5_28.png',
        bigIcon: '/icon/kz_roadsigns-big/kz_zn5_28.png',
        name: '5.28',
      },

      '5.29.1': {
        icon: '/icon/kz_roadsigns/kz_5_29_1.png',
        bigIcon: '/icon/kz_roadsigns-big/kz_zn5_29_1.png',
        name: '5.29.1',
      },

      '5.29.2': {
        icon: '/icon/kz_roadsigns/kz_5_29_2.png',
        bigIcon: '/icon/kz_roadsigns-big/kz_zn5_29_2.png',
        name: '5.29.2',
      },

      '5.30.1': {
        icon: '/icon/kz_roadsigns/kz_5_30_1.png',
        bigIcon: '/icon/kz_roadsigns-big/kz_zn5_30_1.png',
        name: '5.30.1',
      },

      '5.30.2': {
        icon: '/icon/kz_roadsigns/kz_5_30_2.png',
        bigIcon: '/icon/kz_roadsigns-big/kz_zn5_30_2.png',
        name: '5.30.2',
      },

      '5.30.3': {
        icon: '/icon/kz_roadsigns/kz_5_30_3.png',
        bigIcon: '/icon/kz_roadsigns-big/kz_zn5_30_3.png',
        name: '5.30.3',
      },

      5.31: {
        icon: '/icon/kz_roadsigns/kz_5_31.png',
        bigIcon: '/icon/kz_roadsigns-big/kz_zn5_31.png',
        name: '5.31',
      },

      '5.32.1': {
        icon: '/icon/kz_roadsigns/kz_5_32_1.png',
        bigIcon: '/icon/kz_roadsigns-big/kz_zn5_32_1.png',
        name: '5.32.1',
      },

      '5.32.2': {
        icon: '/icon/kz_roadsigns/kz_5_32_2.png',
        bigIcon: '/icon/kz_roadsigns-big/kz_zn5_32_2.png',
        name: '5.32.2',
      },

      '5.32.3': {
        icon: '/icon/kz_roadsigns/kz_5_32_3.png',
        bigIcon: '/icon/kz_roadsigns-big/kz_zn5_32_3.png',
        name: '5.32.3',
      },

      5.33: {
        icon: '/icon/kz_roadsigns/kz_5_33.png',
        bigIcon: '/icon/kz_roadsigns-big/kz_zn5_33.png',
        name: '5.33',
      },

      '5.34.1': {
        icon: '/icon/kz_roadsigns/kz_5_34_1.png',
        bigIcon: '/icon/kz_roadsigns-big/kz_zn5_34_1.png',
        name: '5.34.1',
      },

      '5.34.2': {
        icon: '/icon/kz_roadsigns/kz_5_34_2.png',
        bigIcon: '/icon/kz_roadsigns-big/kz_zn5_34_2.png',
        name: '5.34.2',
      },

      5.35: {
        icon: '/icon/kz_roadsigns/kz_5_35.png',
        bigIcon: '/icon/kz_roadsigns-big/kz_zn5_35.png',
        name: '5.35',
      },

      5.36: {
        icon: '/icon/kz_roadsigns/kz_5_36.png',
        bigIcon: '/icon/kz_roadsigns-big/kz_zn5_36.png',
        name: '5.36',
      },

      5.37: {
        icon: '/icon/kz_roadsigns/kz_5_37.png',
        bigIcon: '/icon/kz_roadsigns-big/kz_zn5_37.png',
        name: '5.37',
      },

      5.38: {
        icon: '/icon/kz_roadsigns/kz_5_38.png',
        bigIcon: '/icon/kz_roadsigns-big/kz_zn5_38.png',
        name: '5.38',
      },

      5.39: {
        icon: '/icon/kz_roadsigns/kz_5_39.png',
        bigIcon: '/icon/kz_roadsigns-big/kz_zn5_39.png',
        name: '5.39',
      },
    },
  },
  {
    name: '6. Знаки сервиса',
    items: {
      6.1: {
        icon: '/icon/kz_roadsigns/kz_6_1.png',
        bigIcon: '/icon/kz_roadsigns-big/kz_zn6_1.png',
        name: '6.1',
      },

      6.2: {
        icon: '/icon/kz_roadsigns/kz_6_2.png',
        bigIcon: '/icon/kz_roadsigns-big/kz_zn6_2.png',
        name: '6.2',
      },

      6.3: {
        icon: '/icon/kz_roadsigns/kz_6_3.png',
        bigIcon: '/icon/kz_roadsigns-big/kz_zn6_3.png',
        name: '6.3',
      },

      6.4: {
        icon: '/icon/kz_roadsigns/kz_6_4.png',
        bigIcon: '/icon/kz_roadsigns-big/kz_zn6_4.png',
        name: '6.4',
      },

      6.5: {
        icon: '/icon/kz_roadsigns/kz_6_5.png',
        bigIcon: '/icon/kz_roadsigns-big/kz_zn6_5.png',
        name: '6.5',
      },

      6.6: {
        icon: '/icon/kz_roadsigns/kz_6_6.png',
        bigIcon: '/icon/kz_roadsigns-big/kz_zn6_6.png',
        name: '6.6',
      },

      6.7: {
        icon: '/icon/kz_roadsigns/kz_6_7.png',
        bigIcon: '/icon/kz_roadsigns-big/kz_zn6_7.png',
        name: '6.7',
      },

      6.8: {
        icon: '/icon/kz_roadsigns/kz_6_8.png',
        bigIcon: '/icon/kz_roadsigns-big/kz_zn6_8.png',
        name: '6.8',
      },

      6.9: {
        icon: '/icon/kz_roadsigns/kz_6_9.png',
        bigIcon: '/icon/kz_roadsigns-big/kz_zn6_9.png',
        name: '6.9',
      },

      '6.10': {
        icon: '/icon/kz_roadsigns/kz_6_10.png',
        bigIcon: '/icon/kz_roadsigns-big/kz_zn6_10.png',
        name: '6.10',
      },

      6.11: {
        icon: '/icon/kz_roadsigns/kz_6_11.png',
        bigIcon: '/icon/kz_roadsigns-big/kz_zn6_11.png',
        name: '6.11',
      },

      6.12: {
        icon: '/icon/kz_roadsigns/kz_6_12.png',
        bigIcon: '/icon/kz_roadsigns-big/kz_zn6_12.png',
        name: '6.12',
      },

      6.13: {
        icon: '/icon/kz_roadsigns/kz_6_13.png',
        bigIcon: '/icon/kz_roadsigns-big/kz_zn6_13.png',
        name: '6.13',
      },
    },
  },
  {
    name: '7. Знаки доп. информации (таблички)',
    items: {
      '7.1.1': {
        icon: '/icon/kz_roadsigns/kz_7_1_1.png',
        bigIcon: '/icon/kz_roadsigns-big/kz_zn7_1_1.png',
        name: '7.1.1',
      },

      '7.1.2': {
        icon: '/icon/kz_roadsigns/kz_7_1_2.png',
        bigIcon: '/icon/kz_roadsigns-big/kz_zn7_1_2.png',
        name: '7.1.2',
      },

      '7.1.3': {
        icon: '/icon/kz_roadsigns/kz_7_1_3.png',
        bigIcon: '/icon/kz_roadsigns-big/kz_zn7_1_3.png',
        name: '7.1.3',
      },

      '7.1.4': {
        icon: '/icon/kz_roadsigns/kz_7_1_4.png',
        bigIcon: '/icon/kz_roadsigns-big/kz_zn7_1_4.png',
        name: '7.1.4',
      },

      '7.2.1': {
        icon: '/icon/kz_roadsigns/kz_7_2_1.png',
        bigIcon: '/icon/kz_roadsigns-big/kz_zn7_2_1.png',
        name: '7.2.1',
      },

      '7.2.2': {
        icon: '/icon/kz_roadsigns/kz_7_2_2.png',
        bigIcon: '/icon/kz_roadsigns-big/kz_zn7_2_2.png',
        name: '7.2.2',
      },

      '7.2.3': {
        icon: '/icon/kz_roadsigns/kz_7_2_3.png',
        bigIcon: '/icon/kz_roadsigns-big/kz_zn7_2_3.png',
        name: '7.2.3',
      },

      '7.2.4': {
        icon: '/icon/kz_roadsigns/kz_7_2_4.png',
        bigIcon: '/icon/kz_roadsigns-big/kz_zn7_2_4.png',
        name: '7.2.4',
      },

      '7.2.5': {
        icon: '/icon/kz_roadsigns/kz_7_2_5.png',
        bigIcon: '/icon/kz_roadsigns-big/kz_zn7_2_5.png',
        name: '7.2.5',
      },

      '7.2.6': {
        icon: '/icon/kz_roadsigns/kz_7_2_6.png',
        bigIcon: '/icon/kz_roadsigns-big/kz_zn7_2_6.png',
        name: '7.2.6',
      },

      '7.3.1': {
        icon: '/icon/kz_roadsigns/kz_7_3_1.png',
        bigIcon: '/icon/kz_roadsigns-big/kz_zn7_3_1.png',
        name: '7.3.1',
      },

      '7.3.2': {
        icon: '/icon/kz_roadsigns/kz_7_3_2.png',
        bigIcon: '/icon/kz_roadsigns-big/kz_zn7_3_2.png',
        name: '7.3.2',
      },

      '7.3.3': {
        icon: '/icon/kz_roadsigns/kz_7_3_3.png',
        bigIcon: '/icon/kz_roadsigns-big/kz_zn7_3_3.png',
        name: '7.3.3',
      },

      '7.4.1': {
        icon: '/icon/kz_roadsigns/kz_7_4_1.png',
        bigIcon: '/icon/kz_roadsigns-big/kz_zn7_4_1.png',
        name: '7.4.1',
      },

      '7.4.2': {
        icon: '/icon/kz_roadsigns/kz_7_4_2.png',
        bigIcon: '/icon/kz_roadsigns-big/kz_zn7_4_2.png',
        name: '7.4.2',
      },

      '7.4.3': {
        icon: '/icon/kz_roadsigns/kz_7_4_3.png',
        bigIcon: '/icon/kz_roadsigns-big/kz_zn7_4_3.png',
        name: '7.4.3',
      },

      '7.4.4': {
        icon: '/icon/kz_roadsigns/kz_7_4_4.png',
        bigIcon: '/icon/kz_roadsigns-big/kz_zn7_4_4.png',
        name: '7.4.4',
      },

      '7.4.5': {
        icon: '/icon/kz_roadsigns/kz_7_4_5.png',
        bigIcon: '/icon/kz_roadsigns-big/kz_zn7_4_5.png',
        name: '7.4.5',
      },

      '7.4.6': {
        icon: '/icon/kz_roadsigns/kz_7_4_6.png',
        bigIcon: '/icon/kz_roadsigns-big/kz_zn7_4_6.png',
        name: '7.4.6',
      },

      '7.4.7': {
        icon: '/icon/kz_roadsigns/kz_7_4_7.png',
        bigIcon: '/icon/kz_roadsigns-big/kz_zn7_4_7.png',
        name: '7.4.7',
      },

      '7.4.8': {
        icon: '/icon/kz_roadsigns/kz_7_4_8.png',
        bigIcon: '/icon/kz_roadsigns-big/kz_zn7_4_8.png',
        name: '7.4.8',
      },

      '7.5.1': {
        icon: '/icon/kz_roadsigns/kz_7_5_1.png',
        bigIcon: '/icon/kz_roadsigns-big/kz_zn7_5_1.png',
        name: '7.5.1',
      },

      '7.5.2': {
        icon: '/icon/kz_roadsigns/kz_7_5_2.png',
        bigIcon: '/icon/kz_roadsigns-big/kz_zn7_5_2.png',
        name: '7.5.2',
      },

      '7.5.3': {
        icon: '/icon/kz_roadsigns/kz_7_5_3.png',
        bigIcon: '/icon/kz_roadsigns-big/kz_zn7_5_3.png',
        name: '7.5.3',
      },

      '7.5.4': {
        icon: '/icon/kz_roadsigns/kz_7_5_4.png',
        bigIcon: '/icon/kz_roadsigns-big/kz_zn7_5_4.png',
        name: '7.5.4',
      },

      '7.5.5': {
        icon: '/icon/kz_roadsigns/kz_7_5_5.png',
        bigIcon: '/icon/kz_roadsigns-big/kz_zn7_5_5.png',
        name: '7.5.5',
      },

      '7.5.6': {
        icon: '/icon/kz_roadsigns/kz_7_5_6.png',
        bigIcon: '/icon/kz_roadsigns-big/kz_zn7_5_6.png',
        name: '7.5.6',
      },

      '7.5.7': {
        icon: '/icon/kz_roadsigns/kz_7_5_7.png',
        bigIcon: '/icon/kz_roadsigns-big/kz_zn7_5_7.png',
        name: '7.5.7',
      },

      '7.6.1': {
        icon: '/icon/kz_roadsigns/kz_7_6_1.png',
        bigIcon: '/icon/kz_roadsigns-big/kz_zn7_6_1.png',
        name: '7.6.1',
      },

      '7.6.2': {
        icon: '/icon/kz_roadsigns/kz_7_6_2.png',
        bigIcon: '/icon/kz_roadsigns-big/kz_zn7_6_2.png',
        name: '7.6.2',
      },

      '7.6.3': {
        icon: '/icon/kz_roadsigns/kz_7_6_3.png',
        bigIcon: '/icon/kz_roadsigns-big/kz_zn7_6_3.png',
        name: '7.6.3',
      },

      '7.6.4': {
        icon: '/icon/kz_roadsigns/kz_7_6_4.png',
        bigIcon: '/icon/kz_roadsigns-big/kz_zn7_6_4.png',
        name: '7.6.4',
      },

      '7.6.5': {
        icon: '/icon/kz_roadsigns/kz_7_6_5.png',
        bigIcon: '/icon/kz_roadsigns-big/kz_zn7_6_5.png',
        name: '7.6.5',
      },

      '7.6.6': {
        icon: '/icon/kz_roadsigns/kz_7_6_6.png',
        bigIcon: '/icon/kz_roadsigns-big/kz_zn7_6_6.png',
        name: '7.6.6',
      },

      '7.6.7': {
        icon: '/icon/kz_roadsigns/kz_7_6_7.png',
        bigIcon: '/icon/kz_roadsigns-big/kz_zn7_6_7.png',
        name: '7.6.7',
      },

      '7.6.8': {
        icon: '/icon/kz_roadsigns/kz_7_6_8.png',
        bigIcon: '/icon/kz_roadsigns-big/kz_zn7_6_8.png',
        name: '7.6.8',
      },

      '7.6.9': {
        icon: '/icon/kz_roadsigns/kz_7_6_9.png',
        bigIcon: '/icon/kz_roadsigns-big/kz_zn7_6_9.png',
        name: '7.6.9',
      },

      7.7: {
        icon: '/icon/kz_roadsigns/kz_7_7.png',
        bigIcon: '/icon/kz_roadsigns-big/kz_zn7_7.png',
        name: '7.7',
      },

      7.8: {
        icon: '/icon/kz_roadsigns/kz_7_8.png',
        bigIcon: '/icon/kz_roadsigns-big/kz_zn7_8.png',
        name: '7.8',
      },

      7.9: {
        icon: '/icon/kz_roadsigns/kz_7_9.png',
        bigIcon: '/icon/kz_roadsigns-big/kz_zn7_9.png',
        name: '7.9',
      },

      '7.10': {
        icon: '/icon/kz_roadsigns/kz_7_10.png',
        bigIcon: '/icon/kz_roadsigns-big/kz_zn7_10.png',
        name: '7.10',
      },

      7.11: {
        icon: '/icon/kz_roadsigns/kz_7_11.png',
        bigIcon: '/icon/kz_roadsigns-big/kz_zn7_11.png',
        name: '7.11',
      },

      7.12: {
        icon: '/icon/kz_roadsigns/kz_7_12.png',
        bigIcon: '/icon/kz_roadsigns-big/kz_zn7_12.png',
        name: '7.12',
      },

      7.13: {
        icon: '/icon/kz_roadsigns/kz_7_13.png',
        bigIcon: '/icon/kz_roadsigns-big/kz_zn7_13.png',
        name: '7.13',
      },

      7.14: {
        icon: '/icon/kz_roadsigns/kz_7_14.png',
        bigIcon: '/icon/kz_roadsigns-big/kz_zn7_14.png',
        name: '7.14',
      },

      7.15: {
        icon: '/icon/kz_roadsigns/kz_7_15.png',
        bigIcon: '/icon/kz_roadsigns-big/kz_zn7_15.png',
        name: '7.15',
      },

      7.16: {
        icon: '/icon/kz_roadsigns/kz_7_16.png',
        bigIcon: '/icon/kz_roadsigns-big/kz_zn7_16.png',
        name: '7.16',
      },

      7.17: {
        icon: '/icon/kz_roadsigns/kz_7_17.png',
        bigIcon: '/icon/kz_roadsigns-big/kz_zn7_17.png',
        name: '7.17',
      },

      7.18: {
        icon: '/icon/kz_roadsigns/kz_7_18.png',
        bigIcon: '/icon/kz_roadsigns-big/kz_zn7_18.png',
        name: '7.18',
      },

      7.19: {
        icon: '/icon/kz_roadsigns/kz_7_19.png',
        bigIcon: '/icon/kz_roadsigns-big/kz_zn7_19.png',
        name: '7.19',
      },
    },
  },
];
