import OpenLayers from 'lib/OpenLayers-2.12/OpenLayers.debug';
import Layer from 'js/view/map/Layer';
import RTreeVector from 'js/view/map/RTreeVector';


/**
 * Зум, ниже которого (включительно) для геометрий типа "линия" будет
 * отключена видимость.
 */
const LINE_MIN_ZOOM = 12;

const CustomSVGRenderer = OpenLayers.Class(OpenLayers.Renderer.SVG, {
  drawIcon(featureId, style, location) {
    this._drawIcon(featureId, style, location, '_image');
  },

  drawStart(featureId, style, location) {
    this._drawIcon(featureId, style, location, '_start', '/icon/bg_sel.png');
  },

  _drawIcon(featureId, style, location, sufix, externalGraphic) {
    const resolution = this.getResolution();
    const x = ((location.x - this.featureDx) / resolution + this.left);
    const y = (location.y / resolution - this.top);

    const image = this.nodeFactory(featureId + sufix, 'image');
    image.setAttributeNS(null, 'x', x - style.pointRadius);
    image.setAttributeNS(null, 'y', -y - style.pointRadius);
    image.setAttributeNS(null, 'width', style.pointRadius * 2);
    image.setAttributeNS(null, 'height', style.pointRadius * 2);
    image.setAttributeNS(this.xlinkns, 'xlink:href', externalGraphic || style.externalGraphic);
    image._featureId = featureId;
    image._style = style;

    if (this.indexer) {
      const insert = this.indexer.insert(image);
      if (insert) {
        this.vectorRoot.insertBefore(image, insert);
      } else {
        this.vectorRoot.appendChild(image);
      }
    } else if (image.parentNode !== this.vectorRoot) {
      // if there's no indexer, simply append the node to root,
      // but only if the node is a new one
      this.vectorRoot.appendChild(image);
    }
  },

  removeImages(featureId) {
    const image1 = document.getElementById(`${featureId}_image`);
    const image2 = document.getElementById(`${featureId}_start`);
    if (image1) {
      image1.parentNode.removeChild(image1);
    }
    if (image2) {
      image2.parentNode.removeChild(image2);
    }
  },

  eraseFeatures(features) {
    if (!(OpenLayers.Util.isArray(features))) {
      // eslint-disable-next-line no-param-reassign
      features = [features];
    }
    for (let i = 0, len = features.length; i < len; i += 1) {
      const feature = features[i];
      this.eraseGeometry(feature.geometry, feature.id);
      this.removeText(feature.id);
      this.removeImages(feature.id);
    }
  },

  lessCleverCentroid(geometry) {
    const ring = geometry.components[0];
    const x0 = ring.components[0].x;
    const y0 = ring.components[0].y;
    const len = ring.components.length - 1;

    let sumX = 0.0;
    let sumY = 0.0;
    for (let i = 0; i < len; i += 1) {
      sumX += ring.components[i].x - x0;
      sumY += ring.components[i].y - y0;
    }

    return new OpenLayers.Geometry.Point(sumX / len + x0, sumY / len + y0);
  },

  _area(x0, y0, x1, y1, x2, y2) {
    let dx = (x0 - x1);
    let dy = (y0 - y1);
    const l0 = Math.sqrt(dx * dx + dy * dy);
    dx = (x1 - x2); dy = (y1 - y2);
    const l1 = Math.sqrt(dx * dx + dy * dy);
    dx = (x2 - x0); dy = (y2 - y0);
    const l2 = Math.sqrt(dx * dx + dy * dy);
    const P = (l0 + l1 + l2) / 2;
    return Math.sqrt(P * (P - l0) * (P - l1) * (P - l2));
  },

  lessCleverCentroid2(geometry) {
    const ring = geometry.components[0].components;
    const len = ring.length - 1;
    let sumArea = 0.0;
    let sumWx = 0.0;
    let sumWy = 0.0;
    const xBase = ring[0].x;
    const yBase = ring[0].y;
    let x0;
    let y0;
    let x1;
    let y1;
    let x2;
    let y2;

    for (let i = 0; i < len - 2; i += 1) {
      x0 = 0.0;
      y0 = 0.0;
      x1 = ring[i + 1].x - xBase;
      y1 = ring[i + 1].y - yBase;
      x2 = ring[i + 2].x - xBase;
      y2 = ring[i + 2].y - yBase;
      const area = this._area(x0, y0, x1, y1, x2, y2);
      sumArea += area;
      sumWx += (x0 + x1 + x2) / 3 * area;
      sumWy += (y0 + y1 + y2) / 3 * area;
    }
    return new OpenLayers.Geometry.Point(sumWx / sumArea + xBase, sumWy / sumArea + yBase);
  },

  startPoint(geometry) {
    return geometry.components[0];
  },

  drawFeature(feature, optionalStyle) {
    let style = optionalStyle || feature.style;
    if (feature.geometry) {
      const bounds = feature.geometry.getBounds();
      if (bounds) {
        let worldBounds;
        if (this.map.baseLayer && this.map.baseLayer.wrapDateLine) {
          worldBounds = this.map.getMaxExtent();
        }
        if (!bounds.intersectsBounds(this.extent, { worldBounds })) {
          style = { display: 'none' };
        } else {
          this.calculateFeatureDx(bounds, worldBounds);
        }
        const rendered = this.drawGeometry(feature.geometry, style, feature.id);
        if (style.display !== 'none' && style.label && rendered !== false) {
          const location = feature.geometry.getCentroid();
          if (style.labelXOffset || style.labelYOffset) {
            const xOffset = Number.isNaN(style.labelXOffset) ? 0 : style.labelXOffset;
            const yOffset = Number.isNaN(style.labelYOffset) ? 0 : style.labelYOffset;
            const res = this.getResolution();
            location.move(xOffset * res, yOffset * res);
          }
          this.drawText(feature.id, style, location);
        } else {
          this.removeText(feature.id);
        }
        if (style.display !== 'none' && rendered !== false) {
          if (this.showDirection
            && feature.geometry
            && feature.geometry.components
            && !feature.geometry.components[0].components
            && feature.geometry.components.length > 0
          ) {
            this.drawStart(feature.id, style, this.startPoint(feature.geometry));
          }
          if (style.externalGraphic && feature.geometry && feature.geometry.components) {
            this.drawIcon(feature.id, style, this.lessCleverCentroid2(feature.geometry));
          }
        } else {
          this.removeImages(feature.id);
        }
        return rendered;
      }
    }
  },

  pathAddArrows(path) {
    const size = 15;
    const emptysize = size * 1.5;
    const asize = 0.15;
    const every = 100;
    const oldPath = path.split(',');
    const newPath = [oldPath[0], oldPath[1]];
    let h;
    let a;
    const addArrow = function (a, x, y) {
      newPath.push(x - Math.cos(a + Math.PI * asize) * size);
      newPath.push(y - Math.sin(a + Math.PI * asize) * size);
      newPath.push(x - Math.cos(a) * size * 0.5);
      newPath.push(y - Math.sin(a) * size * 0.5);
      newPath.push(x - Math.cos(a - Math.PI * asize) * size);
      newPath.push(y - Math.sin(a - Math.PI * asize) * size);
      newPath.push(x);
      newPath.push(y);
    };
    if (oldPath.length < 4) {
      return path;
    }
    for (
      let i = 2, prevx = oldPath[0], prevy = oldPath[1], x = oldPath[2], y = oldPath[3];
      i < oldPath.length;
      // eslint-disable-next-line no-plusplus
      prevx = oldPath[i++], prevy = oldPath[i++], x = oldPath[i], y = oldPath[i + 1]
    ) {
      h = Math.hypot(y - prevy, x - prevx);
      a = Math.atan2(y - prevy, x - prevx);
      for (let tmp = every; tmp < (h - emptysize) && tmp > emptysize; tmp += every) {
        prevx -= Math.cos(a + Math.PI) * every;
        prevy -= Math.sin(a + Math.PI) * every;
        if (tmp > emptysize && (h - tmp) > emptysize) {
          newPath.push(prevx);
          newPath.push(prevy);
          addArrow(a, prevx, prevy);
        }
      }
      newPath.push(x);
      newPath.push(y);
      if (h > emptysize) {
        addArrow(a, x, y);
      }
    }
    return newPath.join(',');
  },

  /**
   * Возвращает удвоенный path. Каждая компонента будет получена
   * перепендикулярным смещением относительно исходного path.
   * @param {string} path - path
   * @param {Number} stroke_width - ширина штриха
   * @returns {string} - удвоенный path
   */
  pathDouble(path, stroke_width) {
    function perpendicularOffset(startp, endp) {
      const dist = stroke_width / 2 + 1;
      let nx = endp[1] - startp[1];
      let ny = startp[0] - endp[0];
      const len = Math.sqrt((nx * nx) + (ny * ny));
      nx /= len;
      ny /= len;
      return { dx: dist * nx, dy: dist * ny };
    }

    const oldPath = path.split(',');
    let paths;
    let startp;
    let endp;

    for (let i = 0; i < oldPath.length; i += 1) {
      // eslint-disable-next-line no-plusplus
      endp = [parseFloat(oldPath[i]), parseFloat(oldPath[++i])];
      if (startp !== undefined && startp[0] !== endp[0] && startp[1] !== endp[1]) {
        const n = perpendicularOffset(startp, endp);
        if (paths === undefined) {
          paths = [[], []];
          paths[0].push([startp[0] + n.dx, startp[1] + n.dy].join());
          paths[1].push([startp[0] - n.dx, startp[1] - n.dy].join());
        }
        paths[0].push([endp[0] + n.dx, endp[1] + n.dy].join());
        paths[1].push([endp[0] - n.dx, endp[1] - n.dy].join());
      }
      startp = endp;
    }

    if (paths === undefined) {
      // В оригинальном пути была одна точка или все точки были одинаковыми
      return [`M ${path}`];
    }
    return [`M ${paths[0].join(' ')}`, ` M ${paths[1].join(' ')}`];
  },

  /** Рисует path:
   *   - со стрелочками если this.showDirection == true
   *   - удвоенный если style.fillColor(fill в sld) == 'double'
   *   - удовенно сплошную (справа) если style.fillColor == 'double_solid_right'
   * @param {Object} node - нода
   * @param {Object} geometry - геометрия
   * @param {Object} style - стиль
   * @returns {(Object|Boolean|null)} - false, если нет path;
   *                                    null, если обработка компонентов не завершена, иначе ноду
   */
  drawLineString(node, geometry, style) {
    const componentsResult = this.getComponentsString(geometry.components);
    let { path } = componentsResult;
    const style_modifier = style.fillColor.trim();

    if (path) {
      if (this.showDirection) {
        path = this.pathAddArrows(path);
      }
      if (['double', 'double_solid_right'].indexOf(style_modifier) !== -1) {
        path = this.pathDouble(path, parseInt(style.strokeWidth, 10));
      } else {
        path = [`M ${path}`];
      }

      for (let i = 0; i < path.length; i += 1) {
        const childNode = this.nodeFactory(`${node.getAttributeNS(null, 'id')}_${i}`, 'path');
        childNode.setAttributeNS(null, 'd', path[i]);
        if (style_modifier === 'double_solid_right' && i === 1) {
          childNode.setAttributeNS(null, 'stroke-dasharray', 'none');
        }
        node.appendChild(childNode);
      }
      return (componentsResult.complete ? node : null);
    }
    return false;
  },

  /**
   * Метод полностью скопирован, но теперь в drawLineString мы
   * передаем еще и стиль.
   *
   * @param {Object} node - нода
   * @param {Object} geometry - геометрия
   * @param {Object} optionalStyle - стиль
   * @returns {(Object|Boolean)} - false, если нода уже отображена, иначе объект
   */
  drawGeometryNode(node, geometry, optionalStyle) {
    const style = optionalStyle || node._style;

    const options = {
      isFilled: style.fill === undefined
        ? true
        : style.fill,
      isStroked: style.stroke === undefined
        ? !!style.strokeWidth
        : style.stroke,
    };
    let drawn;
    switch (geometry.CLASS_NAME) {
      case 'OpenLayers.Geometry.Point':
        if (style.graphic === false) {
          options.isFilled = false;
          options.isStroked = false;
        }
        drawn = this.drawPoint(node, geometry);
        break;
      case 'OpenLayers.Geometry.LineString':
        options.isFilled = false;
        drawn = this.drawLineString(node, geometry, style);
        break;
      case 'OpenLayers.Geometry.LinearRing':
        drawn = this.drawLinearRing(node, geometry);
        break;
      case 'OpenLayers.Geometry.Polygon':
        drawn = this.drawPolygon(node, geometry);
        break;
      case 'OpenLayers.Geometry.Rectangle':
        drawn = this.drawRectangle(node, geometry);
        break;
      default:
        break;
    }

    node._options = options; // eslint-disable-line no-param-reassign

    // set style
    // TBD simplify this
    if (drawn !== false) {
      return {
        node: this.setStyle(node, style, options, geometry),
        complete: drawn,
      };
    }
    return false;
  },

  /**
   * Метод полностью скопирован, но теперь LineString рендерится
   * через path, а не polyline
   * @param {Object} geometry - тип геометрии
   * @param {Object} style - стиль
   * @returns {string} - тип ноды
   */
  getNodeType(geometry, style) {
    let nodeType = null;
    switch (geometry.CLASS_NAME) {
      case 'OpenLayers.Geometry.Point':
        if (style.externalGraphic) {
          nodeType = 'image';
        } else if (this.isComplexSymbol(style.graphicName)) {
          nodeType = 'svg';
        } else {
          nodeType = 'circle';
        }
        break;
      case 'OpenLayers.Geometry.Rectangle':
        nodeType = 'rect';
        break;
      case 'OpenLayers.Geometry.LinearRing':
        nodeType = 'polygon';
        break;
      case 'OpenLayers.Geometry.LineString':
        nodeType = 'g';
        break;
      case 'OpenLayers.Geometry.Polygon':
      case 'OpenLayers.Geometry.Curve':
        nodeType = 'path';
        break;
      default:
        break;
    }
    return nodeType;
  },
});

const PolygonOrLineLayer = Layer.extend({
  createLayer() {
    const meta = this.model.getMeta();
    return new RTreeVector(meta.get('objectName'), {
      styleMap: meta.getStyleMap(),
      rendererOptions: {
        zIndexing: true,
        showDirection: meta.drawArrows(),
      },
      visibility: this.model.get('visible'),
      renderers: [CustomSVGRenderer],
    });
  },

  /**
   * Получить видимость слоя.
   *
   * Скрывает слои с геометрией типа "линия" на зумах
   * меньше `LINE_MIN_ZOOM` (включительно).
   *
   * @returns {Boolean} видимость слоя
   */
  evaluateVisibility() {
    const parentEvaluateVisibility = PolygonOrLineLayer.__super__.evaluateVisibility.apply(this);
    const hideLines = (this.model.getMeta().getGeometryType() === 'line')
                && this.options.map.map.zoom <= LINE_MIN_ZOOM;
    return parentEvaluateVisibility && !hideLines;
  },
});

export default PolygonOrLineLayer;
