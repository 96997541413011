import $ from 'jquery';
import _ from 'underscore';
import LogRocket from 'logrocket';
import * as Sentry from '@sentry/browser';
import OpenLayers from 'lib/OpenLayers-2.12/OpenLayers.debug';
import Backbone from 'js/backbone';
import OpenLayersCustom from 'js/view/map/OpenLayersCustom';
import AIS from 'js/AIS';
import api from 'js/api';
import * as consts from 'js/consts';
import {
  AddressFilter,
  FeaturesFilter,
  LayersFieldsFilter,
  LayersFilter,
  OpticLayerFilter,
  OpticPoleFilter,
  PanoFilter,
  RegionFilter,
  RoadsFilter,
  TrafficLightNodeFilter,
  ValidYearFilter,
} from 'js/filter';
import getBaseLayers from 'js/view/map/GetBaseLayers';
import getCustomLayers from 'js/view/map/GetCustomLayers';
// import getRosreestrLayers from 'js/view/map/GetRosreestrLayers';
import Map from 'js/view/map/Map';
import Grid from 'js/view/grid/Grid';
import RoadsGrid from 'js/view/grid/RoadsGrid';
import Layout from 'js/Layout';
import Login from 'js/view/modal/Login';
import Pano from 'js/model/Pano';
import Core from 'js/Core';
import Workset from 'js/model/Workset';
import User from 'js/model/User';
import FilterPanel from 'js/view/filter/FilterPanel';
import PanoPanel from 'js/view/pano/PanoPanel';
import EditorPanel from 'js/view/editor/EditorPanel';
import 'js/view/map/OpenLayersMonkeyPatch';
import { disableContext, enableContext, updateAllTooltips } from 'js/hotkeys';
import renderReactHeaderApp from 'js/react-header-app';
import createStore from 'js/store';


Backbone.sync = () => null;

OpenLayers.Custom = OpenLayersCustom;

_.extend(AIS, Core);

/** Роутинг в приложении.
 *
 * Приложение не использует роутинг, не считая панорам. Панорамы обрабатываем следующим образом:
 * #pano=prof-2016-10-20:1007,-350.76,0 - обозначение панорамы в урле.
 * -350.76, 0 - angles - углы поворота панорамы.
 * 1007 - pano - айдишник панорамы.
 * prof-2016-10-20 - session - папка, в которой ищется панорама (?).
 */
const HashRouter = Backbone.Router.extend({
  routes: {
    'pano=:panoState': 'pano',
  },
  pano(panoState) {
    const splitted = panoState.split(',');
    const sespano = splitted[0].split(':');
    const session = sespano[0];
    const original_id = parseInt(sespano[1], 10);
    const angles = [splitted[1], splitted[2]];
    AIS.panoPanel.panoView.navigate(original_id, session, angles);
  },
});
AIS.router = new HashRouter();

AIS.workset = new Workset();

function initialRender() {
  AIS.layout = new Layout({
    el: $('body'),
  });
  AIS.layout.render();

  AIS.grid = new Grid({
    el: $('#outer-list'),
    workset: AIS.workset,
  });

  if (!AIS.featureEnabled('hide_roads_tab')) {
    AIS.roads_grid = new RoadsGrid({
      el: $('#roads'),
    });
  }

  AIS.editorPanel = new EditorPanel({
    workset: AIS.workset,
  });
  $('#left-panel').empty().append(AIS.editorPanel.render().el);
}

/** Создаем карту, добавляем на нее необходимые слои, центрируем.
 * @param {Object} config - конфигурация
 * @returns {Promise} - промис, который будет заполнен при получении результата
 */
async function createMap(config) {
  $('title').text(`${config.name} - ${$('title').text()}`);

  const layers = [
    ...getBaseLayers(config.map_config),
    ...await getCustomLayers(config),
    // ...await getRosreestrLayers(),
  ];

  const map = new Map({
    el: $('#map'),
    pano1: AIS.pano1,
    pano2: AIS.pano2,
    workset: AIS.workset,
    layers: layers,
  });

  if (config.center && config.center.lon && config.center.lat) {
    AIS.trigger('map:center', config.center.lon, config.center.lat);
  }

  return map;
}

/** Создание панорам. */
function createPano() {
  AIS.pano1 = new Pano({ icon: '/icon/point-red.png', name: 'main' }, { workset: AIS.workset });
  AIS.pano2 = new Pano({ icon: '/icon/point-blue.png', name: 'extra' }, { workset: AIS.workset });
}

/** Создаем панель с панорамами.
 * @returns {PanoPanel} - панель с панорамами
 */
function createPanoPanel() {
  const panoPanel = new PanoPanel({
    el: $('.panos'),
    pano1: AIS.pano1,
    pano2: AIS.pano2,
    workset: AIS.workset,
  });
  panoPanel.render();
  panoPanel.createLayout();
  return panoPanel;
}

/** Создаем панель фильтров.
 * @returns {FilterPanel} - панель фильтров
 */
function createFilterPanel() {
  const filterPanel = new FilterPanel({
    workset: AIS.workset,
  });
  $('#right-panel').empty().append(filterPanel.render().el);
  return filterPanel;
}

/** Вызывается после получения конфига.
 *
 * Пытаемся авторизоваться, если не удается, отображаем модальное окно авторизации.
 * После успешной авторизации выводим имя пользователя рядом с кнопкой выхода.
 */
async function auth() {
  const waiter = User.waitForLogin();
  if (!await User.login()) {
    const loginView = new Login();
    loginView.render();
  }
  await waiter;
}

/**
 * Создать фильтр и подписать панораму на его изменения.
 * @param  {Pano}  pano панорама
 * @returns {array}      массив из фильтра и событий
 */
async function subscribePano(pano) {
  const panoFilter = await PanoFilter.Create(
    AIS.featuresFilter.id,
    pano.id,
    consts.PANO_DISPLAY_DISTANCE,
    pano.id,
    consts.PANO_ARCHIVE_DISPLAY_DISTANCE,
  );
  const panoEvents = await api.subscribe('pano', panoFilter);
  panoEvents.on('diff_update', (...args) => pano.onUpdate(...args), this);
  pano.on('change:id', () => {
    const actual_pano = pano.get('actual_pano') || {};
    if (panoFilter.displayPanoId !== pano.id || panoFilter.panoId !== actual_pano.id) {
      panoFilter.changePanoId(pano.id, actual_pano.id);
    }
  }, this);
  return [panoFilter, panoEvents];
}

/**
 * Создать фильтр на основе существующего, либо пустой фильтр.
 *
 * Если у пользователя уже есть принадлежащий ему фильтр с указанным ключом, то на его основе будет создан
 * новый фильтр указанного типа. Иначе будет создан пустой фильтр указанного типа.
 * Идентификатор нового созданного фильтра сохраняется.
 *
 * @param {Object} filterClass класс фильтра, экземпляр которого будет создаваться
 * @param {string} filterKey ключ фильтра, по которому проверяется наличие фильтра среди принадлежащих пользователю
 * @returns {Filter} новый фильтр
 */
async function cloneOrCreateFilter(filterClass, filterKey) {
  const state = AIS.store.getState();
  const userFilters = state.user.filters || {};

  let filter;

  if (filterKey in userFilters) {
    const sourceFilterId = userFilters[filterKey];
    const sourceFilterData = await api.getFilterData(sourceFilterId);
    filter = await filterClass.deserialize(sourceFilterData);
  } else {
    filter = await filterClass.Create();
  }

  await api.updateUserFilters({ ...userFilters, [filterKey]: filter.id });
  return filter;
}

/**
 * Создать на сервере все нужные фильтры и подключиться к ним.
 *
 * Вызывается в случае успешной авторизации.
 * Фильтр по слоям `LayersFilter` создаётся на основе существующего фильтра, чтобы список слоёв сохранялся.
 */
async function setupFilters() {
  AIS.layersFilter = await cloneOrCreateFilter(LayersFilter, 'layers'); // фильтр по добавленным слоям
  AIS.regionFilter = await RegionFilter.Create(); // фильтр по региону
  AIS.addressFilter = await AddressFilter.Create(); // фильтр по району и/или улице
  AIS.trafficLightNodeFilter = await TrafficLightNodeFilter.Create(); // фильтр по району и/или улице
  AIS.layersFieldsFilter = await LayersFieldsFilter.Create(); // фильтр по району и/или улице
  AIS.validYearFilter = await ValidYearFilter.Create(); // фильтр по году действия
  AIS.roadsFilter = await RoadsFilter.Create(); // фильтр для дорог

  const incompleteFeaturesFilter = await FeaturesFilter.Create([
    AIS.layersFilter.id,
    AIS.regionFilter.id,
    AIS.addressFilter.id,
    AIS.trafficLightNodeFilter.id,
    AIS.layersFieldsFilter.id,
    AIS.validYearFilter.id,
  ]); // фильтр, объединяющий все фильтры фич, кроме фильтра опор по оптике

  AIS.opticPoleFilter = await OpticPoleFilter.Create(
    (
      await OpticLayerFilter.Create([
        incompleteFeaturesFilter.id,
      ])
    ).id, // фильтр, в который попадают видимые линии оптики
  ); // фильтр опор по линиям оптики

  AIS.featuresFilter = await FeaturesFilter.Create([
    incompleteFeaturesFilter.id,
    AIS.opticPoleFilter.id,
  ]); // фильтр, объединяющий все фильтры фич

  AIS.layersEvents = await api.subscribe('wgs', AIS.layersFilter);
  AIS.featuresEvents = await api.subscribe('ids', AIS.featuresFilter);
  AIS.roadsEvents = await api.subscribe('roads', AIS.roadsFilter);

  if (!AIS.featureEnabled('hide_roads_tab')) {
    AIS.roads_grid.subscribe();
  }

  [AIS.panoFilter1, AIS.panoEvents1] = await subscribePano(AIS.pano1);
  [AIS.panoFilter2, AIS.panoEvents2] = await subscribePano(AIS.pano2);

  AIS.workset.loadInitialFilters();

  // после того, как фильтры созданы, мы можем подписаться на уведомления и показывать крутилку
  AIS.filterPanel.subscribeLoading();
}

const setupHotkeys = () => {
  // при открытии и закрытии модальных окон включаем и отключаем горячие клавиши для них
  $(window).on('shown.bs.modal', (event) => {
    /* нужна проверка, что это действительно модалка, потому что бутстрап запускает одинаковые события для
     * модалок и табов (один общий shown)
     */
    if (event.target.classList.contains('modal')) {
      enableContext('modal');
    }
  });
  $(window).on('hidden.bs.modal', (event) => {
    if (event.target.classList.contains('modal')) {
      disableContext('modal');
    }
  });

  // активируем контексты горячих клавиш
  enableContext('globalHelp');
  enableContext('questionMark');
  enableContext('tabs');

  // подписываемся на клики, нажатия клавиш и изменения DOM, чтобы вовремя обновлять тултипы
  document.body.addEventListener('click', updateAllTooltips);
  document.body.addEventListener('keypress', updateAllTooltips);
  (new MutationObserver(updateAllTooltips))
    .observe(
      document.body,
      {
        childList: true,
        subtree: true,
      },
    );
};

async function main() {
  // создаём хранилище Redux, где указанные ключи синхронизируются с коллекциями Backbone
  AIS.store = createStore({
    workset: AIS.workset,
    ephemeras: AIS.ephemeras,
    meta: AIS.meta,
  });

  await auth();
  const currentUser = AIS.store.getState().user;
  if ('logrocketToken' in AIS.environment && AIS.environment.logrocketToken !== '') {
    LogRocket.init(AIS.environment.logrocketToken);
    LogRocket.identify(currentUser.username + currentUser.token, { name: currentUser.username });
  }

  if ('sentryDSN' in AIS.environment && AIS.environment.sentryDSN !== '') {
    Sentry.init({
      dsn: AIS.environment.sentryDSN,
      tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
      // Session Replay
      // replaysSessionSampleRate: 0.1,
      replaysSessionSampleRate: AIS.environment.sentryReplaysSessionSampleRate,
      replaysOnErrorSampleRate: 1.0,
      integrations: [
        new Sentry.BrowserTracing(),
        new Sentry.Replay({
          mutationLimit: AIS.environment.sentryMutationLimit,
          maskAllText: false,
          maskAllInputs: false,
          blockAllMedia: false,
        }),
      ],
    });
    Sentry.setUser({
      username: currentUser.username,
      id: currentUser.username + currentUser.token,
      ip_address: '{{auto}}',
    });
  }

  AIS.config = await api.getConfig();

  // запускаем рендеринг реакта
  renderReactHeaderApp();

  initialRender();

  createPano();

  AIS.map = await createMap(AIS.config);
  AIS.panoPanel = createPanoPanel();
  AIS.filterPanel = createFilterPanel();

  if (!Backbone.History.started) {
    Backbone.history.start();
  }

  AIS.relations = (await api.getRelations()).relations;
  const meta_json_revision = await AIS.meta.getMetaWithSLD();
  api.onMetaRevisionChange(meta_json_revision).then(
    () => {
      $('#notice-metajson-window').dialog({
        draggable: false,
        resizable: false,
        width: 700,
        height: 100,
        position: { at: 'bottom', of: window },
        closeOnEscape: false,
        open: () => {
          $('.ui-dialog-titlebar-close').hide();
        },
      });
    },
  );

  setupHotkeys();

  await setupFilters();
}

$(document).ready(() => {
  main().catch((error) => {
    $('body').html($('#tmpl-unavailable').html());
    throw error;
  });
});
