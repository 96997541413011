exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".antd-scope #header___1F-BW {\n  height: 42px;\n  line-height: 42px;\n  padding: 0 20px;\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n}\n.antd-scope #header_menu___3gAn9 {\n  line-height: 42px;\n  font-size: 16px;\n}\n.antd-scope #header_right___3VGVw {\n  max-width: 40vw;\n}\n.antd-scope #header_right___3VGVw #help_button___TFgdo {\n  margin: 0px 5px;\n}\n.antd-scope .quickCreationDropdown___1Ie5b > ul.ant-dropdown-menu {\n  overflow-y: auto;\n  max-height: 90vh;\n}\n.antd-scope .quickCreationDropdownGroup___2fwVt div {\n  font-weight: bold;\n}\n.antd-scope .quickCreationDropdownGroup___2fwVt ul {\n  padding-inline-start: 0px;\n}\n.antd-scope .quickCreationDropdownItem___1M67x {\n  list-style-type: none;\n  padding: 5px 12px 5px 25px !important;\n}\n.antd-scope .quickCreationDropdownItem___1M67x span {\n  position: relative;\n}\n.antd-scope #search_box___1rxDy {\n  max-width: 400px;\n  width: 20vw;\n  min-width: 150px;\n}\n.antd-scope #username___2zgCg:hover,\n.antd-scope #username___2zgCg:active,\n.antd-scope #username___2zgCg:focus {\n  color: rgba(0, 0, 0, 0.65);\n}\n", ""]);

// Exports
exports.locals = {
	"header": "header___1F-BW",
	"header_menu": "header_menu___3gAn9",
	"header_right": "header_right___3VGVw",
	"help_button": "help_button___TFgdo",
	"quickCreationDropdown": "quickCreationDropdown___1Ie5b",
	"quickCreationDropdownGroup": "quickCreationDropdownGroup___2fwVt",
	"quickCreationDropdownItem": "quickCreationDropdownItem___1M67x",
	"search_box": "search_box___1rxDy",
	"username": "username___2zgCg"
};