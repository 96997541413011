import _ from 'underscore';
import OpenLayers from 'lib/OpenLayers-2.12/OpenLayers.debug';
import CompactPopup from 'js/view/map/CompactPopup';


const _cluster_style = {
  semaforo: {
    renderIntent: 'default',

    graphicWidth: 46,
    graphicHeight: 37,
    graphicXOffset: -15,
    graphicYOffset: -35,
    externalGraphic: '/icon/tl_cluster.png',

    fontWeight: 'bold',
    labelAlign: 'cm',
    labelXOffset: '0',
    labelYOffset: '18',
    fontColor: 'white',
    label: '${getLabel}', // eslint-disable-line no-template-curly-in-string
  },
  sign: {
    renderIntent: 'default',

    graphicWidth: 32,
    graphicHeight: 50,
    graphicXOffset: -16,
    graphicYOffset: -48,
    externalGraphic: '/icon/sign_cluster.png',

    fontWeight: 'bold',
    labelAlign: 'cm',
    labelXOffset: '0',
    labelYOffset: '18',
    fontColor: 'black',
    label: '${getLabel}', // eslint-disable-line no-template-curly-in-string
  },
};

const cluster_context = {
  getLabel: function (feature) {
    if (feature.cl || feature.CLASS_NAME === 'RTreeClusterFeature') {
      return feature.attributes.count;
    }
    return null;
  },
};

const cluster_style = {
  semaforo: new OpenLayers.Style(_cluster_style.semaforo, { context: cluster_context }),
  sign: new OpenLayers.Style(_cluster_style.sign, { context: cluster_context }),
};

cluster_style.semaforo_project = cluster_style.semaforo;
cluster_style.sign_project = cluster_style.sign;
cluster_style.sign_run = cluster_style.sign;
cluster_style.semaforo_run = cluster_style.sign;

const RTreeClusterFeature = OpenLayers.Class(OpenLayers.Feature.Vector, {
  cl: null,
  dirty: true,

  selectControl: null,
  popup: null,
  featureInFocus: false,
  popupInFocus: false,
  popupTimeoutDelay: 300,
  popupTimeoutId: null,

  initialize: function (ll, className) {
    let style = null;
    if (className && cluster_style[className]) {
      style = cluster_style[className];
    }
    OpenLayers.Feature.Vector.prototype.initialize.call(this,
      new OpenLayers.Geometry.Point(ll.lon, ll.lat),
      { count: 0 },
      style);
    this.cl = [];
  },

  clean: function () {
    this.dirty = false;
  },

  isClean: function () {
    return !this.dirty;
  },

  attach: function (feature) {
    feature.cluster = this; // eslint-disable-line no-param-reassign
    this.cl.push(feature);
    this.attributes.count = this.cl.length;
    this.dirty = true;
  },

  detach: function (feature) {
    const index = _.indexOf(this.cl, feature);
    if (index >= 0) {
      feature.cluster = null; // eslint-disable-line no-param-reassign
      this.cl.splice(index, 1);
      this.attributes.count = this.cl.length;
      this.dirty = true;
      return true;
    }
    return false;
  },

  renderPopup: function () {
    const me = this;
    const div = document.createElement('div');
    const clusters = _.sortBy(this.cl, feature => Number(feature.model.id));
    let w = 0;
    let h = 0;
    _.each(clusters, (feature) => {
      const img = document.createElement('img');
      const style = feature.model.getStyle();
      if (style.rotation) {
        img.style.transform = `rotate(${style.rotation}deg)`;
      }
      img.title = feature.model.get('id');
      img.src = style.externalGraphic;
      img.style.cursor = style.pointer;
      img.style.height = `${style.graphicHeight}px`;
      w += style.graphicHeight;
      h = Math.max(h, style.graphicHeight);
      img.addEventListener('click', (event) => {
        if (!event.ctrlKey) {
          event.stopPropagation();
          return me.selectControl.clickFeature(feature, me.popup.lonlat);
        }
      });
      div.appendChild(img);
    }, this);
    div.style.textAlign = 'center';
    div.style.width = `${w}px`;
    div.style.height = `${h}px`;
    return div;
  },

  createPopup: function (lonlat, selectControl) {
    if (this.popup) {
      this.destroyPopup();
    }
    this.selectControl = selectControl;
    this.popupInFocus = false;
    this.featureInFocus = true;
    this.popup = new CompactPopup(`cluster_popup_${this.id}`,
      lonlat, null,
      this.renderPopup(),
      null, false,
      null);
    this.popup.setMousePropogation(true);
    this.popup.events.on({
      destroy: this.removePopup,
      mouseover: function () {
        this.popupInFocus = true;
        this.clearTimeout();
      },
      mouseout: function () {
        this.popupInFocus = false;
        this.setTimeout();
      },
      scope: this,
    });

    if (this.layer && this.layer.map) {
      this.addToMap(this.layer.map);
    }

    return this.popup;
  },

  addToMap: function (map) {
    map.addPopup(this.popup);
    this.popup.updateSize();
  },

  featureIn: function () {
    this.featureInFocus = true;
    this.clearTimeout();
  },

  featureOut: function () {
    this.featureInFocus = false;
    this.setTimeout();
  },

  setTimeout: function () {
    this.clearTimeout();
    const me = this;
    this.popupTimeoutId = setTimeout(() => {
      if (!me.featureInFocus && !me.popupInFocus) {
        me.destroyPopup();
        me.clearTimeout();
      }
    }, this.popupTimeoutDelay);
  },

  clearTimeout: function () {
    if (this.popupTimeoutId !== null) {
      clearTimeout(this.popupTimeoutId);
      this.popupTimeoutId = null;
    }
  },

  removePopup: function () {
    this.clearTimeout();
    if (this.popup) {
      this.popup = null;
      this.selectControl = null;
    }
  },

  destroyPopup: function () {
    if (this.popup) {
      this.popup.destroy();
      this.removePopup();
    }
  },

  destroy: function () {
    this.cl = null;
    this.destroyPopup();
    OpenLayers.Feature.Vector.prototype.destroy.apply(this, arguments);
  },

  CLASS_NAME: 'RTreeClusterFeature',
});

export default RTreeClusterFeature;
