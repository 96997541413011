import $ from 'jquery';
import Backbone from 'js/backbone';
import AIS from 'js/AIS';
import api from 'js/api';


const ExportTool = Backbone.View.extend({
  className: 'panel',

  events: {
    'click .export-drawing': 'exportDrawing',
  },

  set_loading(loading) {
    this.$el.find('input, button').prop('disabled', loading);
    if (loading) this.$el.find('.spinner').show();
    else this.$el.find('.spinner').hide();
  },

  export_success(filename) {
    const step_ms = 1000;
    const max_wait_ms = 1000 * 60 * 30;
    const url = `/storage/${filename}`;

    const start = new Date().getTime();
    const that = this;
    const tick = function () {
      const now = new Date().getTime();
      if ((now - start) > max_wait_ms) {
        that.set_loading(false);
        return;
      }
      $.ajax(`${url}.ready`, {
        type: 'HEAD',
        dataType: 'text',
        success() {
          that.$el.find('.download-frame').prop('src', url);
          that.set_loading(false);
        },
        error() {
          setTimeout(tick, step_ms);
        },
      });
    };

    this.set_loading(true);
    setTimeout(tick, step_ms);
  },

  exportDrawing() {
    const format = this.$el.find('input[name=export-format]:checked').val() || 'dxf';
    const region_wkt = AIS.regionFilter.variables().regionGeom;

    if (!(format === 'dxf' || format === 'pdf')) {
      alert(`Неподдерживаемый формат экспорта: ${format}`); // eslint-disable-line no-alert
      return;
    }
    if (region_wkt === undefined) {
      alert('Необходимо указать фильтр по области.'); // eslint-disable-line no-alert
      return;
    }

    api.exportDXF(
      format,
      AIS.regionFilter.variables().regionGeom,
    ).then(
      (response) => {
        this.export_success(response.filename);
      },
    ).catch(
      () => {
        alert('Невозможно получить ответ от сервера.'); // eslint-disable-line no-alert
      },
    );
  },

  render() {
    this.$el.html(`<div class="panel-name"><span>Печать дислокации</span></div>${
      AIS.featureEnabled('export_pdf')
        ? '<label class="radio"><input type="radio" name="export-format" value="pdf" checked>PDF</label>'
                    + '<label class="radio"><input type="radio" name="export-format" value="dxf">AutoCAD</label>'
        : ''
    }<button class="btn export-drawing" data-loading-text="Печать...">Печать</button>`
                + '<img src="/icon/ajax-loader.gif" class="spinner" style="display: none">'
                + '<iframe class="download-frame" style="display:none"></iframe>');

    return this;
  },
});

export default ExportTool;
