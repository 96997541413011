import $ from 'jquery';
import _ from 'underscore';
import Backbone from 'js/backbone';


const GlobalFilter = Backbone.Model.extend({
  submit(name, value) {
    if (this.get(name) !== value) {
      this.once(`change:${name}`, this.triggerGlobal, this);
      this.set(name, value);
      this.off(`change:${name}`, this.triggerGlobal);
    }
  },
  triggerGlobal() {
    AIS.trigger('global_filter');
  },
});

const Application = Backbone.Model.extend({
  initialize: function () {
    this.address = new Backbone.Model();
    this.ephemeras = new Backbone.Collection();
    this.globalFilters = new GlobalFilter();
  },
  getService() {
    return this.service;
  },
  featureEnabled(name) {
    return _.indexOf(this.config.features, name) !== -1;
  },
  pano_click(classId, id) {
    const collection = this.workset.get(classId).getCollection();
    const model = collection.get(id);
    this.trigger('select_model', model);
  },
  lockAlert() {
    $('#notice-window').modal('show');
  },
});

window.AIS = new Application();
export default window.AIS;
