import $ from 'jquery';
import Backbone from 'js/backbone';
import 'backbone.stickit';
import AIS from 'js/AIS';
import User from 'js/model/User';

/** Модальное окно авторизации. */
export default Backbone.View.extend({
  el: $('#login-window'),
  model: User,
  events: {
    'click button.btn-success': 'login', // при нажатии на кнопку "войти" пытаемся залогиниться.
    'click button.btn-guest': 'guest_login', // при нажатии на кнопку "гостевой доступ" заполняем поля и входим.
    keydown: 'handle_enter_keydown', // при нажатии на Enter пытаемся залогиниться.
  },
  /** Двусторонний дата биндинг в беконе. */
  bindings: {
    '#username': 'username',
    '#password': 'password',
  },

  /** Обработка нажатия клавиши Enter.
   * @param {Event} event - событие нажатия на клавишу keydown
   */
  handle_enter_keydown(event) {
    if (event.which === 13) { // enter key
      this.login();
    }
  },

  /** Потряхивание окна логина. Используем в случае, если авторизация не была пройдена. */
  shake() {
    const me = this;
    me.$el.css('left', '48%');
    setTimeout(() => {
      me.$el.css('left', '50%');
    }, 50);
    setTimeout(() => {
      me.$el.css('left', '52%');
    }, 100);
    setTimeout(() => {
      me.$el.css('left', '50%');
    }, 150);
  },

  /** Сокрытие модального окна логина. */
  hide() {
    this.$el.modal('hide');
  },

  /** Авторизация пользователя.
  *
  * Пытаемся авторизоваться:
  * * если смогли, то прячем модальное окно
  * * иначе потряхиваем модальное окно
  */
  async login() {
    if (await this.model.login(false)) {
      this.hide();
    } else {
      this.shake();
    }
  },

  /** Авторизация гостевого пользователя.
  *
  * Заполняем поля ввода значениями по умолчанию и пытаемся авторизоваться
  */
  async guest_login() {
    this.model.set('username', 'guest');
    this.model.set('password', 'guest');
    this.login();
  },

  /** Рендерим модальное окно авторизации.
   * @returns {Backbone.View} - модальное окно авторизации
   */
  render() {
    this.$el.html($('#tmpl-login-modal').html());
    this.$el.modal();
    const hasGuestAccess = ('guestAccess' in AIS.environment) && AIS.environment.guestAccess;
    this.el.getElementsByClassName('btn-guest')[0].style.display = hasGuestAccess ? 'inline' : 'none';
    this.stickit();
    return this;
  },
});
