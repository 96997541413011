/* eslint-disable */
import numeric from 'numeric';
import GeographicLib from 'geographiclib';

var KrpanoAnglesSolver = {};

KrpanoAnglesSolver.toRadians = 0.01745329;
KrpanoAnglesSolver.toDegrees = 57.29577951;
//    KrpanoAnglesSolver.lengthVec = 15000;
KrpanoAnglesSolver.LTPTranslationConst = 1000.0;

KrpanoAnglesSolver.lengthVec = function () {
    return 10000;
};

KrpanoAnglesSolver.getVecFromKrpanoAngles = function (krangles) {
// Get unit vector for given krpano angles in 'pano' coordinate system.
//  Pano CS: imagine a car and a pano with the cut at the front part of it. Here z is backwards, x to the left, y down.
    // krangles = {hlookat, vlookat}
    var phi = krangles.hlookat || 0;
    var theta = krangles.vlookat || 0;
    //phi,theta -> radians
    phi *= this.toRadians;
    theta *= this.toRadians;
    var y = Math.sin(theta);
    var x = Math.cos(theta) * Math.sin(phi);
    var z = Math.cos(theta) * Math.cos(phi);
    var p_pano = [x, y, z];
    return p_pano;
};

KrpanoAnglesSolver.RT4x4 = function (r, t) {
    var RT = [
        [r[0][0], r[0][1], r[0][2], t[0]],
        [r[1][0], r[1][1], r[1][2], t[1]],
        [r[2][0], r[2][1], r[2][2], t[2]],
        [0, 0, 0, 1]
    ];
    return RT;
};

KrpanoAnglesSolver.ChangeCS = function (vec, r, t) {
// change coordinate system with r(rotation matrix), t(translation vector)
    var vec4 = [vec[0], vec[1], vec[2], 1];
    var RT = this.RT4x4(r, t);
    var rez4 = numeric.dot(RT, vec4); // RT*vec4
    // if point in infinity
    if (Math.abs(rez4[3]) < 0.00001)
        return [0, 0, 0];
    var rez = [rez4[0] / rez4[3], rez4[1] / rez4[3], rez4[2] / rez4[3]];
    return rez;
};

KrpanoAnglesSolver.getCosSin = function (angle) {
    var c = Math.cos(angle);
    var s = Math.sin(angle);
    return [c, s];
};

KrpanoAnglesSolver.givensMatrices = function (angles) {
    var yaw = angles[0];
    var pitch = angles[1];
    var roll = angles[2];

    // individual Givens rotations
    var cs = this.getCosSin(roll);
    var givensX = [
        [1, 0, 0],
        [0, cs[0], -cs[1]],
        [0, cs[1], cs[0]]
    ];
    var cs = this.getCosSin(pitch);
    var givensY = [
        [cs[0], 0, cs[1]],
        [0, 1, 0],
        [-cs[1], 0, cs[0]]
    ];
    var cs = this.getCosSin(yaw);
    var givensZ = [
        [cs[0], -cs[1], 0],
        [cs[1], cs[0], 0],
        [0, 0, 1]
    ];

    return [givensX, givensY, givensZ];
};

KrpanoAnglesSolver.RfromEuler = function (angles, anglesInRadians) {
// Computes rotation matrix from Euler angles (aerospace-style, like xsens). The angles are converted from degrees to radians if necessary. '''
    var anlglesVec = [angles.yaw, angles.pitch, angles.roll];
    var ifanglesInRadians = anglesInRadians || false;
    if (!ifanglesInRadians)
        anlglesVec = numeric.mulVS(anlglesVec, this.toRadians);
    var gMs = this.givensMatrices(anlglesVec);
    return numeric.dot(numeric.dot(gMs[2], gMs[1]), gMs[0]);
};

KrpanoAnglesSolver.LTPTranslation = function (ref, p) {
// Find NWU-system LTP coordinates of translation vector between two WGS-84 points (in mm).
    var geod = GeographicLib.Geodesic.WGS84;
    var lon_ref = ref.lon;
    var lat_ref = ref.lat;
    var A_ref = ref.alt;
    var lon_p = p.lon;
    var lat_p = p.lat;
    var A_p = p.alt;
    var rr = geod.Inverse(lat_ref, lon_ref, lat_p, lon_p);
    var az12 = rr.azi1;
    var dist = rr.s12;
    az12 *= this.toRadians;

    var N = dist * Math.cos(az12);
    var W = -dist * Math.sin(az12);
    return numeric.mulVS([N, W, A_p - A_ref], this.LTPTranslationConst);
};

KrpanoAnglesSolver.getKrpanoAnglesFromVec = function (vec) {
// Get krpano hlookat and vlookat from vector in 'pano' coordinate system.
    var norm = Math.sqrt(vec[0] * vec[0] + vec[1] * vec[1] + vec[2] * vec[2]);
    var x = vec[0] / norm;
    var y = vec[1] / norm;
    var z = vec[2] / norm;
    //atan2(x,z) => arctan(x/z)
    return [(Math.atan2(x, z)) * this.toDegrees, (Math.asin(y) * this.toDegrees)];
};

KrpanoAnglesSolver.getKrpanoAngles =
    function (krangles, rotations, translations, rotations2, translations2) {
// main function
// take objects
//		krangles = {hlookat, vlookat}
//		rotations = {yaw, pitch, roll}
//		translations = {lat, lon, alt}
// return vector [hlookat, vlookat]
        // correction rotations
        rotations.yaw = -rotations.yaw + 180.0;
        rotations2.yaw = -rotations2.yaw + 180.0;
        // get unit vector in first panoram
        var vecPano1 = this.getVecFromKrpanoAngles(krangles);
        // to IMU coordinate system
        var R_pano_IMU = [
            [0, 0, -1],
            [1, 0, 0],
            [0, -1, 0]
        ];
        //var vecIMU = ChangeCS(vecPano1, R_pano_IMU, [0, 0, 0]);
        var vecIMU = numeric.dot(R_pano_IMU, vecPano1);
        // calculate rotations for IMU in global coordinates
        var Rtr = [
            [1, 0, 0],
            [0, -1, 0],
            [0, 0, -1]
        ];
        //var RP_NED = this.RfromEuler(rotations);
        var R_IMU_NWU = this.RfromEuler(rotations);//numeric.dot(Rtr, numeric.dot(RP_NED, Rtr));
        // to global coordinate system
        var vecGlob = numeric.dot(R_IMU_NWU, vecIMU);
        var vecGlobl = numeric.mulVS(vecGlob, this.lengthVec());
        // calculate rotations for second panoram
//            var RP_NED2 = this.RfromEuler(rotations2);
        var R_IMU_NWU_2 = this.RfromEuler(rotations2);//numeric.dot(Rtr, numeric.dot(RP_NED2, Rtr));
        // calculate translations of second panoram
        var t_IMU = this.LTPTranslation(translations, translations2);
        // RT4*4 matrix for second IMU
        var RT_IMU_NWU_2 = this.RT4x4(R_IMU_NWU_2, t_IMU);
        RT_IMU_NWU_2 = numeric.inv(RT_IMU_NWU_2);
        // to second IMU
        var univecGlobl = [vecGlobl[0], vecGlobl[1], vecGlobl[2], 1];
        var tmp4 = numeric.dot(RT_IMU_NWU_2, univecGlobl);
        // if point in infinity
        if (Math.abs(tmp4[3]) < 0.00001)
            return [0, 0, 0];
        var vecIMU2 = [tmp4[0] / tmp4[3], tmp4[1] / tmp4[3], tmp4[2] / tmp4[3]];
        // to second panoram
        var v2_pano = numeric.dot(numeric.transpose(R_pano_IMU), vecIMU2);
        var rezAngles = this.getKrpanoAnglesFromVec(v2_pano);
        return rezAngles;
    };

export default KrpanoAnglesSolver;
