import * as actionTypes from 'js/constants/actionTypes';

/**
 * Создать экшн переключения табов.
 * @param {string} activeTab новая вкладка
 * @returns {{type: string, activeTab: string}} экшн переключения табов
 */
export const layoutChangeTab = activeTab => ({ // eslint-disable-line import/prefer-default-export
  type: actionTypes.LAYOUT_CHANGE_TAB,
  activeTab,
});

// TODO: удалить комментарий eslint-disable-..., когда в этом файле появятся ещё функции
