import OpenLayers from 'lib/OpenLayers-2.12/OpenLayers.debug';


const osm = new OpenLayers.Layer.OSM('SPB',
  // eslint-disable-next-line no-template-curly-in-string
  '/spb_basemap/${z}/${x}/${y}.png', {
    transitionEffect: 'resize',
    isBaseLayer: true,
    numZoomLevels: 20,
    tileOptions: { crossOriginKeyword: null },
    attribution: '',
  });

export default [osm];
