import $ from 'jquery';
import Backbone from 'js/backbone';
import FeatureForm from 'js/view/editor/FeatureForm';
import FastCreationPanel from 'js/view/editor/FastCreationPanel';
import AIS from 'js/AIS';
import { enableContext, disableContext } from 'js/hotkeys';


const EditorPanel = Backbone.View.extend({
  initialize: function () {
    this.fastCreation = new FastCreationPanel({
      collection: this.options.workset,
    });
    AIS.on('editor:close', this.showFastCreationPanel, this);
    AIS.on('editor:close', () => disableContext('editor'));
    AIS.on('editor:blank', function (meta, inheritedValues) {
      const Class = meta.getClass();
      const model = new Class();
      this.addForEditing(model, inheritedValues);
    }, this);
  },
  showFastCreationPanel() {
    this.$el.find('.creation-form-content').empty().append(
      this.fastCreation.render().el,
    );
    this.form = null;
  },
  /**
   * Открытие формы для редактирования.
   * Проверяем, если форма редактирования уже открыта и в ней есть несохраненная измененная геометрия,
   * то откроем модальное окно с подсказкой, что изменения следует сохранить или отменить и разблокируем фичу,
   * которую мы пытаемся открыть на редактирование.
   * Если форма редактирования уже открыта, то закроем ее.
   *
   * @param  {Object} model               редактируемая модель фичи
   * @param  {Object} inheritedValues     помещаемые в форму значения полей (используется при нажатии на "сохранить и добавить")
   * @param  {Lock}   lock                лок
   */
  addForEditing(model, inheritedValues, lock) {
    if (this.form) {
      if (this.form.geometryChanged()) {
        $('#unsaved').modal();
        lock.release();
        return;
      }
      this.form.cancel();
    }
    if (!model.getFilter().getVisible()) {
      lock.release();
      // eslint-disable-next-line no-alert
      alert('Невозможно редактировать объект на скрытом слое!');
      return;
    }
    this.form = this.createFeatureForm(model, inheritedValues, lock);
    this.show(this.form);
    AIS.trigger('editor:started');
    enableContext('editor');
  },

  /**
   * Создание формы.
   *
   * @param   {Object} model            редактируемая модель фичи
   * @param   {Object} inheritedValues  помещаемые в форму значения полей (предыдущая модель фичи)
   * @param   {Lock}   lock             лок
   * @returns {Object}  форма редактирования фичи
   */
  createFeatureForm(model, inheritedValues, lock) {
    return new FeatureForm({
      model,
      inheritedValues,
      lock,
    });
  },
  show(view) {
    const panel = this.$el.find('.creation-form-content');
    panel.empty().append(view.render().el);
  },
  /**
   * Получить фичи для отображения на панорамах.
   * @returns {array} фичи для отображения на панорамах
   */
  getPanoFeatures() {
    if (!this.form) { return []; }
    return this.form.getPanoFeatures() || [];
  },
  render() {
    this.$el.empty().append($('#tmpl-editor-panel').html());
    this.showFastCreationPanel();
    return this;
  },
});

export default EditorPanel;
