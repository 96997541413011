import Layer from 'js/view/map/Layer';
import RTreeVector from 'js/view/map/RTreeVector';
import RTreeCluster from 'js/view/map/RTreeCluster';
import RTreeDistanceCluster from 'js/view/map/RTreeDistanceCluster';


const PointLayer = Layer.extend({
  clusteringMaxZoom: 16,
  clusteringActive: null,
  distanceClusteringMaxZoom: 17,
  distanceClusteringActive: null,
  togglingClustering: false,

  initialize: function (options) { // eslint-disable-line no-unused-vars
    Layer.prototype.initialize.apply(this, arguments);

    this.toggleClustering(this.ifNeedClustering(), this.ifNeedDistanceClustering(), true);
  },
  createLayer() {
    this.strategyZoom = new RTreeCluster({
      threshold: 2,
    });
    this.strategyDistance = new RTreeDistanceCluster({
      threshold: 2,
      collection: this.collection,
    });

    const meta = this.model.getMeta();
    const layer = new RTreeVector(meta.get('objectName'), {
      strategies: [this.strategyZoom, this.strategyDistance],
      styleMap: meta.getStyleMap(),
      rendererOptions: { zIndexing: true },
      visibility: this.model.get('visible'),
    });

    layer.events.register('moveend', this, function (event) {
      if (!this.togglingClustering && event.zoomChanged) {
        this.togglingClustering = true;
        this.toggleClustering(this.ifNeedClustering(), this.ifNeedDistanceClustering());
        this.togglingClustering = false;
      }
    }, this);

    return layer;
  },
  ifNeedClustering() {
    const cls = this.model.getMeta().get('class');
    if (
      cls === 'sign' || cls === 'sign_project' || cls === 'semaforo' || cls === 'semaforo_project'
      || cls === 'sign_run' || cls === 'semaforo_run'
    ) {
      return true;
    } if (cls === 'trajectory_run') {
      return false;
    }
    return this.options.map.map.zoom < this.clusteringMaxZoom;
  },
  ifNeedDistanceClustering() {
    return this.options.map.map.zoom >= this.distanceClusteringMaxZoom;
  },
  toggleClustering(needClustering, by_distance, force) {
    if (!force && this.clusteringActive === needClustering && this.distanceClusteringActive === by_distance) return;

    this.clusteringActive = needClustering;
    this.distanceClusteringActive = by_distance;
    if (!needClustering) {
      this.toggleStrategy(this.strategyZoom, false);
      this.toggleStrategy(this.strategyDistance, false);
    } else {
      this.toggleStrategy(this.strategyZoom, !by_distance);
      this.toggleStrategy(this.strategyDistance, by_distance);
    }
    this.refreshFeatures();
  },
  toggleStrategy(strategy, on) {
    if (on) {
      strategy.activate();
    } else {
      strategy.deactivate();
    }
  },
  /**
   * Колбек вызываемый когда обновляются фичи или их id сет.
   *
   * Переопределяем его здесь, т.к. RTreeCluster не поддерживает
   * удаление добавление/удаление фич не целиком.
   */
  updateFeatures() {
    if (this.strategyZoom.active) {
      this.strategyZoom.clearCache();
      this.refreshFeatures();
    } else {
      Layer.prototype.updateFeatures.apply(this, arguments);
    }
  },
});

export default PointLayer;
