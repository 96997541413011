import OpenLayers from 'lib/OpenLayers-2.12/OpenLayers.debug';
import AIS from 'js/AIS';
import SelectFeature from 'js/view/map/SelectFeature';


export default OpenLayers.Class(SelectFeature, {
  initialize: function (layers, options) {
    /**
     * Без использования box Openlayers использует хендлер OpenLayers.Hanlder.Feature, в котором нет
     * никакой возможности передать дальше event клика по карте.
     */
    this.box = true;
    this.multiple = false;

    this.selectedFeatures = [];

    OpenLayers.Control.prototype.initialize.apply(this, [options]);

    if (this.scope === null) {
      this.scope = this;
    }

    this.initLayer(layers);

    this.handlers = {
      /* Для корректной работы с кластерами объектов добавляем хендлеры наведения на фичу-кластер. */
      feature: new OpenLayers.Handler.Feature(
        this, this.layer,
        {
          over: this.overFeature,
          out: this.outFeature,
        },
        { geometryTypes: this.geometryTypes },
      ),
      /*
          * Для выбора нескольких вариантов дефолтное решение в Openlayers - использование OpenLayers.Handler.Box,
          * который нам не подходит, так как так как в таком случае нет возможности двигать карту перетаскиванием.
          * Поэтому переопределяем используемый хендлер на OpenLayers.Handler.Click, мимикрируя под встроенную реализацию.
          * */
      box: new OpenLayers.Handler.Click(
        this, {
          click: this.clickMap,
        },
      ),
    };
  },

  deactivate: function () {
    this.unselectAll();
    return SelectFeature.prototype.deactivate.apply(this, arguments);
  },

  /** Получение всех фич в указанной точке.
   *
   * Является копипастой с небольшими правками метода getFeatures с евента featureclick с Openlayers 2.13.
   * https://github.com/openlayers/ol2/blob/e85a087c8fb857648aa7c5e4f771d1d2a13fc48b/lib/OpenLayers/Events/featureclick.js
   *
   * Вместо this.map.layers (как в родительском методе) используем this.layers
   *
   * @param  {Event} evt нажатие на карте
   * @returns {Array} Список фич в указанной точке
   */
  getFeatures: function (evt) {
    const features = [];
    const featuresElements = [];
    const watchedLayers = [];

    this.layers.forEach((layer) => {
      const { renderer } = layer;

      if (layer.div.style.display !== 'none') {
        if (layer instanceof OpenLayers.Layer.Vector) {
          let featureId;
          let element;
          // Присваиваем значения сразу в условии цикла, чтобы не писать дважды.
          while ( // eslint-disable-line no-cond-assign
            (element = document.elementFromPoint(evt.clientX, evt.clientY))
              && (featureId = renderer.getFeatureIdFromEvent({ target: element }))
          ) {
            const feature = layer.getFeatureById(featureId);

            if (!feature) break;

            if (!feature.cl) {
              features.push(feature);
            } else {
              // Для использования селектора вместе с кластерами, следует фичи из кластера добавлять отдельно.
              feature.cl.forEach(clusterFeature => features.push({ ...clusterFeature, layer: feature.layer }));
            }

            element.style.display = 'none';
            featuresElements.push(element);
          }
        }
        watchedLayers.push(layer);
        layer.div.style.display = 'none'; // eslint-disable-line no-param-reassign
      }
    });
    // Возвращаем скрытые ранее объекты на карту.
    featuresElements.forEach((target) => {
      target.style.display = ''; // eslint-disable-line no-param-reassign
    });
    watchedLayers.forEach((layer) => {
      layer.div.style.display = 'block'; // eslint-disable-line no-param-reassign
    });

    /* Приняли решение, что полигональные объекты в списке не указываем. Возвращаем верхний полигон, если полигонов несколько. */
    if (features.length > 1) {
      const withoutPolygons = features.filter(f => f.geometry.CLASS_NAME !== 'OpenLayers.Geometry.Polygon');
      if (withoutPolygons.length === 0) {
        return [features[0]];
      }

      return withoutPolygons;
    }
    return features;
  },


  /** Коллбек нажатия на карте.
   *
   * @param  {Event} event нажатие на карте
   */
  clickMap: function (event) {
    if (event.ctrlKey) {
      AIS.map.sendMapClickFromEvent(event);
    } else {
      this.unselectAll();

      this.selectedFeatures = this.getFeatures(event);
      this.selectedFeatures.forEach(feature => this.highlight(feature));

      if (this.selectedFeatures.length > 0) {
        this.onSelect(this.selectedFeatures, this.map.getLonLatFromPixel(event.xy));
      }
    }
  },

  /** Переопределенный метод родительского контрола SelectFeature.
   *
   * Данный метод вызывается при выборе одной из фич в кластере фич.
   *
   * @param {Object} feature - выбранная фича
   * @param {Object} lonlat - позиция начала кластера
   */
  clickFeature: function (feature, lonlat) {
    this.select(feature);
    this.onSelect(this.selectedFeatures, lonlat);
  },

  /** Выбираем только одну фичу.
   *
   * @param  {Feature} feature - выбранная фича
   */
  select: function (feature) {
    this.unselectAll();
    this.selectedFeatures = [feature];
    this.highlight(feature);
  },

  /** Снимаем для выделенных до этого фич выделение. */
  unselectAll: function () {
    this.selectedFeatures.forEach(feature => this.unhighlight(feature));
    this.selectedFeatures = [];
    this.onUnselect();
  },
});
