import $ from 'jquery';
import _ from 'underscore';
import Backbone from 'js/backbone';
import AIS from 'js/AIS';
import FilterWrapper from 'js/view/filter/FilterWrapper';


const ClassFilter = Backbone.View.extend({
  template: _.template($('#tmpl-class-filter').html()),
  events: {
    'change .layer-trigger': 'filterToggle',
    'click .expander': 'toggle',
    'click .close': 'close',
  },
  initialize: function () {
    const updateStatDebounce = _.debounce(this.updateStat, 300);
    this.collection = this.model.getCollection();

    this.collection.on('reset', updateStatDebounce, this);
    this.collection.on('change:summary', updateStatDebounce, this);
    this.collection.on('add', updateStatDebounce, this);
    this.collection.on('remove', updateStatDebounce, this);

    const me = this;
    $.when(this.collection.ready).done(() => {
      me.updateStat();
    });

    this.model.on('update', updateStatDebounce, this);
    const conditions = this.model.getFilterable();

    this._conditions = _.map(conditions, filterState => new FilterWrapper({
      model: filterState,
    }), this);

    /**
     * После изменения значения любого поля для фильтрации проверяем, что хотя бы одно поле не изменено.
     * Если условие выполняется, устанавливаем иконку.
     */
    this.model.addListener(() => {
      const isActive = this._conditions.some(wrapper => !wrapper.isEmpty());
      this.showFilterMark(isActive);
      this.model.set('filtered', isActive);
    });
  },
  /**
   * Установка иконки, что используется фильтр.
   * @param {boolean} active   следует ли отобразить иконку
   */
  showFilterMark(active) {
    if (this._markActive === active) return;
    if (active) {
      this.$el.find('.expander').append('<i class="icon-filter"></i>');
    } else {
      this.$el.find('.expander i').remove();
    }
    this._markActive = active;
  },
  getFiltered() {
    return this.model.get('filtered');
  },
  getVisible() {
    return this.model.get('visible');
  },
  setVisible(_visible) {
    if (this.isClassEdited()) {
      // вернуть чекбокс в исходное состояние, т.е. отменить нажатие
      this.$el.find('.layer-trigger').prop('checked', this.getVisible());
    } else {
      const visible = Boolean(_visible);
      this.model.set('visible', visible);
      this.$el.find('.layer-trigger').prop('checked', visible);
    }
  },
  /**
   * Проверить, открыт ли сейчас редактор с этим слоем.
   * @returns {boolean} True, если этот слой сейчас используется в редакторе, иначе False
   */
  isClassEdited() {
    return AIS.editorPanel.form != null && AIS.editorPanel.form.model.getClassName() === this.model.getClassName();
  },
  /**
   * Обновить свойство `disabled` у чекбокса для скрытия слоя.
   */
  updateDisabled() {
    this.$el.find('.layer-trigger').prop('disabled', this.isClassEdited());
  },
  filterToggle(event) {
    this.setVisible(event.target.checked);
  },
  toggle() {
    this.$el.find('.extra-panel').toggle();
    this.model.set('advanced', !this.model.get('advanced'));
  },
  aggregate() {
    const { model } = this;
    const filterables = this.model.getCalculationStates();
    let filterable;
    const sum = function (field) {
      return _.reduce(model.getFiltered(), (acc, model) => {
        const x = model.get(field);
        return (typeof x === 'number') ? x + acc : acc;
      }, 0).toFixed(1);
    };
    for (const i in filterables) {
      filterable = filterables[i];
      switch (filterable.get('function')) {
        case 'length':
          return `, ${sum(filterable.get('id')).toString()}м`;
        case 'area':
          return `, ${sum(filterable.get('id')).toString()}м<sup>2</sup>`;
        default:
      }
    }
    return '';
  },
  updateStat() {
    const count = this.model.getLength();
    this.$el.find('.stat').html(
      `(${count} из ${
        this.collection.getLength()}${this.aggregate()})`,
    );
  },
  close() {
    if (this.isClassEdited()) {
      // eslint-disable-next-line no-alert
      alert(`Невозможно удалить слой "${this.model.getMeta().get('objectName')}", потому что он открыт для редактирования.`);
    } else {
      this.collection.off();
      this.remove();
      this.model.destroy();
    }
  },
  render() {
    this.$el.html(this.template({
      id: this.model.getMeta().id,
      visible: this.model.get('visible'),
      objectName: this.model.getMeta().get('objectName'),
      count: this.model.getLength(),
      total: this.collection.getLength(),
      expanded: this.model.get('advanced'),
      aggregate: this.aggregate(),
    }));

    _.each(this._conditions, function (view) {
      this.$el.find('.filters').append(view.render().el);
    }, this);

    return this;
  },
});

export default ClassFilter;
