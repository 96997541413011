import * as actionTypes from 'js/constants/actionTypes';

/**
 * Создать экшн центрирования карты на указанных координатах.
 * @param {number} lon долгота
 * @param {number} lat широта
 * @param {number} zoom зум карты
 * @returns {{type: string, lon: number, lat: number, zoom: number}} экшн центрирования карты
 */
export const mapCenter = (lon, lat, zoom) => ({
  type: actionTypes.MAP_CENTER,
  lon,
  lat,
  zoom,
});

/**
 * Создать экшн показа ближайшей панорамы к указанным координатам.
 * @param {number} lon долгота
 * @param {number} lat широта
 * @returns {{type: string, lon: number, lat: number}} экшн показа панорамы
 */
export const mapShowPano = (lon, lat) => ({
  type: actionTypes.MAP_SHOW_PANO,
  lon,
  lat,
});

/**
 * Создать экшн показа попапа для заданной фичи в указанных координатах.
 * @param {number} featureId идентификатор фичи
 * @param {number} lon долгота
 * @param {number} lat широта
 * @returns {{type: string, featureId:number, lon: number, lat: number}} экшн показа попапа
 */
export const mapOpenPopupForId = (featureId, lon, lat) => ({
  type: actionTypes.MAP_OPEN_POPUP_FOR_ID,
  featureId,
  lon,
  lat,
});
