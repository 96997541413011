export default {
  '1.14.1': {
    icon: '/icon/roadmark/1_14_1.png',
    name: '1.14.1 (Пешеходный переход)',
  },
  '1.14.1y': {
    icon: '/icon/roadmark/1_14_1y.png',
    name: '1.14.1 (Пешеходный переход, бело-желтая)',
  },
  '1.14.2': {
    icon: '/icon/roadmark/1_14_2.png',
    name: '1.14.2 (Пешеходный переход)',
  },
  '1.14.2y': {
    icon: '/icon/roadmark/1_14_2y.png',
    name: '1.14.2 (Пешеходный переход, бело-желтая)',
  },
  '1.14.3': {
    icon: '/icon/roadmark/1_14_3.png',
    name: '1.14.3 (Границы пешеходного перехода)',
  },
  1.15: {
    icon: '/icon/roadmark/1_15.png',
    name: '1.15 (Переезд велосипедистов)',
  },
  '1.16.1': {
    icon: '/icon/roadmark/1_16_1.png',
    name: '1.16.1 (Островок: противоположные потоки)',
  },
  '1.16.2': {
    icon: '/icon/roadmark/1_16_2.png',
    name: '1.16.2 (Островок: сонаправленные потоки)',
  },
  '1.16.3': {
    icon: '/icon/roadmark/1_16_3.png',
    name: '1.16.3 (Островок: слияние потоков)',
  },
  '1.17.1': {
    icon: '/icon/roadmark/1_17_1.png',
    name: '1.17.1 (Остановка транспорта)',
  },
  '1.17.2': {
    icon: '/icon/roadmark/1_17_2.png',
    name: '1.17.2 (Границы зоны остановочного пункта поперек ПЧ)',
  },
  1.25: {
    icon: '/icon/roadmark/1_25.png',
    name: '1.25 ("Лежачий полицейский")',
  },
  1.26: {
    icon: '/icon/roadmark/1_26.png',
    name: '1.26 (Участок регулируемого перекрестка)',
  },
};
