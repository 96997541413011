import _ from 'underscore';
import OpenLayers from 'lib/OpenLayers-2.12/OpenLayers.debug';
import Backbone from 'js/backbone';
import AIS from 'js/AIS';

const GeoObject = {};
GeoObject.Generic = Backbone.Model.extend({
  dirty: false,
  cloned: false,
  _typedarrays: ['geojson', 'commentary', 'info'],
  defaultStyle: {},
  selectStyle: {},
  modifyStyle: {
    graphicZIndex: 998,
    pointRadius: 6,
    fillOpacity: 1,
    fillColor: 'black',
    strokeWidth: 0,
  },

  get(attr) {
    if (_.contains(this._typedarrays, attr) && typeof (this.attributes[attr]) !== 'string') {
      let result = '';
      const raw = this.attributes[attr];
      const chunkSize = 3072;
      if (!raw || raw.length <= chunkSize) {
        return String.fromCharCode.apply(null, raw);
      }
      for (let i = 0; i < raw.length; i += chunkSize) {
        const x = (i + chunkSize < raw.length) ? chunkSize : raw.length - i;
        const view = new Uint16Array(raw.buffer, i * 2, x);
        result += String.fromCharCode.apply(null, view);
      }
      return result;
    }
    return Backbone.Model.prototype.get.call(this, attr);
  },

  // TODO: set

  toJSON(options) {
    const attrs = Backbone.Model.prototype.toJSON.call(this, options);

    _.each(this._typedarrays, function (key) {
      if (_.has(attrs, key) && typeof (attrs[key]) !== 'string') {
        attrs[key] = this.get(key);
      }
    }, this);

    return attrs;
  },

  newFrom() {
    const n = this.clone();
    n.unset('id', { silent: true });
    return n;
  },
  getMeta() {
    return this._meta;
  },
  getFilter() {
    return AIS.workset.get(this._meta.id);
  },
  createGeometry() {
    return AIS.transformReaderGeoJSON.read(this.get('geojson'), 'Geometry');
  },
  toRow() {
    const hash = {
      id: this.id,
      class: this.getMeta().get('objectName'),
      self: this,
    };

    this.getMeta().getProperties().each(function (property) {
      hash[property.get('field')] = property.extractFrom(this);
    }, this);

    return hash;
  },
  setAttributes(feature) {
    if (feature.layer) {
      feature.layer.drawFeature(feature);
    }
  },
  /* Этот обработчик биндится в коллекции */
  onChange(model) {
    if (model.feature && model.feature.locked) {
      return;
    }

    if (model.feature) {
      model.setAttributes(model.feature);
    }

    if ('geojson' in model.changed && model.get('geojson')) {
      const geometry = model.createGeometry();
      model.updateGeometry(geometry);
    }
  },
  bindFeature(feature) {
    if (this.feature) {
      this.unbindFeature();
    }
    this.feature = feature;
    this.feature.model = this;
  },
  unbindFeature() {
    if (this.feature) {
      this.feature.model = null;
    }
    this.feature = null;
  },
  redraw() {
    const { layer } = this.feature;
    layer.drawFeature(this.feature);
  },
  updateGeometry(geometry) {
    if (!geometry) return;

    if (!this.feature) {
      const feature = this.createFeature();
      const filter = this.getFilter();
      const layer = filter.getLayer();
      const layerVisible = filter.get('visible');
      if (layerVisible) {
        layer.addFeatures(feature);
      }
    } else {
      this.setGeometry(geometry, this.feature);
      this.trigger('geom_update', this);
    }
  },
  getPanoStyle() {
    return null;
  },
  getClassName() {
    return this._meta.get('class');
  },
  getStyle() {
    return null;
  },
  formatCoordinatesArray(x) {
    return Math.abs(x[1]).toFixed(7)
                + (x[1] > 0 ? 'N ' : 'S ')
                + Math.abs(x[0]).toFixed(7)
                + (x[0] > 0 ? 'E ' : 'W ')
                + (x[2] ? (`${x[2].toFixed(2)} м.`) : '');
  },
  getAttachments() {
    let reply = [];

    if (this.get('attachments')) {
      try {
        reply = JSON.parse(this.get('attachments'));
        if (!(reply instanceof Array)) {
          throw new Error('Content of attachment column is not Array');
        }
      } catch (e) {
        console.error(e); // eslint-disable-line no-console
        reply = [];
      }
    }

    return reply;
  },
  getAttributes() {
    const hash = { geojson: this.get('geojson') };
    this.getMeta().getProperties().each(function (prop) {
      hash[prop.getName()] = this.get(prop.getName());
    }, this);
    return hash;
  },
  createFeature() {
    try {
      const geometry = this.createGeometry();
      const style = this.getStyle();
      this.feature = new OpenLayers.Feature.Vector(geometry, {}, style);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(
        'При получении геометрии объекта с id: %s произошла ошибка %s',
        this.attributes.id,
        error,
      );
      this.feature = {};
    }
    this.setAttributes(this.feature);
    this.bindFeature(this.feature);

    return this.feature;
  },
  destroyFeature() {
    if (!this.feature) {
      return;
    }
    const filter = this.getFilter();
    const layer = filter.getLayer();
    const { feature } = this;
    if (feature.layer) {
      layer.removeFeatures([feature]);
    }
    this.unbindFeature();
    feature.destroy();
  },
});

GeoObject.Point = GeoObject.Generic.extend({
  defaultStyle: {
    cursor: 'pointer',
    graphic: true,
    graphicZIndex: 13,
    graphicYOffset: -35,
    graphicHeight: 37,
    fill: true,
    fillColor: '#ffcc66',
    stroke: true,
    strokeColor: '#cc6633',
    strokeWidth: 2,
    strokeOpacity: 0.8,
  },
  selectStyle: {
    backgroundGraphic: '/icon/bg_sel.png',
    graphic: true,
    graphicZIndex: 33,
    backgroundGraphicZIndex: 23,
    backgroundWidth: 24,
    backgroundHeight: 24,
    stroke: true,
    strokeWidth: 4,
  },
  setGeometry(geometry, feature) {
    const { layer } = feature;
    const visible = feature.getVisibility();
    if (visible) {
      layer.removeFeatures(feature);
    }
    _.extend(feature.geometry, _.pick(geometry, 'x', 'y', 'alt'));
    feature.geometry.clearBounds();
    if (visible) {
      layer.addFeatures(feature);
    }
  },
  /**
    * Получить путь до иконки данной точки.
    * @returns {string} путь до иконки
    */
  getIcon() {
    const meta = this.getMeta();
    return meta ? meta.getIcon(this) : '/icon/other/unknown.png';
  },
  getStyle() {
    return this.getMeta().getIconStyle(this);
  },
  setAttributes(feature) {
    feature.style = this.getStyle(); // eslint-disable-line no-param-reassign
    GeoObject.Generic.prototype.setAttributes.apply(this, arguments);
  },
  popupLon() {
    return this.feature.geometry.x;
  },
  popupLat() {
    return this.feature.geometry.y;
  },
  // eslint-disable-next-line valid-jsdoc
  /** Стили, используемые для отображения фичи на панораме. */
  getPanoStyle() {
    return {
      url: this.getIcon(),
      edge: 'bottom',
      zoom: false,
      distorted: true,
    };
  },
});

GeoObject.Line = GeoObject.Generic.extend({
  defaultStyle: {
    graphic: true,
    graphicZIndex: 12,
    stroke: true,
    strokeColor: '#cccc33',
    strokeWidth: 5,
    fill: true,
    fillOpacity: 0.4,
    pointRadius: 8,
  },
  selectStyle: {
    graphic: true,
    graphicZIndex: 32,
  },
  setGeometry(geometry, feature) {
    const { layer } = feature;
    const visible = feature.getVisibility();
    if (visible) {
      layer.removeFeatures(feature);
    }
    feature.geometry.components = geometry.components; // eslint-disable-line no-param-reassign
    feature.geometry.clearBounds();
    if (visible) {
      layer.addFeatures(feature);
    }
  },
  // eslint-disable-next-line valid-jsdoc
  /** Стили, используемые для отображения фичи на панораме. */
  getPanoStyle() {
    const mapStyle = (this.getMeta() && this.getMeta().get('style')) || {};
    return {
      borderalpha: 1.0,
      alpha: 0.8,
      visible: false,
      fillalpha: 0.3,
      fillcolor: `0x${mapStyle.fillColor || 'cccc33'}`,
      bordercolor: `0x${mapStyle.strokeColor || 'cccc33'}`,
      borderwidth: mapStyle.strokeWidth || 3,
    };
  },
  popupLon() {
    const n = Math.ceil(this.feature.geometry.components.length / 2);
    return this.feature.geometry.components[n].x;
  },
  popupLat() {
    const n = Math.ceil(this.feature.geometry.components.length / 2);
    return this.feature.geometry.components[n].y;
  },
  setAttributes(feature) {
    _.extend(feature.attributes, this.toJSON(), {
      class: this.getClassName(),
    });// we need this for sld styleMap rule filtering
    GeoObject.Generic.prototype.setAttributes.apply(this, arguments);
  },
});

GeoObject.Polygon = GeoObject.Line.extend({
  defaultStyle: {
    graphic: true,
    graphicZIndex: 11,
    fill: true,
    fillOpacity: 0.4,
    stroke: true,
    strokeColor: '#cccc33',
    strokeWidth: 2,
    pointRadius: 4,
  },
  selectStyle: {
    graphic: true,
    graphicZIndex: 31,
  },
  popupLon() {
    const n = Math.ceil(this.feature.geometry.components[0].components.length / 2);
    return this.feature.geometry.components[0].components[n].x;
  },
  popupLat() {
    const n = Math.ceil(this.feature.geometry.components[0].components.length / 2);
    return this.feature.geometry.components[0].components[n].y;
  },
});

export default GeoObject;
