export default {
  1.1: {
    icon: '/icon/roadmark/1_1.png',
    name: '1.1 (Разделение полос, потоков)',
  },
  1.2: {
    icon: '/icon/roadmark/1_2_1.png',
    name: '1.2 (Край проезжей части)',
  },
  1.3: {
    icon: '/icon/roadmark/1_3.png',
    name: '1.3 (Разделение потоков)',
  },
  1.4: {
    icon: '/icon/roadmark/1_4.png',
    name: '1.4 (Остановка запрещена)',
  },
  1.5: {
    icon: '/icon/roadmark/1_5.png',
    name: '1.5 (Разделение полос, потоков)',
  },
  1.6: {
    icon: '/icon/roadmark/1_6.png',
    name: '1.6 (Приближение к сплошной)',
  },
  1.7: {
    icon: '/icon/roadmark/1_7.png',
    name: '1.7 (Разделение полос в пределах перекрестка)',
  },
  1.8: {
    icon: '/icon/roadmark/1_8.png',
    name: '1.8 (Отделение полосы разгона/торможения)',
  },
  1.9: {
    icon: '/icon/roadmark/1_9.png',
    name: '1.9 (Отделение полосы реверсивного движения)',
  },
  '1.10': {
    icon: '/icon/roadmark/1_10.png',
    name: '1.10 (Стоянка запрещена)',
  },
  1.11: {
    icon: '/icon/roadmark/1_11.png',
    name: '1.11 (Разделение потоков)',
  },
  1.12: {
    icon: '/icon/roadmark/1_12.png',
    name: '1.12 (Стоп-линия)',
  },
  1.13: {
    icon: '/icon/roadmark/1_13.png',
    name: '1.13 (Уступи дорогу)',
  },
  2.5: {
    icon: '/icon/roadmark/2_5.png',
    name: '2.5 (Дорожное ограждение)',
  },
  2.6: {
    icon: '/icon/roadmark/2_6.png',
    name: '2.6 (Дорожное ограждение)',
  },
  2.7: {
    icon: '/icon/roadmark/2_7.png',
    name: '2.7 (Бордюр)',
  },
};
