import $ from 'jquery';
import _ from 'underscore';
import Backbone from 'js/backbone';
import AIS from 'js/AIS';
import api from 'js/api';
import { layoutChangeTab } from 'js/actions/layoutActions';
import { mapCenter } from 'js/actions/mapActions';
import { TAB_MAP } from 'js/constants/tabs';


/**
 * Slick.Grid с информацией о дорогах из слоя `road`.
 */
const RoadsGrid = Backbone.View.extend({
  initialize: function () {
    this.render = this.render.bind(this);
    this.sort = this.sort.bind(this);
    this.nameFormatter = this.nameFormatter.bind(this);
    this.openMap = this.openMap.bind(this);

    this.grid_columns = [
      {
        name: 'Название', id: 'name', field: 'name', sortable: true, width: 350, formatter: this.nameFormatter,
      },
      {
        name: 'Реестровый номер', id: 'reestr', field: 'reestr', sortable: true, width: 120,
      },
      {
        name: 'Год ввода в экспл.', id: 'construct_year', field: 'construct_year', sortable: true,
      },
      {
        name: 'Категория', id: 'category', field: 'category', sortable: true,
      },
      {
        name: 'Идентификационный код', id: 'idcode', field: 'idcode', sortable: true, width: 150,
      },
      {
        name: 'Собственник', id: 'owner', field: 'owner', sortable: true,
      },
      {
        name: 'Балансовая стоимость', id: 'balance_cost', field: 'balance_cost', sortable: true, width: 150,
      },
    ];

    this.grid_options = {
      enableCellNavigation: true,
      enableColumnReorder: false,
      enableTextSelectionOnCells: true,
    };
    this.grid = new Slick.Grid('#roads-grid', [], this.grid_columns, this.grid_options);
    this.grid.onSort.subscribe(this.sort);
    this.grid.onClick.subscribe(this.openMap);

    AIS.on('layout:center:resize', function () {
      this.render();
    }, this);

    this.roads = [];
  },

  /**
   * Подписаться на обновления списка дорог.
   */
  subscribe() {
    AIS.roadsEvents.on('diff_update', this.onUpdate, this);
  },

  /**
   * Отписаться от обновлений списка дорог.
   */
  unsubscribe() {
    AIS.roadsEvents.off('diff_update', this.onUpdate, this);
  },

  /**
   * Удалить элемент.
   */
  remove() {
    this.unsubscribe();
    Backbone.View.prototype.remove.apply(this, arguments);
  },

  /**
   * Коллбек, вызываемый при обновлении фильтра дорог.
   *
   * Применяет к списку дорог пришедшее дифференциальное обновление.
   *
   * @param {Object} message - обновление с сервера
   */
  onUpdate(message) {
    const rows = message.payload;
    const removed = rows.filter(row => row.remove).map(row => row.feature_id);
    const updated = rows.filter(row => !row.remove).map(row => row.data);
    const updated_ids = updated.map(row => row.id);
    const updated_as_rows = updated.map(({ fields, id, lonlat }) => ({ ...fields, id, lonlat }));
    if (rows && rows.length) {
      this.roads = [
        ...this.roads.filter(
          ({ id }) => !updated_ids.includes(id)
                            && removed.includes(id),
        ),
        ...updated_as_rows,
      ];
    }
    this.render();
  },

  // eslint-disable-next-line valid-jsdoc
  /**
   * Сортирует таблицу по колонке.
   */
  sort(e, args) {
    const { field } = args.sortCol;
    const sortAsc = args.sortAsc ? 1 : -1;
    this.grid.getData().sort((a, b) => {
      const fielda = a[field] || '';
      const fieldb = b[field] || '';
      return fielda.localeCompare(fieldb) * sortAsc;
    });
    this.grid.invalidate();
  },

  // eslint-disable-next-line valid-jsdoc
  /**
   * Открывает карту центрируя по координатам группы с таким же
   * названием как и дорога, либо использует геокодинг если такой
   * группы нет.
   */
  openMap(e) {
    if ($(e.target).hasClass('open-map')) {
      const row = this.grid.getDataItem((this.grid.getCellFromEvent(e).row));
      const { lonlat } = row;

      (new Promise(((resolve, reject) => {
        if (lonlat !== null) {
          resolve(JSON.parse(lonlat).coordinates);
        } else {
          api.geocode(row.name).then(
            (lonlat) => {
              if (lonlat) {
                resolve([lonlat.lon, lonlat.lat]);
              } else {
                throw Error('Ничего не найдено');
              }
            },
          ).fail(() => {
            console.log('Geocoding error'); // eslint-disable-line no-console
            reject();
          });
        }
      }))).then((lonlat) => {
        AIS.store.dispatch(layoutChangeTab(TAB_MAP));
        AIS.store.dispatch(mapCenter(lonlat[0], lonlat[1]));
      });
    }
  },

  // eslint-disable-next-line valid-jsdoc
  /**
   * Форматирует название дороги в ссылку по которой потом можно будет
   * перейти на карту.
   */
  nameFormatter(row, cell, value) {
    return `<a class='open-map'>${value}</a>`;
  },

  /** Рендерит таблицу с дорогами. */
  render: _.debounce(function () {
    this.$el.find('#roads-grid').css('height', parseInt($('#main').height(), 10));

    if (this.grid) {
      this.grid.resizeCanvas();
    }

    this.grid.setData(this.roads);
    this.grid.invalidate();
  }, 100),
});

export default RoadsGrid;
