import $ from 'jquery';
import _ from 'underscore';
import OpenLayers from 'lib/OpenLayers-2.12/OpenLayers.debug';
import Backbone from 'js/backbone';
import AIS from 'js/AIS';
import PropertiesTable from 'js/view/PropertiesTable';
import CSVWriter from 'js/view/grid/CSVWriter';
import KMLWriter from 'js/view/grid/KMLWriter';
import { tryParseFloat } from 'js/utils';


const Grid = Backbone.View.extend({
  events: {
    'click #grid-save-csv': 'saveCSV',
    'click #grid-save-kml': 'saveKML',
  },

  initialize: function (options) {
    this.filterModel = options.filterModel;

    options.workset.on('add', function (filter) {
      const c = filter.getCollection();
      c.on('reset', this.updateGrid, this);
      c.on('add', this.updateGrid, this);
      c.on('remove', this.updateGrid, this);
      c.on('change', function () {
        this.updateGrid();
      }, this);

      filter.on('change:visible update', function () {
        this.updateGrid();
      }, this);

      this.render();
    }, this);

    options.workset.on('remove', this.render, this);
    this.$el.find('#list').css('height', this.$el.context.height - 88);

    options.workset.on('change:visible', function () {
      if (this.popupCell) {
        this.showPopover(this.popupCell.row);
      }
    }, this);

    AIS.on('layout:center:resize', function (height) {
      this.updateSize(height);
    }, this);
    AIS.on('select_model', function (model) {
      if (this.tabActive()) {
        const row = this.modelToRow(model);
        this.grid.setActiveCell(row);
        this.showPopover(row);
      }
    }, this);
  },
  tabActive() {
    return this.$el.hasClass('active');
  },
  updateGrid: _.debounce(function () {
    if (this.tabActive()) {
      this.destroyPopup(true);
      this.data = this.options.workset.compileData();

      this.grid.setData(this.data);
      const cc = this.columnsConfig();
      this.grid.setColumns(cc);

      this.grid.updateRowCount();
      this.grid.render();
      this.restorePopup();

      const sortOptions = this.grid.getSortColumns()[0];
      if (sortOptions) {
        const field = sortOptions.columnId;
        const order = sortOptions.sortAsc;
        AIS.grid.sortGrid(field, order);
      }
    }
  }, 100),
  /** Сохранить открытый список объектов как CSV.
   */
  saveCSV() {
    const csvWriter = new CSVWriter({
      columns: this.columnsConfig(),
      data: this.data,
      filename: 'ais_data.csv',
      appendWKT: true,
    });
    csvWriter.write();
  },
  /** Сохранить открытый список объектов как KML.
   */
  saveKML() {
    const kmlWriter = new KMLWriter(
      this.data,
      'ais_data.kml',
    );
    kmlWriter.write();
  },
  popupDirection(row) {
    // Если мы кликнули на строку, которая находится близко к нижнему краю экрана
    const viewPort = this.grid.getViewport();
    let dir;
    if (this.data.length > 10 && viewPort.bottom - row < 7) {
      dir = 'top';
    } else {
      dir = 'bottom';
    }
    return dir;
  },
  showPopover(row) {
    if (this.popupRow) {
      this.destroyPopup();
    }

    const model = this.data[row].self;
    this.popupModel = model;

    const node = this.grid.getCellNode(row, 1);
    this.popupRow = $(node);

    const dir = this.popupDirection(row);

    this.popupRow.attr({
      'data-html': true,
      'data-title': `<span>Объект ${model.id}</span>`
                      + '<button type="button" class="close close-popup-button">&times</button>',
      'data-placement': dir,
      'data-dismiss': true,
    });

    const html = $(`<div id="popover-${model.id}"><div class="balloon"></div></div>`);

    const props = new PropertiesTable({ model });
    html.find('.balloon').html(props.render().el);
    this.popupRow.popover({ content: html });
    this.popupRow.popover('show');

    const me = this;
    $('.close-popup-button').click(() => {
      me.destroyPopup();
    });
  },
  /** Сортировка таблицы.
   *
   * Сортирует данные и перерендеривает таблицу.
   * Каждое значение пытается превратить в флоат и сортировать как числа,
   * если не получается, то сортирует как строки с учетом локали.
   *
   * @param {string} field    столбец, по которому происходит сортировка
   * @param {boolean} order   флаг - прямой или обратный порядок
   */
  sortGrid(field, order) {
    this.data.sort((a, b) => {
      const parsedA = tryParseFloat(a[field]);
      const parsedB = tryParseFloat(b[field]);
      const anyString = typeof (parsedA) === 'string' || typeof (parsedB) === 'string';
      let result;
      if (anyString) {
        result = parsedA.toString().localeCompare(parsedB.toString());
      } else if (parsedA > parsedB) {
        result = 1;
      } else if (parsedA < parsedB) {
        result = -1;
      } else {
        result = 0;
      }
      return order ? result : -result;
    });

    this.grid.setData(this.data);
    this.grid.updateRowCount();
    this.grid.render();
  },
  render() {
    const me = this;
    const columnOptions = {
      enableCellNavigation: true,
      enableColumnReorder: false,
    };

    this.data = this.options.workset.compileData();

    if (this.grid instanceof Slick.Grid) {
      this.grid.destroy();
    }
    const grid = new Slick.Grid('#list', this.data, this.columnsConfig(), columnOptions);
    this.grid = grid;

    grid.onClick.subscribe((e) => {
      const cell = grid.getCellFromEvent(e);
      me.showPopover(cell.row);
      const model = me.data[cell.row].self;

      if (!AIS.featureEnabled('disable_pano') && model.getMeta().getGeometryType() !== 'empty') {
        const olCrd = new OpenLayers.LonLat(model.popupLon(), model.popupLat());
        olCrd.transform('EPSG:900913', 'EPSG:4326');
        AIS.trigger('map:click', olCrd);
      }
    });

    grid.onSort.subscribe((e, args) => { // args: sort information.
      const { field } = args.sortCol;
      me.sortGrid(field, args.sortAsc);
    });

    /** Коллбек на изменение размеров колонок. */
    grid.onColumnsResized.subscribe(() => {
      /*
      В коллбеке нет никакой информации об изменившихся колонках.
      Поэтому находим изменившуюся колонку сами. Сохраняем ее ширину в sessionStorage.
      */
      grid.getColumns().forEach((column) => {
        if (column.width !== column.previousWidth) {
          sessionStorage.setItem(`grid_width_${column.id}`, column.width);
        }
      });
    });

    this.updateSize($('#main').height());

    this.grid.render();
  },

  // eslint-disable-next-line valid-jsdoc
  /** Конфигруация колонок списочного вида.
   *
   * В случае, если списочный вид используется для одного класса, добавляем поля этого класса.
   * Если классов несколько - отображаем идентификатор и название класса.
   * При изменении ширины колонки сохраняем значение ширины в sessionStorage.
   */
  columnsConfig() {
    // eslint-disable-next-line valid-jsdoc
    /** Получение ширины текущего поля. По умолчанию 150. */
    const getFieldWidth = fieldId => parseInt(sessionStorage.getItem(`grid_width_${fieldId}`) || '150', 10);

    const base = [
      {
        id: 'id', name: 'ID', field: 'id', sortable: true, common: true, width: getFieldWidth('id'),
      },
      {
        id: 'class', name: 'Слой', field: 'class', sortable: true, common: true, width: getFieldWidth('class'),
      },
    ];

    const active = this.options.workset.where({ visible: true });

    if (active.length === 0) return [];

    if (active.length === 1) {
      const fields = active[0].getMeta().get('fields');
      const sortable_fields = fields.map(
        field => ({ ...field, sortable: true, width: getFieldWidth(field.id) }),
      );
      return [...base, ...sortable_fields];
    }
    return base;
  },
  updateSize(height) {
    this.$el.find('#list').css('height', parseInt(height, 10) - 46);
    if (this.grid) this.grid.resizeCanvas();
  },
  destroyPopup(hide) {
    if (!this.popupRow) {
      return;
    }
    this.popupRow.popover('destroy');
    this.popupRow = null;
    if (!hide) {
      this.popupModel = null;
    }
  },
  modelToRow(model) {
    let idx;
    for (let i = 0; i < this.data.length; i += 1) {
      if (this.data[i].id === model.id && this.data[i].self.getMeta().id === model.getMeta().id) {
        idx = i;
        break;
      }
    }
    return idx;
  },
  restorePopup() {
    if (!this.popupModel) {
      return;
    }
    const visible = this.options.workset.isVisible(this.popupModel);
    const row = this.modelToRow(this.popupModel);
    if (!visible || _.isUndefined(row)) {
      return;
    }
    this.showPopover(row);
  },
});

export default Grid;
